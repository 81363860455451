export class EnumRolesJob {
    public static rolesJobSelect: any = [
        { name: 'Admin', enum: 'ROLE_ADMIN' },
        { name: 'Support', enum: 'ROLE_BUSDEV' },
        { name: 'Company', enum: 'ROLE_COMPANY' },
        { name: 'Internal', enum: 'ROLE_INTERNAL_JOB' },
        { name: 'Freelance', enum: 'ROLE_FREELANCE_JOB' },
    ];

    public static findRolesJob(roles: string): any {
        return EnumRolesJob.rolesJobSelect.find((fin: any) => fin.enum === roles.trim().toUpperCase());
    }
}
