export class EnumCommutingType {
    public static commutingTypeSelect: any = [
        { name: 'Kantor', enum: 'office' },
        { name: 'Remote', enum: 'remote' },
        { name: 'Penempatan', enum: 'site' },
    ];

    public static commutingTypeDetail: any = [
        { name: 'Kantor', enum: 'comm_office' },
        { name: 'Kantor', enum: 'office' },
        { name: 'Remote', enum: 'comm_remote' },
        { name: 'Remote', enum: 'remote' },
        { name: 'Penempatan', enum: 'site' },
    ];

    public static generateCommuting(commuting: string, showOther?: boolean): string {
        const tempCommuting = EnumCommutingType.commutingTypeDetail.find((fin: any) => fin.enum === commuting.trim().toLowerCase());
        return (tempCommuting) ? tempCommuting.name : (showOther ? 'Other' : '-');
    }

    public static findCommuting(commuting: string, postMode?: boolean): any {
        if (postMode) {
            return EnumCommutingType.commutingTypeSelect.find((fin: any) => fin.enum === commuting.trim().toLowerCase());
        }
        return EnumCommutingType.commutingTypeDetail.find((fin: any) => fin.enum === commuting.trim().toLowerCase());
    }
}
