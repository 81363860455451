export class EnumStatusApplicant {
    public static statusApplicantSelect: any = [
        { name: 'New Applicant', enum: 'APPLIED' },
        { name: 'HR Interview', enum: 'HR_INTERVIEW' },
        { name: 'Assessment', enum: 'ASSESSMENT' },
        { name: 'Submitted', enum: 'SUBMITTED' },
        { name: 'Final Interview', enum: 'FINAL_INTERVIEW' },
        { name: 'Offering', enum: 'OFFERING' },
        { name: 'Onboarding', enum: 'ONBOARDING' },
        { name: 'Ditolak', enum: 'REJECTED' },
    ];

    public static generateApplicant(status: string): string {
        const tempPayment = EnumStatusApplicant.statusApplicantSelect.find((fin: any) => fin.enum === status.trim().toUpperCase());
        return (tempPayment) ? tempPayment.name : '-';
    }
}
