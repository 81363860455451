import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  // this will be replaced by actual hash post-build.js
  currentHash = '85EB24C9F7729C08CEC9A63F9CABBD19';

  constructor() { }

  public initVersionCheck(): void {
    this.checkVersion(localStorage.getItem('hash') ? localStorage.getItem('hash') + '' : '');
  }

  private checkVersion(hash: string): void {
    const hashChanged = this.hashChanged(this.currentHash, hash);
    setTimeout(() => {
      // If new version, do something
      if (hashChanged) {
        localStorage.setItem('hash', this.currentHash);
        // tslint:disable-next-line: deprecation
        window.location.reload();
      }
    }, 250);
  }

  hashChanged(currentHash: string, newHash: string): boolean {
    return (currentHash !== newHash);
  }
}
