import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable/*, of*/ } from 'rxjs';
import { retry, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ErrorJsonHandlingService } from './error-handling.service';
import { HttpConfigService } from './http-config.service';
import { WorkerProfileClass, WorkerProfileModel } from '../layouts/main/pages/manage-applicants';

import * as moment from 'moment';
moment.locale('id');

const API_PROFILE_URL = `${environment.apiUrl}`;

export interface WorkPref {
  dayPreff: string;
  timePreff: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isProfileIncompleted$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  isProfileIncompletedSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private config: HttpConfigService,
    private jsonHandling: ErrorJsonHandlingService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isProfileIncompletedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.isProfileIncompleted$ = this.isProfileIncompletedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Get elastic profile data by ID
  getProfile(name: string): Observable<any> {
    return this.http.get<any>(`/api/_search/worker-profiles?query=fullName:(*${name}*)`, this.config.httpOptionsDefault());
  }
  getProfileId(id: any): Observable<Response> {
    return this.http.get<Response>(`/api/_search/worker-profiles?page=0&query=user.id:(${id})&size=10000&sort=id,asc`,
      this.config.httpOptionsDefault()
    ).pipe(retry(1));
  }

  // Get single profile data by ID
  getWorkerProfileGOapi(id: number): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.http.get<any>(`${API_PROFILE_URL}/worker/${id}`)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // Put single profile data by ID
  putWorkerProfileGOapi(id: number, data: WorkerProfileModel): Observable<Response | any> {
    const tempData = WorkerProfileClass.formatWorkerProfile(data);

    const errorMessage = WorkerProfileClass.workerProfileValidation(tempData);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('WORKER PROFILE -> Put Worker Profile', errorMessage, tempData);
    }

    // Debugging Purpose
    // console.log(tempData);
    // return of(tempData);

    this.isSubmitedSubject.next(true);
    return this.http.put<any>(`${API_PROFILE_URL}/worker/${id}`, tempData)
      .pipe(
        finalize(() => {
          this.isSubmitedSubject.next(false);
          this.isProfileIncompletedSubject.next(false);
        })
      );
  }

  // Deactive single profile data by ID
  deactiveProfileGOapi(id: number, data: any): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.http.post<any>(`${API_PROFILE_URL}/account/deactivate/${id}`, data)
      .pipe(finalize(() => this.isSubmitedSubject.next(false)));
  }

  // Additional Method
  sortChild(data: any, index?: string): any {
    return (index) ?
      data.sort((a: any, b: any) => {
        const dateA: any = new Date(a.endDate);
        const dateB: any = new Date(b.endDate);
        return dateB - dateA;
      })[0][index] :
      data.sort((a: any, b: any) => {
        const dateA: any = new Date(a.endDate);
        const dateB: any = new Date(b.endDate);
        return dateB - dateA;
      });
  }
  generateDuration(data: any): string {
    const tempData = this.sortChild(data);
    let countDays = 0;
    tempData.forEach((element: any) => {
      countDays = countDays + moment(element.endDate).diff(moment(element.startDate), 'days');
    });
    return this.generateExacDuration(countDays);
  }
  generateExacDuration(duration: number): string {
    return (duration) ? (
      (duration / 30 > 12) ? Math.floor(((duration / 30) / 12)) + ' tahun' : Math.floor((duration / 30)) + ' bulan'
    ) : 'unknown';
  }
  generateDurationByOne(data: any): string {
    let countDays = 0;
    countDays = countDays + moment(data.endDate).diff(moment(data.startDate), 'days');
    return this.generateExacDuration(countDays);
  }
  generateAge(birthDate: string): string {
    if (moment(birthDate).format('YYYY-MM-DD') === '1970-01-01') {
      return 'unknown'; // J2G-2698
    }

    return (birthDate) ?
      moment(birthDate, 'YYYYMMDD')
        .fromNow()
        .replace(' tahun yang lalu', ' Tahun') :
      'unknown';
  }
  generateWorkTimePref(workTime: any): WorkPref {
    const workPref: WorkPref = {} as WorkPref;

    if (workTime.onMondays && workTime.onTuesdays && workTime.onWednesdays &&
      workTime.onThursdays && workTime.onFridays && workTime.onSaturdays &&
      workTime.onSundays) {
        workPref.dayPreff = 'everyday';
    } else if (workTime.onMondays && workTime.onTuesdays && workTime.onWednesdays &&
      workTime.onThursdays && workTime.onFridays && (
        !workTime.onSaturdays && !workTime.onSundays
      )) {
        workPref.dayPreff = 'workday';
    } else {
        workPref.dayPreff = 'weekday';
    }

    if (workTime.shiftWork02 && workTime.shiftWork03 && (
      !workTime.shiftWork01 && !workTime.shiftWork04 && !workTime.shiftWork05 && !workTime.shiftWorkAll
    )) {
        workPref.timePreff = 'normal';
    } else if (workTime.shiftWork04 && workTime.shiftWork05 && (
      !workTime.shiftWork01 && !workTime.shiftWork02 && !workTime.shiftWork03 && !workTime.shiftWorkAll
    )) {
        workPref.timePreff = 'night';
    } else {
        workPref.timePreff = 'custom';
    }

    return workPref;
  }
}
