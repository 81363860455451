import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';
import { environment } from 'src/environments/environment';

const API_APPLICANT_URL = `${environment.apiUrlAts}`;

@Injectable({
  providedIn: 'root'
})
export class ApplicantHTTPService {

  constructor(private http: HttpClient) {}

  getApplicant(filter: FilterModel): Observable<any> {
    const url = `${API_APPLICANT_URL}/applicant?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : 10}&sort=${filter.sort ? filter.sort : 'id'}${filter.asc ? ':asc' : ':desc'}`;
    return this.http.get<any>(url);
  }

  getApplicantById(id: number): Observable<any> {
    return this.http.get<any>(`${API_APPLICANT_URL}/applicant/${id}`);
  }

  // exportApplicantGOapi(companyId: number, query: string): Observable<Response> {
  //   return this.http.get<Response>(`${API_APPLICANT_URL}/company/${companyId}/applicant_report?metadata=true&${query}`);
  // }

  patchApplicantStatus(id: number, data: {applicationStatus: string}): Observable<Response> {
    return this.http.patch<Response>(`${API_APPLICANT_URL}/applicant/${id}`, data);
  }

  patchApplicantAssessment(id: number, data: {assessmentUrl: string}): Observable<Response> {
    return this.http.patch<Response>(`${API_APPLICANT_URL}/applicant/${id}`, data);
  }

  deleteApplicant(id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_APPLICANT_URL}/applicant/download/${id}`);
  }
}
