export class EnumCompanyVerified {
    public static companyVerifiedSelect: any = [
        { name: 'Verified', enum: true },
        { name: 'Unverified', enum: false },
    ];

    public static companyVerifiedFilter: any = [
        { name: 'Verified', enum: 'true' },
        { name: 'Unverified', enum: 'false' },
    ];

    public static findCompanyVerifiedString(isVerified: string): any {
        return EnumCompanyVerified.companyVerifiedFilter.find((fin: any) => fin.enum === isVerified.trim().toLowerCase());
    }
}
