export class EnumEmploymeeReligion {
    public static employeeReligionSelect: any = [
        { name: 'Islam', enum: 'Islam' },
        { name: 'Kristen', enum: 'Kristen' },
        { name: 'Katolik', enum: 'Katolik' },
        { name: 'Hindu', enum: 'Hindu' },
        { name: 'Buddha', enum: 'Buddha' },
        { name: 'Konghuchu', enum: 'Konghuchu' },
        { name: 'Lainnya', enum: 'Lainnya' },
    ];

    public static findReligion(religion: string): any {
        return EnumEmploymeeReligion.employeeReligionSelect.find((fin: any) => fin.enum === religion.trim());
    }
}
