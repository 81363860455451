export class EnumIndustry {
    public static industrySelect: any = [
        { name: 'SDM', enum: 'human_resources' },
        { name: 'Technology', enum: 'technology' },
        { name: 'FMCG', enum: 'fmcg', url: 'assets/media/png/fake-company/FnB.png' },
        { name: 'FnB', enum: 'food_and_beverages', url: 'assets/media/png/fake-company/FnB.png' },
        { name: 'Fashion', enum: 'fashion', url: 'assets/media/png/fake-company/Sales.png' },
        { name: 'Logistik', enum: 'logistic', url: 'assets/media/png/fake-company/HouseKeeping.png' },
        { name: 'Media', enum: 'media' },
        { name: 'Ritel', enum: 'retail', url: 'assets/media/png/fake-company/Sales.png' },
        { name: 'Finansial', enum: 'financial' },
        { name: 'Pendidikan', enum: 'education' },
        { name: 'Pelayanan / Pariwisata / Perhotelan', enum: 'hospitality', url: 'assets/media/png/fake-company/HouseKeeping.png' },
        { name: 'Peminyakan & Pertambangan', enum: 'mining' },
        { name: 'Telekomunikasi', enum: 'telco' },
        { name: 'Otomotif', enum: 'automotive', url: 'assets/media/png/fake-company/Transportation.png' },
        { name: 'IT', enum: 'it' },
        { name: 'Properti', enum: 'property', url: 'assets/media/png/fake-company/Sales.png' },
        { name: 'Hukum', enum: 'legal' },
        { name: 'Energi', enum: 'energy' },
        { name: 'E-Commerce', enum: 'e-commerce', url: 'assets/media/png/fake-company/Sales.png' },
        { name: 'Manufaktur', enum: 'manufacture' },
        { name: 'Farmasi', enum: 'pharmacy' },
        { name: 'Outsource', enum: 'outsource' },
        { name: 'Pendidikan Informal', enum: 'education_informal' },
        { name: 'Kontraktor', enum: 'contractor' },
        { name: 'Konsultan', enum: 'consultant' },
        { name: 'Perkebunan', enum: 'plantation' },
        { name: 'Jasa', enum: 'services', url: 'assets/media/png/fake-company/Service.png' },
        { name: 'Arsitektur', enum: 'architecture_and_engineering' },
        { name: 'Kimia', enum: 'chemical' },
        { name: 'Distribusi', enum: 'distribution', url: 'assets/media/png/fake-company/Transportation.png' },
        { name: 'Medis', enum: 'medical' },
        { name: 'Event', enum: 'event' },
        { name: 'Other', enum: 'other' },
    ];

    public static generateIndustry(industry: string) {
        let tempIndustry = EnumIndustry.industrySelect.find((fin: any) => fin.enum === industry.trim().toLowerCase());
        return (tempIndustry) ? tempIndustry.name : '-'
    }
    
    public static generateIndustryLogo(industry: string) {
        let tempIndustry = EnumIndustry.industrySelect.find((fin: any) => fin.enum === industry.trim().toLowerCase());
        return (tempIndustry && tempIndustry.url) ? tempIndustry.url : './assets/media/stock-600x600/img-13.jpg'
    }

    public static findIndustry(industry: string) {
        return EnumIndustry.industrySelect.find((fin: any) => fin.enum === industry.trim().toLowerCase());
    }

    //khusus gawe tester GG :`) sort data gak masuk enum lempar ae sak ono.e
    public static generateIndustryByEnum(industry: string) {
        let tempIndustry = EnumIndustry.industrySelect.find((fin: any) => fin.enum === industry.toLowerCase());
        return (tempIndustry) ? tempIndustry.enum.replace(/_/g,' ') : industry;
    }
}
