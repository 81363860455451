import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UploadWorkerDocsModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { EmployeeDocumentHTTPService } from './employee-http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeDocumentService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private employeeDocs: EmployeeDocumentHTTPService,
    private config: HttpConfigService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  uploadEmployeeDocs(userId: number, item: UploadWorkerDocsModel): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeDocs.uploadDocument(userId, item).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  updateEmployeeDocs(userId: number, id: number, item: UploadWorkerDocsModel): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeDocs.updateDocument(userId, id, item).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  deleteEmployeeDocs(userId: number, id: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeDocs.deleteDocumentById(userId, id).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
