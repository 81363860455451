export class EnumScale {
    public static scaleSelect: any = [
        { name: 'Kecil', enum: 'S' },
        { name: 'Menengah', enum: 'M' },
        { name: 'Besar', enum: 'L' },
    ];

    public static findScale(scale: string): any {
        return EnumScale.scaleSelect.find((fin: any) => fin.enum === scale.trim().toUpperCase());
    }
}

export class EnumScaleSignUp {
    public static scaleSignupSelect: any = [
        { name: 'Kecil (0-3 Karyawan)', enum: 's' },
        { name: 'Menengah (> 3 Karyawan)', enum: 'm' },
        { name: 'Besar (> 20 Karyawan)', enum: 'l' },
    ];

    public static generateScale(scale: string): string {
        const tempScale = EnumScaleSignUp.scaleSignupSelect.find((fin: any) => fin.enum === scale.trim());
        return (tempScale) ? tempScale.name : '-';
    }
}
