import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';

import { SpinnerComponent } from './spinner/spinner.component';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';


@NgModule({
  imports: [
      CommonModule,
      InlineSVGModule,
  ],
  declarations: [
      SpinnerComponent,
      CustomSnackbarComponent,
  ],
  exports: [
      SpinnerComponent,
      CustomSnackbarComponent,
  ],
})
export class SharedModule { }
