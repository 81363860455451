import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  DeleteShiftAssignmentModel,
  PostShiftAssignmentModel,
  PatchShiftAssignmentModel,
  DataPutAssignmentModel
} from '../..';

const API_COMPANY_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class ShiftAssignmentHTTPService {

  constructor(private http: HttpClient) { }

  // Holiday
  getCompanyHoliday(companyId: number): Observable<Response> {
    return this.http.get<any>(`${API_COMPANY_URL}/company/${companyId}/company_holiday`);
  }
  getNationalHoliday(): Observable<Response> {
    return this.http.get<any>(`${API_COMPANY_URL}/company/1/employee/1/national_holiday`);
  }
  // End of Holiday

  // Company Shift Assignment
  getShiftAssign(companyId: number, query: string, page: number, size: number): Observable<Response> {
    return this.http.get<any>(`${API_COMPANY_URL}/company/${companyId}/attendance/shift_assignment?${query ? query + '&' : ''}page=${page ? page : 0}&limit=${size ? size : 20}&metadata=true`);
  }

  postShiftAssign(companyId: number, data: PostShiftAssignmentModel[]): Observable<any> {
    return this.http.post<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance/shift_assignment`, {items: data});
  }

  putShiftAssign(companyId: number, shiftId: number, data: DataPutAssignmentModel): Observable<any> {
    return this.http.put<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance/shift_assignment/${shiftId}`, data);
  }

  patchShiftAssign(companyId: number, data: PatchShiftAssignmentModel): Observable<any> {
    return this.http.patch<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance/schedule_exchange`, data);
  }

  deleteSingleShiftAssign(companyId: number, userId: number, startDate: string): Observable<Response> {
    return this.http.delete<Response>(`${API_COMPANY_URL}/company/${companyId}/employee/${userId}/shift_assignment/${startDate}`);
  }

  deleteShiftAssign(companyId: number, data: DeleteShiftAssignmentModel[]): Observable<Response> {
    return this.http.post<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance/shift_assignment/delete`, {items: data});
  }
  // End of Company Shift Assignment
}
