export class EnumJobCategory {
    // * "jangan diutek" sudah disesuaikan dengan enum department
    public static jobCategorySelect: any = [
        { name: 'Human Resource', enum: 'human_resources' },
        { name: 'Technology', enum: 'technology' },
        { name: 'Operation', enum: 'operation' },
        { name: 'Accounting', enum: 'accounting' },
        { name: 'Finance', enum: 'finance' },
        { name: 'General Affair', enum: 'general_affair' },
        { name: 'Marketing', enum: 'marketing' },
        { name: 'Sales', enum: 'sales' },
        { name: 'Commercial', enum: 'commercial' },
        { name: 'Customer Service', enum: 'customer_service' },
        { name: 'Collection', enum: 'collection' },
        { name: 'Product', enum: 'product' },
        { name: 'Recruitment', enum: 'recruitment' },
        { name: 'Purchasing', enum: 'purchasing' },
        { name: 'RnD', enum: 'rnd' },
        { name: 'General Management', enum: 'general_management' },
        { name: 'Other', enum: 'other' }
    ];

    // Departemen
    public static generateJobCategory(category: string, showOther?: boolean): string {
        // ? to Lower Case dihilangin soalnya ada enum operation_HR_&_GA
        const tempCategory = EnumJobCategory.jobCategorySelect.find((fin: any) => fin.enum === category.trim());
        return (tempCategory) ? tempCategory.name : (showOther ? 'Other' : '-');
    }

    public static findJobCategory(category: string): any {
        // ? to Lower Case dihilangin soalnya ada enum operation_HR_&_GA
        return EnumJobCategory.jobCategorySelect.find((fin: any) => fin.enum === category.trim());
    }

    public static findJobCategoryByName(name: string): any {
        return EnumJobCategory.jobCategorySelect.find((fin: any) => fin.name === name);
    }
}
