export class EnumProjectStatus {
    public static projectStatusSelect: any = [
        { name: 'Aktif', enum: 'active' },
        { name: 'Tidak Aktif', enum: 'inactive' },
    ];

    public static findProjectStatus(status: string): any {
        return EnumProjectStatus.projectStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
    }
}
