import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { FilterModel } from 'src/app/_class';

const API_COMPANY_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  getCompanyGOApi(filter: FilterModel): Observable<any>{
    const url = `${API_COMPANY_URL}/company?${filter.query}metadata=true&page=${filter.page}&limit=${filter.size ? filter.size : 20}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}`;
    return this.http.get<any>(url, this.config.GHttpOptionsXProvider());
  }
  getCompanyByIdGOApi(id: number): Observable<any> {
    const url = `${API_COMPANY_URL}/company/${id}`;
    return this.http.get<Response>(url, this.config.GHttpOptionsXProvider());
  }
  getCompanyByNameGOApi(name: string, size?: number, approved?: boolean): Observable<Response> {
    const url = `${API_COMPANY_URL}/company?page=0&name=${name}${approved ? '&status=approved' : ''}&limit=${(size) ? size : 1}`;
    return this.http.get<Response>(url, this.config.GHttpOptionsXProvider());
  }
  putCompanyGOApi(id: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_COMPANY_URL}/company/${id}`, data);
  }
  patchCompanyGOApi(id: number, data: {status: string, reason?: string}): Observable<Response> {
    return this.http.patch<Response>(`${API_COMPANY_URL}/company/${id}`, data);
  }
  verifiedCompanyGOApi(id: number, data: {verified: boolean}): Observable<Response> {
    return this.http.patch<Response>(`${API_COMPANY_URL}/company/${id}/verified`, data);
  }
  putCompanyModulesGOApi(id: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_COMPANY_URL}/company/${id}/modules`, data);
  }
}
