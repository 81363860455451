import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { concatMap, finalize, map, switchMap } from 'rxjs/operators';

import { FilterModel, GlobalHelper } from 'src/app/_class';
import { ErrorJsonHandlingService, HttpConfigService } from 'src/app/_service';
import { ResponseCompany, ClientDataModel, PostCompanyClass, PostCompanyModel } from '..';
import { ClientHTTPService, ManageJobHTTPService } from './ats-http';

import * as moment from 'moment';
moment.locale('id');

export type ResponseType = ResponseCompany | undefined;

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  // public fields
  currentCompany$: Observable<ResponseType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isCompanyProfileIncompleted$: Observable<boolean>;
  currentCompanySubject: BehaviorSubject<ResponseType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  isCompanyProfileIncompletedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private jsonHandling: ErrorJsonHandlingService,
    private clientHttp: ClientHTTPService,
    private manageJobHttp: ManageJobHTTPService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentCompanySubject = new BehaviorSubject<ResponseType>(undefined);
    this.isCompanyProfileIncompletedSubject = new BehaviorSubject<boolean>(false);
    this.currentCompany$ = this.currentCompanySubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.isCompanyProfileIncompleted$ = this.isCompanyProfileIncompletedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  clientRegister(payloadCompany: PostCompanyModel): Observable<any> {
    const errorMessage = PostCompanyClass.companyInfoValidation(payloadCompany);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('SIGNUP -> Company Registration by Admin', errorMessage, payloadCompany);
    }

    this.isLoadingSubject.next(true);
    return this.clientHttp.clientRegister(payloadCompany).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getClient(filter: FilterModel): Observable<ResponseType> {
    this.isLoadingSubject.next(true);
    return this.clientHttp.getAtsClient(filter).pipe(
      map((company: ResponseType) => {
        // checking company
        if (company) {
          this.currentCompanySubject.next(company);
        }
        return company;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getClientAutoComplete(query?: string): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.clientHttp.getAtsClient(GlobalHelper.generateParams(query ? query : '', 1)).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getClientAutoCompleteById(id: number): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.clientHttp.getAtsClientById(id).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getClientAutoCompleteByName(companyId: number, name: string, size?: number, skipLoading?: boolean): Observable<Response> {
    if (!skipLoading) {
      this.isLoadingSubject.next(true);
    }
    return this.clientHttp.getAtsClientByName(companyId, name, size).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  patchClient(companyId: number, params: { status: string, reason?: string }, filter?: FilterModel): Observable<ResponseType | any> {
    this.isSubmitedSubject.next(true);
    if (filter) {
      return this.clientHttp.patchAtsClient(companyId, params).pipe(
        switchMap(() => this.getClient(filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.clientHttp.patchAtsClient(companyId, params).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  putClient(clientId: number, data: ClientDataModel): Observable<Response> {
    const rawData = PostCompanyClass.formatCompany(data);

    const errorMessage = PostCompanyClass.companyInfoValidation(rawData, true);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('COMPANY -> Put Company Data', errorMessage, rawData);
    }

    this.isSubmitedSubject.next(true);
    return this.clientHttp.putAtsClient(clientId, rawData).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  deleteClient(companyId: number, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.clientHttp.deleteAtsClient(companyId).pipe(
      switchMap(() => this.getClient(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  postAssignClient(jobID: number, data: ClientDataModel[]): Observable<any> {
    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      concatMap((el) => {
        let job = {
          clientID: el.id,
          jobID
        };
        return this.manageJobHttp.assignJob(job);
      }),
      finalize(() => this.isSubmitedSubject.next(false))
    )
  }
  // End of Logic Before Hit API
}
