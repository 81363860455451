import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

import {GlobalConstants, GlobalHelper, SessionStorage} from 'src/app/_class';
import {TagsService} from 'src/app/_service';
import {EnumCity, EventFilterJobModel, IndexJobsVariables, JobsButtonDisabled, JobsModel, ManageJobService} from '..';

import * as moment from 'moment';
moment.locale('id');

@Injectable()
export class JobClass {

  jobReady$: Observable<boolean>;
  // currentJob$: Observable<JobsModel | undefined>;
  currentJobList$: Observable<JobsModel[] | undefined>;
  jobReadySubject: BehaviorSubject<boolean>;
  // currentJobSubject: BehaviorSubject<JobsModel | undefined>;
  currentJobListSubject: BehaviorSubject<JobsModel[] | undefined>;

  constructor(
    private jobService: ManageJobService,
    private tagsService: TagsService
  ) {
    this.jobReadySubject = new BehaviorSubject<boolean>(false);
    // this.currentJobSubject = new BehaviorSubject<JobsModel | undefined>(undefined);
    this.currentJobListSubject = new BehaviorSubject<JobsModel[] | undefined>(undefined);
    this.jobReady$ = this.jobReadySubject.asObservable();
    // this.currentJob$ = this.currentJobSubject.asObservable();
    this.currentJobList$ = this.currentJobListSubject.asObservable();
  }

  // Variables Initial
  initVariable(isAdmin: boolean, isFreelance: boolean, isJobfair: boolean): IndexJobsVariables {
    const varModel = {} as IndexJobsVariables;

    const Session = SessionStorage.getStorageJson('jobsTags');
    const tempFreeText = this.tagsService.filterSession(Session, 'freetext');
    const tempCity = this.tagsService.filterSession(Session, 'city');
    const tempJob = this.tagsService.filterSession(Session, 'job');
    const tempStatus = this.tagsService.filterSession(Session, 'job_status');
    // const tempRoles = this.tagsService.filterSession(Session, 'roles');
    const tempDate = this.tagsService.filterSession(Session, 'date');
    const tempcreatedDate = this.tagsService.filterSession(Session, 'createdDate');
    const tempPayment = this.tagsService.filterSession(Session, 'payment');
    const tempEmployment = this.tagsService.filterSession(Session, 'job_employment');
    const tempCommuting = this.tagsService.filterSession(Session, 'commuting');
    // const tempScrapping = this.tagsService.filterSession(Session, 'scrapping');

    varModel.tempQuery = SessionStorage.getStorage('jobsQuery');
    varModel.companyPick = (isAdmin || isFreelance) ?
      SessionStorage.getStorage('jobsCompanySelected') : (
        isJobfair ?
          SessionStorage.getStorageJson('companyStorage')?.companySelected :
          GlobalConstants.companyId
      );
    // varModel.arrFreeText = (tempFreeText.length) ? tempFreeText[0].value : [];
    varModel.freeText = (tempFreeText.length) ? tempFreeText[0].value : null;
    varModel.jobPick = (tempJob.length) ? tempJob[0].value : null;
    varModel.cityPick = (tempCity.length) ? tempCity[0].value : null;
    // varModel.categoryPick = (tempDepartment.length) ? tempDepartment[0].value : null); J2G-1525 - Become Free Tet
    varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    // varModel.rolesPick = (tempRoles.length) ? tempRoles[0].value : null;
    varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    varModel.createdDatePickBegin = (tempcreatedDate.length) ? tempcreatedDate[0].value.begin : null;
    varModel.createdDatePickEnd = (tempcreatedDate.length) ? tempcreatedDate[0].value.end : null;
    varModel.paymentPick = (tempPayment.length) ? tempPayment[0].value : null;
    varModel.employmentPick = (tempEmployment.length) ? tempEmployment[0].value : null;
    varModel.commutingPick = (tempCommuting.length) ? tempCommuting[0].value : null;
    // varModel.scrappingPick = (tempScrapping.length) ? tempScrapping[0].value : null;
    varModel.page = SessionStorage.getStorageNumber('jobsPageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('jobsPageSize', 10);
    varModel.sortBy = SessionStorage.getStorage('jobsSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('jobsSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    // varModel.scrapping = (varModel.scrappingPick && varModel.scrappingPick === 'Scrapping') ? true : false;

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(
    companyPick: string,
    freeText: FormControl,
    // arrFreeText: FormControl,
    cityPick: FormControl,
    statusPick: FormControl,
    // rolesPick: FormControl,
    // jobPick: FormControl,
    datePick: FormGroup,
    createdDatePick: FormGroup,
    paymentPick: FormControl,
    employmentPick: FormControl,
    commutingPick: FormControl,
    // scrappingPick: FormControl,
    tags: {key: string, value: string}[]
  ): string {
    // if (freeText.value || arrFreeText.value.length) {
    //   let tempFreeText = freeText.value ? freeText.value.split(',') : [];
    //   let tempArrFreeText = arrFreeText.value;
    //   if (tempFreeText.length) tempFreeText.forEach((element: any) => { tempArrFreeText.push(element); });
    //   arrFreeText.setValue(tempArrFreeText);
    //   tags = this.tagsService.updateKey(tags, 'freetext', arrFreeText, 'array');
    //   freeText.reset();
    // }
    if (freeText.value) {
      tags = this.tagsService.updateKey(tags, 'freetext', freeText);
    }
    if (cityPick.value) {
      tags = this.tagsService.updateKey(tags, 'city', cityPick, 'array');
    }
    if (statusPick.value) {
      tags = this.tagsService.updateKey(tags, 'job_status', statusPick);
    }
    // if (rolesPick.value) {tags = this.tagsService.updateKey(tags, 'roles', rolesPick);}
    if (datePick.value) {
      tags = this.tagsService.updateKey(tags, 'date', datePick, 'date');
    }
    if (createdDatePick.value) {
      tags = this.tagsService.updateKey(tags, 'createdDate', createdDatePick, 'date');
    }
    if (paymentPick.value) {
      tags = this.tagsService.updateKey(tags, 'payment', paymentPick);
    }
    if (employmentPick.value) {
      tags = this.tagsService.updateKey(tags, 'job_employment', employmentPick);
    }
    if (commutingPick.value) {
      tags = this.tagsService.updateKey(tags, 'commuting', commutingPick);
    }
    // if (scrappingPick.value) {tags = this.tagsService.updateKey(tags, 'scrapping', scrappingPick);}

    // if (categoryPick.value) { J2G-1525 - Become Free Text
    //   remove({key: 'department'});
    //   tags.push({key: 'department', value: categoryPick.value});
    // }

    // const freetext = arrFreeText.value.length ? `${arrFreeText.value.join(',')}` : null;
    const freetext = freeText.value ? `title=${freeText.value.replace('&', '%26')}` : null;
    const company = companyPick ? `company_id=${companyPick}` : null;
    // const departmentPick = categoryPick.value ? `categoryType:(${categoryPick.value})` : null; J2G-1525 - Become Free Text
    const status = statusPick.value ? `status=${statusPick.value}` : null; // J2G-1819 - Menampilkan Job Expired untuk user Corporate
    // const roles = rolesPick.value ? `roles.name=${rolesPick.value}` : null; // J2G-1860 - Filter Job By Role
    // const job = jobPick.value ? `title=${jobPick.value}` : null;
    const city = cityPick.value ? `area=${cityPick.value.join(',')}` : null;
    const date = datePick.value.end ?
      `start_date=${moment(datePick.value.begin).format('YYYY-MM-DD') + 'T00:00:00Z'}&end_date=${moment(datePick.value.end).format('YYYY-MM-DD') + 'T23:59:59Z'}` :
      null;
    const createdDate = createdDatePick.value.end ?
      `create_date_min=${moment(createdDatePick.value.begin).format('YYYY-MM-DD') + 'T00:00:00Z'}&create_date_max=${moment(createdDatePick.value.end).format('YYYY-MM-DD') + 'T23:59:59Z'}` :
      null;
    const payment = paymentPick.value ? `payment_type=${paymentPick.value}` : null;
    const employment = employmentPick.value ? `employment_type=${employmentPick.value}` : null;
    const commuting = commutingPick.value ? `commuting_type=${commutingPick.value}` : null;
    // const scrapping = !scrappingPick.value ? null : (
    //   (scrappingPick.value === 'Scrapping') ? `externalUrl=http*` : `!externalUrl=http*`
    // );

    // console.log(scrapping);

    const data = [
      freetext, company, status/*, roles/* , job */, city, date, createdDate, payment, employment, commuting/* , scrapping */
    ];

    // console.log(company);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('jobsQuery', tempQuery);
    sessionStorage.setItem('jobsTags', JSON.stringify(tags));
    return tempQuery;
  }

  eventFilter(
    tags: {key: string, value: string}[],
    event?: any,
    resetFilter?: boolean,
    autoType?: string
  ): EventFilterJobModel {
    const eventModel: EventFilterJobModel = {} as EventFilterJobModel;
    const tempJobControl = new FormControl();

    if (event.id) {
      if (autoType === 'company') {
        eventModel.companyPick = event.id;
        sessionStorage.setItem('jobsCompanySelected', eventModel.companyPick);
      }
      if (autoType === 'job') {
        eventModel.jobPick = event.title;
        tempJobControl.setValue(event.title);
        // tags = this.tagsService.updateKey(tags, 'job', tempJobControl);
      }
    } else { // Reset Filter
      if (!resetFilter && autoType !== 'job') { // Reset Company Filter
        sessionStorage.removeItem('jobsCompanySelected');
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      sessionStorage.removeItem('jobsTags');
      // sessionStorage.removeItem('jobsScrapping');
    }
    return eventModel;
  }

  removeTags(
    tags: {key: string, value: string}[],
    // arrFreeText: FormControl,
    // jobPick: FormControl,
    freeText: FormControl,
    cityPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
      // if (tag.key === 'freetext') tags = this.tagsService.removeKey(tags, tag.key, arrFreeText, 'array', indexTagValue);
      // if (tag.key === 'department') categoryPick.setValue(null); J2G-1525 - Become Free Text
      if (tag.key === 'job') {
        tags = this.tagsService.removeKey(tags, tag.key, freeText);
      } else if (tag.key === 'city') {
        tags = this.tagsService.removeKey(tags, tag.key, cityPick, 'array', indexTagValue);
      } else {
        tags = this.tagsService.removeKey(tags, tag.key); // by default yang bukan array dan tanpa custom logic disini
      }

      sessionStorage.setItem('jobsTags', JSON.stringify(tags));
  }
  // End Filter Logic

  getJobCombo(query?: string): void {
    this.jobReadySubject.next(false);
    
    this.jobService.getJobsAutoComplete(query ? query : '', 20)
      .subscribe((arg: any) => {
        const listData = arg.data ? arg.data : [];
        this.currentJobListSubject.next(listData);
        this.jobReadySubject.next(true);
      }, (error: any) => {
        this.jobReadySubject.next(true);
        this.jobService.openSnackbar(error.status, error);
      });
  }

  // Disable Button
  disabledButton(status: string, isAdmin: boolean): JobsButtonDisabled {
    const jobButtonModel: JobsButtonDisabled = {} as JobsButtonDisabled;
    if (!status) {
      jobButtonModel.draft = true;
      jobButtonModel.publish = true;
      jobButtonModel.reject = true;
      jobButtonModel.delete = true;
      jobButtonModel.backToSales = true;
    }
    if (status === 'DRAFT') {
      jobButtonModel.draft = true;
      jobButtonModel.publish = false;
      jobButtonModel.reject = false;
      jobButtonModel.delete = true;
      jobButtonModel.backToSales = false;
    }
    if (status === 'PUBLISHED') {
      jobButtonModel.draft = !isAdmin;
      jobButtonModel.publish = true;
      jobButtonModel.reject = true;
      jobButtonModel.delete = !isAdmin;
      jobButtonModel.backToSales = false;
    }
    if (status === 'REJECTED') {
      jobButtonModel.draft = !isAdmin;
      jobButtonModel.publish = true;
      jobButtonModel.reject = true;
      jobButtonModel.delete = true;
      jobButtonModel.backToSales = false;
    }
    if (status === 'SOFT_DELETED') {
      jobButtonModel.draft = true;
      jobButtonModel.publish = true;
      jobButtonModel.reject = true;
      jobButtonModel.delete = true;
      jobButtonModel.backToSales = false;
    }
    if (status === 'BACK_TO_SALES') {
      jobButtonModel.draft = !isAdmin;
      jobButtonModel.publish = false;
      jobButtonModel.reject = false;
      jobButtonModel.delete = false;
      jobButtonModel.backToSales = true;
    }
    return jobButtonModel;
  }
  // End of Disable Button


  // Additional Method
  uniqueJobs(data: JobsModel[]): JobsModel[] {
    const uniqueData: JobsModel[] = [];
    data.forEach((element: JobsModel) => {
      const index = uniqueData.findIndex((ind: any) => ind.title === element.title);
      if (index === -1) {
        uniqueData.push(element);
      }
    });
    return uniqueData;
  }
  cityChanged(cityPick: FormControl, value: string): boolean {
    const tempCity = EnumCity.citySelect.find((city: any) => city.name === value);
    if (tempCity) {
      const arrCity = cityPick.value ? cityPick.value : [];
      const index = arrCity.findIndex((ind: any) => ind === value);
      if (index === -1) {
        arrCity.push(tempCity.enum);
        cityPick.setValue(arrCity);
        return true;
      } else {
        this.jobService.customSnackbar(417, 'Kota sudah dipilih!');
      }
    }
    return false;
  }
  // End of Additional Method
}
