import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import {GlobalConstants, SessionStorage} from 'src/app/_class';
import {TagsService} from 'src/app/_service';
import {EnumCity, EventFilterJobModel, IndexJobsVariables, JobsButtonDisabled, JobsModel, ManageJobService} from '..';

import * as moment from 'moment';
moment.locale('id');

@Injectable()
export class JobClass {

  jobReady$: Observable<boolean>;
  // currentJob$: Observable<JobsModel | undefined>;
  currentJobList$: Observable<JobsModel[] | undefined>;
  jobReadySubject: BehaviorSubject<boolean>;
  // currentJobSubject: BehaviorSubject<JobsModel | undefined>;
  currentJobListSubject: BehaviorSubject<JobsModel[] | undefined>;

  constructor(
    private jobService: ManageJobService,
    private tagsService: TagsService
  ) {
    this.jobReadySubject = new BehaviorSubject<boolean>(false);
    // this.currentJobSubject = new BehaviorSubject<JobsModel | undefined>(undefined);
    this.currentJobListSubject = new BehaviorSubject<JobsModel[] | undefined>(undefined);
    this.jobReady$ = this.jobReadySubject.asObservable();
    // this.currentJob$ = this.currentJobSubject.asObservable();
    this.currentJobList$ = this.currentJobListSubject.asObservable();

  }

  // Variables Initial
  initVariable(isAdmin: boolean, companyId?: number, clientId?: number): IndexJobsVariables {
    const varModel = {} as IndexJobsVariables;

    const Session = SessionStorage.getStorageJson('atsJobsTags');
    const tempFreeText = this.tagsService.filterSession(Session, 'freetext');
    const tempCity = this.tagsService.filterSession(Session, 'city');
    const tempIndustry = this.tagsService.filterSession(Session, 'industry');
    // const tempJob = this.tagsService.filterSession(Session, 'job');
    const tempStatus = this.tagsService.filterSession(Session, 'client_job_status');
    // const tempRoles = this.tagsService.filterSession(Session, 'roles');
    // const tempDate = this.tagsService.filterSession(Session, 'date');
    // const tempcreatedDate = this.tagsService.filterSession(Session, 'createdDate');
    // const tempPayment = this.tagsService.filterSession(Session, 'payment');
    // const tempEmployment = this.tagsService.filterSession(Session, 'job_employment');
    // const tempCommuting = this.tagsService.filterSession(Session, 'commuting');
    // const tempScrapping = this.tagsService.filterSession(Session, 'scrapping');

    varModel.tempQuery = SessionStorage.getStorage('atsJobsQuery');
    varModel.companyPick = (isAdmin) ? (
        SessionStorage.getStorage('atsJobsCompanySelected') ?
          SessionStorage.getStorage('atsJobsCompanySelected') : (
            companyId ? companyId : GlobalConstants.companyId
          )
      ) : (
        companyId ? companyId : GlobalConstants.companyId
      );
    varModel.clientPick = clientId ? clientId : SessionStorage.getStorage('atsJobsClientSelected');
    // varModel.arrFreeText = (tempFreeText.length) ? tempFreeText[0].value : [];
    varModel.freeText = (tempFreeText.length) ? tempFreeText[0].value : null;
    // varModel.jobPick = (tempJob.length) ? tempJob[0].value : null;
    varModel.industryPick = (tempIndustry.length) ? tempIndustry[0].value : null;
    varModel.cityPick = (tempCity.length) ? tempCity[0].value : null;
    varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    // varModel.rolesPick = (tempRoles.length) ? tempRoles[0].value : null;
    // varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    // varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    // varModel.createdDatePickBegin = (tempcreatedDate.length) ? tempcreatedDate[0].value.begin : null;
    // varModel.createdDatePickEnd = (tempcreatedDate.length) ? tempcreatedDate[0].value.end : null;
    // varModel.paymentPick = (tempPayment.length) ? tempPayment[0].value : null;
    // varModel.employmentPick = (tempEmployment.length) ? tempEmployment[0].value : null;
    // varModel.commutingPick = (tempCommuting.length) ? tempCommuting[0].value : null;
    varModel.page = SessionStorage.getStorageNumber('atsJobsPageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('atsJobsPageSize', 10);
    varModel.sortBy = SessionStorage.getStorage('atsJobsSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('atsJobsSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    // varModel.scrapping = (varModel.scrappingPick && varModel.scrappingPick === 'Scrapping') ? true : false;

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(
    freeText: FormControl,
    clientPick: number,
    industryPick: FormControl,
    cityPick: FormControl,
    statusPick: FormControl,
    // rolesPick: FormControl,
    // jobPick: FormControl,
    // datePick: FormGroup,
    // createdDatePick: FormGroup,
    // paymentPick: FormControl,
    // employmentPick: FormControl,
    // commutingPick: FormControl,
    // scrappingPick: FormControl,
    tags: {key: string, value: string}[]
  ): string {
    // if (freeText.value || arrFreeText.value.length) {
    //   let tempFreeText = freeText.value ? freeText.value.split(',') : [];
    //   let tempArrFreeText = arrFreeText.value;
    //   if (tempFreeText.length) tempFreeText.forEach((element: any) => { tempArrFreeText.push(element); });
    //   arrFreeText.setValue(tempArrFreeText);
    //   tags = this.tagsService.updateKey(tags, 'freetext', arrFreeText, 'array');
    //   freeText.reset();
    // }
    if (freeText.value) {
      tags = this.tagsService.updateKey(tags, 'freetext', freeText);
    }
    if (cityPick.value) {
      tags = this.tagsService.updateKey(tags, 'city', cityPick, 'array');
    }
    if (industryPick.value) {
      tags = this.tagsService.updateKey(tags, 'industry', industryPick, 'array');
    }
    if (statusPick.value) {
      tags = this.tagsService.updateKey(tags, 'client_job_status', statusPick);
    }
    // if (rolesPick.value) {tags = this.tagsService.updateKey(tags, 'roles', rolesPick);}
    // if (datePick.value) {
    //   tags = this.tagsService.updateKey(tags, 'date', datePick, 'date');
    // }
    // if (createdDatePick.value) {
    //   tags = this.tagsService.updateKey(tags, 'createdDate', createdDatePick, 'date');
    // }
    // if (paymentPick.value) {
    //   tags = this.tagsService.updateKey(tags, 'payment', paymentPick);
    // }
    // if (employmentPick.value) {
    //   tags = this.tagsService.updateKey(tags, 'job_employment', employmentPick);
    // }
    // if (commutingPick.value) {
    //   tags = this.tagsService.updateKey(tags, 'commuting', commutingPick);
    // }

    const clientId = clientPick ? `client_id=${clientPick}` : null;
    const freetext = freeText.value ? `title=${freeText.value.replace('&', '%26')}` : null;
    const industry = industryPick.value ? `industry_type=${industryPick.value.join(',')}` : null;
    const city = cityPick.value ? `city=${cityPick.value.join(',')}` : null;
    const status = statusPick.value ? `status=${statusPick.value}` : `status=draft`; // J2G-1819 - Menampilkan Job Expired untuk user Corporate
    // const date = datePick.value.end ?
    //   `start_date=${moment(datePick.value.begin).format('YYYY-MM-DD') + 'T00:00:00Z'}&end_date=${moment(datePick.value.end).format('YYYY-MM-DD') + 'T23:59:59Z'}` :
    //   null;
    // const createdDate = createdDatePick.value.end ?
    //   `create_date_min=${moment(createdDatePick.value.begin).format('YYYY-MM-DD') + 'T00:00:00Z'}&create_date_max=${moment(createdDatePick.value.end).format('YYYY-MM-DD') + 'T23:59:59Z'}` :
    //   null;
    // const payment = paymentPick.value ? `payment_type=${paymentPick.value}` : null;
    // const employment = employmentPick.value ? `employment_type=${employmentPick.value}` : null;
    // const commuting = commutingPick.value ? `commuting_type=${commutingPick.value}` : null;

    // console.log(scrapping);

    const data = [
      clientId, freetext, status, industry, city/* , date, createdDate, payment, employment, commuting */
    ];

    // console.log(company);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('atsJobsQuery', tempQuery);
    sessionStorage.setItem('atsJobsTags', JSON.stringify(tags));
    return tempQuery;
  }

  filterText(freeText: string){
    const freetext = freeText ? `title=${freeText}` : null;

    const data = [freetext];
    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    return tempQuery;
  }

  removeTags(
    tags: {key: string, value: string}[],
    // arrFreeText: FormControl,
    // jobPick: FormControl,
    freeText: FormControl,
    cityPick: FormControl,
    industryPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
      // if (tag.key === 'freetext') tags = this.tagsService.removeKey(tags, tag.key, arrFreeText, 'array', indexTagValue);
      // if (tag.key === 'department') categoryPick.setValue(null); J2G-1525 - Become Free Text
      if (tag.key === 'job') {
        tags = this.tagsService.removeKey(tags, tag.key, freeText);
      } else if (tag.key === 'city') {
        tags = this.tagsService.removeKey(tags, tag.key, cityPick, 'array', indexTagValue);
      } else if (tag.key === 'industry') {
        tags = this.tagsService.removeKey(tags, tag.key, industryPick, 'array', indexTagValue);
      } else {
        tags = this.tagsService.removeKey(tags, tag.key); // by default yang bukan array dan tanpa custom logic disini
      }

      sessionStorage.setItem('atsJobsTags', JSON.stringify(tags));
  }
  // End Filter Logic


  getJobCombo(companyId: number, query?: string): void {
    this.jobReadySubject.next(false);
    
    this.jobService.getJobsAutoComplete(companyId, query ? query : '').subscribe((arg: any) => {
      const listData = arg.data ? arg.data : [];
      this.currentJobListSubject.next(listData);
      this.jobReadySubject.next(true);
    }, (error: any) => {
      this.jobReadySubject.next(true);
      this.jobService.openSnackbar(error.status, error);
    });
  }


  // Additional Method
  uniqueJobs(data: JobsModel[]): JobsModel[] {
    const uniqueData: JobsModel[] = [];
    data.forEach((element: JobsModel) => {
      const index = uniqueData.findIndex((ind: any) => ind.title === element.title);
      if (index === -1) {
        uniqueData.push(element);
      }
    });
    return uniqueData;
  }
  cityChanged(cityPick: FormControl, value: string): boolean {
    const tempCity = EnumCity.citySelect.find((city: any) => city.name === value);
    if (tempCity) {
      const arrCity = cityPick.value ? cityPick.value : [];
      const index = arrCity.findIndex((ind: any) => ind === value);
      if (index === -1) {
        arrCity.push(tempCity.enum);
        cityPick.setValue(arrCity);
        return true;
      } else {
        this.jobService.customSnackbar(417, 'Kota sudah dipilih!');
      }
    }
    return false;
  }
  // End of Additional Method
}
