import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RemoteLoggingService } from '../_service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RemoteLoggingInterceptor implements HttpInterceptor {

  constructor(
    private remoteLogging: RemoteLoggingService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(event => {
        if (
          !request.url.includes('rlog') &&
          !request.url.includes('account/authenticate') &&
          !request.url.includes('replace_password') &&
          !request.url.includes('register') &&
          event instanceof HttpResponse
        ) {
          if (!environment.production) {
            if (this.urlInfo(request.method)) {
              this.remoteLogging.logDetails(event, request.body, request.method, 'info', this.generateModul(request.url, request.method));
            } else {
              this.remoteLogging.logDetails(event, request.body, request.method, 'debug', this.generateModul(request.url));
            }
          }
        }
      }, (error: HttpErrorResponse) => {
        if (
          !request.url.includes('rlog') &&
          !request.url.includes('account/authenticate') &&
          !request.url.includes('replace_password') &&
          !request.url.includes('register')
        ) {
          if (this.urlWarning(request.url, request.method, error.status)) {
            this.remoteLogging.errorDetails(
              error, request.body, request.method, 'warning', this.generateModul(request.url, request.method)
            );
          } else {
            this.remoteLogging.errorDetails(
              error, request.body, request.method, 'error', this.generateModul(request.url, request.method)
            );
          }
        }
      })
    );
  }

  generateModul(url: string, method?: string): 'attendance' | 'motorist' | 'payroll' | 'applicant' | 'worker' | 'company' | 'kasbon' | 'kancil' | 'kini' | 'leave-approval' | 'leave-request' {
    if (url.includes('attendance')) {
      return 'attendance';
    } else if (url.includes('motorist') || url.includes('poi/') || url.includes('pjp') || url.includes('project')) {
      return 'motorist';
    } else if (url.includes('payroll')) {
      return 'payroll';
    } else if (url.includes('/applicant') || url.includes('invitation')) {
      return 'applicant';
    } else if (url.includes('worker') || (url.includes('worker') && url.includes('document'))) {
      return 'worker';
    } else if (url.includes('kasbon') || url.includes('flog')) {
      return 'kasbon';
    } else if (url.includes('kancil') || url.includes('loan_eligibility')) {
      return 'kancil';
    } else if (url.includes('kini')) {
      return 'kini';
    } else if (url.includes('leave') && method === 'PATCH') {
      return 'leave-approval';
    } else if (url.includes('leave') && (method === 'POST' || method === 'PUT')) {
      return 'leave-request';
    } else if (url.includes('/company') || url.includes('/job/') || url.includes('/jobtemplate') ||
      url.includes('campaign-job') || url.includes('campaign-companies') || url.includes('webinars') ||
      url.includes('walk-in-interviews') || url.includes('/point')) {
      return 'company';
    } else {
      return 'worker';
    }
  }

  urlInfo(method: string): boolean {
    const arrMethod = ['POST', 'PUT', 'PATCH', 'DELETE'];
    return arrMethod.includes(method);
  }
  urlWarning(url: string, method: string, code: number): boolean {
    // ? 1. Check Email Worker
    // ? 2. Check FCM Token Worker
    // ? 3. Check Status Code

    const arrCode = [401, 403, 404];

    if (url.includes('account') && url.includes('@') && method === 'GET') { return true; }
    if (url.includes('account') && url.includes('fcm') && method === 'GET') { return true; }
    return (arrCode.includes(code) && method === 'GET');
  }
}
