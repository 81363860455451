/* tslint:disable:no-non-null-assertion */
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, from} from 'rxjs';
import {filter, finalize, map, mergeMap, switchMap} from 'rxjs/operators';
import { FilterModel } from 'src/app/_class';

import {ResponseEmployee, ResponsePostEmployee, PostEmployeeClass, TemporaryPostEmployeeModel} from '..';
import {EmployeeHTTPService, EmployeeProfileHTTPService} from './employee-http';

export type ResponseType = ResponseEmployee | undefined;

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  // public fields
  currentEmployee$: Observable<ResponseType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentEmployeeSubject: BehaviorSubject<ResponseType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private employeeHttp: EmployeeHTTPService,
    private employeeProfileHttp: EmployeeProfileHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentEmployeeSubject = new BehaviorSubject<ResponseType>(undefined);
    this.currentEmployee$ = this.currentEmployeeSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.employeeHttp.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.employeeHttp.openCustomBar(status, message);
  }


  // Logic Before Hit API
  // public method
  getEmployee(companyId: number, filter: FilterModel): Observable<ResponseType> {
    this.isLoadingSubject.next(true);
    return this.employeeHttp.getEmployeeList(companyId, filter).pipe(
      map((employee: ResponseType) => {
        // checking employee
        if (employee) {
          this.currentEmployeeSubject.next(employee);
        }
        return employee;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getEmployeeAutoComplete(companyId: number, filter: FilterModel): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.employeeHttp.getAllEmployee(companyId, filter).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  filterEmployeeById(companyId: number, userId: string[]): Observable<Response> {
    this.isLoadingSubject.next(true);
    return from(userId).pipe(
      mergeMap((el) => {
        return this.employeeHttp.getEmployeeById(companyId, +el!);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  patchEmployee(companyId: number, userId: number, data: { status: string, quitDate?: string, quitReason: string }, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.employeeHttp.patchEmployee(companyId, userId, data).pipe(
      switchMap(() => this.getEmployee(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  employeeWithRole(companyId: number, role: string, rawEmployee: any): Observable<Response> {
    return this.employeeHttp.createEmployee(companyId, rawEmployee).pipe(
      switchMap((arg: any) => this.employeeProfileHttp.updateEmployeeRoles(companyId, arg.userId, {authority: role}))
    );
  }

  createEmployee(companyId: number, data: TemporaryPostEmployeeModel[]): Observable<any> {
    const responseStorer: ResponsePostEmployee = {success: [], error: []};
    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      filter(fil => !!fil.userID),
      mergeMap(async (common: TemporaryPostEmployeeModel) => {
        const rawEmployee: any = PostEmployeeClass.formatPostEmployee(common);

        try {
          if (common.roles !== 'role_employee') {
            await this.employeeWithRole(companyId, common.roles, rawEmployee).toPromise();
          } else {
            await this.employeeHttp.createEmployee(companyId, rawEmployee).toPromise();
          }
          responseStorer.success.push(common.id);
        } catch (error: any) {
          responseStorer.error.push({
            Email: common.email,
            Message: error.error.error,
          });
        }
        return responseStorer;
      }),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  createEmployeeBypass(companyId: number, data: TemporaryPostEmployeeModel[]): Observable<any> {
    const responseStorer: ResponsePostEmployee = {success: [], error: []};
    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      filter(fil => !fil.userID),
      mergeMap(async (bypass: TemporaryPostEmployeeModel) => {
        const rawEmployee: any = PostEmployeeClass.formatPostEmployeeBypass(bypass);

        try {
          await this.employeeHttp.createEmployeeByPass(companyId, rawEmployee).toPromise();
          responseStorer.success.push(bypass.id);
        } catch (error: any) {
          responseStorer.error.push({
            Email: bypass.email,
            Message: error.error.error,
          });
        }
        return responseStorer;
      }),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  uploadCsvBypass(companyId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeHttp.uploadEmployeeByPass(companyId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  checkEmail(email: string): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeHttp.checkEmail(email).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
