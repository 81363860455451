export class EnumCountCuti {
    public static countCutiSelect: any = [
        { name: '1', enum: 1 },
        { name: '2', enum: 2 },
        { name: '3', enum: 3 },
        { name: '4', enum: 4 },
        { name: '5', enum: 5 },
        { name: '6', enum: 6 },
        { name: '7', enum: 7 },
        { name: '8', enum: 8 },
        { name: '9', enum: 9 },
        { name: '10', enum: 10 },
        { name: '11', enum: 11 },
        { name: '12', enum: 12 },
        { name: '15', enum: 15 },
        { name: '20', enum: 20 },
        { name: '25', enum: 25 },
        { name: '30', enum: 30 },
        { name: '35', enum: 35 },
        { name: '40', enum: 40 },
        { name: '44', enum: 44 },
    ];
}
