import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable/*, of*/ } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ErrorJsonHandlingService } from 'src/app/_service/error-handling.service';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { EmployeeProfileClass, EmployeeProfileModel } from '..';
import { EmployeeProfileHTTPService } from './employee-http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeProfileService {

  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isProfileIncompleted$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  isProfileIncompletedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private jsonHandling: ErrorJsonHandlingService,
    private employeeProfileHttp: EmployeeProfileHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isProfileIncompletedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.isProfileIncompleted$ = this.isProfileIncompletedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getEmployeeProfile(companyId: number, userId: number): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.employeeProfileHttp.getEmployeeProfile(companyId, userId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  putEmployeeProfile(companyId: number, userId: number, data: EmployeeProfileModel): Observable<Response | any> {
    const tempData = EmployeeProfileClass.formatEmployeeProfile(data);

    const errorMessage = EmployeeProfileClass.employeeProfileValidation(tempData);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('EMPLOYEE PROFILE -> Put Employee Profile', errorMessage, tempData);
    }

    // Debugging Purpose
    // console.log(tempData);
    // return of(tempData);

    this.isSubmitedSubject.next(true);
    return this.employeeProfileHttp.editEmployee(companyId, userId, tempData).pipe(
      finalize(() => {
        this.isSubmitedSubject.next(false);
        this.isProfileIncompletedSubject.next(false);

        // Nyingkronize Data
        this.isLoadingSubject.next(true);
        setTimeout(() => {
          this.isLoadingSubject.next(false);
        }, 150);
      })
    );
  }
  // End of Logic Before Hit API
}
