import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service';
import { ResponseMember } from '../models/response.model';
import { ManageTeamHTTPService } from './manage-team-http';

export type ResponseMemberType = ResponseMember | undefined;

@Injectable({
  providedIn: 'root'
})
export class ManageTeamService {

  // public fields
  currentProject$: Observable<ResponseMemberType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentProjectSubject: BehaviorSubject<ResponseMemberType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private memberHttp: ManageTeamHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentProjectSubject = new BehaviorSubject<ResponseMemberType>(undefined);
    this.currentProject$ = this.currentProjectSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackbar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  customSnackbar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getMembers(companyId: number, projectId: number, filter: FilterModel): Observable<ResponseMemberType> {
    this.isLoadingSubject.next(true);
    return this.memberHttp.getMemberList(companyId, projectId, filter).pipe(
      map((template: ResponseMemberType) => {
        // checking template
        if (template) {
          this.currentProjectSubject.next(template);
        }
        return template;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  updateMember(companyId: number, projectId: number, userId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.memberHttp.editMember(companyId, projectId, userId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  updatePjp(companyId: number, projectId: number, pjpId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.memberHttp.editPjp(companyId, projectId, pjpId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
}
