import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpConfigService } from 'src/app/_service/http-config.service';
import { environment } from 'src/environments/environment';

const API_PROJECT_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class ManageProjectHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getProjectList(companyId: number, query: any, page: number, size: number, sortBy?: string, sortAsc?: boolean): Observable<any> {
    return this.http.get<any>(`${API_PROJECT_URL}/company/${companyId}/project?${query ? query + '&' : ''}page=${page}&limit=${size ? size : '10'}&sort=${sortBy ? sortBy : 'id'}:${sortAsc ? 'asc' : 'desc'}&metadata=true`);
  }

  getProjectDetail(companyId: number, id: number): Observable<Response> {
    return this.http.get<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${id}`);
  }

  createProject(companyId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_PROJECT_URL}/company/${companyId}/project`, data);
  }

  editProject(companyId: number, id: number, data: any): Observable<Response> {
    delete data.id;
    delete data.companyId;
    delete data.companyName;
    delete data.memberCount;
    delete data.createdBy;
    delete data.createdDate;
    delete data.lastModifiedBy;
    delete data.lastModifiedDate;

    return this.http.put<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${id}`, data);
  }

  deleteProject(companyId: number, id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${id}`);
  }
}
