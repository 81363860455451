import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import * as moment from 'moment';
moment.locale('id');

import {SessionStorage} from 'src/app/_class';
import {TagsService} from 'src/app/_service';

import {IndexKiniWhitelistVariables, EmployeeClass, EmployeeListKiniModel} from '..';

@Injectable()
export class EmployeeKiniClass {

  currentRawEmployee$: Observable<any>;
  currentRawEmployeeSubject: BehaviorSubject<any>;

  constructor(
    private tagsService: TagsService,
    private employeeClass: EmployeeClass,
  ) {
    this.currentRawEmployeeSubject = new BehaviorSubject<any>(undefined);
    this.currentRawEmployee$ = this.currentRawEmployeeSubject.asObservable();
  }

  // Variables Initial
  initVariable(): IndexKiniWhitelistVariables {
    const varModel = {} as IndexKiniWhitelistVariables;

    const Session = SessionStorage.getStorageJson('whitelistKiniTags');
    const tempFreeText = this.tagsService.filterSession(Session, 'freetext');
    const tempDepartment = this.tagsService.filterSession(Session, 'department');
    const tempEmployment = this.tagsService.filterSession(Session, 'employment');
    const tempDate = this.tagsService.filterSession(Session, 'date');
    const tempSalary = this.tagsService.filterSession(Session, 'salary');
    // const tempLimit = this.tagsService.filterSession(Session, 'limit');

    varModel.tempQuery = SessionStorage.getStorage('whitelistKiniQuery');
    varModel.freeText = (tempFreeText.length) ? tempFreeText[0].value : null;
    varModel.departmentPick = (tempDepartment.length) ? tempDepartment[0].value : null;
    varModel.employmentPick = (tempEmployment.length) ? tempEmployment[0].value : null;
    varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    varModel.salaryPickMin = (tempSalary.length) ? tempSalary[0].value.min : null;
    varModel.salaryPickMax = (tempSalary.length) ? tempSalary[0].value.max : null;
    // varModel.limitPickMin = (tempLimit.length) ? tempLimit[0].value.min : null;
    // varModel.limitPickMax = (tempLimit.length) ? tempLimit[0].value.max : null;
    varModel.page = SessionStorage.getStorageNumber('whitelistKiniPageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('whitelistKiniPageSize', 10);
    varModel.sortBy = SessionStorage.getStorage('whitelistKiniSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('whitelistKiniSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    return varModel;
  }

  // End of Variables Initial


  // Filter Logic
  filterService(
    freeText: FormControl,
    departmentPick: FormControl,
    employmentPick: FormControl,
    datePick: FormGroup,
    salaryPick: FormGroup,
    // limitPick: FormGroup,
    tags: { key: string, value: string }[]
  ): string {
    
    if (freeText.value) {
      tags = this.tagsService.updateKey(tags, 'freetext', freeText);
    }
    if (departmentPick.value) {
      tags = this.tagsService.updateKey(tags, 'department', departmentPick);
    }
    if (employmentPick.value) {      
      tags = this.tagsService.updateKey(tags, 'employment', employmentPick, 'array');
    }
    if (datePick.value) {
      tags = this.tagsService.updateKey(tags, 'date', datePick, 'date');
    }
    if (salaryPick.value) {
      tags = this.tagsService.updateKey(tags, 'salary', salaryPick, 'amount');
    }
    // if (this.limitPick.value) {
    //   if (this.limitPick.value.max > this.limitPick.value.min) {
    //     this.remove({key: 'limit'});
    //     this.tags.push({key: 'limit', value: this.limitPick.value});
    //   };
    // }
    
    const freetext = freeText.value ? `name=${freeText.value}` : null;
    const deparment = departmentPick.value ? `department=${departmentPick.value}` : null;
    const employment = employmentPick.value ? `employment_type=${employmentPick.value.join(',')}` : null;
    // const date = datePick.value ? this.employeeClass.generateDateFilter(datePick) : null;
    const date = (datePick.controls.begin.value && datePick.controls.end.value) ? `join_date_min=${moment(datePick.controls.begin.value).format('YYYY-MM-DDT00:00:00')+'Z'}&join_date_max=${moment(datePick.controls.end.value).format('YYYY-MM-DDT23:59:00')+'Z'}` : null;
    const salary = salaryPick.value.max ? `salary_min=${salaryPick.value.min}&salary_max=${salaryPick.value.max}` : null;
    // const limit = limitPick.value.max ? `limit_min=${limitPick.value.min}&limit_max=${limitPick.value.max}` : null;

    const data = [freetext, deparment, employment, date, salary];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('whitelistKiniQuery', tempQuery);
    sessionStorage.setItem('whitelistKiniTags', JSON.stringify(tags));
    return tempQuery;
  }

  removeTags(
    tags: { key: string, value: string }[],
    employmentPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
    if (tag.key === 'employment') {
      tags = this.tagsService.removeKey(tags, tag.key, employmentPick, 'array', indexTagValue);
    } else {
      tags = this.tagsService.removeKey(tags, tag.key);
    }
    // if (tag.key === 'limit') { this.limitPick.controls.min.setValue(null); this.limitPick.controls.max.setValue(null); }

    sessionStorage.setItem('whitelistKiniTags', JSON.stringify(tags));
  }

  // End Filter Logic


  // Checkbox Handler
  addWhiteListAll(dataSource: EmployeeListKiniModel[]): any {
    const tempWhitelist = dataSource.filter((fil: EmployeeListKiniModel) =>
      (!fil.status || (fil.status && fil.status.toLowerCase() === 'deactive'))
    );
    const rawEmployeeRegistered: any = [];
    const rawEmployeeUnRegistered: any = [];

    // console.log(tempWhitelist);

    // tslint:disable-next-line:prefer-for-of
    for (let index = 0; index < tempWhitelist.length; index++) {
      const element = tempWhitelist[index];

      if (element.status) {
        const rawResult: any = Object.assign({}, element);
        rawResult.status = 'ACTIVE';
        // rawEmployee.limit = 500000;

        rawEmployeeRegistered.push(rawResult);
      } else {
        const rawResult: any = Object.assign({}, element);

        rawEmployeeUnRegistered.push(rawResult);
      }
    }

    const rawEmployee = {employeeRegistered: rawEmployeeRegistered, employeeUnregistered: rawEmployeeUnRegistered};
    this.currentRawEmployeeSubject.next(rawEmployee);
  }

  updateWhiteListAll(dataSource: EmployeeListKiniModel[]): any {
    const tempWhitelist = dataSource.filter((fil: EmployeeListKiniModel) =>
      fil.status && fil.status.toLowerCase() === 'active'
    );
    const rawEmployeeRegistered: any = [];

    // console.log(tempWhitelist);

    tempWhitelist.forEach((element: any) => {
      const rawResult: any = Object.assign({}, element);
      rawResult.status = 'DEACTIVE';
      // rawEmployee.limit = 0;

      rawEmployeeRegistered.push(rawResult);
    });

    return {employeeRegistered: rawEmployeeRegistered};
  }
  // End of Checkbox Handler
}
