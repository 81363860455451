import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { FcmTableGoapiModel } from 'src/app/_model/fcm-table.model';
import { HttpConfigService } from '../http-config.service';

const API_NOTIF_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class PushNotificationHTTPService {

  constructor(
    private http: HttpClient,
    private config: HttpConfigService
  ) { }

  getUserToken(userId: number): Observable<any> {
    return this.http.get<any>(`${API_NOTIF_URL}/account/${userId}/fcm`, this.config.GHttpOptionsDefault());
  }
  postUserToken(userId: number, body: FcmTableGoapiModel): Observable<any> {
    return this.http.post<any>(`${API_NOTIF_URL}/account/${userId}/fcm`, body, this.config.GHttpOptionsDefault());
  }

  addNotification(body: any): Observable<any> {
    return this.http.post<any>(`/api/notifications`, body, this.config.httpOptions())
      .pipe(catchError(this.config.handleError));
  }
  getNotification(userId: number): Observable<any> {
    return this.http.get<any>(
      `${API_NOTIF_URL}/notification?metadata=true&user_id=${userId}&sort=id:desc&limit=200`,
      this.config.GHttpOptionsDefault()
    )
      .pipe(catchError(this.config.handleErrorReturnAll));
  }

  sendFcmPortal(body: any): Observable<any> {
    return this.http.post<any>(`/api/j2g/notification/portal`, body, this.config.httpOptions())
      .pipe(catchError(this.config.handleError));
  }
}
