export class EnumAttendanceCommuting {
    public static attendanceCommutingSelect: any = [
        { name: 'Office', enum: 'office' },
        { name: 'Remote', enum: 'remote' },
        { name: 'On Site', enum: 'site' },
    ];

    public static findAttendanceComm(commuting: string): any {
        return EnumAttendanceCommuting.attendanceCommutingSelect.find((fin: any) => fin.enum === commuting.trim().toLowerCase());
    }
}
