import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { GlobalConstants } from 'src/app/_class';
import { FcmRequestModel, FcmTableGoapiModel, PushNotificationModel, NotificationModel } from 'src/app/_model';

import { HttpConfigService } from '.';
import { PushNotificationHTTPService } from './service-http';

import * as moment from 'moment';
moment.locale('id');

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private afMessaging: AngularFireMessaging,
    private config: HttpConfigService,
    private pushNotifHttp: PushNotificationHTTPService
  ) {
    this.message$ = this.currentMessage.asObservable();
    this.totalNotif$ = this.totalNotification.asObservable();
    this.notification$ = this.currentNotification.asObservable();

    this.afMessaging.messages.subscribe(
      (messaging: any) => {
        messaging.onMessage = messaging.onMessage.bind(messaging);
        messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
      });
  }

  notif = {} as NotificationModel;
  fcm = {} as PushNotificationModel;

  message$: Observable<any> = new BehaviorSubject<any>(null);
  totalNotif$: Observable<number> = new BehaviorSubject<number>(0);
  notification$: Observable<NotificationModel[]> = new BehaviorSubject<NotificationModel[]>([]);
  currentMessage = new BehaviorSubject<any>(null);
  totalNotification = new BehaviorSubject<number>(0);
  currentNotification = new BehaviorSubject<NotificationModel[]>([]);

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }

  openSnackbar(status: number): void {
    this.config.openSnackBar(status);
  }

  // Firebase FCM
  requestPermission(emptyToken?: boolean): Observable<Response> {
    const responseModel: FcmRequestModel = {} as FcmRequestModel;

    if (!emptyToken) {
      return this.afMessaging.requestToken.pipe(
        switchMap(async (token) => {
          const arg: any = await this.pushNotifHttp.getUserToken(GlobalConstants.userId).toPromise();
          responseModel.token = token;
          responseModel.userToken = arg;
          return responseModel;
        }),
        switchMap((result: FcmRequestModel) => {
          const data = {} as FcmTableGoapiModel;

          data.androidPortalToken = result.userToken.androidPortalToken;
          data.androidWorkforceToken = result.userToken.androidWorkforceToken;
          data.iosPortalToken = result.userToken.iosPortalToken;
          data.iosWorkforceToken = result.userToken.iosWorkforceToken;
          data.portalToken = result.userToken.portalToken;
          data.wmtToken = result.token ? result.token : '';

          return this.pushNotifHttp.postUserToken(result.userToken.userId, data);
        }),
        switchMap(() => this.getNotification())
      );
    }

    return this.afMessaging.requestToken.pipe(
      switchMap((token) => {
        const data = {} as FcmTableGoapiModel;

        responseModel.token = token;

        data.androidPortalToken = null;
        data.androidWorkforceToken = null;
        data.iosPortalToken = null;
        data.iosWorkforceToken = null;
        data.portalToken = null;
        data.wmtToken = responseModel.token ? responseModel.token : '';

        return this.pushNotifHttp.postUserToken(GlobalConstants.userId, data);
      }),
      switchMap(() => this.getNotification())
    );
  }

  receiveMessage(): Observable<Response> {
    return this.afMessaging.messages.pipe(
      map((payload: any) => {
        this.currentMessage.next(payload);
      }),
      switchMap(() => this.getNotification())
    );
  }
  // End Firebase FCM

  // Begin Get Notification
  getNotification(): Observable<Response> {
    return this.pushNotifHttp.getNotification(GlobalConstants.userId);
  }
  // End of Get Notification

  // Logic Push Notification Job Application
  async pushNotificationApplicant(
    userid: number,
    status: string,
    title: string,
    company: string
  ): Promise<any> {
    let arg: any;
    let hasError = false;

    // Get Token User
    try {
      arg = await this.pushNotifHttp.getUserToken(userid).toPromise();
    } catch (error: any) {
      console.log('failed get notification data');
      this.openSnackbar(error);
    }

    const token = (arg[0] && arg[0].portalToken) ? arg[0].portalToken : null;
    const jobTitle = title;
    const companyName = company;

    // Insert to Entity Notification
    this.notif.createdBy = GlobalConstants.userLogin;
    this.notif.createdDate = moment().format();
    this.notif.datetime = +moment().format('X');
    this.notif.isRead = false;
    this.notif.userId = userid;
    // setting up notif title
    this.notif.title = (status === 'HIRED') ? 'Selamat Bekerja' : (
      (status === 'SHORTLISTED' || status === 'READ' || status === 'FORWARDED') ? 'Satu langkah lagi' : (
        (status === 'NOT_QUALIFIED' || status === 'REJECTED') ? 'Mohon maaf' : (
          (status === 'APPLIED') ? 'Halo' : (
            (status === 'INVITED') ? 'Kabar gembira' : 'Sampai Jumpa'
          )
        )
      )
    );
    // seting up notif content
    if (status === 'HIRED') {
      this.notif.content = 'Kamu sudah diterima di perusahaan impianmu sebagai ' + jobTitle;
    } else if (status === 'SHORTLISTED' || status === 'READ' || status === 'FORWARDED') {
      this.notif.content = 'Lamaran kerjamu pada posisi ' + jobTitle + ' di ' + (companyName ? companyName : 'Perusahaan Tujuan') + ' sedang di review. Semoga sukses ya :)';
    } else if (status === 'APPLIED') {
      this.notif.content = 'Selamat menjadi pelamar baru lagi di '  + (companyName ? companyName : 'Perusahaan Sebelumnya');
    } else if (status === 'INVITED') {
      this.notif.content = 'Kamu telah diundang untuk melakukan interview pada posisi ' + jobTitle + ' di ' + (companyName ? companyName : 'Perusahaan Tujuan') + '. Semoga sukses ya :)';
    } else {
      this.notif.content = 'Kamu sudah resmi keluar dari pekerjaan mu di ' + (companyName ? companyName : 'Perusahaan Sebelumnya');
    }
    this.notif.tag = 'job';
    this.notif.target = 'portal';

    if (token) {
      try {
        // Insert to Entity Notification
        await this.pushNotifHttp.addNotification(this.notif).toPromise();
      } catch (error: any) {
        console.log('failed insert to tabel notification', jobTitle, this.notif);
        this.openSnackbar(error);
        hasError = true;
      }

      if (!hasError) {
        this.fcm.title = this.notif.title;
        this.fcm.body = this.notif.content;
        this.fcm.message = this.notif.content;
        this.fcm.tag = this.notif.tag;
        this.fcm.registrationIds = [token];

        // Send FCM
        // console.log('Checking FCM', this.fcm);
        this.pushNotifHttp.sendFcmPortal(this.fcm).subscribe(() => {
          console.log('success send fcm');
        }, (error: any) => {
          console.log('failed send fcm', jobTitle, this.fcm);
          this.openSnackbar(error);
        });
      }
    } else {
      console.log('Portal Undefined, Proccess terminated');
      // Insert to Entity Notification
      this.pushNotifHttp.addNotification(this.notif).subscribe(() => {
        console.log('success save into table notification');
      }, (error: any) => {
        console.log('failed save into table notification', jobTitle, this.notif);
        this.openSnackbar(error);
      });
    }
  }
}
