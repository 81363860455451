/* tslint:disable:no-non-null-assertion */
import * as moment from 'moment';
moment.locale('id');

export interface PostEmployeeModel {
  nik: string;
  userID: number;
  title: string;
  department: string;
  fullName: string | null;
  birthDate: string | null;
  joinDate: string | null;
  religion: string | null;
  ktp: string | null;
  ktpAddress: string | null;
  npwp: string | null;
  personalEmail: string | null;
  corporateEmail: string | null;
  nationality: string | null;
  hobby: string | null;
  parentSimpleAddress: string | null;
  homeBase: string | null;
  econName: string | null;
  econSimpleAddress: string | null;
  econPhone: string | null;
  econRelationship: string | null;
  modules: string | null;
  employmentType: string;
  endContractDate: string | null;
}

export interface PostEmployeeBypassModel {
  id?: number;
  email: string;
  phone: string;
  // password: string;
  fullName: string | null;
  employmentType: string;
  title: string;
  department: string;
  birthDate: string | null;
  gender: string;
  roles: string;
  employeeCode: string;
  endContractDate: string | null;
  ktp: string;
  npwp: string | null;
  bankAccount: string | null;
  bankName: string | null;
  bankBeneficiary: string | null;
}

export interface TemporaryPostEmployeeModel {
  id: number;
  email: string;
  employmentType: string;
  fullName: string | null;
  title: string;
  department: string;
  birthDate: string;
  gender: string;
  roles: string;
  employeeCode: string;
  nik: string;
  // password: string;
  phone: string;
  endContractDate: string | null;
  ktp: string;
  npwp: string | null;
  bankAccount: string | null;
  bankName: string | null;
  bankBeneficiary: string | null;
  userID?: number; // ? Existed User
  imageUrl?: string | null; // ? Existed User
}

export class PostEmployeeClass {

  // Buat Ngeformat Add Employee
  public static formatPostEmployee(payload: TemporaryPostEmployeeModel): PostEmployeeModel {
    const formatData: PostEmployeeModel = {} as PostEmployeeModel;

    formatData.nik = payload.nik;
    formatData.userID = payload.userID!;
    formatData.title = payload.title;
    formatData.department = payload.department;
    formatData.fullName = payload.fullName;
    formatData.birthDate = payload.birthDate ? moment(payload.birthDate).utcOffset('+07:00').format() : null;
    formatData.joinDate = null;
    formatData.religion = null;
    formatData.ktp = payload.ktp;
    formatData.ktpAddress = null;
    formatData.npwp = payload.npwp;
    formatData.personalEmail = null;
    formatData.corporateEmail = null;
    formatData.nationality = null;
    formatData.hobby = null;
    formatData.parentSimpleAddress = null;
    formatData.homeBase = null;
    formatData.econName = null;
    formatData.econSimpleAddress = null;
    formatData.econPhone = null;
    formatData.econRelationship = null;
    formatData.modules = null;
    formatData.employmentType = payload.employmentType;
    formatData.endContractDate = payload.endContractDate ? moment(payload.endContractDate).utcOffset('+07:00').format() : null;

    return formatData;
  }

  // Buat Ngeformat Add Employee Bypass
  public static formatPostEmployeeBypass(payload: TemporaryPostEmployeeModel): PostEmployeeBypassModel {
    const formatData: PostEmployeeBypassModel = {} as PostEmployeeBypassModel;

    formatData.email = payload.email;
    formatData.phone = payload.phone;
    // formatData.password = payload.password;
    formatData.fullName = payload.fullName;
    formatData.employmentType = payload.employmentType;
    formatData.title = payload.title;
    formatData.department = payload.department;
    formatData.birthDate = payload.birthDate ? moment(payload.birthDate).utcOffset('+07:00').format() : null;
    formatData.gender = payload.gender;
    formatData.roles = payload.roles;
    formatData.employeeCode = payload.employeeCode;
    formatData.endContractDate = payload.endContractDate ? moment(payload.endContractDate).utcOffset('+07:00').format() : null;
    formatData.ktp = payload.ktp;
    formatData.npwp = payload.npwp;
    formatData.bankAccount = payload.bankAccount;
    formatData.bankBeneficiary = payload.bankBeneficiary;
    formatData.bankName = payload.bankName;

    return formatData;
  }
}
