export class EnumToP {
    public static topSelect: any = [
        { name: '1 Hari', enum: 1 },
        { name: '2 Hari', enum: 2 },
        { name: '3 Hari', enum: 3 },
        { name: '4 Hari', enum: 4 },
        { name: '5 Hari', enum: 5 },
        { name: '6 Hari', enum: 6 },
        { name: '7 Hari', enum: 7 },
        { name: '8 Hari', enum: 8 },
        { name: '9 Hari', enum: 9 },
        { name: '10 Hari', enum: 10 },
        { name: '11 Hari', enum: 11 },
        { name: '12 Hari', enum: 12 },
        { name: '13 Hari', enum: 13 },
        { name: '14 Hari', enum: 14 },
        { name: '15 Hari', enum: 15 },
        { name: '16 Hari', enum: 16 },
        { name: '17 Hari', enum: 17 },
        { name: '18 Hari', enum: 18 },
        { name: '19 Hari', enum: 19 },
        { name: '20 Hari', enum: 20 },
        { name: '21 Hari', enum: 21 },
        { name: '22 Hari', enum: 22 },
        { name: '23 Hari', enum: 23 },
        { name: '24 Hari', enum: 24 },
        { name: '25 Hari', enum: 25 },
        { name: '26 Hari', enum: 26 },
        { name: '27 Hari', enum: 27 },
        { name: '28 Hari', enum: 28 },
        { name: '29 Hari', enum: 29 },
        { name: '30 Hari', enum: 30 },
    ];
}