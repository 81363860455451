import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeAttendanceHTTPService {

  constructor(
    private http: HttpClient
  ) { }

  getLeaveConfig(companyId: number, userId: number, query?: string): Observable<Response> {
    return this.http.get<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/attendance_employee_config?metadata=true${query ? '&' + query : ''}`);
  }

  putLeaveConfig(companyId: number, userId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/attendance_employee_config`, data);
  }
}
