import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';
import { environment } from 'src/environments/environment';

const API_KINI_URL = `${environment.apiUrlKini}`;
const API_KINI_URL_AFNAN = `${environment.apiUrl}/kini`;

@Injectable({
  providedIn: 'root'
})
export class CompanyKiniHTTPService {

  constructor(private http: HttpClient) { }

  getCompanyKini(filter: FilterModel): Observable<Response> {
    // Uncomment kalo filter uda jalan
    // return this.http.get<Response>(`${API_KINI_URL_AFNAN}/company?metadata=true${filter.query}&page=${filter.page}&limit=${filter.size ? filter.size : 20}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}`);

    // Hard Code dulu sementara
    return this.http.get<Response>(`${API_KINI_URL_AFNAN}/company?metadata=true&limit=200`);
  }

  postCompanyKini(companyId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_KINI_URL_AFNAN}/company/${companyId}`, data);
  }

  putCompanyKini(companyId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_KINI_URL_AFNAN}/company/${companyId}`, data);
  }

  deleteCompanyKini(companyId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_KINI_URL}/loan/kini/company/${companyId}`);
  }

  disconnectCompanyKini(companyId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_KINI_URL_AFNAN}/company/${companyId}`);
  }
}
