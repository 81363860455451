import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { ResponseCompanyBank } from '../..';

const API_COMPANY_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyBankHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  getCompanyBank(companyId: number): Observable<ResponseCompanyBank> {
    return this.http.get<ResponseCompanyBank>(`${API_COMPANY_URL}/company/${companyId}/bank`);
  }

  checkCompanyBank(companyId: number, bankId: number): Observable<Response> {
    const hasAccess = (
      GlobalConstants.role.includes('ROLE_HR') ||
      GlobalConstants.role.includes('ROLE_FINANCE') ||
      GlobalConstants.role.includes('ROLE_OWNER')
    );

    const url = `${API_COMPANY_URL}/company/${companyId}/bank/${bankId}/validate`;
    return this.http.get<Response>(url, (hasAccess) ? this.config.GHttpOptionsXVendor('Midtrans_Iris') : this.config.GHttpOptionsDefault());
  }

  postCompanyBank(companyId: number, data: any): Observable<Response> {
    const url = `${API_COMPANY_URL}/company/${companyId}/bank`;
    return this.http.post<Response>(url, data, this.config.GHttpOptionsXVendor('Midtrans_Iris'));
  }

  putCompanyBank(companyId: number, bankId: number, data: any): Observable<Response> {
    const url = `${API_COMPANY_URL}/company/${companyId}/bank/${bankId}`;
    return this.http.put<Response>(url, data, this.config.GHttpOptionsXVendor('Midtrans_Iris'));
  }

  delCompanyBank(companyId: number, bankId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_COMPANY_URL}/company/${companyId}/bank/${bankId}`);
  }
}
