import { Injectable } from '@angular/core';
import { UploadTalentDocumentModel } from '../layouts/main/pages/ats/modules/talent-profile/components/modal-talent/upload-document/upload-document.component';
import { UploadWorkerDocumentModel } from '../layouts/main/pages/employee-list/modules/employee-profile/modal-employee/upload-document/upload-document.component';
import { UploadCompanyDocumentModel } from '../layouts/main/pages/manage-companies/modules/edit-company/modal-company/upload-document/upload-document.component';

export interface UploadWorkerDocsModel {
  docUrl: string;
  docType: string;
  docStatus: string;
}

export interface UploadTalentDocsModel {
  docUrl: string;
  docType: string;
  docStatus: string;
}

export interface WorkerDialogDocsModel {
  CV: UploadWorkerDocsModel[];
  TRANSKRIP_SERTIFIKAT_IJAZAH: UploadWorkerDocsModel[];
  PAKLARING: UploadWorkerDocsModel[];
  KTP_SIM_PASSPOR: UploadWorkerDocsModel[];
  SKCK_NPWP_BPJSTK: UploadWorkerDocsModel[];
}

export interface TalentDialogDocsModel {
  CV: UploadTalentDocsModel[];
  TRANSKRIP_SERTIFIKAT_IJAZAH: UploadTalentDocsModel[];
  PAKLARING: UploadTalentDocsModel[];
  KTP_SIM_PASSPOR: UploadTalentDocsModel[];
  SKCK_NPWP_BPJSTK: UploadTalentDocsModel[];
}

export interface UploadCompanyDocsModel {
  type: string;
  url: string;
}

export interface CompanyDialogDocsModel {
  GALERY: UploadCompanyDocsModel[];
  KTP_NPWP_SIUP: UploadCompanyDocsModel[];
}

@Injectable()
export class UserDocument {
  // Worker
  mappingWorkerDocument(document: any): WorkerDialogDocsModel {
    const documentValue: WorkerDialogDocsModel = {} as WorkerDialogDocsModel;
    documentValue.CV = [];
    documentValue.TRANSKRIP_SERTIFIKAT_IJAZAH = [];
    documentValue.PAKLARING = [];
    documentValue.KTP_SIM_PASSPOR = [];
    documentValue.SKCK_NPWP_BPJSTK = [];

    if (document && document.length) {
      document.forEach((element: any) => {
        if (element.docType.toUpperCase() === 'CV') {
          documentValue.CV.push(element);
        } else if (
          element.docType.toUpperCase() === 'TRANSKRIP_NILAI' ||
          element.docType.toUpperCase() === 'SERTIFIKAT' ||
          element.docType.toUpperCase() === 'IJAZAH'
        ) {
          documentValue.TRANSKRIP_SERTIFIKAT_IJAZAH.push(element);
        } else if (element.docType.toUpperCase() === 'PAKLARING') {
          documentValue.PAKLARING.push(element);
        } else if (element.docType.toUpperCase() === 'KTP' || element.docType.toUpperCase() === 'SIM' || element.docType.toUpperCase() === 'PASSPOR') {
          documentValue.KTP_SIM_PASSPOR.push(element);
        } else {
          documentValue.SKCK_NPWP_BPJSTK.push(element);
        }
      });
    }
    return documentValue;
  }

  private putIntoWorkerDocsArray(
    arrType: 'CV' | 'TRANSKRIP_SERTIFIKAT_IJAZAH' | 'PAKLARING' | 'KTP_SIM_PASSPOR' | 'SKCK_NPWP_BPJSTK',
    existedData: UploadWorkerDocumentModel,
    newData: any,
    isDelete?: boolean
  ): UploadWorkerDocumentModel {
    const data: UploadWorkerDocumentModel = existedData;

    console.log(arrType);
    const indexRaw = data.rawDocument.findIndex((fil: any) => fil.id === newData.id);
    const index = data.document[arrType].findIndex((fil: any) => fil.id === newData.id);

    if (isDelete) {
      data.rawDocument.splice(indexRaw, 1);
      data.document[arrType].splice(index, 1);
      return data;
    }

    if (index !== -1) {
      data.rawDocument[indexRaw].docUrl = newData.docUrl;
      data.document[arrType][index].docUrl = newData.docUrl;
    } else {
      data.rawDocument.push(newData);
      data.document[arrType].push(newData);
    }

    return data;
  }

  afterUploadMappingWorkerDocument(
    existedData: UploadWorkerDocumentModel,
    newData: any,
    isDelete?: boolean
  ): UploadWorkerDocumentModel {
    console.log(newData);
    const arrKsp = ['KTP', 'SIM', 'PASSPOR'];
    const arrSnb = ['SKCK', 'NPWP', 'BPJSTK'];
    const arrTsi = ['TRANSKRIP_NILAI', 'SERTIFIKAT', 'IJAZAH'];

    if (arrKsp.indexOf(newData.docType.toUpperCase()) !== -1) {
      return this.putIntoWorkerDocsArray('KTP_SIM_PASSPOR', existedData, newData, isDelete);
    }
    if (arrSnb.indexOf(newData.docType.toUpperCase()) !== -1) {
      return this.putIntoWorkerDocsArray('SKCK_NPWP_BPJSTK', existedData, newData, isDelete);
    }
    if (arrTsi.indexOf(newData.docType.toUpperCase()) !== -1) {
      return this.putIntoWorkerDocsArray('TRANSKRIP_SERTIFIKAT_IJAZAH', existedData, newData, isDelete);
    }
    if (newData.docType.toUpperCase() === 'CV') {
      return this.putIntoWorkerDocsArray('CV', existedData, newData, isDelete);
    }
    if (newData.docType.toUpperCase() === 'PAKLARING') {
      return this.putIntoWorkerDocsArray('PAKLARING', existedData, newData, isDelete);
    }
    return existedData;
  }

  // Talent
  mappingTalentDocument(document: any): TalentDialogDocsModel {
    const documentValue: TalentDialogDocsModel = {} as TalentDialogDocsModel;
    documentValue.CV = [];
    documentValue.TRANSKRIP_SERTIFIKAT_IJAZAH = [];
    documentValue.PAKLARING = [];
    documentValue.KTP_SIM_PASSPOR = [];
    documentValue.SKCK_NPWP_BPJSTK = [];

    if (document?.length) {
      document.forEach((element: any) => {
        if (element.docType.toUpperCase() === 'CV') {
          documentValue.CV.push(element);
        } else if (
          element.docType.toUpperCase() === 'TRANSKRIP_NILAI' ||
          element.docType.toUpperCase() === 'SERTIFIKAT' ||
          element.docType.toUpperCase() === 'IJAZAH'
        ) {
          documentValue.TRANSKRIP_SERTIFIKAT_IJAZAH.push(element);
        } else if (element.docType.toUpperCase() === 'PAKLARING') {
          documentValue.PAKLARING.push(element);
        } else if (element.docType.toUpperCase() === 'KTP' || element.docType.toUpperCase() === 'SIM' || element.docType.toUpperCase() === 'PASSPOR') {
          documentValue.KTP_SIM_PASSPOR.push(element);
        } else {
          documentValue.SKCK_NPWP_BPJSTK.push(element);
        }
      });
    }
    return documentValue;
  }

  private putIntoTalentDocsArray(
    arrType: 'CV' | 'TRANSKRIP_SERTIFIKAT_IJAZAH' | 'PAKLARING' | 'KTP_SIM_PASSPOR' | 'SKCK_NPWP_BPJSTK',
    existedData: UploadTalentDocumentModel,
    newData: any,
    isDelete?: boolean
  ): UploadTalentDocumentModel {
    const data: UploadTalentDocumentModel = existedData;

    console.log(arrType);
    const indexRaw = data.rawDocument.findIndex((fil: any) => fil.id === newData.id);
    const index = data.document[arrType].findIndex((fil: any) => fil.id === newData.id);

    if (isDelete) {
      data.rawDocument.splice(indexRaw, 1);
      data.document[arrType].splice(index, 1);
      return data;
    }

    if (index !== -1) {
      data.rawDocument[indexRaw].docUrl = newData.docUrl;
      data.document[arrType][index].docUrl = newData.docUrl;
    } else {
      data.rawDocument.push(newData);
      data.document[arrType].push(newData);
    }

    return data;
  }

  afterUploadMappingTalentDocument(
    existedData: UploadTalentDocumentModel,
    newData: any,
    isDelete?: boolean
  ): UploadTalentDocumentModel {
    console.log(newData);
    const arrKsp = ['KTP', 'SIM', 'PASSPOR'];
    const arrSnb = ['SKCK', 'NPWP', 'BPJSTK'];
    const arrTsi = ['TRANSKRIP_NILAI', 'SERTIFIKAT', 'IJAZAH'];

    if (arrKsp.indexOf(newData.docType.toUpperCase()) !== -1) {
      return this.putIntoTalentDocsArray('KTP_SIM_PASSPOR', existedData, newData, isDelete);
    }
    if (arrSnb.indexOf(newData.docType.toUpperCase()) !== -1) {
      return this.putIntoTalentDocsArray('SKCK_NPWP_BPJSTK', existedData, newData, isDelete);
    }
    if (arrTsi.indexOf(newData.docType.toUpperCase()) !== -1) {
      return this.putIntoTalentDocsArray('TRANSKRIP_SERTIFIKAT_IJAZAH', existedData, newData, isDelete);
    }
    if (newData.docType.toUpperCase() === 'CV') {
      return this.putIntoTalentDocsArray('CV', existedData, newData, isDelete);
    }
    if (newData.docType.toUpperCase() === 'PAKLARING') {
      return this.putIntoTalentDocsArray('PAKLARING', existedData, newData, isDelete);
    }
    return existedData;
  }

  // Company
  mappingCompanyDocument(document: any): CompanyDialogDocsModel {
    const documentValue: CompanyDialogDocsModel = {} as CompanyDialogDocsModel;
    documentValue.GALERY = [];
    documentValue.KTP_NPWP_SIUP = [];

    if (document && document.length) {
      document.forEach((element: UploadCompanyDocsModel) => {
        if (element.type.toUpperCase() === 'GALERY_FRONT' || element.type.toUpperCase() === 'GALERY_IN' || element.type.toUpperCase() === 'GALERY_OWNER') {
          documentValue.GALERY.push(element);
        } else {
          documentValue.KTP_NPWP_SIUP.push(element);
        }
      });
    }
    return documentValue;
  }

  private putIntoCompanyDocsArray(
    arrType: 'GALERY' | 'KTP_NPWP_SIUP',
    existedData: UploadCompanyDocumentModel,
    newData: any,
    isDelete?: boolean
  ): UploadCompanyDocumentModel {
    const data: UploadCompanyDocumentModel = existedData;

    const indexRaw = existedData.rawDocument.findIndex((fil: any) => fil.id === newData.id);
    const index = existedData.document[arrType].findIndex((fil: any) => fil.id === newData.id);
    if (isDelete) {
      existedData.rawDocument.splice(indexRaw, 1);
      existedData.document[arrType].splice(index, 1);
      return data;
    }
    if (index !== -1) {
      existedData.rawDocument[indexRaw].url = newData.url;
      existedData.document[arrType][index].url = newData.url;
    } else {
      existedData.rawDocument.push(newData);
      existedData.document[arrType].push(newData);
    }

    return data;
  }

  afterUploadMappingCompanyDocument(
    existedData: UploadCompanyDocumentModel,
    newData: any,
    isDelete?: boolean
  ): UploadCompanyDocumentModel {
    console.log(newData);
    const arrGalery = ['GALERY_FRONT', 'GALERY_IN', 'GALERY_OWNER'];
    const arrKns = ['KTP_OWNER', 'NPWP_OWNER', 'SIUP_OWNER'];

    if (arrGalery.indexOf(newData.type.toUpperCase()) !== -1) {
      return this.putIntoCompanyDocsArray('GALERY', existedData, newData, isDelete);
    }
    if (arrKns.indexOf(newData.type.toUpperCase()) !== -1) {
      return this.putIntoCompanyDocsArray('KTP_NPWP_SIUP', existedData, newData, isDelete);
    }
    return existedData;
  }
}
