import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeBankHTTPService {

  constructor(
    private http: HttpClient,
    private config: HttpConfigService
  ) { }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getEmployeeBank(companyId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/bank`);
  }
  checkEmployeeBank(companyId: number, userId: number, bankId: number): Observable<Response> {
    const hasAccess = (GlobalConstants.role.includes('ROLE_HR') || GlobalConstants.role.includes('ROLE_OWNER'));
    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/bank/${bankId}/validate`;
    return this.http.get<Response>(url, (hasAccess) ? this.config.GHttpOptionsXVendor('Midtrans_Iris') : this.config.GHttpOptionsDefault());
  }
  createEmployeeBank(companyId: number, userId: number, data: any): Observable<Response> {
    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/bank`;
    return this.http.post<Response>(url, data, this.config.GHttpOptionsXVendor('Midtrans_Iris'));
  }
  updateEmployeeBank(companyId: number, userId: number, bankId: number, data: any): Observable<Response> {
    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/bank/${bankId}`;
    return this.http.put<Response>(url, data, this.config.GHttpOptionsXVendor('Midtrans_Iris'));
  }
  deleteEmployeeBank(companyId: number, userId: number, bankId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/bank/${bankId}`);
  }
}
