export class EnumJobStatus {
    public static jobStatusSelect: any = [
        { name: 'Draft', enum: 'DRAFT', disabled: false },
        { name: 'Publish', enum: 'PUBLISHED', disabled: false },
        { name: 'Reject', enum: 'REJECTED', disabled: false },
        { name: 'Expired', enum: 'SOFT_DELETED', disabled: false },
        // { name: 'Kembali ke Sales', enum: 'BACK_TO_SALES', disabled: false }
    ];

    public static generateJobStatus(status: string): string {
        const tempJobStatus = EnumJobStatus.jobStatusSelect.find((fin: any) => fin.enum === status.trim().toUpperCase());
        return tempJobStatus ? tempJobStatus.name : '-';
    }

    public static findJobStatus(status: string): any {
        return EnumJobStatus.jobStatusSelect.find((fin: any) => fin.enum === status.trim().toUpperCase());
    }
}
