export class EnumLanguage {
    public static languageSelect: any = [
        { name: 'Indonesia', enum: 'Indonesia' },
        { name: 'English', enum: 'English' },
        { name: 'Jawa', enum: 'Jawa' },
        { name: 'Madura', enum: 'Madura' },
        { name: 'Minangkabau', enum: 'Minangkabau' },
        { name: 'Musi', enum: 'Musi' },
        { name: 'Bugis', enum: 'Bugis' },
        { name: 'Aceh', enum: 'Aceh' },
        { name: 'Bali', enum: 'Bali' },
        { name: 'Betawi', enum: 'Betawi' },
        { name: 'Batak', enum: 'Batak' },
        { name: 'Gayo', enum: 'Gayo' },
        { name: 'Davayan', enum: 'Davayan' },
        { name: 'Bali', enum: 'Bali' },
        { name: 'Nias', enum: 'Nias' },
        { name: 'Melayu', enum: 'Melayu' },
        { name: 'Mentawai', enum: 'Mentawai' },
        { name: 'Rejang', enum: 'Rejang' },
        { name: 'Enggano', enum: 'Enggano' },
        { name: 'Bengkulu', enum: 'Bengkulu' },
        { name: 'Lampung', enum: 'Lampung' },
        { name: 'Kayu Agung', enum: 'Kayu Agung' },
        { name: 'Deutsch', enum: 'Deutsch' },
        { name: 'China Hokkian', enum: 'China Hokkian' },
        { name: 'China Khek', enum: 'China Khek' },
        { name: 'Mandarin Kanton', enum: 'Mandarin Kanton' },
        { name: 'Mandarin Tiochiu', enum: 'Mandarin Tiochiu' },
        { name: 'Mandarin Hainan', enum: 'Mandarin Hainan' },
        { name: 'Arabic', enum: 'Arabic' },
        { name: 'English(US)', enum: 'English(US)' },
        { name: 'Korea', enum: 'Korea' },
        { name: 'Japan', enum: 'Japan' },
        { name: 'Spanyol', enum: 'Spanyol' },
        { name: 'Italy', enum: 'Italy' },
        { name: 'France', enum: 'France' },
        { name: 'Rusia', enum: 'Rusia' },
        { name: 'Portugis', enum: 'Portugis' },
        { name: 'Hindi', enum: 'Hindi' },
        { name: 'Bengali', enum: 'Bengali' }
    ];
}
