/* tslint:disable:no-non-null-assertion */
import { EnumRegex } from 'src/app/_enum';
import { EnumHowDoYou } from '..';
import { EnumIndustry } from '../../main/pages/manage-companies';

export interface PostCompanyModel {
    name: string;
    email: string | null;
    phone: string;
    industry: string;
    scale: string;
    addressStreet: string | null;
    addressDistrict: string | null;
    addressSubDistrict: string | null;
    addressCity: string | null;
    addressZip: string | null;
    howDoYouKnowUs: string | null;
    registerVia: string | null;
    webUrl: string | null;

    // Buat Update
    description?: string | null;
    url?: string | null;
    location?: string | null;
    externalUrl?: string | null;
    startYear?: number | null;
    totalEmployee?: number | null;
    transactionPerMonth?: number | null;
    revenuePerMonth?: number | null;
    facebookUrl?: string | null;
    twitterUrl?: string | null;
    linkedinUrl?: string | null;
    instagramUrl?: string | null;
    notes?: string | null;
}

export class CompanyInfoClass {

  static objModel = [
    'name', 'url', 'industry', 'scale', 'addressStreet', 'addressDistrict', 'addressSubDistrict',
    'addressCity', 'addressZip', 'description', 'phone', 'email', 'location', 'externalUrl',
    'howDoYouKnowUs', 'startYear', 'totalEmployee', 'transactionPerMonth', 'registerVia', 'revenuePerMonth',
    'webUrl', 'facebookUrl', 'twitterUrl', 'linkedinUrl', 'instagramUrl', 'notes'
  ];

  public static companyInfoValidation(payload: PostCompanyModel, isEdit?: boolean): string | null {
      const objPayload = Object.keys(payload);
      const objInvalid: string[] = [];


      // ? Validasi Object Key Payload
      objPayload.forEach((element) => {
          if (!CompanyInfoClass.objModel.includes(element)) { objInvalid.push(element); }
      });
      if (objInvalid.length) { return objInvalid.toString() + ' is invalid payload'; }


      // ? Validasi Mandatory Payload
      if (!payload.howDoYouKnowUs || !payload.industry || !payload.name || !payload.phone || !payload.scale) {
          return 'empty mandatory payload detected';
      }


      // ? Validasi Regex Payload
      const regexEmail = EnumRegex.parsingRegex('email')!.test(payload.email!);
      const regexWebUrl = EnumRegex.parsingRegex('web')!.test(payload.webUrl!);
      const regexPhone = EnumRegex.parsingRegex('phone')!.test(payload.phone!);
      const regexScale = EnumRegex.parsingRegex('scale')!.test(payload.scale!);
      const regexStreet = EnumRegex.parsingRegex('address')!.test(payload.addressStreet!);
      const regexCity = EnumRegex.parsingRegex('letter_35')!.test(payload.addressCity!);
      const regexDistrict = EnumRegex.parsingRegex('letter_25')!.test(payload.addressDistrict!);
      const regexSubDistrict = EnumRegex.parsingRegex('alpha_sub_district_25')!.test(payload.addressSubDistrict!);
      const regexZip = EnumRegex.parsingRegex('zip')!.test(payload.addressZip!);
      const regexZipChar = !(payload.addressZip && payload.addressZip[0] === '0');
      const industryChecker = EnumIndustry.findIndustry(payload.industry!);
      const howDoYouChecker = EnumHowDoYou.findHowDoYou(payload.howDoYouKnowUs!);
      if (payload.email && (
        !regexEmail ||
        payload.email.split('@')[1].includes('gnail.') ||
        payload.email.split('@')[1].includes('.con'))
      ) {
          return 'email invalid';
      }
      if (payload.webUrl && !regexWebUrl) { return 'web url invalid'; }
      if (payload.registerVia !== 'wmt_web' && !isEdit) { return 'register via invalid'; }
      if (payload.addressStreet && !regexStreet) { return 'street invalid'; }
      if (payload.addressCity && (!regexCity/*  || !EnumCity.findCity(payload.addressCity) */)) { return 'city invalid'; }
      if (payload.addressDistrict && !regexDistrict) { return 'district invalid'; }
      if (payload.addressSubDistrict && !regexSubDistrict) { return 'sub district invalid'; }
      if (payload.addressZip && (!regexZip || !regexZipChar)) { return 'zip invalid'; }
      if (!regexPhone) { return 'phone invalid'; }
      if (!regexScale) { return 'scale invalid'; }
      if (!industryChecker) { return 'industry invalid'; }
      if (!howDoYouChecker) { return 'how do you know us invalid'; }

      return null;
  }
}
