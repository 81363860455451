import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service';
import { ResponseItemType } from '../payroll-detail.service';
import { FilterModel } from 'src/app/_class';

// const API_PAYROLL_URL_V3 = `${environment.apiUrl}`;
const API_PAYROLL_URL_V3 = `${environment.apiUrl}`;
const API_PAYROLL_URL = `${environment.apiUrlV4}`;

@Injectable({
  providedIn: 'root'
})
export class PayrollDetailHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  getPayrollItem(
    companyId: number,
    id: number,
    filter: FilterModel
  ): Observable<ResponseItemType> {
    const url = `${API_PAYROLL_URL}/company/${companyId}/payroll/${id}?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : '20'}&sort=${filter.sort ? filter.sort : 'wpr.full_name'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<ResponseItemType>(
      url,
      environment.production ? this.config.GHttpOptionsDefault() : this.config.GHttpOptionsXGandalf()
    );
  }
  getPayrollItemStatus(companyId: number, id: number, idItem: number): Observable<Response> {
    return this.http.get<Response>(`${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/item/${idItem}/update`);
  }
  retryPayrollItem(companyId: number, id: number, idItem: number): Observable<Response> {
    const url = `${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/item/${idItem}/payout_retry`;
    return this.http.post<Response>(
      url,
      null,
      (environment.production) ? this.config.GHttpOptionsDefault() : this.config.GHttpOptionsXGandalf()
    );
  }
  sendEmailPayslip(companyId: number, userId: number, index: number): Observable<Response> {
    return this.http.get<Response>(
      `${API_PAYROLL_URL_V3}/company/${companyId}/employee/${userId}/payslips?index=${index}&is_download=true`
    );
  }
  downloadPayslip(companyId: number, userId: number, id: number): Observable<any> {
    const url = `${API_PAYROLL_URL_V3}/company/${companyId}/employee/${userId}/payslip/${id}`;
    return this.http.get(url, this.config.GHttpOptionsPdf());
  }
  downloadPayslipZip(companyId: number, id: number): Observable<any> {
    const url = `${API_PAYROLL_URL_V3}/company/${companyId}/payroll/${id}/payslip`;
    return this.http.get(url, this.config.GHttpOptionsPdf());
  }
  createPayrollItem(companyId: number, id: number, params: any): Observable<Response> {
    const url = `${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/item`;
    return this.http.post<Response>(
      url,
      params,
      (environment.production) ? this.config.GHttpOptionsDefault() : this.config.GHttpOptionsXGandalf()
    );
  }
  updatePayrollItem(companyId: number, id: number, idItem: number, params: any): Observable<Response> {
    const url = `${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/item/${idItem}`;
    return this.http.put<Response>(
      url,
      params,
      (environment.production) ? this.config.GHttpOptionsDefault() : this.config.GHttpOptionsXGandalf()
    );
  }
  deletePayrollItem(companyId: number, id: number, idItem: number): Observable<any> {
    return this.http.delete<any>(`${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/item/${idItem}`);
  }
  uploadPayrollItem(companyId: number, id: number, params: any): Observable<Response> {
    return this.http.post<Response>(`${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/bulk_upload`, { items: params });
  }
}
