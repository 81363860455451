import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadWorkerDocsModel } from 'src/app/_class';
import { environment } from 'src/environments/environment';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeDocumentHTTPService {

  constructor(private http: HttpClient) { }

  uploadDocument(userId: number, item: UploadWorkerDocsModel): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/document`, item);
  }
  updateDocument(userId: number, id: number, item: UploadWorkerDocsModel): Observable<Response> {
    return this.http.put<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/document/${id}`, item);
  }
  deleteDocumentById(userId: number, id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/document/${id}`);
  }
}
