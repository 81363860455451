import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import * as sha512 from 'js-sha512';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modified = request.clone({
      setHeaders: localStorage.getItem('gid_token') && localStorage.getItem('gid_token') !== 'null' ? {
        Authorization: 'Bearer ' + localStorage.getItem('gid_token'),
        'X-Signature': this.generateSignature(request.url),
        'X-Platform': 'web_wmt'
      } : {
        'X-Signature': this.generateSignature(request.url),
        'X-Platform': 'web_wmt'
      }
    });

    return next.handle(modified);
  }

  generateSignature(url: string) {
    const rawSignature = (environment.baseUrlSHA + '/' + url + environment.apiKey)
      .trim()
      .replace(/\s/g, '%20')
      .replace('//', '/');

    if (!environment.production && !environment.staging) {
      console.group();
      console.log(rawSignature);
      console.log(sha512.sha512(rawSignature));
      console.groupEnd();
    }
    
    return sha512.sha512(rawSignature);
  }

  // sha512(rawSignature: string) {
  //   return crypto.subtle.digest("SHA-512", new TextEncoder().encode(rawSignature)).then(buf => {
  //     return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
  //   });
  // }
}
