export class EnumEmploymeeRelationship {
    public static employeeRelationshipSelect: any = [
        {name: 'Pasangan', enum: 'spouse'},
        {name: 'Orang Tua', enum: 'parent'},
        {name: 'Saudara', enum: 'sibling'},
        {name: 'Teman', enum: 'friend'},
        {name: 'Kolega', enum: 'colleagues'},
        {name: 'Tetangga', enum: 'neighbor'},
        {name: 'Bibi', enum: 'aunt'},
        {name: 'Paman', enum: 'uncle'},
        {name: 'Nenek', enum: 'grandmother'},
        {name: 'Kakek', enum: 'grandfather'},
        {name: 'Lainnya', enum: 'other relatives'},
    ];

    public static generateRelation(relation: string): string {
        let tempRelation: any;
        tempRelation = EnumEmploymeeRelationship.employeeRelationshipSelect.find((fin: any) => fin.enum === relation.trim().toLowerCase());
        return (tempRelation) ? tempRelation.name : '-';
    }
}
