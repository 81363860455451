import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service/http-config.service';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeProfileHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getEmployeeProfile(companyId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}`);
  }
  editEmployee(companyId: number, userId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}`, data);
  }

  // Roles
  getEmployeeRoles(companyId: number, userId: number): Observable<Response> {
    return this.http.get<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/roles`);
  }
  updateEmployeeRoles(companyId: number, userId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/roles`, data);
  }
  deleteEmployeeRoles(companyId: number, userId: number, data: any): Observable<Response> {
    return this.http.delete<Response>(
      `${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/roles`,
      this.config.GHttpOptionsPayload(data)
    );
  }
}
