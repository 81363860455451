import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// import { AuthService } from '../_service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  constructor(
    // private authService: AuthService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!environment.production) {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            console.error('An error occurred:', error.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
          }
        }

        // ? Handling kalo dapet unauthorized
        // Umumnya karena akses dihapus secara dadakan dan dapet balikan dari API
        if (error.status === 403 && (
            !error.url?.includes('project') &&
            !error.url?.includes('type=sales_motorist') &&
            !error.url?.includes('balance')
          )) {
          localStorage.removeItem('companyStorer');
          // const url = this.authService.conditionalRouting(true);
          this.router.navigateByUrl('/simple-page');

          setTimeout(() => {
            window.location.reload();
          }, 250);
        }
        return throwError(error);
      })
    );
  }
}
