import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { FilterModel } from 'src/app/_class';

import * as moment from 'moment';
moment.locale('id');

const API_JOB_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class ManageJobHTTPService {

  constructor(
    private http: HttpClient
  ) { }

  getJobs(filter: FilterModel): Observable<any>{
    const url = `${API_JOB_URL}/job?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : 20}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<any>(url);
  }

  getJobsDashboard(query: any): Observable<any>{
    const url = `${API_JOB_URL}/job?${query ? query + '&' : ''}metadata=true`;
    return this.http.get<any>(
      url, {
        observe: 'response'
      }
    );
  }

  getJobsDetail(jobId: any): Observable<Response>{
    return this.http.get<any>(`${API_JOB_URL}/job/${jobId}`);
  }

  postJob(data: any): Observable<Response> {
    return this.http.post<Response>(`${API_JOB_URL}/job`, data);
  }

  republishJob(id: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_JOB_URL}/job/${id}/republish`, data);
  }

  patchJob(id: number, status: any): Observable<Response> {
    return this.http.patch<Response>(`${API_JOB_URL}/job/${id}`, {status});
  }

  putJob(id: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_JOB_URL}/job/${id}`, data);
  }
}
