import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, map, switchMap} from 'rxjs/operators';

import {SessionStorage} from 'src/app/_class';
import {HttpConfigService, TagsService} from 'src/app/_service';
import {IndexLeaveVariables, ResponseLeave} from '..';
import {LeaveHTTPService} from './attendance-http';

import * as moment from 'moment';
moment.locale('id');

export type ResponseType = ResponseLeave | undefined;

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  currentLeave$: Observable<ResponseType>;
  currentLeaveHistory$: Observable<ResponseType>;
  currentLeaveSubject: BehaviorSubject<ResponseType>;
  currentLeaveHistorySubject: BehaviorSubject<ResponseType>;

  constructor(
    private config: HttpConfigService,
    private tagsService: TagsService,
    private leaveHttp: LeaveHTTPService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();

    this.currentLeaveSubject = new BehaviorSubject<ResponseType>(undefined);
    this.currentLeaveHistorySubject = new BehaviorSubject<ResponseType>(undefined);
    this.currentLeave$ = this.currentLeaveSubject.asObservable();
    this.currentLeaveHistory$ = this.currentLeaveHistorySubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // public method
  getLeave(
    companyId: number,
    start_date: string,
    end_date: string,
    query?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortAsc?: boolean
  ): Observable<ResponseType> {

    this.isLoadingSubject.next(true);
    return this.leaveHttp.getDataLeave(
      companyId,
      start_date,
      end_date,
      query,
      page,
      size,
      sortBy,
      sortAsc
    ).pipe(
      map((result) => {
        if (result) {
          this.currentLeaveSubject.next(result);
        }
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getLeaveHistory(
    companyId: number,
    userId: number,
    query?: string,
    page?: number,
    size?: number,
    sortBy?: string,
    sortAsc?: boolean
  ): Observable<ResponseType> {

    this.isLoadingSubject.next(true);
    return this.leaveHttp.getHistoryLeave(
      companyId,
      userId,
      query,
      page,
      size,
      sortBy,
      sortAsc
    ).pipe(
      map((result) => {
        if (result) {
          this.currentLeaveHistorySubject.next(result);
        }
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  patchLeave(
    companyId: number,
    userId: number,
    id: number,
    params: {
      status: string,
      reason?: string
    },
    getParams: {
      start_date: string,
      end_date: string,
      query?: string,
      page?: number,
      size?: number,
      sortBy?: string,
      sortAsc?: boolean
    }): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.leaveHttp.patchDataLeave(companyId, userId, id, params).pipe(
      switchMap(() => this.getLeave(
        companyId,
        getParams.start_date,
        getParams.end_date,
        getParams.query,
        getParams.page,
        getParams.size,
        getParams.sortBy,
        getParams.sortAsc
      )),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  // Variables Initial
  initVariable(): IndexLeaveVariables {
    const varModel = {} as IndexLeaveVariables;

    const Session = SessionStorage.getStorageJson('leaveTags');
    const tempUser = this.tagsService.filterSession(Session, 'user');
    const tempType = this.tagsService.filterSession(Session, 'leave_type');
    const tempStatus = this.tagsService.filterSession(Session, 'leave_status');
    const tempDate = this.tagsService.filterSession(Session, 'date');

    varModel.tempQuery = sessionStorage.getItem('leaveQuery');
    varModel.userPick = (tempUser.length) ? tempUser[0].value : null;
    varModel.typePick = (tempType.length) ? tempType[0].value : null;
    varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    varModel.page = SessionStorage.getStorageNumber('leavePageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('leavePageSize', 20);
    varModel.sortBy = SessionStorage.getStorage('leaveSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('leaveSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(
    userIdPick: number,
    typePick: FormControl,
    statusPick: FormControl,
    tags: {key: string, value: string}[]
  ): string {
    if (typePick.value) {
      tags = this.tagsService.updateKey(tags, 'leave_type', typePick, 'array');
    }
    if (statusPick.value) {
      tags = this.tagsService.updateKey(tags, 'leave_status', statusPick, 'array');
    }

    const userId = userIdPick ? `user_id=${userIdPick}` : null;
    const type = typePick.value ? `type=${typePick.value}` : '';
    const status = statusPick.value ? `status=${statusPick.value}` : '';

    const data = [
      userId, type, status
    ];

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('leaveQuery', tempQuery);
    return tempQuery;
  }

  eventFilter(tags: {key: string, value: string}[], event?: any): string {
    let userPick = '';
    const tempUserPick = new FormControl();

    if (event.id) {
      userPick = event.fullName;
      tempUserPick.setValue(event.fullName);
      tags = this.tagsService.updateKey(tags, 'user', tempUserPick);
    } else { // Reset Filter
      sessionStorage.removeItem('leaveTags');
      sessionStorage.removeItem('leaveQuery');
      sessionStorage.removeItem('leaveSortBy');
      sessionStorage.removeItem('leaveSortAsc');
    }
    return userPick;
  }

  removeTags(
    tags: {key: string, value: string}[],
    typePick: FormControl,
    statusPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
      if (tag.key === 'leave_type') { tags = this.tagsService.removeKey(tags, tag.key, typePick, 'array', indexTagValue); }
      else if (tag.key === 'leave_status') { tags = this.tagsService.removeKey(tags, tag.key, statusPick, 'array', indexTagValue); }
      else { tags = this.tagsService.removeKey(tags, tag.key); }

      sessionStorage.setItem('leaveTags', JSON.stringify(tags));
  }
  // End Filter Logic
}
