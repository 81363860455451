<div
    *ngIf="data"
    class="d-flex flex-column"
    >
    <div class="d-flex justify-content-between">
        <div class="d-flex my-auto">
            <span class="svg-icon svg-icon-xl mr-3" [inlineSVG]="svgTitle"></span>
            <h6 class="my-auto">{{data.title}}</h6>
        </div>
        <button class="btn btn-icon" (click)="snackbar.dismiss()">
            <span class="svg-icon svg-icon-xl" [inlineSVG]="svgClose"></span>
        </button>
    </div>
    <ng-container  *ngIf="data.message">
        <div><hr></div>
        <span *ngIf="data.message" class="textColor">{{data.message}}</span>
    </ng-container>
    <ng-container *ngIf="data.error">
        <div class="d-flex justify-content-between">
            <span *ngIf="data.error" class="textColor">{{data.error}}</span>
            <span *ngIf="data.key" class="textColor my-auto">{{data.key}}</span>
        </div>
    </ng-container>
    <ng-container *ngIf="data.url">
        <div><hr></div>
        <span *ngIf="data.url" class="textColor">{{data.url}}</span>
    </ng-container>
</div>
