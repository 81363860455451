/* tslint:disable:no-non-null-assertion */
import { Injectable } from '@angular/core';
import { CurriculumVitae } from '../_class';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  pdfMake: any;

  rotateImage = false;

  constructor() { }

  loadPdfMaker(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const pdfMakeModule = await import('pdfmake/build/pdfmake'!);
        const pdfFontsModule = await import('pdfmake/build/vfs_fonts'!);
        this.pdfMake = pdfMakeModule.default;
        this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;

        resolve('success load');
      } catch (error) {
        reject(error);
      }
    });
  }

  convertWorkerToPDF(
    profileData: any,
    userPreferences: any,
    experienceData: any,
    educationData: any
  ): Promise<any> {    
    return new Promise(async (resolve, reject) => {
      try {
        console.log(profileData.imageUrl);
    
        this.rotateImage =
          (document.getElementById('profileImage')!.clientHeight /
          document.getElementById('profileImage')!.clientWidth) > 1.7;
        console.log(this.rotateImage);
    
        const generatedExperiences = await CurriculumVitae.generateExperiences(experienceData);
        const generatedEducation = await CurriculumVitae.generateEducation(educationData);
        const url = profileData.imageUrl ? profileData.imageUrl : './assets/media/users/blank.png';
      
        const profile = await CurriculumVitae.generateProfile(url);
        console.log(profile);
  
        let image = await CurriculumVitae.getBase64ImageFromUrl(url);

        // Chrome || Firefox
        if (image === 'data:' || image === 'data:application/octet-stream;base64,') {
          image = await CurriculumVitae.getBase64ImageFromUrl('./assets/media/users/blank.png');
        }
        console.log(image);

        const loadPdf = await this.loadPdfMaker();
        console.log(loadPdf);
        
        const pdfMake = this.pdfMake.createPdf(
          CurriculumVitae.pdfConfig(
            profileData,
            userPreferences,
            generatedExperiences,
            generatedEducation,
            image
        ));

        // Nangkep kalo ada error
        pdfMake.getStream();
        console.log(pdfMake);

        // Download kalo gaada error
        pdfMake.download(profileData.fullName + '.pdf');

        resolve('ok');
  
      } catch (error) {
        reject(error);
      }
    });
  }
}
