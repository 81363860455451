import { Injectable } from "@angular/core";
import { SessionStorage } from "src/app/_class";
import { IndexCompanyVariables } from "../models/company-data.model";

@Injectable()
export class CompanyKiniClass {

    // Variables Initial
  initVariable(): IndexCompanyVariables {
    const varModel = {} as IndexCompanyVariables;

    varModel.tempQuery = SessionStorage.getStorage('companyKiniQuery');
    varModel.companyPick = SessionStorage.getStorage('companyKiniSelected');
    varModel.page = SessionStorage.getStorageNumber('companyKiniPageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('companyKiniPageSize', 20);
    varModel.sortBy = SessionStorage.getStorage('companyKiniSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('companyKiniSortAsc', 'true');

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(companyPick: string): string {
    const company = (companyPick && companyPick !== 'ignore') ? `name=${companyPick}` : null;

    const data = [company];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('companyKiniQuery', tempQuery);
    return tempQuery;
  }

  eventFilter(event?: any, resetFilter?: boolean): string {
    let companyPick = '';

    if (event.id) {
      companyPick = event.name;
      sessionStorage.setItem('companyKiniSelected', companyPick);
    } else { // Reset Filter
      if (!resetFilter) {
        sessionStorage.removeItem('companyKiniSelected');
        sessionStorage.removeItem('companyKiniSortBy');
        sessionStorage.removeItem('companyKiniSortAsc');
      }
    }
    return companyPick;
  }
  // End Filter Logic

}