export interface BankIntructionModel {
    bankCode: 'mandiri' | 'bca' | 'bni' | 'permata' | 'bri';
    Atm: AtmModel[];
    IBanking: IBankingModel[];
    MBanking: MBankingModel[];
}

interface AtmModel {
    name: string;
    description: string[];
}
interface IBankingModel {
    name: string;
    description: string[];
}
interface MBankingModel {
    name: string;
    description: string[];
}

export class XenditBankInstruction {

    static getInstruction(bankCode: 'mandiri' | 'bca' | 'bni' | 'permata' | 'bri', virtualAccount: string): BankIntructionModel | null {
        let bankInstruction: BankIntructionModel | null = null;

        if (bankCode === 'mandiri') {

            bankInstruction = {
                bankCode: 'mandiri',
                Atm: [
                    {
                        name: 'LANGKAH 1: CARI ATM TERDEKAT',
                        description: [
                            'Masukkan kartu ATM Anda dan pilih "Bahasa Indonesia"',
                            'Masukkan PIN, lalu pilih "ENTER"',
                            'Pilih "PAYMENT", lalu pilih "MULTI PAYMENT"'
                        ]
                    },
                    {
                        name: 'LANGKAH 2: DETAIL PEMBAYARAN',
                        description: [
                            'Masukkan kode perusahaan <font class="text-primary font-weight-bolder">"88608" (88608 XENDIT)</font>, lalu tekan "BENAR"',
                            `Masukkan Nomor Virtual Account <font class="text-primary font-weight-bolder">${virtualAccount}</font>, lalu tekan "BENAR"`,
                            'Masukkan jumlah yang akan ditransfer, lalu tekan "BENAR"',
                            'Akan tampil detail merchant, pilih nomor 1 sesuai dengan jumlah tagihan lalu tekan "YA"',
                            'Konfirmasi pembayaran akan ditampilkan. Pilih "YA", untuk melanjutkan'
                        ]
                    },
                    {
                        name: 'LANGKAH 3: TRANSAKSI BERHASIL',
                        description: [
                            'Simpan struk sebagai bukti pembayaran',
                            'Transaksi Anda berhasil',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    },
                ],
                IBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Buka website Mandiri Internet Banking <a href="https://ibank.bankmandiri.co.id" target="_blank">https://ibank.bankmandiri.co.id</a>',
                            'Login dengan USER ID dan PASSWORD Anda',
                            'Masuk ke halaman Beranda, lalu pilih "Pembayaran"',
                            'Pilih "Pembayaran Multi"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih <font class="text-primary font-weight-bolder">88608</font> XENDIT sebagai penyedia layanan',
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Tekan lanjutkan',
                            'Jika semua detail sudah benar dan kemudian klik "KONFIRMASI"',
                            'Masukkan PIN / Kode Tantangan Token'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Setelah pembayaran berhasil, simpan struk transaksi atau screenshot layar sebagai bukti pembayaran',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ],
                MBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Buka Livin by Mandiri, lalu masukkan PASSWORD atau lakukan verifikasi wajah',
                            'Pilih "Pembayaran"',
                            'Cari "Xendit <font class="text-primary font-weight-bolder">88608</font>"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih "Xendit <font class="text-primary font-weight-bolder">88608</font>" sebagai penyedia layanan',
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Nominal yang akan ditransfer akan otomatis terisi',
                            'Tinjau dan konfirmasi detail transaksi',
                            'Selesaikan transaksi dengan memasukkan MPIN Anda'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Setelah pembayaran berhasil, simpan struk transaksi atau screenshot layar sebagai bukti pembayaran',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ]
            };
        }
        if (bankCode === 'bca') {

            bankInstruction = {
                bankCode: 'bca',
                Atm: [
                    {
                        name: 'LANGKAH 1: CARI ATM TERDEKAT',
                        description: [
                            'Masukkan kartu ATM dan PIN BCA Anda',
                            'Masukkan PIN ATM Anda'
                        ]
                    },
                    {
                        name: 'LANGKAH 2: DETAIL PEMBAYARAN',
                        description: [
                            'Pilih Menu "Transaksi Lainnya"',
                            'Pilih "Transfer"',
                            'Pilih "Ke BCA Virtual Account"',
                            `Masukkan Nomor Virtual Account <font class="text-primary font-weight-bolder">${virtualAccount}</font>. Tekan "Benar" untuk melanjutkan`,
                            // tslint:disable-next-line:max-line-length
                            'Verifikasi detail Akun Virtual lalu masukkan jumlah yang akan ditransfer dan pilih "Benar" untuk mengonfirmasi',
                            'Konfirmasikan detail transaksi Anda yang ditampilkan',
                            'Pilih "Ya" jika detailnya benar atau "Tidak" jika detailnya tidak benar'
                        ]
                    },
                    {
                        name: 'LANGKAH 3: TRANSAKSI BERHASIL',
                        description: [
                            'Anda telah menyelesaikan transaksi Anda. Pilih "Tidak" untuk mengakhiri transaksi',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    },
                ],
                IBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Login ke KlikBCA Perorangan (<a href="https://ibank.klikbca.com" target="_blank">https://ibank.klikbca.com</a>)',
                            'Pilih "Transfer", lalu pilih "Transfer ke BCA Virtual Account"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            `Masukkan Nomor Virtual Account <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Pilih "Lanjutkan" untuk melanjutkan pembayaran Anda',
                            'Masukkan "RESPON KEYBCA APPLI 1" yang tertera di Token BCA Anda, lalu klik tombol "Kirim"',
                            'Masukkan kode token otentikasi'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Anda telah menyelesaikan transaksi Anda. Pilih "Tidak" untuk mengakhiri transaksi',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ],
                MBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Buka BCA Mobile App',
                            'Pilih "m-BCA", lalu pilih "m-Transfer"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih "m-BCA", lalu pilih "m-Transfer"',
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>, lalu tekan "OK"`,
                            'Klik tombol "Kirim" di sudut kanan atas untuk melanjutkan',
                            'Klik "OK" untuk melanjutkan',
                            'Masukkan PIN Anda untuk mengotorisasi transaksi'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Anda telah menyelesaikan transaksi Anda. Pilih "Tidak" untuk mengakhiri transaksi',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ]
            };
        }
        if (bankCode === 'bni') {

            bankInstruction = {
                bankCode: 'bni',
                Atm: [
                    {
                        name: 'LANGKAH 1: CARI ATM TERDEKAT',
                        description: [
                            'Masukkan Kartu ATM Anda',
                            'Pilih bahasa pilihan Anda',
                            'Masukkan PIN ATM Anda'
                        ]
                    },
                    {
                        name: 'LANGKAH 2: DETAIL PEMBAYARAN',
                        description: [
                            'Pilih "Transaksi Lainnya"',
                            'Pilih "Transfer"',
                            'Pilih jenis rekening yang Anda gunakan untuk mentransfer (yaitu dari rekening Tabungan)',
                            'Pilih "Penagihan Akun Virtual"',
                            `Masukkan nomor akun virtual <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Jumlah yang ditagih akan muncul di layar',
                            'Konfirmasi transaksi, jika sudah benar lanjutkan'
                        ]
                    },
                    {
                        name: 'LANGKAH 3: TRANSAKSI BERHASIL',
                        description: [
                            'Transaksi Anda berhasil',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    },
                ],
                IBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Masukkan alamat berikut <a href="https://ibank.bni.co.id" target="_blank">https://ibank.bni.co.id</a> dan klik "Enter"',
                            'Masukkan ID Pengguna dan Kata Sandi Anda'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih menu "Transfer"',
                            'Pilih menu "Virtual Account Billing"',
                            `Masukkan nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Pilih jenis akun yang Anda gunakan untuk mentransfer dan tekan "Lanjutkan"',
                            'Jumlah yang ditagih akan muncul di layar',
                            'Masukkan kode token otentikasi'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Transaksi Anda berhasil',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ],
                MBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Masukkan ID Pengguna dan Kata Sandi Anda',
                            'Pilih menu "Transfer"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih menu "Virtual Account Billing" lalu pilih debit rekening',
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font> pada menu "Input New"`,
                            'Jumlah yang ditagih akan muncul di layar',
                            'Konfirmasikan transaksi dan masukkan kata sandi Anda'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Transaksi Anda berhasil',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ]
            };
        }
        if (bankCode === 'permata') {

            bankInstruction = {
                bankCode: 'permata',
                Atm: [
                    {
                        name: 'LANGKAH 1: CARI ATM TERDEKAT',
                        description: [
                            'Masukkan kartu ATM Permata dan PIN Anda',
                            'Masukkan PIN ATM Anda'
                        ]
                    },
                    {
                        name: 'LANGKAH 2: DETAIL PEMBAYARAN',
                        description: [
                            'Pilih Menu "Transaksi Lainnya"',
                            'Pilih "Transfer"',
                            'Pilih "Transfer Antar Rekening Permata Bank"',
                            `Masukkan Nomor Virtual Account <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Pilih jenis rekening yang Anda gunakan untuk mentransfer (yaitu dari rekening Tabungan)',
                            'Konfirmasikan detail transaksi Anda yang ditampilkan'
                        ]
                    },
                    {
                        name: 'LANGKAH 3: TRANSAKSI BERHASIL',
                        description: [
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    },
                ],
                IBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Buka <a href="https://new.permatanet.com" target="_blank">https://new.permatanet.com</a> dan login'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih "Pembayaran"',
                            'Pilih "Pembayaran Tagihan"',
                            'Pilih "Akun Virtual"',
                            'Pilih sumber pembayaran Anda',
                            'Pilih "Masukkan Daftar Tagihan Baru"',
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Periksa informasi detail Anda dan lanjutkan',
                            'Masukkan kode respons token SMS'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Transaksi Anda berhasil',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ],
                MBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Buka Aplikasi Permata',
                            'Pilih "Transfer"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            'Pilih "Rekening PermataBank"',
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>`,
                            'Masukkan token otentikasi'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Transaksi Anda berhasil',
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ]
            };
        }
        if (bankCode === 'bri') {

            bankInstruction = {
                bankCode: 'bri',
                Atm: [
                    {
                        name: 'LANGKAH 1: CARI ATM TERDEKAT',
                        description: [
                            'Masukkan kartu, pilih bahasa, lalu masukkan PIN Anda',
                            'Pilih "Menu Lainnya" dan pilih "Pembayaran"',
                            'Pilih "Pembayaran Lainnya" dan pilih "Briva"'
                        ]
                    },
                    {
                        name: 'LANGKAH 2: DETAIL PEMBAYARAN',
                        description: [
                            `Masukkan nomor virtual account <font class="text-primary font-weight-bolder">${virtualAccount}</font> dan nominal yang ingin Anda bayarkan`,
                            'Cek data transaksi dan tekan "YA"'
                        ]
                    },
                    {
                        name: 'LANGKAH 3: TRANSAKSI BERHASIL',
                        description: [
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    },
                ],
                IBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Login ke <a href="https://ib.bri.co.id/ib-bri/" target="_blank">https://ib.bri.co.id/ib-bri/</a>, masukkan USER ID dan Password Anda',
                            'Pilih "Pembayaran" dan pilih "Briva"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>, masukkan jumlah yang ingin Anda bayar, dan klik kirim`,
                            'Masukkan kembali kata sandi Anda bersama dengan kode otentikasi dari bank internet mToken'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ],
                MBanking: [
                    {
                        name: 'Langkah 1: Masuk Akun Anda',
                        description: [
                            'Login ke BRI Mobile Banking, masukkan USER ID dan PIN Anda',
                            'Pilih "Pembayaran" dan pilih "Briva"'
                        ]
                    },
                    {
                        name: 'Langkah 2: Detail Pembayaran',
                        description: [
                            `Masukkan Nomor Virtual Account Anda <font class="text-primary font-weight-bolder">${virtualAccount}</font>, dan jumlah yang ingin Anda bayar`,
                            'Masukkan PIN Anda dan klik "Kirim"'
                        ]
                    },
                    {
                        name: 'Langkah 3: Transaksi Berhasil',
                        description: [
                            'Setelah transaksi pembayaran selesai, faktur ini akan diperbarui secara otomatis. Ini mungkin memakan waktu hingga 5 menit'
                        ]
                    }
                ]
            };
        }

        return bankInstruction;
    }

}
