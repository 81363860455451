import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

import {PaginationClass} from 'src/app/_class';
import {CompanyHolidayModel, NationalHolidayModel} from '../models/holiday.model';
import {
  ShiftAssignmentDay,
  ShiftAssignmentDBModel,
  ShiftAssignmentEmployee,
  ShiftAssignmentModel,
  ShiftBoardModel,
  ShiftSchedule
} from '../models/shift-assignment.model';
import {CompanyShiftModel} from '../../manage-companies';

import * as moment from 'moment';

moment.locale('id');

export interface WeekNavAssignment {
  dayActive: number;
  weekNow: number;
  weekActive: number;
  monthActive: string;
  yearNow: number;
  yearActive: number;
  lastWeekofYear: number;
}

export class ShiftAssignment {

  static initNavigation(): WeekNavAssignment {
    const weekNav: WeekNavAssignment = {} as WeekNavAssignment;

    weekNav.dayActive = moment().isoWeekday();
    weekNav.weekNow = weekNav.weekActive = moment().isoWeek();
    weekNav.monthActive = moment().format('MMM');
    weekNav.yearNow = weekNav.yearActive = moment().year();
    weekNav.lastWeekofYear = moment().endOf('year').isoWeek();

    return weekNav;
  }

  static weekNav(weekNav: WeekNavAssignment, next: boolean): WeekNavAssignment {
    if (next) {
      if (weekNav.lastWeekofYear !== weekNav.weekActive) {
        weekNav.weekActive++;

      } else if (weekNav.yearActive > weekNav.yearNow) { // * Cegatan Active Week ke Tahun Depan
        weekNav.weekActive = 1;
        weekNav.yearActive++;
        weekNav.lastWeekofYear = moment(weekNav.yearActive).endOf('year').isoWeek();

      } else { // * Cegatan Active Week kembali ke Tahun Sekarang
        weekNav.weekActive = 1;
        weekNav.lastWeekofYear = moment().endOf('year').isoWeek();
        weekNav.yearActive++;
      }

      weekNav.monthActive = moment().subtract((weekNav.weekNow - weekNav.weekActive), 'weeks').utcOffset('+07:00').format('MMM');

      // * Buat Ndebug
      // console.log(
      //   'Active Week: ' + this.weekActive,
      //   '\nActive Year: ' + this.yearActive,
      //   '\nActive last Week of Year: ' + this.lastWeekofYear
      // );
      return weekNav;
    }

    if (weekNav.weekActive > 1) {
      weekNav.weekActive--;

    } else { // * Cegatan Active Week ke Tahun Lalu
      weekNav.weekActive = weekNav.lastWeekofYear = moment().startOf('year').subtract(1, 'days').isoWeek();
      weekNav.yearActive--;
    }

    weekNav.monthActive = moment().subtract((weekNav.weekNow - weekNav.weekActive), 'weeks').utcOffset('+07:00').format('MMMM');
    return weekNav;

    // * Buat Ndebug
    // console.log(
    //   'Active Week: ' + this.weekActive,
    //   '\nActive Year: ' + this.yearActive,
    //   '\nActive last Week of Year: ' + this.lastWeekofYear
    // );
  }

  static generateEmployees(shiftsAssignmentDB: ShiftAssignmentDBModel[]): ShiftAssignmentEmployee[] {
    const employees: ShiftAssignmentEmployee[] = [];

    shiftsAssignmentDB.forEach((element: ShiftAssignmentDBModel) => {
      employees.push({
        userId: element.userID,
        fullName: element.fullName,
        department: element.department,
        imgUrl: element.imageURL
      });
    });

    return employees;
  }

  static generateDays(
    startDayOfWeek: moment.Moment,
    companyHoliday: CompanyHolidayModel[],
    nationalHoliday: NationalHolidayModel[]
  ): ShiftAssignmentDay[] {
    const days: ShiftAssignmentDay[] = [];

    for (let index = 0; index < 7; index++) {
      days.push({
        date: moment(startDayOfWeek).add((index + 1), 'days').utcOffset('+07:00').format('YYYY-MM-DD'),
        displayDate: moment(startDayOfWeek).add((index + 1), 'days').utcOffset('+07:00').format('ddd, DD MMM')
      });

      // Checking Company Holiday
      if (companyHoliday) {
        companyHoliday.forEach(element => {
          if (
            moment(startDayOfWeek)
              .add((index + 1), 'days')
              .utcOffset('+07:00')
              .format('YYYY-MM-DD') ===
            moment(element.eventDate)
              .utcOffset('+07:00')
              .format('YYYY-MM-DD')
          ) {
            days[index].isHoliday = true;
            days[index].holidayName = element.eventName;
          }
        });
      }

      // Checking National Holiday
      if (nationalHoliday) {
        nationalHoliday.forEach(element => {
          if (moment(startDayOfWeek).add((index + 1), 'days').utcOffset('+07:00').format('YYYY-MM-DD') === moment(element.eventDate).format('YYYY-MM-DD')) {
            days[index].isHoliday = true;
            days[index].holidayName = element.eventName;
          }
        });
      }

      // Saturday and Sunday Holiday
      if (index > 4) {
        days[index].isHoliday = true;
        days[index].holidayName = (index === 5) ? 'Libur Hari Sabtu' : 'Libur Hari Minggu';
      }
    }

    return days;
  }

  static generateBoards(
    startDayOfWeek: moment.Moment,
    days: ShiftAssignmentDay[],
    shiftsAssignmentDB: ShiftAssignmentDBModel[],
    employees: ShiftAssignmentEmployee[],
    companyShiftList: CompanyShiftModel[],
    styleBackground: string[]
  ): ShiftAssignmentModel[] {
    // ? 1. Filter dulu yang punya shift schedule -> Buat By Pass di Logic berikutnya
    const tempShiftAssignment = shiftsAssignmentDB.filter((fil: ShiftAssignmentDBModel) => fil.shiftSchedule);

    const shiftsAssignment: ShiftAssignmentModel[] = [];

    days.forEach((element, index) => {
      shiftsAssignment.push({
        date: moment(startDayOfWeek).add((index + 1), 'days').utcOffset('+07:00').format('YYYY-MM-DD'),
        arrShift: []
      });

      // ? 2. Handle Hari sudah lewat & Holiday
      // # J2G-4587 - Tahu Bulat digoreng Dadakan
      if (shiftsAssignment[index].date < moment().utcOffset('+07:00').format('YYYY-MM-DD')) {
        shiftsAssignment[index].disableAssign = true;
      }
      if (element.isHoliday) {
        shiftsAssignment[index].isWeekend = true;
      }

      employees.forEach((employee: ShiftAssignmentEmployee) => {
        let tempEmployeeShift: ShiftAssignmentDBModel | undefined;
        tempEmployeeShift = tempShiftAssignment.find((fin: ShiftAssignmentDBModel) => fin.fullName === employee.fullName);

        if (!tempEmployeeShift) {
          // ? 3. Kalo Employee gaada brati gapunya shift, langsung bypass kesini
          shiftsAssignment[index].arrShift.push({
            fullName: employee.fullName,
            userId: employee.userId,
            shiftName: '',
            date: element.date
          });

        } else {
          // ? 4. Kalo Employee ada cek lagi apakah punya shift sesuai hari yang aktif
          let tempEmployeeShiftDate: ShiftSchedule | undefined;
          tempEmployeeShiftDate = tempEmployeeShift.shiftSchedule.find((fin: ShiftSchedule) => moment(fin.date).utcOffset('+07:00').format('YYYY-MM-DD') === shiftsAssignment[index].date);

          if (!tempEmployeeShiftDate) {
            // ? 5. Employee gak punya shift ditanggal ini
            shiftsAssignment[index].arrShift.push({
              fullName: employee.fullName,
              userId: employee.userId,
              shiftName: '',
              date: element.date
            });

          } else {
            // ? 6. Employee punya shift ditanggal ini
            const indexShift = companyShiftList.findIndex((ind: CompanyShiftModel) =>
              // tslint:disable-next-line:no-non-null-assertion
              ind.id === tempEmployeeShiftDate!.shiftID
            );

            // ? 7. Cegatan Terakhir Shift Id nya nggak match sama shift List
            if (indexShift !== -1) {
              const tempShiftByID: CompanyShiftModel = companyShiftList[indexShift];
              shiftsAssignment[index].arrShift.push({
                fullName: employee.fullName,
                userId: employee.userId,
                shiftId: tempEmployeeShiftDate.shiftID,
                assignmentId: tempEmployeeShiftDate.assignmentID,
                date: tempEmployeeShiftDate.date,
                shiftName: tempShiftByID ? tempShiftByID.shiftName : '',
                start: tempShiftByID ? tempShiftByID.start : '',
                end: tempShiftByID ? tempShiftByID.end : '',
                allowWeekend: tempShiftByID ? tempShiftByID.isWeekendAllowed : false,
                class: styleBackground[indexShift]
              });

            } else {
              shiftsAssignment[index].arrShift.push({
                fullName: employee.fullName,
                userId: employee.userId,
                shiftName: '',
                date: element.date
              });
            }
          }
        }
      });
    });

    return shiftsAssignment;
  }

  static manipulateBoardsData(selectedUserId: number[], shiftsAssignment: ShiftAssignmentModel[]): ShiftAssignmentModel[] {
    shiftsAssignment.forEach((assignment: ShiftAssignmentModel) => {
      // Active Assignment
      const tempAssignmentActive = assignment.arrShift.filter((board: ShiftBoardModel) => selectedUserId.includes(board.userId));
      tempAssignmentActive.forEach((board: ShiftBoardModel) => {
        delete board.inactiveAssign;
      });

      // Inactive Assignment
      const tempAssignmentInactive = assignment.arrShift.filter((board: ShiftBoardModel) => !selectedUserId.includes(board.userId));
      tempAssignmentInactive.forEach((board: ShiftBoardModel) => {
        board.inactiveAssign = true;
      });
    });
    return shiftsAssignment;
  }

  static resetManipulatedBoardsData(shiftsAssignment: ShiftAssignmentModel[]): ShiftAssignmentModel[] {
    shiftsAssignment.forEach((assignment: ShiftAssignmentModel) => {
      assignment.arrShift.forEach((board: ShiftBoardModel) => {
        delete board.inactiveAssign;
      });
    });
    return shiftsAssignment;
  }

  static generateQuery(tempQuery: string, weekNavigation: WeekNavAssignment, paginateOption: PaginationClass): any {
    const query = tempQuery ? '&' + tempQuery : '';
    return {
      query: `year=${weekNavigation.yearActive}&week=${weekNavigation.weekActive}${query}`,
      page: paginateOption.page - 1,
      size: paginateOption.size
    };
  }

  // ? Corelate With:
  // 1. Logic untuk ganti shift di hari yang sama
  // 2. Logic untuk assign shift dari shift lain
  static switchFullNameUserId(event: any): any {
    const fullNameCurrent = event.previousContainer.data[event.previousIndex].fullName;
    const userIdCurrent = event.previousContainer.data[event.previousIndex].userId;
    event.previousContainer.data[event.previousIndex].fullName = event.container.data[event.currentIndex].fullName;
    event.previousContainer.data[event.previousIndex].userId = event.container.data[event.currentIndex].userId;
    event.container.data[event.currentIndex].fullName = fullNameCurrent;
    event.container.data[event.currentIndex].userId = userIdCurrent;

    return event;
  }

  // ? Corelate With:
  // 1. Logic untuk ganti shift di hari yang sama
  static moveContainerData(event: any): void {

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

    if (event.previousIndex < event.currentIndex) {
      moveItemInArray(event.container.data, event.currentIndex - 1, event.previousIndex);
    } else {
      moveItemInArray(event.container.data, event.currentIndex + 1, event.previousIndex);
    }
  }

  // ? Corelate With:
  // 2. Logic untuk assign shift dari shift lain
  static switchContainerData(event: any): void {
    // ? Transfer ke Tujuan
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );

    // ? Ngeswitch Tujuan ke Sebelumnya
    transferArrayItem(
      event.container.data,
      event.previousContainer.data,
      event.currentIndex + 1,
      event.previousIndex,
    );
  }

  // ? Corelate With:
  // 3. Logic untuk assign shift dari shift list
  static setContainerData(
    itemContainer: any,
    prevIndex: number,
    shiftId: number,
    shiftName: string,
    shiftStart: string,
    shiftEnd: string,
    allowWeekend: boolean,
    styleBackground: string[]
  ): any {
    itemContainer.shiftId = shiftId;
    itemContainer.shiftName = shiftName;
    itemContainer.start = shiftStart;
    itemContainer.end = shiftEnd;
    itemContainer.allowWeekend = allowWeekend;
    itemContainer.class = styleBackground[prevIndex];

    return itemContainer;
  }

  // ? Corelate With dropTrash
  static eraseBoardPrimaryData(event: any): any {
    const tempObject = Object.assign({}, event);

    tempObject.shiftName = '';
    delete tempObject.shiftId;
    delete tempObject.start;
    delete tempObject.end;
    delete tempObject.class;
    delete tempObject.isWeekendAllowed;

    return tempObject;
  }

  // ? Corelate with Check Allow Weekend
  static disableAssignFormater(
    days: ShiftAssignmentDay[],
    dragStart: boolean,
    shiftsAssignment: ShiftAssignmentModel[]
  ): ShiftAssignmentModel[] {
    days.forEach((element, index) => {
      if (element.isHoliday) {
        if (dragStart) {
          shiftsAssignment[index].disableAssign = true;
        } else {
          setTimeout(() => {
            // # J2G-4587 - Tahu Bulat digoreng Dadakan
            shiftsAssignment[index].disableAssign =
              moment(moment(shiftsAssignment[index].date)
                .format('YYYY-MM-DD'))
                .unix() <
              moment(moment()
                .format('YYYY-MM-DD'))
                .unix();
          }, 250);
        }
      }
    });

    return shiftsAssignment;
  }
}
