export class EnumResetCuti {
    public static resetCutiSelect: any = [
        { name: 'Januari', enum: 1 },
        { name: 'Februari', enum: 2 },
        { name: 'Maret', enum: 3 },
        { name: 'April', enum: 4 },
        { name: 'Mei', enum: 5 },
        { name: 'Juni', enum: 6 },
        { name: 'Juli', enum: 7 },
        { name: 'Agustus', enum: 8 },
        { name: 'September', enum: 9 },
        { name: 'Oktober', enum: 10 },
        { name: 'November', enum: 11 },
        { name: 'Desember', enum: 12 },
    ];
}
