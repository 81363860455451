/* tslint:disable:no-non-null-assertion */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { concatMap, finalize, map, mergeMap, retry, switchMap } from 'rxjs/operators';

import { FilterModel } from 'src/app/_class';
import { EnumRegex } from 'src/app/_enum';
import { DataService, HttpConfigService } from 'src/app/_service';
import { UploadPayrollData, Payrollitems, PayrolItemModel, PayrollService, ResponseType } from '..';
import { PayrollHTTPService, PayrollDetailHTTPService } from './payroll-http';
import { EmployeeListModel, EmployeeSalaryService } from '../../employee-list';

export type ResponseItemType = PayrolItemModel | undefined;

@Injectable({
  providedIn: 'root'
})
export class PayrollDetailService {

  // public fields
  currentPayrollItem$: Observable<ResponseItemType>;
  isLoading$: Observable<boolean>;
  isLoadingUpload$: Observable<boolean>;
  isExport$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isDelete$: Observable<boolean>;
  currentPayrollItemSubject: BehaviorSubject<ResponseItemType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoadingUploadSubject: BehaviorSubject<boolean>;
  isExportSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  isDeleteSubject: BehaviorSubject<boolean>;

  constructor(
    private employeeSalary: EmployeeSalaryService,
    private payrollService: PayrollService,
    private payrollHttp: PayrollHTTPService,
    private payrollDetailHttp: PayrollDetailHTTPService,
    private config: HttpConfigService,
    private dataService: DataService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoadingUploadSubject = new BehaviorSubject<boolean>(false);
    this.isExportSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isDeleteSubject = new BehaviorSubject<boolean>(false);
    this.currentPayrollItemSubject = new BehaviorSubject<ResponseItemType>(undefined);
    this.currentPayrollItem$ = this.currentPayrollItemSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingUpload$ = this.isLoadingUploadSubject.asObservable();
    this.isExport$ = this.isExportSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.isDelete$ = this.isDeleteSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse, showMessage?: boolean): void {
    this.config.openSnackBar(status, error, undefined, showMessage);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  getPayrollItem(
    companyId: number,
    payrollId: number,
    filter: FilterModel
  ): Observable<ResponseItemType> {
    this.isLoadingSubject.next(true);
    return this.payrollDetailHttp.getPayrollItem(companyId, payrollId, filter).pipe(
      map((payrollItem: ResponseItemType) => {
        // checking payrollItem
        if (payrollItem) {
          this.currentPayrollItemSubject.next(payrollItem);
        }
        return payrollItem;
      }),
      retry(2), // ? Jaga Jaga kalo Error ditengah jalan
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  exportPayrollItem(
    companyId: number,
    payrollId: number,
    filter: FilterModel
  ): Observable<ResponseItemType> {
    this.isExportSubject.next(true);
    return this.payrollDetailHttp.getPayrollItem(companyId, payrollId, filter).pipe(
      retry(2), // ? Jaga Jaga kalo Error ditengah jalan
      finalize(() => this.isExportSubject.next(false))
    );
  }

  createPayrollItemSingle(companyId: number, payrollId: number, item: any, filter: FilterModel): Observable<ResponseItemType> {
    delete item.email;

    this.isSubmitedSubject.next(true);
    return this.payrollDetailHttp.createPayrollItem(companyId, payrollId, item).pipe(
      switchMap(() => this.getPayrollItem(companyId, payrollId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  updatePayrollItem(companyId: number, payrollId: number, id: number, data: any): Observable<Response> {
    const payroll: any = Object.assign({}, data);
    payroll.allowanceMeal = payroll.allowanceMeal ? payroll.allowanceMeal + '' + '' : '0';
    payroll.allowanceTitle = payroll.allowanceTitle ? payroll.allowanceTitle + '' : '0';
    payroll.allowanceTransport = payroll.allowanceTransport ? payroll.allowanceTransport + '' : '0';
    payroll.amount = payroll.amount ? payroll.amount + '' : '0';
    payroll.deductionAdmin = payroll.deductionAdmin ? payroll.deductionAdmin + '' : '0';
    payroll.deductionAttendance = payroll.deductionAttendance ? payroll.deductionAttendance + '' : '0';
    payroll.deductionBpjsKes = payroll.deductionBpjsKes ? payroll.deductionBpjsKes + '' : '0';
    payroll.deductionBpjsTk = payroll.deductionBpjsTk ? payroll.deductionBpjsTk + '' : '0';
    payroll.deductionLoan = payroll.deductionLoan ? payroll.deductionLoan + '' : '0';
    payroll.deductionOther = payroll.deductionOther ? payroll.deductionOther + '' : '0';
    payroll.deductionPph21 = payroll.deductionPph21 ? payroll.deductionPph21 + '' : '0';
    payroll.incomeIncentives = payroll.incomeIncentives ? payroll.incomeIncentives + '' : '0';
    payroll.incomeOther = payroll.incomeOther ? payroll.incomeOther + '' : '0';
    payroll.incomeOvertime = payroll.incomeOvertime ? payroll.incomeOvertime + '' : '0';
    payroll.incomePerdiem = payroll.incomePerdiem ? payroll.incomePerdiem + '' : '0';
    payroll.salaryBase = payroll.salaryBase ? payroll.salaryBase + '' : '0';

    this.isSubmitedSubject.next(true);
    return this.payrollDetailHttp.updatePayrollItem(companyId, payrollId, id, payroll).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  patchPayroll(
    companyId: number,
    payrollId: number,
    data: { status: string, reason?: string },
    filter: FilterModel,
    fromMaster?: boolean): Observable<ResponseType | ResponseItemType> {
    this.isSubmitedSubject.next(true);
    if (fromMaster) {
      return this.payrollHttp.patchPayroll(companyId, payrollId, data).pipe(
        switchMap(() => this.payrollService.getPayroll(
          companyId,
          filter
        )),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.payrollHttp.patchPayroll(companyId, payrollId, data).pipe(
        switchMap(() => this.getPayrollItem(
          companyId,
          payrollId,
          filter
        )),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deletePayrollItem(companyId: number, payrollId: number, deleteIds: number[], filter: FilterModel): Observable<ResponseItemType> {
    this.isDeleteSubject.next(true);
    return from(deleteIds).pipe(
      concatMap((deleteId) => {
        return this.payrollDetailHttp.deletePayrollItem(companyId, payrollId, deleteId);
      }),
      switchMap(() => this.getPayrollItem(companyId, payrollId, filter)),
      finalize(() => this.isDeleteSubject.next(false))
    );
  }

  checkStatusItem(companyId: number, payrollId: number, filter: FilterModel): Observable<ResponseItemType> {
    this.isSubmitedSubject.next(true);
    return this.payrollHttp.getPayrollStatus(companyId, payrollId).pipe(
      switchMap(() => this.getPayrollItem(companyId, payrollId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  checkStatusSingle(companyId: number, payrollId: number, id: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.payrollDetailHttp.getPayrollItemStatus(companyId, payrollId, id).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  payoutRetrySingle(companyId: number, payrollId: number, id: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.payrollDetailHttp.retryPayrollItem(companyId, payrollId, id).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  sendEmailPayslip(companyId: number, userId: number, index: number): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.payrollDetailHttp.sendEmailPayslip(companyId, userId, index).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  downloadPayslipZip(companyId: number, payrollId: number): Observable<Blob> {
    this.isSubmitedSubject.next(true);
    return this.payrollDetailHttp.downloadPayslipZip(companyId, payrollId).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  downloadPayslip(companyId: number, userIds: number[], payrollId: number): Observable<Blob> {
    this.isSubmitedSubject.next(true);
    return from(userIds).pipe(
      concatMap((userId) => {
        return this.payrollDetailHttp.downloadPayslip(companyId, userId, payrollId);
      }),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  // payrollItemAdjacent(companyId: number, userId: number): Observable<Response> {
  //   this.isSubmitedSubject.next(true);
  //   return this.payrollDetailHttp.getPayrollItemAdjacent(companyId, userId).pipe(
  //     catchError(this.config.handleErrorReturnAll),
  //     finalize(() => this.isSubmitedSubject.next(false))
  //   );
  // }

  generateSalaryEmployee(companyId: number, data: EmployeeListModel[]): Observable<any> {
    const itemList: Payrollitems[] = [];
    const tempError: {Email: string, Message: string, Item?: any}[] = [];
    let index = 0;

    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      mergeMap(async (el) => {
        let arg: any;
        let tempSalaryBase: number;
        const item: Payrollitems = {} as Payrollitems;

        // Begin::Check per Employee Salary
        try {
          arg = await this.employeeSalary.getEmployeeSalary(companyId, el.userId).toPromise();
          tempSalaryBase = Number(arg.salaryBase);
          if (Object.is(tempSalaryBase, NaN)) {
            tempError.push({Email: el.email, Message: 'Gaji masih terenkripsi'});
          } else {
            delete arg.id;
            delete arg.companyId;
            delete arg.employeeId;

            Object.assign(item, arg);
            item.bankId = el.selectedBankId;
            item.email = el.email;
          }
        } catch (error: any) {
          if (error.status === 404) {
            tempError.push({Email: el.email, Message: 'Belum memiliki gaji'});
          } else {
            tempError.push({
              Email: el.email,
              Message: (error.error.error.toLowerCase() === 'data tidak ditemukan') ? 'Belum memiliki gaji' : error.error.error
            });
          }
        }
        // End::Check per Employee Salary

        // Begin::Check Payroll Adjacent
        // try {
        //   arg = await this.payrollItemAdjacent(companyId, el.userId).toPromise();

        //   if (arg) {
        //     let message = '';
        //     arg.forEach((element: any, index: number) => {
        //       // console.log(element);
        //       // console.log(this.data.payrollSchedule);
        //       // console.log(moment(element.lastModifiedDate).format('MMM YYYY'), moment(this.data.payrollSchedule).format('MMM YYYY'));
        //       // if (element.lastModifiedDate &&
        //              (moment(element.lastModifiedDate).format('MMM YYYY') === moment(this.data.payrollSchedule).format('MMM YYYY'))) {
        //       //   message = message + 'Sudah terdaftar di Payroll: ' + element.notes + ';';
        //       // };
        //       if (message && (index + 1 === arg.length)) {
        //         tempError.push({Email: el.email, Message: message.substring(0, message.length - 1), Item: item});
        //       }
        //     });
        //   }
        // } catch (error: any) {
        //   tempError.push({Email: el.email, Message: error.error.error});
        // }
        // End::Check Payroll Adjacent

        // delete item.userId;
        // delete item.bankId;
        itemList.push(item);
        index++;

        if (index === data.length) {
          return {item: itemList, error: tempError};
        }
        return {item: null, error: null};
      }),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  createPayrollItem(companyId: number, payrollId: number, data: Payrollitems[]): Observable<any> {
    const tempError: {Email: string, Message: string, Item?: any}[] = [];
    const tempResponse: PayrolItemModel[] = [];
    let index = 0;

    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      mergeMap(async (el: Payrollitems) => {
        let arg: any;
        const payroll: any = Object.assign({}, el);
        payroll.allowanceMeal = payroll.allowanceMeal ? payroll.allowanceMeal : '0';
        payroll.allowanceTitle = payroll.allowanceTitle ? payroll.allowanceTitle : '0';
        payroll.allowanceTransport = payroll.allowanceTransport ? payroll.allowanceTransport : '0';
        payroll.amount = payroll.amount ? payroll.amount : '0';
        payroll.deductionAdmin = payroll.deductionAdmin ? payroll.deductionAdmin : '0';
        payroll.deductionAttendance = payroll.deductionAttendance ? payroll.deductionAttendance : '0';
        payroll.deductionBpjsKes = payroll.deductionBpjsKes ? payroll.deductionBpjsKes : '0';
        payroll.deductionBpjsTk = payroll.deductionBpjsTk ? payroll.deductionBpjsTk : '0';
        payroll.deductionLoan = payroll.deductionLoan ? payroll.deductionLoan : '0';
        payroll.deductionOther = payroll.deductionOther ? payroll.deductionOther : '0';
        payroll.deductionPph21 = payroll.deductionPph21 ? payroll.deductionPph21 : '0';
        payroll.incomeIncentives = payroll.incomeIncentives ? payroll.incomeIncentives : '0';
        payroll.incomeOther = payroll.incomeOther ? payroll.incomeOther : '0';
        payroll.incomeOvertime = payroll.incomeOvertime ? payroll.incomeOvertime : '0';
        payroll.incomePerdiem = payroll.incomePerdiem ? payroll.incomePerdiem : '0';
        payroll.salaryBase = payroll.salaryBase ? payroll.salaryBase : '0';
        delete payroll.email;
        try {
          arg = await this.payrollDetailHttp.createPayrollItem(companyId, payrollId, payroll).toPromise();
          tempResponse.push(arg);
        } catch (error: any) {
          tempError.push({Email: el.email!, Message: error.error.extendedMessage});
        }

        index++;

        if (index === data.length) {
          return {error: tempError, response: tempResponse};
        }
        return {error: null, response: null};
      }),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API

  // Upload CSV
  convertFile(event: any, skipDisburse: boolean): any {
    if (event.target.files.length > 0) {

      this.isLoadingUploadSubject.next(true);

      const input = event.target.files[0];

      const reader = new FileReader();
      reader.readAsText(input);
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          const Data: UploadPayrollData[] = [];
          const text = reader.result as string;

          // ! Koma / Titik Koma paling belakang mestinya gaada;
          console.log(text);

          const arrText = text.split(/\r?\n|\r/g);
          const arrIndex: any = [];

          // Double Checking Empty Array or Column
          arrText.forEach((element, index) => {
            // Beware This When Add Field
            if (element === ',,,,,,,,,,,,,,,,,' || element === ';;;;;;;;;;;;;;;;;' ||
                element === ',,,,,,,,,,,,,,,,' || element === ';;;;;;;;;;;;;;;;') {
              arrIndex.push(index);
            } else {
              if (element.includes(',,,')) {
                arrText[index] = element.replace(',,,', '');
              }
              if (element.includes(';;;')) {
                arrText[index] = element.replace(';;;', '');
              }
              if (element.includes(',,')) {
                arrText[index] = element.replace(',,', '');
              }
              if (element.includes(';;')) {
                arrText[index] = element.replace(';;', '');
              }
            }
          });
          for (let index = arrIndex.length; index > 0; index--) {
            const element = arrIndex[index - 1];
            arrText.splice(element, 1);
          }
          // End of Doble Checking Empty Array

          arrText.pop();
          // console.log(arrText);
          // return;
          try {
            // Ngetrap 200 Record
            // if (arrText.length > 201) throw new Error('Data yang diupload melebihi 200 record.')
            // End of Ngetrap 200 Record

            arrText.forEach((element: any, index: number) => {
              // Column Name Validation
              if (!index) {
                const rawColumn = element.includes(';') ? element.split(';') : element.split(',');
                if (rawColumn[0].toLowerCase() !== 'email') {
                  throw new Error('Penamaan kolom ' + rawColumn[0] + ' tidak sesuai format. NB: Kolom 1');
                }
                if (rawColumn[1].toLowerCase() !== 'gaji pokok') {
                  throw new Error('Penamaan kolom ' + rawColumn[1] + ' tidak sesuai format. NB: Kolom 2');
                }
                if (rawColumn[2].toLowerCase() !== 'tunj. jabatan') {
                  throw new Error('Penamaan kolom ' + rawColumn[2] + ' tidak sesuai format. NB: Kolom 3');
                }
                if (rawColumn[3].toLowerCase() !== 'tunj. transport') {
                  throw new Error('Penamaan kolom ' + rawColumn[3] + ' tidak sesuai format. NB: Kolom 4');
                }
                if (rawColumn[4].toLowerCase() !== 'tunj. makan') {
                  throw new Error('Penamaan kolom ' + rawColumn[4] + ' tidak sesuai format. NB: Kolom 5');
                }
                if (rawColumn[5].toLowerCase() !== 'insentif') {
                  throw new Error('Penamaan kolom ' + rawColumn[5] + ' tidak sesuai format. NB: Kolom 6');
                }
                if (rawColumn[6].toLowerCase() !== 'perjalanan dinas') {
                  throw new Error('Penamaan kolom ' + rawColumn[6] + ' tidak sesuai format. NB: Kolom 7');
                }
                if (rawColumn[7].toLowerCase() !== 'lembur') {
                  throw new Error('Penamaan kolom ' + rawColumn[7] + ' tidak sesuai format. NB: Kolom 8');
                }
                // if (rawColumn[8].toLowerCase() !== 'thr') {
                // throw new Error('Penamaan kolom ' + rawColumn[8] + ' tidak sesuai format. NB: Kolom 9');
                // }
                if (rawColumn[8].toLowerCase() !== 'tunj. lain') {
                  throw new Error('Penamaan kolom ' + rawColumn[8] + ' tidak sesuai format. NB: Kolom 10');
                }
                // if (rawColumn[9].toLowerCase() !== 'pesangon') {
                // throw new Error('Penamaan kolom ' + rawColumn[9] + ' tidak sesuai format. NB: Kolom 10');
                // }
                // if (rawColumn[10].toLowerCase() !== 'bonus') {
                // throw new Error('Penamaan kolom ' + rawColumn[10] + ' tidak sesuai format. NB: Kolom 11');
                // }
                // if (rawColumn[11].toLowerCase() !== 'jht') {
                // throw new Error('Penamaan kolom ' + rawColumn[10] + ' tidak sesuai format. NB: Kolom 11');
                // }
                // if (rawColumn[12].toLowerCase() !== 'jp') {
                // throw new Error('Penamaan kolom ' + rawColumn[11] + ' tidak sesuai format. NB: Kolom 12');
                // }
                if (rawColumn[9].toLowerCase() !== 'bpjs kesehatan') {
                  throw new Error('Penamaan kolom ' + rawColumn[9] + ' tidak sesuai format. NB: Kolom 11');
                }
                if (rawColumn[10].toLowerCase() !== 'tk') {
                  throw new Error('Penamaan kolom ' + rawColumn[10] + ' tidak sesuai format. NB: Kolom 12');
                }
                // if (rawColumn[12].toLowerCase() !== 'kk') {
                // throw new Error('Penamaan kolom ' + rawColumn[12] + ' tidak sesuai format. NB: Kolom 13');
                // }
                // if (rawColumn[13].toLowerCase() !== 'jk') {
                // throw new Error('Penamaan kolom ' + rawColumn[13] + ' tidak sesuai format. NB: Kolom 14');
                // }
                if (rawColumn[11].toLowerCase() !== 'pph21') {
                  throw new Error('Penamaan kolom ' + rawColumn[11] + ' tidak sesuai format. NB: Kolom 13');
                }
                if (rawColumn[12].toLowerCase() !== 'admin') {
                  throw new Error('Penamaan kolom ' + rawColumn[12] + ' tidak sesuai format. NB: Kolom 14');
                }
                if (rawColumn[13].toLowerCase() !== 'absensi') {
                  throw new Error('Penamaan kolom ' + rawColumn[13] + ' tidak sesuai format. NB: Kolom 15');
                }
                if (rawColumn[14].toLowerCase() !== 'salary advance') {
                  throw new Error('Penamaan kolom ' + rawColumn[14] + ' tidak sesuai format. NB: Kolom 16');
                }
                if (rawColumn[15].toLowerCase() !== 'pengurangan lain') {
                  throw new Error('Penamaan kolom ' + rawColumn[15] + ' tidak sesuai format. NB: Kolom 17');
                }
                if (rawColumn[16].toLowerCase() !== 'total') {
                  throw new Error('Penamaan kolom ' + rawColumn[16] + ' tidak sesuai format. NB: Kolom 18');
                }
              }
              // Column Name Validation

              if (index) {
                // Cara Anyar
                const rawData = element.includes(';') ? element.split(';') : element.split(',');
                // console.log(rawData);
                // End::Marsing Rupiah

                // Begin: Column Length
                // console.log(rawData.length);
                if (rawData.length > 18) {
                  throw new Error('Panjang kolom tidak sesuai format. Mohon periksa kembali apakah ada pemisah desimal dengan Koma. ' + 'Line ' + (index + 1));
                }
                // End: Column Length

                // Email, Gaji Pokok, Tunj. Jabatan, Tunj. Transport, Tunj. Makan, THR, Pesangon, Bonus, JHT, JP, BPJS Kesehatan, TK, KK,
                // JK, Pph21, Admin, Absensi, Salary Advance, Total
                const tempData: UploadPayrollData = {} as UploadPayrollData;
                tempData.email = rawData[0].trim();
                tempData.salaryBase = parseFloat(rawData[1]) + '';
                tempData.allowanceTitle = parseFloat(rawData[2]) + '';
                tempData.allowanceTransport = parseFloat(rawData[3]) + '';
                tempData.allowanceMeal = parseFloat(rawData[4]) + '';
                tempData.incomeIncentives = parseFloat(rawData[5]) + '';
                tempData.incomePerdiem = parseFloat(rawData[6]) + '';
                tempData.incomeOvertime = parseFloat(rawData[7]) + '';
                // tempData.incomeThr = parseFloat(rawData[8]) + '';
                tempData.incomeOther = parseFloat(rawData[8]) + '';
                // tempData.incomeSeverance = parseFloat(rawData[9]);
                // tempData.incomeBonus = parseFloat(rawData[10]);
                // tempData.deductionBpjsJht = parseFloat(rawData[11]);
                // tempData.deductionBpjsJp = parseFloat(rawData[12]);
                tempData.deductionBpjsKes = parseFloat(rawData[9]) + '';
                tempData.deductionBpjsTk = parseFloat(rawData[10]) + '';
                // tempData.deductionBpjsKk = parseFloat(rawData[15]);
                // tempData.deductionBpjsJk = parseFloat(rawData[16]);
                tempData.deductionPph21 = parseFloat(rawData[11]) + '';
                tempData.deductionAdmin = parseFloat(rawData[12]) + '';
                tempData.deductionAttendance = parseFloat(rawData[13]) + '';
                tempData.deductionLoan = parseFloat(rawData[14]) + '';
                tempData.deductionOther = parseFloat(rawData[15]) + '';
                tempData.amount = parseFloat(rawData[16]) + '';

                // console.log(tempData);

                // Begin: Data Validation
                // OLD Logic
                // tempData.salaryBase + tempData.allowanceMeal + tempData.allowanceTransport + tempData.allowanceTitle +
                // tempData.incomeThr + tempData.incomeIncentives + tempData.incomePerdiem + tempData.incomeOvertime + tempData.incomeOther;

                // let totalDeduction = tempData.deductionAdmin + tempData.deductionAttendance + tempData.deductionBpjsJht +
                // tempData.deductionBpjsJk + tempData.deductionBpjsJp + tempData.deductionBpjsKes + tempData.deductionBpjsKk +
                // tempData.deductionBpjsTk + tempData.deductionLoan + tempData.deductionPph21;

                // NEW Logic
                const totalIncome = Number(tempData.salaryBase) + Number(tempData.allowanceMeal) + Number(tempData.allowanceTransport) +
                  Number(tempData.allowanceTitle) + Number(tempData.incomeIncentives) + Number(tempData.incomePerdiem) +
                  Number(tempData.incomeOvertime) + Number(tempData.incomeOther);
                const totalDeduction = Number(tempData.deductionAdmin) + Number(tempData.deductionAttendance) +
                  Number(tempData.deductionBpjsKes) + Number(tempData.deductionBpjsTk) + Number(tempData.deductionLoan) +
                  Number(tempData.deductionOther) + Number(tempData.deductionPph21);
                const totalAmount = Number(tempData.amount);

                // Cek Regex Email
                const mailDomain = tempData.email.split('@')[1];
                if (
                  !EnumRegex.parsingRegex('email')!.test(tempData.email.trim()) ||
                  mailDomain.includes('gnail.') ||
                  mailDomain.includes('.con')
                ) {
                  throw new Error('Email tidak valid. ' + 'Line ' + (index + 1));
                }
                // Cek Kosong Semua
                if (!tempData.salaryBase && !tempData.allowanceMeal && !tempData.allowanceTitle && !tempData.allowanceTransport &&
                    !tempData.incomeIncentives && !tempData.incomePerdiem && !tempData.incomeOvertime && !tempData.incomeOther &&
                    !tempData.deductionAdmin && !tempData.deductionAttendance && !tempData.deductionBpjsKes && !tempData.deductionBpjsTk &&
                    !tempData.deductionLoan && !tempData.deductionOther && !tempData.deductionPph21 && !tempData.amount) {
                  throw new Error('Data penggajian tidak boleh kosong. ' + 'Line ' + (index + 1));
                }
                // Cek Ada yang Negatif
                if ((Number(tempData.salaryBase) < 0) || (Number(tempData.allowanceMeal) < 0) || (Number(tempData.allowanceTitle) < 0) ||
                    (Number(tempData.allowanceTransport) < 0) || (Number(tempData.incomeIncentives) < 0) ||
                    (Number(tempData.incomeOther) < 0) || (Number(tempData.deductionAdmin) < 0) ||
                    (Number(tempData.deductionAttendance) < 0) || (Number(tempData.deductionBpjsKes) < 0) ||
                    (Number(tempData.deductionBpjsTk) < 0) || (Number(tempData.deductionLoan) < 0) ||
                    (Number(tempData.deductionOther) < 0) || (Number(tempData.deductionPph21) < 0)) {
                  throw new Error('Data penggajian tidak boleh kurang dari 0. ' + 'Line ' + (index + 1));
                }

                // Selisih Perhitungan Manual dengan Total Amount
                const selisih = (((totalIncome - totalDeduction + Number.EPSILON) * 100) / 100) - totalAmount;
                const nilaiMutlak = (selisih < 0) ? selisih * -1 : selisih;
                // console.log(tempData);
                // console.log(totalIncome, totalDeduction, totalAmount);
                // console.log(nilaiMutlak);

                // Selisih yang diperbolehkan maksimal 2
                if (nilaiMutlak > 2) {
                  throw new Error('Total Penggajian Salah (CSV: ' + totalAmount + ', SISTEM: ' +
                    ((totalIncome - totalDeduction + Number.EPSILON) * 100) / 100 + '). Selisih: ' +
                    nilaiMutlak + '. Line ' + (index + 1));
                }

                // Tidak Boleh Lebih dari 0 dan kurang dari 10000
                if ((totalAmount > 0) && (totalAmount < 10000) && !skipDisburse) {
                  throw new Error('Total Penggajian tidak boleh kurang dari 10 ribu rupiah. ' + 'Line ' + (index + 1));
                }
                // End: Data Validation
                Data.push(tempData);
              }
            });

            // console.log(Data);
            // return;

            // Cegatan Duplicate
            Data.forEach(element => {
              const tempEmail = Data.filter((fil: any) => fil.email === element.email);
              if (tempEmail.length > 1) {
                throw new Error(`Ada data yang duplikat (email: ${element.email}).`);
              }
            });
            // End of Cegatan Duplicate
          } catch (error: any) {
            reject(error);
          }
          resolve(Data);
        };
      });
    }
  }

  uploadPayrollItem(companyId: number, payrollId: number, data: any): Observable<Response> {
    this.isLoadingUploadSubject.next(true);
    return this.payrollDetailHttp.uploadPayrollItem(companyId, payrollId, data).pipe(
      finalize(() => this.isLoadingUploadSubject.next(false))
    );
  }

  prepareHitPayroll(data: UploadPayrollData[], companyId: number, payrollId: number): void {
    this.uploadPayrollItem(companyId, payrollId, data).subscribe((arg: any) => {
      if (arg !== 'ok') {
        this.dataService.errorEvent(arg);
      } else {
        this.dataService.errorEvent([]);
      }
    }, error => {
      if (error.status === 400) {
        // console.log(error.error.split('\n'));
        this.dataService.errorEvent(error.error);
        return;
      }
      this.dataService.errorEvent([]);
      this.openSnackBar(error.status);
    });
    // End::Hit API
  }
}
