import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { ErrorJsonHandlingService } from 'src/app/_service/error-handling.service';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { ResponseCompany, CompanyDataModel, PostCompanyClass } from '..';
import { CompanyHTTPService } from './company-http/';
import { CompanyInfoClass } from 'src/app/layouts/auth';

import * as moment from 'moment';
import { FilterModel, GlobalHelper } from 'src/app/_class';
moment.locale('id');

export type ResponseType = ResponseCompany | undefined;

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  // public fields
  currentCompany$: Observable<ResponseType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isCompanyProfileIncompleted$: Observable<boolean>;
  currentCompanySubject: BehaviorSubject<ResponseType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  isCompanyProfileIncompletedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private jsonHandling: ErrorJsonHandlingService,
    private companyHttp: CompanyHTTPService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentCompanySubject = new BehaviorSubject<ResponseType>(undefined);
    this.isCompanyProfileIncompletedSubject = new BehaviorSubject<boolean>(false);
    this.currentCompany$ = this.currentCompanySubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.isCompanyProfileIncompleted$ = this.isCompanyProfileIncompletedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getCompany(filter: FilterModel): Observable<ResponseType> {
    this.isLoadingSubject.next(true);
    return this.companyHttp.getCompanyGOApi(filter).pipe(
      map((company: ResponseType) => {
        // checking company
        if (company) {
          this.currentCompanySubject.next(company);
        }
        return company;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCompanyAutoComplete(query?: string): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.companyHttp.getCompanyGOApi(GlobalHelper.generateParams(query ? query : '', 1)).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCompanyAutoCompleteById(id: number): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.companyHttp.getCompanyByIdGOApi(id).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCompanyAutoCompleteByName(name: string, size?: number, approved?: boolean, skipLoading?: boolean): Observable<Response> {
    if (!skipLoading) {
      this.isLoadingSubject.next(true);
    }
    return this.companyHttp.getCompanyByNameGOApi(name, size, approved).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  patchCompany(companyId: number, params: {status: string, reason?: string}, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.companyHttp.patchCompanyGOApi(companyId, params).pipe(
      switchMap(() => this.getCompany(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  patchCompanyVerified(companyId: number, dataVerified: {verified: boolean}, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.companyHttp.verifiedCompanyGOApi(companyId, dataVerified).pipe(
      switchMap(() => this.getCompany(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  patchCompanyLeadgen(companyId: number, params: {status: string}): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyHttp.patchCompanyGOApi(companyId, params).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  patchCompanyVerifiedLeadgen(companyId: number, dataVerified: {verified: boolean}): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyHttp.verifiedCompanyGOApi(companyId, dataVerified).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  patchCompanyDetail(companyId: number, dataStatus: {status: string}, dataVerified: {verified: boolean}): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyHttp.patchCompanyGOApi(companyId, dataStatus).pipe(
      switchMap(() => this.companyHttp.verifiedCompanyGOApi(companyId, dataVerified)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  putCompany(companyId: number, data: CompanyDataModel, activeId?: number): Observable<Response> {
    const rawData = PostCompanyClass.formatCompany(data);

    const errorMessage = CompanyInfoClass.companyInfoValidation(rawData, true);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('COMPANY -> Put Company Data', errorMessage, rawData);
    }

    this.isSubmitedSubject.next(true);

    if ((data.status === 'fraud') && activeId === 3) {
      delete rawData.startYear;

      return this.patchCompanyDetail(companyId, {status: data.status}, {verified: false}).pipe(
        switchMap(() => this.companyHttp.putCompanyGOApi(companyId, rawData)),
        finalize(() => {
          this.isCompanyProfileIncompletedSubject.next(false);
          this.isSubmitedSubject.next(false);
        })
      );
    }

    return this.companyHttp.putCompanyGOApi(companyId, rawData).pipe(
      finalize(() => {
        this.isCompanyProfileIncompletedSubject.next(false);
        this.isSubmitedSubject.next(false);
      })
    );
  }

  putCompanyModule(companyId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyHttp.putCompanyModulesGOApi(companyId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
