<ng-container *ngIf="!href; else ButtonLink">
  <button
    [attr.id]="id ? id : null"
    [attr.type]="type ? type : null"
    [attr.routerLink]="routerLink ? routerLink : null"
    class="btn"
    [ngbTooltip]="tooltip ? tooltip : ''"
    [placement]="placement ? placement : ''"
    [ngClass]="{
      'btn-icon': icon,
      'btn-outline': outline,
      'btn-lg': large,
      'btn-sm': small,
      'btn-pill': pill,
      'btn-block': block
    }"
    [class]="additionalClass ?
      transformColor + ' ' + additionalClass :
      transformColor + ' ' + ''"
    [disabled]="disabled || loading"
    (click)="onClick.emit(true);"
  >
    <ng-container *ngTemplateOutlet="ButtonContent"></ng-container>
  </button>
</ng-container>

<ng-template #ButtonLink>
  <a
    [href]="href"
    [attr.target]="blank ? '_blank' : null"
    [attr.download]="download ? '' : null"
    [attr.id]="id ? id : null"
    [attr.type]="type ? type : null"
    [attr.disabled]="(disabled || loading) ? '' : null"
    class="btn"
    [ngClass]="{
      'btn-icon': icon,
      'btn-outline': outline,
      'btn-lg': large,
      'btn-sm': small,
      'btn-pill': pill,
      'btn-block': block
    }"
    [class]="additionalClass ?
      transformColor + ' ' + additionalClass :
      transformColor + ' ' + ''"
  >
    <ng-container *ngTemplateOutlet="ButtonContent"></ng-container>
  </a>
</ng-template>

<ng-template #ButtonContent>
  <!-- begin::Icon Non-Image -->
  <ng-container *ngIf="(iconClass || svgIcon) && !loading">
    <ng-container *ngIf="!svgIcon; else SvgIcon">
      <i class="font-size-h6"
        [ngClass]="{
          'fas': (iconClass && iconClass.includes('fa')),
          'bi': (iconClass && iconClass.includes('bi'))
        }"
        [class]="iconClass ? iconClass : ''"
        [ngbTooltip]="tooltipIcon ? tooltipIcon : ''"
        [placement]="placementIcon ? placementIcon : ''"
      ></i>
    </ng-container>
  </ng-container>
  <!-- end::Icon Non-Image -->

  <!-- begin::Icon Image -->
  <ng-container *ngIf="imgIcon && !loading">
    <img src="{{ imgIcon }}" alt=""
         [class]="imgIconClass ? imgIconClass : ''">
  </ng-container>
  <!-- end::Icon Image -->

  <!-- begin::Icon Loading -->
  <ng-container *ngIf="loading">
    <span class="indicator-progress" [style.display]="'block'">
      <span
        class="spinner-border spinner-border-sm align-middle mr-2"
      ></span>
      {{loadingLabel ? loadingLabel : ''}}
    </span>
  </ng-container>
  <!-- end::Icon Loading -->

  <!-- begin::Label -->
  <ng-container *ngIf="!loading">
    <span class="indicator-label">{{label ? label : ''}}</span>
  </ng-container>
  <!-- end::Label -->
</ng-template>

<ng-template #SvgIcon>
  <span
    class="svg-icon"
    [class]="iconClass ? iconClass : ''"
    [inlineSVG]="svgIcon ? svgIcon : ''"
  ></span>
</ng-template>