import { Sort } from "@angular/material/sort";
import { SessionStorage } from "./session-storage.class";

export interface SortModel {
    sortBy?: string;
    sortAsc?: boolean;
    sortByStorage?: string;
    sortAscStorage?: string;
}

export class SortClass {
    /** The current sort paramether. */
    sortBy: string;
    /** The current sort direction */
    sortAsc: boolean;
    /** Session Storage */
    sortByStorage: string;
    sortAscStorage: string;

    constructor(params?: SortModel) {
        this.sortBy = (params && params.sortBy) ? params.sortBy : '';
        this.sortAsc = (params && params.sortAsc) ? params.sortAsc : false;
        this.sortByStorage = (params && params.sortByStorage) ? params.sortByStorage : '';
        this.sortAscStorage = (params && params.sortAscStorage) ? params.sortAscStorage : '';
    }

    sortData(sort: Sort): void {
        if (!sort.active || !sort.direction) {
            SessionStorage.removeStorage(this.sortByStorage);
            SessionStorage.removeStorage(this.sortAscStorage);
            this.sortBy = '';
            this.sortAsc = false;
            return;
        }

        this.sortBy = sort.active;
        this.sortAsc = (sort.direction === 'asc');
        SessionStorage.setStorage(this.sortByStorage, this.sortBy);
        SessionStorage.setStorage(this.sortAscStorage, this.sortAsc + '');
      }
}
