import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { PostEmployeeBypassModel, PostEmployeeModel } from '../..';
import { FilterModel } from 'src/app/_class';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getEmployeeList(companyId: number, filter: FilterModel): Observable<any> {
    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : '10'}&sort=${filter.sort ? filter.sort : 'join_date'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<any>(url);
  }
  getEmployeeById(companyId: number, userId: number): Observable<any> {
    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}`;
    return this.http.get<any>(url);
  }
  getAllEmployee(companyId: number, filter: FilterModel): Observable<Response> {
    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee?${filter.query}page=${filter.page}&limit=100&metadata=true`;
    return this.http.get<Response>(url);
  }
  checkEmail(email: string): Observable<Response> {
    return this.http.get<Response>(`${API_EMPLOYEE_URL}/account/${email}`);
  }
  checkEmailPayroll(email: string): Observable<Response> {
    return this.http.get<Response>(`${API_EMPLOYEE_URL}/account/${email}`);
  }
  createEmployee(companyId: number, data: PostEmployeeModel): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee`, data);
  }
  createEmployeeByPass(companyId: number, data: PostEmployeeBypassModel): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/register_bypass`, data);
  }
  uploadEmployeeByPass(companyId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/register_bypass_bulk`, data);
  }
  patchEmployee(companyId: number, userId: number, data: {
    status: string,
    quitDate?: string,
    quitReason: string
  }): Observable<Response> {
    return this.http.patch<Response>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}`, data);
  }
  // deleteEmployee(companyId: number, userId: number): Observable<any> {
  //   return this.http.delete<any>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}`);
  // }

  // Employee Payslip
  getEmployeePayslip(companyId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/payslips`);
  }
}
