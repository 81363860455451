import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { EmployeeSalaryHTTPService } from './employee-http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSalaryService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private employeeSalary: EmployeeSalaryHTTPService,
    private config: HttpConfigService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  getEmployeeSalary(companyId: number, userId: number): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.employeeSalary.getEmployeeSalary(companyId, userId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  postEmployeeSalary(companyId: number, userId: number, data: any): Observable<Response> {
    const payroll: any = Object.assign({}, data);
    payroll.allowanceMeal = payroll.allowanceMeal ? payroll.allowanceMeal + '' + '' : '0';
    payroll.allowanceTitle = payroll.allowanceTitle ? payroll.allowanceTitle + '' : '0';
    payroll.allowanceTransport = payroll.allowanceTransport ? payroll.allowanceTransport + '' : '0';
    payroll.amount = payroll.amount ? payroll.amount + '' : '0';
    payroll.deductionAdmin = payroll.deductionAdmin ? payroll.deductionAdmin + '' : '0';
    payroll.deductionAttendance = payroll.deductionAttendance ? payroll.deductionAttendance + '' : '0';
    payroll.deductionBpjsKes = payroll.deductionBpjsKes ? payroll.deductionBpjsKes + '' : '0';
    payroll.deductionBpjsTk = payroll.deductionBpjsTk ? payroll.deductionBpjsTk + '' : '0';
    payroll.deductionLoan = payroll.deductionLoan ? payroll.deductionLoan + '' : '0';
    payroll.deductionOther = payroll.deductionOther ? payroll.deductionOther + '' : '0';
    payroll.deductionPph21 = payroll.deductionPph21 ? payroll.deductionPph21 + '' : '0';
    payroll.incomeIncentives = payroll.incomeIncentives ? payroll.incomeIncentives + '' : '0';
    payroll.incomeOther = payroll.incomeOther ? payroll.incomeOther + '' : '0';
    payroll.incomeOvertime = payroll.incomeOvertime ? payroll.incomeOvertime + '' : '0';
    payroll.incomePerdiem = payroll.incomePerdiem ? payroll.incomePerdiem + '' : '0';
    payroll.salaryBase = payroll.salaryBase ? payroll.salaryBase + '' : '0';

    // Amount
    const income = (Number(payroll.allowanceMeal) + Number(payroll.allowanceTitle) + Number(payroll.allowanceTransport) +
      Number(payroll.incomeIncentives) + Number(payroll.incomeOther) + Number(payroll.incomeOvertime) +
      Number(payroll.incomePerdiem) + Number(payroll.salaryBase));

    const deduction = (Number(payroll.deductionAdmin) + Number(payroll.deductionAttendance) + Number(payroll.deductionBpjsKes) +
      Number(payroll.deductionBpjsTk) + Number(payroll.deductionLoan) + Number(payroll.deductionOther) + Number(payroll.deductionPph21));

    const amount = income - deduction;
    payroll.amount = amount ? amount + '' : '0';

    this.isSubmitedSubject.next(true);
    return this.employeeSalary.createEmployeeSalary(companyId, userId, payroll).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  updateEmployeeSalary(companyId: number, userId: number, data: any): Observable<Response> {
    const payroll: any = Object.assign({}, data);
    payroll.allowanceMeal = payroll.allowanceMeal ? payroll.allowanceMeal + '' + '' : '0';
    payroll.allowanceTitle = payroll.allowanceTitle ? payroll.allowanceTitle + '' : '0';
    payroll.allowanceTransport = payroll.allowanceTransport ? payroll.allowanceTransport + '' : '0';
    payroll.deductionAdmin = payroll.deductionAdmin ? payroll.deductionAdmin + '' : '0';
    payroll.deductionAttendance = payroll.deductionAttendance ? payroll.deductionAttendance + '' : '0';
    payroll.deductionBpjsKes = payroll.deductionBpjsKes ? payroll.deductionBpjsKes + '' : '0';
    payroll.deductionBpjsTk = payroll.deductionBpjsTk ? payroll.deductionBpjsTk + '' : '0';
    payroll.deductionLoan = payroll.deductionLoan ? payroll.deductionLoan + '' : '0';
    payroll.deductionOther = payroll.deductionOther ? payroll.deductionOther + '' : '0';
    payroll.deductionPph21 = payroll.deductionPph21 ? payroll.deductionPph21 + '' : '0';
    payroll.incomeIncentives = payroll.incomeIncentives ? payroll.incomeIncentives + '' : '0';
    payroll.incomeOther = payroll.incomeOther ? payroll.incomeOther + '' : '0';
    payroll.incomeOvertime = payroll.incomeOvertime ? payroll.incomeOvertime + '' : '0';
    payroll.incomePerdiem = payroll.incomePerdiem ? payroll.incomePerdiem + '' : '0';
    payroll.salaryBase = payroll.salaryBase ? payroll.salaryBase + '' : '0';

    // Amount
    const income = (Number(payroll.allowanceMeal) + Number(payroll.allowanceTitle) + Number(payroll.allowanceTransport) +
      Number(payroll.incomeIncentives) + Number(payroll.incomeOther) + Number(payroll.incomeOvertime) +
      Number(payroll.incomePerdiem) + Number(payroll.salaryBase));

    const deduction = (Number(payroll.deductionAdmin) + Number(payroll.deductionAttendance) + Number(payroll.deductionBpjsKes) +
      Number(payroll.deductionBpjsTk) + Number(payroll.deductionLoan) + Number(payroll.deductionOther) + Number(payroll.deductionPph21));

    const amount = income - deduction;
    payroll.amount = amount ? amount + '' : '0';

    this.isSubmitedSubject.next(true);
    return this.employeeSalary.updateEmployeeSalary(companyId, userId, payroll).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API

}
