import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';
import { environment } from 'src/environments/environment';
import { AssetClassModel, AssignAssetModel, PostAssetModel, ResponseAsset, ResponseAssetAssignType } from '../..';

const API_ASSET_URL = `${environment.apiUrl}/lms`;

@Injectable({
  providedIn: 'root'
})
export class AssetClassHTTPService {

  constructor(
    private http: HttpClient
  ) { }

  // Assets Class
  getAsset(filter: FilterModel): Observable<ResponseAsset> {
    return this.http.get<ResponseAsset>(`${API_ASSET_URL}/asset_class?metadata=true&${filter.query}page=${filter.page}&limit=${filter.size}&sort=${filter.sort ? filter.sort : 'name'}:${filter.asc ? 'asc' : 'desc'}`);
  }

  postAsset(asset: PostAssetModel): Observable<AssetClassModel> {
    return this.http.post<AssetClassModel>(`${API_ASSET_URL}/asset_class`, asset);
  }

  putAsset(assetId: number, asset: PostAssetModel): Observable<AssetClassModel> {
    return this.http.put<AssetClassModel>(`${API_ASSET_URL}/asset_class/${assetId}`, asset);
  }

  deleteAsset(assetId: number): Observable<AssetClassModel> {
    return this.http.delete<AssetClassModel>(`${API_ASSET_URL}/asset_class/${assetId}`);
  }


  // Assign Assets Class
  getCompanyAsset(filter: FilterModel): Observable<ResponseAssetAssignType> {
    return this.http.get<ResponseAssetAssignType>(`${API_ASSET_URL}/asset_class_company?metadata=true&${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : 20}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}`);
  }

  selectCompanyAsset(data: AssignAssetModel): Observable<AssetClassModel> {
    return this.http.post<AssetClassModel>(`${API_ASSET_URL}/asset_class_company`, data);
  }

  deselectCompanyAsset(assignId: number): Observable<AssetClassModel> {
    return this.http.delete<AssetClassModel>(`${API_ASSET_URL}/asset_class_company/${assignId}`);
  }

  putCompanyAsset(assignId: number, data: AssignAssetModel): Observable<AssetClassModel> {
    return this.http.put<AssetClassModel>(`${API_ASSET_URL}/asset_class_company/${assignId}`, data);
  }

  // getAssetById(assetId: number): Observable<AssetClassModel> {
  //   return this.http.get<AssetClassModel>(`${API_ASSET_URL}/asset_class/${assetId}`);
  // }
}
