import { Injectable } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  form(model: FormGroup, name: string): boolean {
    // if (model.controls[name].invalid && model.controls[name].touched) console.log(model.controls[name]); // Ndebug
    return model.controls[name].invalid && model.controls[name].touched;
  }

  filterSession(filterSession: any, key: any): any {
    return (filterSession) ? filterSession.filter((fil: any) => fil.key === key) : [];
  }

  scrollTop(): void {
    // tslint:disable-next-line:no-non-null-assertion
    document.getElementById('kt_scrolltop')!.click();
  }

  camelCase(word: string): string {
    const words = word.split(' ');
    let ind = 0;
    for (const iterator of words) {
      words[ind] = words[ind].replace(iterator.charAt(0), iterator.charAt(0).toUpperCase());
      ind++;
    }
    return words.join(' ');
  }

  // Begin::Tags
  contains(target: string, pattern: string[]): number {
    let value = 0;
    pattern.forEach((element: string) => {
      const temp = target.includes(element) ? 1 : 0;
      value = value + temp;
    });
    return value;
  }
  setValueMultipleTags(formControl: FormControl, indexTagValue?: number, isSpecial?: boolean): FormControl {
    if (isSpecial) {
      // console.log(indexTagValue);
      formControl.value.splice(indexTagValue, 1);
      formControl.setValue(formControl.value.length ? formControl.value : null);
      return formControl;
    }
    formControl.setValue(null);
    return formControl;
  }
  // End::Tags

  // Begin::Checkbox
  getCheckbox(
    arrId: number[],
    id: number,
    isChecked: boolean
  ): number[] {

    if (isChecked) {
      const indexChecked = arrId.findIndex((x: number) => x === id);
      if (indexChecked === -1 && !arrId.includes(id)) {
        arrId.push(id);
      }
    } else {
      const indexUnchecked = arrId.findIndex((x: number) => x === id);
      arrId.splice(indexUnchecked, 1);
    }
    return arrId;
  }
  getCheckboxAll(
    arrId: number[],
    listItem: any,
    checked: boolean,
    selector?: 'userId'
  ): number[] {

    if (listItem) {
      listItem.forEach((element: any) => {
        arrId = this.getCheckbox(
          arrId,
          selector ? element[selector] : element.id,
          checked
        );
      });
    }
    return arrId;
  }
  // End::Checkbox
}
