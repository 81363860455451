export class EnumAge {
    public static ageSelect: any = [
        { name: '18 - 21 tahun', enum: '18,21' },
        { name: '22 - 27 tahun', enum: '22,27' },
        { name: '28 - 37 tahun', enum: '28,37' },
        { name: '38 - 47 tahun', enum: '38,47' },
        { name: '48 - 57 tahun', enum: '48,57' },
        { name: '> 57 tahun', enum: '57' },
    ];

    public static findAge(age: string): any {
        return EnumAge.ageSelect.find((fin: any) => fin.enum === age.trim());
    }
}
