export class EnumLocation {
  public static locationSelect: any = [
    {name: 'Jakarta', enum: 'jakarta'},
    {name: 'Jabodetabek', enum: 'jabodetabek'},
    {name: 'Bandung', enum: 'bandung'},
    {name: 'Surabaya', enum: 'surabaya'},
    {name: 'Semarang', enum: 'semarang'},
    {name: 'Yogyakarta', enum: 'yogyakarta'},
    {name: 'Malang', enum: 'malang'},
    {name: 'Jember', enum: 'jember'},
    {name: 'Banyuwangi', enum: 'banyuwangi'},
    {name: 'Tangerang', enum: 'tangerang'},
    {name: 'Jakarta', enum: 'jakarta'},
    {name: 'Dki Jakarta', enum: 'dki jakarta'},
    {name: 'Jakarta Pusat', enum: 'jakarta pusat'},
    {name: 'Jakarta Utara', enum: 'jakarta utara'},
    {name: 'Jakarta Selatan', enum: 'jakarta selatan'},
    {name: 'Jakarta Timur', enum: 'jakarta timur'},
    {name: 'Jakarta Barat', enum: 'jakarta barat'},
    {name: 'Bekasi', enum: 'bekasi'},
    {name: 'Depok', enum: 'depok'},
    {name: 'Bogor', enum: 'bogor'},
    {name: 'Cirebon', enum: 'cirebon'},
    {name: 'Medan', enum: 'medan'},
  ];
}
