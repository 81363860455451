import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { GlobalConstants, SessionStorage } from "src/app/_class";
import { TagsService } from "src/app/_service";
import { IndexApplicantVariables } from "..";

import * as moment from "moment";
moment.locale('id');

@Injectable()
export class ApplicantClass {

  constructor(
    private tagsService: TagsService,
    // private notification: PushNotificationService,
  ) {}

  // Variables Initial
  initVariable(isAdmin: boolean): IndexApplicantVariables {
    const varModel = {} as IndexApplicantVariables;

    const Session = SessionStorage.getStorageJson('applicantTags');

    const tempJob = this.tagsService.filterSession(Session, 'job');
    const tempAge = this.tagsService.filterSession(Session, 'age');
    // const tempWorkDuration = this.tagsService.filterSession(Session, 'workDuration');
    // const tempCurrentSalary = this.tagsService.filterSession(Session, 'currentSalary');
    const tempExpectedSalary = this.tagsService.filterSession(Session, 'expectedSalary');
    const tempCity = this.tagsService.filterSession(Session, 'city');
    const tempGender = this.tagsService.filterSession(Session, 'gender');
    const tempTransport = this.tagsService.filterSession(Session, 'transport');
    // const tempDocument = this.tagsService.filterSession(Session, 'document');
    const tempDate = this.tagsService.filterSession(Session, 'date');

    // tslint:disable-next-line:no-non-null-assertion
    varModel.tempQuery = sessionStorage.getItem('applicantQuery') ? sessionStorage.getItem('applicantQuery')! : null;
    varModel.companyPick = (isAdmin) ? SessionStorage.getStorage('applicantCompanySelected') : '' + GlobalConstants.companyId;
    varModel.statusPick = sessionStorage.getItem('applicantStatusSelected') ? '' + sessionStorage.getItem('applicantStatusSelected') : '';
    // varModel.arrFreeText = (tempFreeText.length) ? tempFreeText[0].value : [];
    varModel.jobPick = (tempJob.length) ? tempJob[0].value : null;
    varModel.agePick = (tempAge.length) ? tempAge[0].value : null;
    // varModel.workDurationPick = (tempWorkDuration.length) ? tempWorkDuration[0].value : null;
    // varModelCurrentSalaryPick. = (tempCurrentSalary.length) ? tempCurrentSalary[0].value : null;
    varModel.expectedSalaryMin = (tempExpectedSalary.length) ? tempExpectedSalary[0].value.min : null;
    varModel.expectedSalaryMax = (tempExpectedSalary.length) ? tempExpectedSalary[0].value.max : null;
    varModel.cityPick = (tempCity.length) ? tempCity[0].value : null;
    varModel.genderPick = (tempGender.length) ? tempGender[0].value : null;
    varModel.transportPick = (tempTransport.length) ? tempTransport[0].value : null;
    // varModelDocumentPick. = (tempDocument.length) ? tempDocument[0].value : null;
    varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    varModel.tags = Session ? Session : [];

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(
    companyPick: string,
    statusPick: string,
    jobPick: FormControl,
    agePick: FormControl,
    // workDurationPick: FormControl,
    expectedSalaryPick: FormGroup,
    cityPick: FormControl,
    genderPick: FormControl,
    transportPick: FormControl,
    datePick: FormGroup,
    tags: {key: string, value: string}[]
  ): string {
    // if (this.freeText || this.arrFreeText.length) {
    //   this.remove({key: 'freetext'});
    //   const arrFreeText = this.freeText ? this.freeText.split(',') : [];
    //   if (arrFreeText.length) {
    //     arrFreeText.forEach(element => {
    //       this.arrFreeText.push(element);
    //     });
    //   }
    //   this.tags.push({key: 'freetext', value: this.arrFreeText});
    //   this.freeText = '';
    // }
    if (agePick.value) {
      tags = this.tagsService.updateKey(tags, 'age', agePick);
    }
    // if (workDurationPick.value) tags = this.tagsService.updateKey(tags, 'workDuration', workDurationPick);
    if (expectedSalaryPick.value) {
      tags = this.tagsService.updateKey(tags, 'expectedSalary', expectedSalaryPick, 'amount');
    }
    if (cityPick.value) {
      tags = this.tagsService.updateKey(tags, 'city', cityPick, 'array');
    }
    if (genderPick.value) {
      tags = this.tagsService.updateKey(tags, 'gender', genderPick, 'array');
    }
    if (transportPick.value) {
      tags = this.tagsService.updateKey(tags, 'transport', transportPick, 'array');
    }
    if (datePick.value) {
      tags = this.tagsService.updateKey(tags, 'date', datePick, 'date');
    }

    // if (documentPick.value) {
    //   remove({key: 'document'});
    //   if (documentPick.value.length) {
    //     tags.push({key: 'document', value: documentPick.value});
    //   }
    // }

    // const freeText = arrFreeText.length ? `${arrFreeText.join(',')}` : null;
    const idCompany = (companyPick) ? `company_id=${companyPick}` : null;
    const status = (statusPick) ? `status=${statusPick.toLowerCase()}` : null;
    const job = jobPick.value ? `title=${jobPick.value}` : null;
    const age = agePick.value ? `${this.generateAge(agePick.value)}` : null;
    // const workDuration = workDurationPick.value ? `${this.generateWorkDuration(workDurationPick.value)}` : null;
    // const currentSalaryPick = currentSalaryPick.value ? `current_salary:(${currentSalaryPick.value})` : null;
    const expectedSalary = expectedSalaryPick.value.max ? `expected_salary_min=${expectedSalaryPick.value.min}&expected_salary_max=${expectedSalaryPick.value.max}` : null;
    const city = cityPick.value ? `worker_address_city=${cityPick.value.join(', ')}` : null;
    const gender = genderPick.value ? `gender=${genderPick.value.join(', ').toLowerCase()}` : null;
    const transport = transportPick.value ? `${this.generateTransport(transportPick.value)}` : null;
    // const tempDocument = documentPick.value ? `docs=${documentPick.value.join(', ').toLowerCase()}` : null;
    const date = datePick.value.end ? `application_date_min=${moment(datePick.value.begin).format('YYYY-MM-DD')}T00:00:00Z&application_date_max=${moment(datePick.value.end).format('YYYY-MM-DD')}T23:59:59Z` : null;

    const data = [
      idCompany, status, job, age/* , workDuration */, expectedSalary, city, gender, transport, date
    ];

    // console.log(data);

    const filteredData = data.filter((el: string | null) => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('applicantQuery', tempQuery);
    sessionStorage.setItem('applicantTags', JSON.stringify(tags));
    return tempQuery;
  }

  eventFilter(tags: {key: string, value: string}[], event?: any, autoType?: string): any {
    const eventModel = {} as {
      companyPick: string,
      jobPick: string
    };
    const tempJobControl = new FormControl();

    if (event.id) {
      // J2G-1698 - New Filter per title Job
      if (autoType === 'company') {
        eventModel.companyPick = event.id;
        sessionStorage.setItem('applicantCompanySelected', eventModel.companyPick);
      }
      if (autoType === 'job') {
        eventModel.jobPick = event.title;
        tempJobControl.setValue(event.title);
        // tags = this.tagsService.updateKey(tags, 'job', tempJobControl);
      }
    } else {
      // J2G-1698 - New Filter per title Job
      if (autoType === 'company') { // Reset Company Filter
        sessionStorage.removeItem('applicantCompanySelected');
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      sessionStorage.removeItem('applicantQuery');
      sessionStorage.removeItem('applicantTags');
    }
    return eventModel;
  }

  removeTags(
    tags: {key: string, value: string}[],
    cityPick: FormControl,
    genderPick: FormControl,
    transportPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
      // Debugging Purpose
      // console.log(tag, indexTagValue, tag.key);
      // return;

      // if (tag.key === 'freetext') { this.arrFreeText.splice(ind, 1); if (!this.arrFreeText.length) this.tags.splice(index, 1); }
      if (tag.key === 'city') { tags = this.tagsService.removeKey(tags, tag.key, cityPick, 'array', indexTagValue); }
      else if (tag.key === 'gender') { tags = this.tagsService.removeKey(tags, tag.key, genderPick, 'array', indexTagValue); }
      else if (tag.key === 'transport') { tags = this.tagsService.removeKey(tags, tag.key, transportPick, 'array', indexTagValue); }
      else { tags = this.tagsService.removeKey(tags, tag.key); } // by default yang bukan array disini

      sessionStorage.setItem('applicantTags', JSON.stringify(tags));
  }
  // End Filter Logic

  // Generate Filter Value
  generateAge(age: string): string {
    const arrAge: string[] = age.trim().split(',');
    if (arrAge.length === 1) {
      return `age_min=${arrAge[0]}&age_max=100`;
    } else {
      return `age_min=${arrAge[0]}&age_max=${arrAge[1]}`;
    }
  }
  generateWorkDuration(month: string): string {
    const arrMonth = month.trim().split(',');
    if (arrMonth.length === 1) {
      return `work_duration_month_min=${arrMonth[0]}&work_duration_month_max=480`;
    } else {
      return `work_duration_month_min=${arrMonth[0]}&work_duration_month_max=${arrMonth[1]}`;
    }
  }
  generateTransport(transport: any): string { // yang filter
    const filter: string[] = [];
    if (transport.includes('MOBIL')) {
      filter.push(`has_car=true`);
    }
    if (transport.includes('MOTOR')) {
      filter.push(`has_motor=true`);
    }
    return filter.join('&');
  }
  // End of Generate Filter Value

  // Generate Table Value
  generateLabelColor(type: string, duration: number): string {
    // Label in Object
    if (type === 'age') { // for Profile
      return (duration <= 25) ? 'label-light-success' : (
        (duration > 45) ? 'label-light-danger' : 'label-light-info'
      );
    }
    if (type === 'workDuration') { // Label in Array
      const temp = this.generateDurationMonth(duration);
      return (temp === 'unknown') ? 'label-light-default' : (
        (temp.includes('bulan')) ? 'label-light-danger' : (
          (+temp.split(' ')[0] <= 2 && temp.includes('tahun')) ? 'label-light-info' : 'label-light-success'
        )
      );
    }
    return 'label-light-default';
  }
  generateDurationMonth(duration: number): string {
    return (duration) ? (
      (duration / 12 > 1) ? Math.floor((duration / 12)) + ' tahun' : duration + ' bulan'
    ) : 'unknown';
  }
  // End of Generate Table Value

}