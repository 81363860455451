/* tslint:disable:only-arrow-functions */
import { EnumEmployeeGender, EnumEmployeeMaritalStatus } from '../layouts/main/pages/employee-list';
import { WorkerProfileModel } from '../layouts/main/pages/manage-applicants';
import { EnumCommutingType, EnumJobCategory } from '../layouts/main/pages/manage-job';
import { AddressPipe } from '../shared/_pipes/address.pipe';

import * as moment from 'moment';
moment.locale('id');

export class CurriculumVitae {

    private static transformCategory(category: string): string {
      const tempCategory: string[] = category.split(', ');
      const arrCategory: string[] = [];
      tempCategory.forEach((element: string) => {
        arrCategory.push(EnumJobCategory.generateJobCategory(element, true));
      });
      return arrCategory.join(', ');
    }

    private static transformCommuting(commuting: string): string {
      const tempCommuting: string[] = commuting.split(', ');
      const arrCommuting: string[] = [];
      tempCommuting.forEach((element: string) => {
        arrCommuting.push(EnumCommutingType.generateCommuting(element, true));
      });
      return arrCommuting.join(', ');
    }

    static generateExperiences(experienceData: any): any {
      const tempExperiences: any = [
        { text: 'Pengalaman Kerja', style: 'header', },
        { canvas: [ { type: 'rect', x: 0, y: 0, w: 520, h: 0, lineColor: '#000000', } ] },
      ];
      let i = 2;
      if (experienceData && experienceData.length > 0) {
        experienceData.forEach((element: any) => {
          tempExperiences[i] = {
            columns: [
              { text: `${element.companyName}`, style: 'company' },
              { text: `${element.startDate} - ${element.startDate}`, style: 'date' }
            ]
          };
          i++;
          tempExperiences[i] = {
            columns: [
              { text: `${element.position}`, style: 'position' }
            ]
          };
          i++;
          tempExperiences[i] = {
            columns: [
              { text: `${element.description ? element.description : 'tidak ada deskripsi pekerjaan'}`, style: 'description' }
            ]
          };
          i++;
        });
      } else {
        tempExperiences[i] = { columns: [ { text: 'Belum Ada Data', style: 'description' } ] };
      }
      return tempExperiences;
    }

    static generateEducation(educationData: any): any {
      const tempEducation: any = [
        { text: 'Riwayat Pendidikan', style: 'header' },
        { canvas: [ { type: 'rect', x: 0, y: 0, w: 520, h: 0, lineColor: '#000000', } ] },
      ];
      let i = 2;
      if (educationData && educationData.length > 0) {
        educationData.forEach((element: any) => {
          tempEducation[i] = {
            columns: [
              { text: `${element.major}`, style: 'company' },
              { text: `${element.startDate} - ${element.endDate}`, style: 'date' }
            ]
          };
          i++;
          tempEducation[i] = {
            columns: [
              { text: `${element.institution}`, style: 'position' }
            ]
          };
          i++;
          tempEducation[i] = {
            columns: [
              { text: `Gelar : ${element.degree}`, style: 'description' }
            ]
          };
          i++;
        });
      } else {
        tempEducation[i] = { columns: [ { text: 'Belum Ada Data', style: 'description' } ] };
      }
      return tempEducation;
    }

    static async getBase64ImageFromUrl(imageUrl: string): Promise<any> {
      const request = new Request(imageUrl, {
        mode: 'no-cors',
        referrerPolicy: 'strict-origin-when-cross-origin'
      });
      const res = await fetch(request);
      const blob = await res.blob();

      console.log(res, blob);

      return new Promise((resolve, reject) => {
        const reader  = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(this);
        reader.readAsDataURL(blob);
      });
    }

    static generateProfile(url: string): Promise<any> {
      // tslint:disable-next-line:typedef
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve('success');
        img.onerror = img.onabort = () => reject('error');
        img.src = url;
      });
    }

    static pdfConfig(
      profileData: WorkerProfileModel,
      userPreferences: any,
      generatedExperiences: any,
      generatedEducation: any,
      dataUri: any
    ): any {
      const addressPipe = new AddressPipe();
      const workerBirthDate = profileData.birthDate ? moment(profileData.birthDate).utcOffset('+07:00').format('LL') : '-';
      const workerAge = profileData.birthDate ? `(${moment().diff(moment(profileData.birthDate), 'years')} tahun)` : '-';
      const workerAddress = addressPipe.transform(profileData, 'worker');
      const workerGender = profileData.gender ? EnumEmployeeGender.generateGender(profileData.gender) : 'Tidak Menyebutkan';
      const workerMarital = profileData.maritalStatus ? EnumEmployeeMaritalStatus.generateMaritalStatus(profileData.maritalStatus) : 'Tidak Menyebutkan';

      // console.log(dataUri); // ? Debuging Purpose
      return {
        content: [{
          columns: [
            { image: 'profile', width: 150 },
            [
              { text: `${profileData.fullName}`, style: 'title' },
              //  { canvas: [ { type: 'rect', x: 15, y: 0, w: 325, h: 0, lineColor: '#000000', } ] },
              {
                columns: [
                  { text: 'E-mail', width: 120, margin: [15, 5], style: 'font_title' },
                  { text: ':', width: 15, margin: [5, 5], style: 'font_title' },
                  { text: `${profileData.email}`, margin: [0, 5], style: 'font_title' }
                ]
              },
              {
                columns: [
                  { text: 'Nomor Telepon', width: 120, margin: [15, 5], style: 'font_title' },
                  { text: ':', width: 15, margin: [5, 5], style: 'font_title' },
                  { text: `${profileData.phoneNumber}`, margin: [0, 5], style: 'font_title' }
                ]
              },
              {
                columns: [
                  { text: 'Tanggal Lahir', width: 120, margin: [15, 5], style: 'font_title' },
                  { text: ':', width: 15, margin: [5, 5], style: 'font_title' },
                  { text: `${workerBirthDate} ${workerAge}`, margin: [0, 5], style: 'font_title' }
                ]
              },
              {
                columns: [
                  { text: 'Jenis Kelamin', width: 120, margin: [15, 5], style: 'font_title' },
                  { text: ':', width: 15, margin: [5, 5], style: 'font_title' },
                  { text: `${workerGender}`, margin: [0, 5], style: 'font_title' }
                ]
              },
              {
                columns: [
                  { text: 'Status Pernikahan', width: 120, margin: [15, 5], style: 'font_title' },
                  { text: ':', width: 15, margin: [5, 5], style: 'font_title' },
                  { text: `${workerMarital}`, margin: [0, 5], style: 'font_title' }
                ]
              },
              {
                columns: [
                  { text: 'Alamat', width: 120, margin: [15, 5], style: 'font_title' },
                  { text: ':', width: 15, margin: [5, 5], style: 'font_title' },
                  { text: `${workerAddress}`, margin: [0, 5], style: 'font_title' }
                ]
              }
            ],
          ],
        }, [
          { text: 'Preferensi Kerja', style: 'header' },
          { canvas: [ { type: 'rect', x: 0, y: 0, w: 520, h: 0, lineColor: '#000000', } ] },
          {
            columns: [
              { text: 'Kategori Pekerjaan Diminati', width: 315, style: 'label_fl' },
              { text: 'Jam Kerja Diminati', style: 'label_fr' }
            ]
          }, {
            columns: [
              { text: `${userPreferences.categoryType ?
                  CurriculumVitae.transformCategory(userPreferences.categoryType) : '-'}`,
                width: 315,
                style: 'text_r'
              },
              { text: `${userPreferences.times ? userPreferences.times : '-'}`, style: 'text_l' }
            ]
          }, {
            columns: [
                { text: 'Tempat Kerja Diminati', width: 315, style: 'label_l' },
                { text: 'Kota Penempatan Diminati', style: 'label_r' }
            ]
          }, {
            columns: [
              { text: `${userPreferences.commutingType ?
                  CurriculumVitae.transformCommuting(userPreferences.commutingType) : '-'}`,
                width: 315,
                style: 'text_r'
              },
              { text: `${userPreferences.location}`, style: 'text_l' }
            ]
          }, {
            columns: [
              { text: 'Hari Kerja Diminati', width: 315, style: 'label_l' },
              { text: 'Bahasa Yang Dikuasai', style: 'label_r' }
            ]
          }, {
            columns: [
              { text: `${userPreferences.daysOfWork}`, width: 315, style: 'text_r' },
              { text: `${userPreferences.language}`, style: 'text_l' }
            ]
          }, {
            columns: [
              { text: 'Skill Yang Dimiliki', width: 315, style: 'label_l' }
            ]
          }, {
            columns: [
              { text: `${userPreferences.skills}`, width: 315, style: 'text_r' }
            ]
          }
        ],
        generatedExperiences,
        generatedEducation
        ],
        images: {
          profile: `${dataUri}`
        },
        styles: {
          title: { fontSize: 21, bold: true, margin: [15, 0, 15, 10] },
          font_title: { fontSize: 11 },
          header: { fontSize: 14, bold: true, margin: [0, 10, 0, 7] },
          label_fl: { fontSize: 11, color: 'grey', margin: [0, 15, 15, 5] },
          label_fr: { fontSize: 11, color: 'grey', margin: [0, 15, 0, 5] },
          label_l: { fontSize: 11, color: 'grey', margin: [0, 0, 15, 5] },
          label_r: { fontSize: 11, color: 'grey', margin: [0, 0, 0, 5] },
          text_r: { fontSize: 11, margin: [0, 0, 15, 10] },
          text_l: { fontSize: 11, margin: [0, 0, 0, 10] },
          company: { fontSize: 13, bold: true, margin: [0, 15, 15, 5] },
          date: { fontSize: 13, bold: true, margin: [0, 15, 0, 5], alignment: 'right' },
          position: { fontSize: 12, bold: true, margin: [0, 0, 15, 5] },
          description: { fontSize: 11, margin: [0, 0, 0, 10] }
        }
      };
    }
}
