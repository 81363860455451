import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { ResponseCompanyDept } from '..';
import { CompanyDepartmentHTTPService } from './company-http';

@Injectable({
  providedIn: 'root'
})
export class CompanyDepartmentService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private companyDeptHttp: CompanyDepartmentHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getCompanyDept(companyId: number): Observable<ResponseCompanyDept> {
    // const auth = this.authService.getAuthFromLocalStorage();
    // if (!auth || !auth.id_token || !auth.companyId) {
    //   return of(undefined);
    // }

    this.isLoadingSubject.next(true);
    return this.companyDeptHttp.getCompanyDept(companyId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitCompanyDept(companyId: number, deptId: number | null, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    if (deptId) {
      return this.companyDeptHttp.putCompanyDept(companyId, deptId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.companyDeptHttp.postCompanyDept(companyId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteCompanyDept(companyId: number, bankId: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyDeptHttp.delCompanyDept(companyId, bankId).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
