import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_COMPANY_URL = `${environment.apiUrl}/company`;

@Injectable({
  providedIn: 'root'
})
export class CompanyShiftHTTPService {

  constructor(private http: HttpClient) { }

  // Company Shift
  getCompanyShift(companyId: number): Observable<any> {
    return this.http.get<any>(`${API_COMPANY_URL}/${companyId}/attendance/shift`);
  }

  postCompanyShift(companyId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_COMPANY_URL}/${companyId}/attendance/shift`, data);
  }

  putCompanyShift(companyId: number, shiftId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_COMPANY_URL}/${companyId}/attendance/shift/${shiftId}`, data);
  }

  delCompanyShift(companyId: number, shiftId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_COMPANY_URL}/${companyId}/attendance/shift/${shiftId}`);
  }
  // End of Company Shift
}
