import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { environment } from 'src/environments/environment';

const API_APPLICANT_URL = `${environment.apiUrl}`;
const API_APPLICANT_URL_V4 = `${environment.apiUrlV4}`;

@Injectable({
  providedIn: 'root'
})
export class ManageApplicantHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) {}

  getApplicant(page: number, data: any, size: number, sort?: string, asc?: boolean): Observable<any> {
    return this.http.get<any>(
        (data) ? `/api/_search/v2/job-applications?query=${data}&page=${(page) ? page : 0}&size=${(size) ? size : 10}&sort=${sort ? sort : 'id'},${asc ? 'asc' : 'desc'}` :
        `api/_search/v2/job-applications?query=applicationStatus:(*)&page=${(page) ? page : 0}&size=${(size) ? size : 10}&sort=${sort ? sort : 'id'},${asc ? 'asc' : 'desc'}`,
        {
          observe: 'response',
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('id_token'),
          }
        }
      );
  }

  getApplicantGOapi(filter: FilterModel): Observable<any> {
    const url = `${API_APPLICANT_URL_V4}/applicant?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : 10}&sort=${filter.sort ? filter.sort : 'id'}${filter.asc ? ':asc' : ':desc'}`;
    return this.http.get<any>(url);
  }

  getApplicantById(id: number): Observable<any> {
    return this.http.get<any>(`${API_APPLICANT_URL}/applicant/${id}`, this.config.GHttpOptionsDefault());
  }

  exportApplicantGOapi(companyId: number, query: string): Observable<Response> {
    return this.http.get<Response>(`${API_APPLICANT_URL}/company/${companyId}/applicant_report?metadata=true&${query}`);
  }

  putApplicantStatus(id: number, data: {status: string}): Observable<Response> {
    return this.http.patch<Response>(`${API_APPLICANT_URL}/applicant/${id}`, data);
  }

  dowloadCvApplicant(id: number): Observable<Response> {
    return this.http.post<Response>(`${API_APPLICANT_URL}/applicant/download/${id}`, '');
  }
}
