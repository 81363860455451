export class EnumJobSummary {
    public static jobSummarySelect: any = [
        { name: 'Kantoran, Fulltime', enum: 'kantoran_fulltime' },
        { name: 'Kantoran, Remote', enum: 'kantoran_remote' },
        { name: 'Magang', enum: 'magang' },
        { name: 'Kemitraan (Freelance)', enum: 'freelance' },
        { name: 'Project Based (Volunteer)', enum: 'project_based' },
        { name: 'Other', enum: 'other' },
    ];

    public static generateJobSummary(summary: string): string {
        const tempSummary = EnumJobSummary.jobSummarySelect.find((fin: any) => fin.enum === summary.trim().toLowerCase());
        return (tempSummary) ? tempSummary.name : '-';
    }
}
