import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service';
import { FilterModel } from 'src/app/_class';

const API_PAYROLL_URL = `${environment.apiUrlV4}`;

@Injectable({
  providedIn: 'root'
})
export class PayrollHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  getBalance(companyId: number): Observable<Response> {
    return this.http.get<Response>(`${API_PAYROLL_URL}/company/${companyId}/balance`);
  }
  getPayroll(companyId: number, filter: FilterModel): Observable<Response | any> {
    const url = `${API_PAYROLL_URL}/company/${companyId}/payroll?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : '20'}&sort=${filter.sort ? filter.sort : 'scheduled_date'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<Response | any>(url);
  }
  getPayrollStatus(companyId: number, id: number): Observable<Response> {
    return this.http.get<Response>(`${API_PAYROLL_URL}/company/${companyId}/payroll/${id}/update`);
  }
  createPayroll(companyId: number, params: any): Observable<Response> {
    return this.http.post<Response>(`${API_PAYROLL_URL}/company/${companyId}/payroll`, params);
  }
  updatePayroll(companyId: number, id: number, params: any): Observable<Response> {
    return this.http.put<Response>(`${API_PAYROLL_URL}/company/${companyId}/payroll/${id}`, params);
  }
  patchPayroll(companyId: number, id: number, params: {
    status: string,
    reason?: string
  }): Observable<Response> {
    const url = `${API_PAYROLL_URL}/company/${companyId}/payroll/${id}`;

    return this.http.patch<Response>(url, params, (params.status === 'queued') ? (
        (!environment.staging) ? this.config.GHttpOptionsXPayment() : this.config.GHttpOptionsXSauron()
      ) : this.config.GHttpOptionsDefault());
  }
  deletePayroll(companyId: number, id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_PAYROLL_URL}/company/${companyId}/payroll/${id}`);
  }
}
