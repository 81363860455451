import { PostCompanyModel } from 'src/app/layouts/auth';
import { CompanyDataModel } from './company-data.model';

export class PostCompanyClass {
    public static formatCompany(company: CompanyDataModel): PostCompanyModel {
        const payloadCompany = {} as PostCompanyModel;

        payloadCompany.name = company.name;
        payloadCompany.url = company.url;
        payloadCompany.industry = company.industry;
        payloadCompany.scale = company.scale;
        payloadCompany.addressStreet = company.addressStreet;
        payloadCompany.addressDistrict = company.addressDistrict;
        payloadCompany.addressSubDistrict = company.addressSubDistrict;
        payloadCompany.addressCity = company.addressCity;
        payloadCompany.addressZip = company.addressZip;
        payloadCompany.description = company.description;
        payloadCompany.phone = company.phone;
        payloadCompany.email = company.email;
        payloadCompany.location = company.location;
        payloadCompany.externalUrl = company.externalUrl;
        payloadCompany.howDoYouKnowUs = company.howDoYouKnowUs;
        payloadCompany.startYear = (company.startYear === '0') ? null : Number(company.startYear);
        payloadCompany.totalEmployee = company.totalEmployee ? Number(company.totalEmployee) : null;
        payloadCompany.transactionPerMonth = company.transactionPerMonth;
        payloadCompany.revenuePerMonth = company.revenuePerMonth;
        payloadCompany.webUrl = company.webUrl;
        payloadCompany.facebookUrl = company.facebookUrl;
        payloadCompany.twitterUrl = company.twitterUrl;
        payloadCompany.linkedinUrl = company.linkedinUrl;
        payloadCompany.instagramUrl = company.instagramUrl;
        payloadCompany.notes = company.notes;

        return payloadCompany;
    }
}
