export class EnumProjectType {
    public static projectTypeSelect: any = [
        {
            name: 'Merchant Acquisition',
            enum: 'merchant_acquisition',
            image_url: './assets/media/png/project-type/merchant-acquisition.png',
            image_url_lg: './assets/media/png/project-type/merchant-acquisition-120x120.png'
        },
        {
            name: 'Sales Support',
            enum: 'sales_support',
            image_url: './assets/media/png/project-type/sales-support.png',
            image_url_lg: './assets/media/png/project-type/sales-support-120x120.png'
        },
        {
            name: 'Sales Motorist',
            enum: 'sales_motorist',
            image_url: './assets/media/png/project-type/sales-support.png',
            image_url_lg: './assets/media/png/project-type/sales-support-120x120.png'
        }
    ];

    public static generateProjectType(type: string): string {
        const tempProjectList = EnumProjectType.projectTypeSelect.find((fin: any) => fin.enum === type);
        return tempProjectList ? tempProjectList.name : 'Unknown';
    }

    public static findProjectType(type: string): any {
        return EnumProjectType.projectTypeSelect.find((fin: any) => fin.enum === type.trim().toLowerCase());
    }
}
