import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { GlobalConstants } from 'src/app/_class';
import { PostCompanyHolidayModel } from '..';
import { ConfigHTTPService } from './attendance-http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentHoliday$: Observable<any>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  currentHolidaySubject: BehaviorSubject<any>;

  constructor(
    private attendanceConfigHttp: ConfigHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentHolidaySubject = new BehaviorSubject<any>(null);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.currentHoliday$ = this.currentHolidaySubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse) {
    this.attendanceConfigHttp.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string) {
    this.attendanceConfigHttp.openCustomBar(status, message);
  }

  // Logic Before Hit API
  // public method
  getCompanyHoliday(companyId: number, query?: string, page?: number, size?: number) {
    this.isLoadingSubject.next(true);
    return this.attendanceConfigHttp.getCompanyHoliday(companyId, query, page, size).pipe(
      map((result: any) => {
        if (result) {
          this.currentHolidaySubject.next(result);
        }
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getNationalHoliday(query?: string, page?: number, size?: number) {
    this.isLoadingSubject.next(true);
    return this.attendanceConfigHttp.getNationalHoliday(query, page, size).pipe(
      map((result: any) => {
        if (result) {
          this.currentHolidaySubject.next(result);
        }
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitCompanyHoliday(companyId: number, holidayId: number | null, data: PostCompanyHolidayModel, getParam: {query: string, page: number, size: number}) {
    this.isSubmitedSubject.next(true);
    if (holidayId) {
      return this.attendanceConfigHttp.putCompanyHoliday(companyId, holidayId, data).pipe(
        switchMap(() => this.getCompanyHoliday(GlobalConstants.companyId, getParam.query, getParam.page, getParam.size)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.attendanceConfigHttp.postCompanyHoliday(companyId, data).pipe(
        switchMap(() => this.getCompanyHoliday(GlobalConstants.companyId, getParam.query, getParam.page, getParam.size)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteCompanyHoliday(companyId: number, holidayId: number, getParam: {query: string, page: number, size: number}) {
    this.isSubmitedSubject.next(true);
    return this.attendanceConfigHttp.deleteCompanyHoliday(companyId, holidayId).pipe(
      switchMap(() => this.getCompanyHoliday(GlobalConstants.companyId, getParam.query, getParam.page, getParam.size)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End ofLogic Before Hit API
}
