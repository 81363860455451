import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EnumRegex } from '../_enum';

export class CustomValidators {

  static phoneNumber = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('phone_form'), { phoneNumber: true })
  ];

  static password = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('number_whitespace'), { number: true }),
    CustomValidators.patternValidator(EnumRegex.parsingRegex('password'), { character: true }),
    Validators.minLength(6)
  ];

  static fullName = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('full_name'), { fullName: true })
  ];

  static alphaNumeric = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('alpha'), { alphaNumeric: true })
  ];

  static webUrl = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('web'), { webUrl: true })
  ];

  static facebookUrl = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('sosmed_fb'), { facebookUrl: true })
  ];

  static instagramUrl = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('sosmed_ig'), { instagramUrl: true })
  ];

  static twitterUrl = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('sosmed_tw'), { twitterUrl: true })
  ];

  static linkedinUrl = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('sosmed_ln'), { linkedinUrl: true })
  ];

  static addressStreet = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('address'), { addressStreet: true })
  ];

  static addressZip = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('zip'), { addressZip: true }),
    Validators.minLength(5),
    Validators.maxLength(5)
  ];

  static alpha25 = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('alpha'), { alpha: true }),
    Validators.maxLength(25),
  ];

  static alphaSubDistrict = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('alpha_sub_district'), { alpha: true }),
    Validators.maxLength(25),
  ];

  static alphaTitle60 = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('alpha_title'), { alphaTitle: true }),
    Validators.minLength(2),
    Validators.maxLength(60)
  ];

  static alphaTitle128 = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('alpha_title'), { alphaTitle: true }),
    Validators.minLength(2),
    Validators.maxLength(128)
  ];

  static letter = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('letter'), { letter: true })
  ];

  static letter25 = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('letter'), { letter: true }),
    Validators.maxLength(25)
  ];

  static letter35 = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('letter'), { letter: true }),
    Validators.maxLength(35)
  ];

  static number = [
    CustomValidators.patternValidator(EnumRegex.parsingRegex('number'), { number: true })
  ];

  static ktp = [
    Validators.minLength(16),
    Validators.maxLength(16),
    ...this.number,
    CustomValidators.patternValidator(EnumRegex.parsingRegex('ktp'), { format: true }),
  ];

  static npwp = [
    Validators.minLength(15),
    Validators.maxLength(15),
    ...this.number
  ];

  static patternValidator(regex: RegExp | null, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        // tslint:disable-next-line:no-non-null-assertion
        return null!;
      }

      if (!regex) {
        // tslint:disable-next-line:no-non-null-assertion
        return null!;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      // tslint:disable-next-line:no-non-null-assertion
      return valid ? null! : error;
    };
  }
}
