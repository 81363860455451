import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AdditionalInfoModel } from '../_model/additional-info.model';
import { HttpConfigService } from './http-config.service';

const API_DATA_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // * Button di Breadcrumb
  buttonBreadcrumb$: Observable<string | null>;
  buttonBreadcrumbEvent$: Observable<boolean>;
  buttonBreadcrumbSubject: Subject<string | null>;
  buttonBreadcrumbEventSubject: Subject<boolean>;

  // * Button di Top Bar
  buttonTopbar$: Observable<string | null>;
  buttonTopbarLoading$: Observable<boolean>;
  buttonTopbarEvent$: Observable<boolean>;
  buttonTopbarSubject: Subject<string | null>;
  buttonTopbarLoadingSubject: Subject<boolean>;
  buttonTopbarEventSubject: Subject<boolean>;

  // * Button Cancel di Top Bar
  buttonCancelTopbar$: Observable<string | null>;
  buttonCancelTopbarEvent$: Observable<boolean>;
  buttonCancelTopbarSubject: Subject<string | null>;
  buttonCancelTopbarEventSubject: Subject<boolean>;

  // * Additional Info di Top Bar
  additionalInfo$: Observable<AdditionalInfoModel | null>;
  additionalInfoSubject: Subject<AdditionalInfoModel | null>;

  // * Message Error
  errorMessage$: Observable<{Email: string, Message: string}[]>;
  errorMessageSubject: Subject<{Email: string, Message: string}[]>;

  constructor(private http: HttpClient, private config: HttpConfigService) {
    // * Button di Breadcrumb
    this.buttonBreadcrumbSubject = new Subject<string | null>();
    this.buttonBreadcrumbEventSubject = new Subject<boolean>();
    this.buttonBreadcrumb$ = this.buttonBreadcrumbSubject.asObservable();
    this.buttonBreadcrumbEvent$ = this.buttonBreadcrumbEventSubject.asObservable();

    // * Button di Top Bar
    this.buttonTopbarSubject = new Subject<string | null>();
    this.buttonTopbarLoadingSubject = new Subject<boolean>();
    this.buttonTopbarEventSubject = new Subject<boolean>();
    this.buttonTopbar$ = this.buttonTopbarSubject.asObservable();
    this.buttonTopbarLoading$ = this.buttonTopbarLoadingSubject.asObservable();
    this.buttonTopbarEvent$ = this.buttonTopbarEventSubject.asObservable();

    // * Button Cancel di Top Bar
    this.buttonCancelTopbarSubject = new Subject<string | null>();
    this.buttonCancelTopbarEventSubject = new Subject<boolean>();
    this.buttonCancelTopbar$ = this.buttonCancelTopbarSubject.asObservable();
    this.buttonCancelTopbarEvent$ = this.buttonCancelTopbarEventSubject.asObservable();

    // * Additional Info di Top Bar
    this.additionalInfoSubject = new Subject<AdditionalInfoModel | null>();
    this.additionalInfo$ = this.additionalInfoSubject.asObservable();

    // * Message Error
    this.errorMessageSubject = new Subject<{Email: string, Message: string}[]>();
    this.errorMessage$ = this.errorMessageSubject.asObservable();
  }

  openSnackBar(status: number) {
    this.config.openSnackBar(status);
  }

  // Service message commands
  showButtonBreadcrumb(label: string | null) {
    this.buttonBreadcrumbSubject.next(label);
  }
  showButtonTopbar(label: string | null) {
    this.buttonTopbarSubject.next(label);
  }
  showButtonCancelTopbar(label: string | null) {
    this.buttonCancelTopbarSubject.next(label);
  }

  // Service button event
  buttonBreadcrumbEvent(action: boolean) {
    this.buttonBreadcrumbEventSubject.next(action);
  }
  buttonTopbarEvent(action: boolean) {
    this.buttonTopbarEventSubject.next(action);
  }
  buttonCancelTopbarEvent(action: boolean) {
    this.buttonCancelTopbarEventSubject.next(action);
  }

  buttonTopbarLoading(isLoading: boolean) {
    this.buttonTopbarLoadingSubject.next(isLoading);
  }

  // Begin::Additional Info
  showAdditionalInfo(icon: string, label: string, value: string) {
    const dataAdditional: AdditionalInfoModel = {} as AdditionalInfoModel;
    dataAdditional.icon = icon;
    dataAdditional.label = label;
    dataAdditional.value = value;

    this.additionalInfoSubject.next(dataAdditional);
  }
  resetAdditionalInfo() {
    this.additionalInfoSubject.next(null);
  }
  // End::Additional Info

  // Begin::Send Error Message
  errorEvent(error: {Email: string, Message: string}[]) {
    this.errorMessageSubject.next(error);
  }
  // End::Send Error Message

  // WMT Dashboard Sekali Hit (North Star)
  getNorthStar(begin: string, end: string) {
    return this.http.get<any>(`${API_DATA_URL}/northstar?start_date=${begin}&end_date=${end}`);
  }
  getNorthStarRange(begin: string, end: string) {
    return this.http.get<any>(`${API_DATA_URL}/northstarrange?start_date=${begin}&end_date=${end}`);
  }

  openSnackbar(status: number, error: HttpErrorResponse) {
    this.config.openSnackBar(status, error);
  }


  // Bulk Error Message
  parsingErrorMessage(message: string) {
    let result = [];
    if (message.includes(';')) result = message.split(';');
    else result = [message];
    return result;
  }
  deleteMessage(email: string, errorSource: any, module: string, key: any) {
    let index = errorSource.findIndex((fil: any) => fil.Email ? (fil.Email === email) : (fil.email === email));
    if (index !== -1) {
      errorSource.splice(index, 1);
      sessionStorage.setItem(module + key, JSON.stringify(errorSource));
    }
    if (!errorSource.length) sessionStorage.removeItem(module + key);
    return errorSource;
  }
  deleteAllMessage(errorSource: any, module: string, key: any) {
    errorSource = [];
    sessionStorage.removeItem(module + key);
    return errorSource;
  }
}
