import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL_TALENT = environment.apiUrlAts;
const API_URL_V3 = environment.apiUrl;

@Injectable()
export class TalentHttpService {

constructor(private http: HttpClient) { }

  getTalent(companyId: number, query: any, page?: number, size?: number, sort?: string, asc?: boolean): Observable<any> {
    return this.http.get<any>(`${API_URL_TALENT}/company/${companyId}/talent?${query ? query + '&' : ''}page=${(page) ? page : 0}&limit=${size ? size : 20}&sort=${sort ? sort : 'id'}${asc ? '' : ':desc'}&metadata=true`);
  }
  getBookmark(companyId: number, query: any, page?: number, size?: number, sort?: string, asc?: boolean): Observable<any> {
    return this.http.get<any>(`${API_URL_TALENT}/company/${companyId}/talent/bookmark?${query ? query + '&' : ''}page=${(page) ? page : 0}&limit=${size ? size : 20}&sort=${sort ? sort : 'id'}${asc ? '' : ':desc'}&metadata=true`);
  }
  bookmarkTalent(jobId: number, talentId: number): Observable<Response> {
    return this.http.post<Response>(`${API_URL_TALENT}/applicant`, {
      jobID: jobId,
      talentID: talentId
    });
  }
  unBookmarkTalent(companyId: number, talentId: number): Observable<Response> {
    return this.http.post<Response>(`${API_URL_TALENT}/company/${companyId}/talent/unbookmark/${talentId}`, '');
  }

  //Ambil dari pengguna Job2Go
  getSearchEmail(email: string){
    return this.http.get<any>(`${API_URL_V3}/account/${email}`);
  }  

  postTalentJob(data: any){
    return this.http.post<Response>(`${API_URL_TALENT}/applicant`, data);
  }

}
