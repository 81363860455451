import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
moment.locale('id');


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  exportExcel(title?: string, type?: string, date?: string, extension?: string): void {
    /* table id is passed over here */
    const element = document.getElementById(type ? type : 'permata-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const judul = title ? title + ' - ' : 'Permata - ';
    const tanggal = date ? date : moment().format('Do MMMM YYYY, hh:mm');
    const ekstensi = extension ? '.' + extension : '.xlsx';
    XLSX.writeFile(wb, judul + tanggal + ekstensi);
  }

  exportExcelSalesMotorist(title?: string, type?: any, date?: string, extension?: string): void {
    /* table id is passed over here */
    const element = document.getElementById(type ? type : 'permata-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const judul = title ? title + ' - ' : 'Permata - ';
    const tanggal = date ? date : moment().format('Do MMMM YYYY, hh:mm');
    const ekstensi = extension ? '.' + extension : '.xlsx';
    XLSX.writeFile(wb, judul + tanggal + ekstensi);
  }

  exportExcelAttendanceMonthly(title?: string, type?: any, date?: string, extension?: string): void {
    /* table id is passed over here */
    const element = document.getElementById(type ? type : 'permata-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const judul = title ? title + ' - ' : 'Permata - ';
    const tanggal = date ? date : moment().format('Do MMMM YYYY, hh:mm');
    const ekstensi = extension ? '.' + extension : '.xlsx';
    XLSX.writeFile(wb, judul + tanggal + ekstensi);
  }

}
