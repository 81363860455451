import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

const API_ATTENDANCE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class LeaveHTTPService {

  constructor(private http: HttpClient) { }

  getDataLeave(companyId: number, start: string, end: string, query?: string, page?: number, size?: number, sortBy?: string, sortAsc?: boolean): Observable<any> {
    const url = `${API_ATTENDANCE_URL}/company/${companyId}/attendance/leave?${query ? query + '&' : ''}${start}&${end}&page=${page ? page : '0'}&limit=${size ? size : 20}&sort=${sortBy ? sortBy : 'start'}:${sortAsc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<any>(url);
  }

  getHistoryLeave(companyId: number, userId: number, query?: string, page?: number, size?: number, sortBy?: string, sortAsc?: boolean): Observable<any> {
    const url = `${API_ATTENDANCE_URL}/company/${companyId}/attendance/leave?${query ? query + '&' : ''}user_id=${userId}&page=${page ? page : '0'}&limit=${size ? size : 20}&sort=${sortBy ? sortBy : 'start'}:${sortAsc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<any>(url);
  }

  patchDataLeave(companyId: number, userId: number, id: number, params: {
    status: string,
    reason?: string
  }): Observable<any> {
    return this.http.patch<any>(`${API_ATTENDANCE_URL}/company/${companyId}/employee/${userId}/leave/${id}`, params);
  }
}
