export class EnumMemberType {
    public static memberTypeSelect: any = [
        { name: 'Manajer', enum: 'project_manager' },
        { name: 'Anggota', enum: 'member' },
    ];

    public static findMember(type: string): any {
        return EnumMemberType.memberTypeSelect.find((member: any) => member.enum === type);
    }
}
