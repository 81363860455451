import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { GlobalConstants, SessionStorage } from "src/app/_class";
import { TagsService } from "src/app/_service";
import { IndexTalentVariables } from "../models/talent.model";

@Injectable()
export class TalentClass {

    filterEvent$: Observable<string | boolean>;
    filterEventSubject: BehaviorSubject<string | boolean>;

    constructor(
        private tagsService: TagsService
    ) {
        this.filterEventSubject = new BehaviorSubject<string | boolean>('');
        this.filterEvent$ = this.filterEventSubject.asObservable();
    }

    // Variables Initial
    initVariable(isAdmin: boolean, companyId?: number/* , clientId?: number */): IndexTalentVariables {
        const varModel = {} as IndexTalentVariables;

        const Session = SessionStorage.getStorageJson('atsTalentFilter');
        const tempFreeText = this.tagsService.filterSession(Session, 'freetext');
        const tempExp = this.tagsService.filterSession(Session, 'experience');
        const tempAge = this.tagsService.filterSession(Session, 'age');
        const tempDegree = this.tagsService.filterSession(Session, 'degree');
        const tempGender = this.tagsService.filterSession(Session, 'gender');
        const tempSalaryMin = this.tagsService.filterSession(Session, 'salary_min');
        const tempSalaryMax = this.tagsService.filterSession(Session, 'salary_max');

        varModel.tempQuery = SessionStorage.getStorage('atsTalentQuery');
        varModel.companyPick = (isAdmin) ? (
            SessionStorage.getStorage('atsTalentCompanySelected') ?
                SessionStorage.getStorage('atsTalentCompanySelected') :
                companyId ? companyId : NaN
        ) : (
            companyId ? companyId : GlobalConstants.companyId
        );
        // varModel.clientPick = SessionStorage.getStorage('atsTalentClientSelected');
        varModel.searchText = (tempFreeText.length) ? tempFreeText[0].value : null;
        // varModel.jobPick = (tempJob.length) ? tempJob[0].value : null;
        varModel.arrExp = (tempExp.length) ? tempExp[0].value : null;
        varModel.arrAge = (tempAge.length) ? tempAge[0].value : null;
        varModel.arrDegree = (tempDegree.length) ? tempDegree[0].value : null;
        varModel.genderPick = (tempGender.length) ? tempGender[0].value : null;
        varModel.salaryMin = (tempSalaryMin.length) ? tempSalaryMin[0].value : null;
        varModel.salaryMax = (tempSalaryMax.length) ? tempSalaryMax[0].value : null;
        varModel.page = SessionStorage.getStorageNumber('atsTalentPageActive', 1);
        varModel.size = SessionStorage.getStorageNumber('atsTalentPageSize', 10);
        // varModel.sortBy = SessionStorage.getStorage('atsTalentSortBy');
        // varModel.sortAsc = SessionStorage.getStorageBoolean('atsTalentSortAsc', 'true');
        varModel.tags = Session ? Session : [];

        return varModel;
    }
    // End of Variables Initial

    // Filter Logic
    filterService(
        fullNamePick: FormControl,
        expPick: FormControl,
        agePick: FormControl,
        degreePick: FormControl,
        genderPick: FormControl,
        salaryMin: FormControl,
        salaryMax: FormControl,
        tags: {key: string, value: string}[]
    ) {

        if (fullNamePick.value) { tags = this.tagsService.updateKey(tags, 'freetext', fullNamePick); }
        if (expPick.value) { tags = this.tagsService.updateKey(tags, 'experience', expPick, 'array'); }
        if (agePick.value) { tags = this.tagsService.updateKey(tags, 'age', agePick, 'array'); }
        if (degreePick.value) { tags = this.tagsService.updateKey(tags, 'degree', degreePick, 'array'); }
        if (genderPick.value) { tags = this.tagsService.updateKey(tags, 'gender', genderPick); }
        if (salaryMin.value) { tags = this.tagsService.updateKey(tags, 'salary_min', salaryMin); }
        if (salaryMax.value) { tags = this.tagsService.updateKey(tags, 'salary_max', salaryMax); }

        const full_name = fullNamePick.value ? `full_name=${fullNamePick.value}` : null;
        const exp = (expPick.value && expPick.value.length) ? `filter_work_experience=${expPick.value.join(',')}` : null;
        const age = (agePick.value && agePick.value.length) ? `filter_age=${agePick.value.join(',')}` : null;
        const degree = (degreePick.value && degreePick.value.length) ? `degree=${degreePick.value.join(',')}` : null;
        const gender = genderPick.value ? `gender=${genderPick.value}` : null;
        const salary_min = salaryMin.value ? `salary_min=${salaryMin.value}` : null;
        const salary_max = salaryMax.value ? `salary_max=${salaryMax.value}` : null;

        const data = [full_name, exp, age, degree, gender, salary_min, salary_max];

        // console.log(data);
        // return;

        const filteredData = data.filter(el => (el ? true : false) === true);
        let tempQuery = filteredData.join('&');
        sessionStorage.setItem('atsTalentQuery', tempQuery);
        sessionStorage.setItem('atsTalentFilter', JSON.stringify(tags));
        this.filterEventSubject.next(tempQuery ? tempQuery : true);
    }

    generateExacDuration(duration: number): string {
        let wordingSisa = '';
        if (duration && (duration / 30) > 12) {
            const sisaBulan = Math.floor(((duration / 30) % 12));
            wordingSisa = sisaBulan ? ` ${sisaBulan} bulan` : '';
        }
        return (duration) ? (
            ((duration / 30) > 12) ?
                Math.floor(((duration / 30) / 12)) + ' tahun' + wordingSisa : (
                    (duration >= 30) ?
                        Math.floor((duration / 30)) + ' bulan' :
                        duration + ' hari'
                )
            ) :
            'unknown';
    }
}