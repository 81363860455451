import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { PostKiniModel, ResponseCompanyKini } from '..';
import { CompanyService } from './company.service';
import { CompanyKiniHTTPService } from './company-http';

import * as moment from 'moment';
moment.locale('id');

export type ResponseKiniType = ResponseCompanyKini | undefined;

@Injectable({
  providedIn: 'root'
})
export class CompanyKiniService {

  // public fields
  currentCompanyMapping$: Observable<ResponseKiniType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentCompanyMappinbSubject: BehaviorSubject<ResponseKiniType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private companyService: CompanyService,
    private companyMappingHttp: CompanyKiniHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentCompanyMappinbSubject = new BehaviorSubject<ResponseKiniType>(undefined);
    this.currentCompanyMapping$ = this.currentCompanyMappinbSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getCompanyKini(filter: FilterModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.companyMappingHttp.getCompanyKini(filter).pipe(
      map((company: any) => {
        // checking company company
        if (company) {
          this.currentCompanyMappinbSubject.next(company);
        }
        return company;
      }),
      switchMap(() => this.companyService.getCompany(filter)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitCompanyKini(companyId: number, data: PostKiniModel, filter: FilterModel, isUpdate?: boolean): Observable<any> {
    this.isSubmitedSubject.next(true);
    if (!isUpdate) {
      return this.companyMappingHttp.postCompanyKini(companyId, data).pipe(
        switchMap(() => this.getCompanyKini(filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.companyMappingHttp.putCompanyKini(companyId, data).pipe(
        switchMap(() => this.getCompanyKini(filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteCompanyKini(companyId: number, filter: FilterModel): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.companyMappingHttp.deleteCompanyKini(companyId).pipe(
      switchMap(() => this.getCompanyKini(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  disconnectCompanyKini(companyId: number, filter: FilterModel): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.companyMappingHttp.disconnectCompanyKini(companyId).pipe(
      switchMap(() => this.getCompanyKini(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API

}
