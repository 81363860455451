export class EnumCity {
    public static citySelect: any = [
        {name: 'Aceh Barat', enum: 'Aceh Barat'},
        {name: 'Aceh Besar', enum: 'Aceh Besar'},
        {name: 'Aceh Selatan', enum: 'Aceh Selatan'},
        {name: 'Aceh Tengah', enum: 'Aceh Tengah'},
        {name: 'Alor', enum: 'Alor'},
        {name: 'Ambon', enum: 'Ambon'},
        {name: 'Asahan', enum: 'Asahan'},
        {name: 'Balangan', enum: 'Balangan'},
        {name: 'Balikpapan', enum: 'Balikpapan'},
        {name: 'Banda Aceh', enum: 'Banda Aceh'},
        {name: 'Bandar Lampung', enum: 'Bandar Lampung'},
        {name: 'Bandung', enum: 'Bandung'},
        {name: 'Banggai', enum: 'Banggai'},
        {name: 'Banggai Kepulauan', enum: 'Banggai Kepulauan'},
        {name: 'Bangka', enum: 'Bangka'},
        {name: 'Bangka Barat', enum: 'Bangka Barat'},
        {name: 'Bangkalan', enum: 'Bangkalan'},
        {name: 'Bangli', enum: 'Bangli'},
        {name: 'Banjar', enum: 'Banjar'},
        {name: 'Banjarbaru', enum: 'Banjarbaru'},
        {name: 'Banjarmasin', enum: 'Banjarmasin'},
        {name: 'Banjarnegara', enum: 'Banjarnegara'},
        {name: 'Bantaeng', enum: 'Bantaeng'},
        {name: 'Bantul', enum: 'Bantul'},
        {name: 'Banyuasin', enum: 'Banyuasin'},
        {name: 'Banyumas', enum: 'Banyumas'},
        {name: 'Banyuwangi', enum: 'Banyuwangi'},
        {name: 'Barito Kuala', enum: 'Barito Kuala'},
        {name: 'Barito Selatan', enum: 'Barito Selatan'},
        {name: 'Barito Timur', enum: 'Barito Timur'},
        {name: 'Barito Utara', enum: 'Barito Utara'},
        {name: 'Barru', enum: 'Barru'},
        {name: 'Batam', enum: 'Batam'},
        {name: 'Batang', enum: 'Batang'},
        {name: 'Batang Hari', enum: 'Batang Hari'},
        {name: 'Batu', enum: 'Batu'},
        {name: 'Bau-Bau', enum: 'Bau-Bau'},
        {name: 'Bekasi', enum: 'Bekasi'},
        {name: 'Belitung', enum: 'Belitung'},
        {name: 'Belitung Timur', enum: 'Belitung Timur'},
        {name: 'Belu', enum: 'Belu'},
        {name: 'Bengkalis', enum: 'Bengkalis'},
        {name: 'Bengkayang', enum: 'Bengkayang'},
        {name: 'Bengkulu', enum: 'Bengkulu'},
        {name: 'Bengkulu Selatan', enum: 'Bengkulu Selatan'},
        {name: 'Bengkulu Tengah', enum: 'Bengkulu Tengah'},
        {name: 'Bengkulu Utara', enum: 'Bengkulu Utara'},
        {name: 'Berau', enum: 'Berau'},
        {name: 'Biak Numfor', enum: 'Biak Numfor'},
        {name: 'Bima', enum: 'Bima'},
        {name: 'Binjai', enum: 'Binjai'},
        {name: 'Bintan', enum: 'Bintan'},
        {name: 'Bitung', enum: 'Bitung'},
        {name: 'Blitar', enum: 'Blitar'},
        {name: 'Blora', enum: 'Blora'},
        {name: 'Bogor', enum: 'Bogor'},
        {name: 'Bojonegoro', enum: 'Bojonegoro'},
        {name: 'Bondowoso', enum: 'Bondowoso'},
        {name: 'Bone', enum: 'Bone'},
        {name: 'Bontang', enum: 'Bontang'},
        {name: 'Boyolali', enum: 'Boyolali'},
        {name: 'Brebes', enum: 'Brebes'},
        {name: 'Bukittinggi', enum: 'Bukittinggi'},
        {name: 'Buleleng', enum: 'Buleleng'},
        {name: 'Bulukumba', enum: 'Bulukumba'},
        {name: 'Bulungan', enum: 'Bulungan'},
        {name: 'Ciamis', enum: 'Ciamis'},
        {name: 'Cianjur', enum: 'Cianjur'},
        {name: 'Cilacap', enum: 'Cilacap'},
        {name: 'Cilegon', enum: 'Cilegon'},
        {name: 'Cimahi', enum: 'Cimahi'},
        {name: 'Cirebon', enum: 'Cirebon'},
        {name: 'Dairi', enum: 'Dairi'},
        {name: 'Deli Serdang', enum: 'Deli Serdang'},
        {name: 'Demak', enum: 'Demak'},
        {name: 'Denpasar', enum: 'Denpasar'},
        {name: 'Depok', enum: 'Depok'},
        {name: 'Dompu', enum: 'Dompu'},
        {name: 'Dumai', enum: 'Dumai'},
        {name: 'Ende', enum: 'Ende'},
        {name: 'Enrekang', enum: 'Enrekang'},
        {name: 'Flores Timur', enum: 'Flores Timur'},
        {name: 'Garut', enum: 'Garut'},
        {name: 'Gianyar', enum: 'Gianyar'},
        {name: 'Gorontalo', enum: 'Gorontalo'},
        {name: 'Gowa', enum: 'Gowa'},
        {name: 'Gresik', enum: 'Gresik'},
        {name: 'Grobogan', enum: 'Grobogan'},
        {name: 'Gunung Kidul', enum: 'Gunung Kidul'},
        {name: 'Gunung Mas', enum: 'Gunung Mas'},
        {name: 'Halmahera Selatan', enum: 'Halmahera Selatan'},
        {name: 'Hulu Sungai Selatan', enum: 'Hulu Sungai Selatan'},
        {name: 'Hulu Sungai Tengah', enum: 'Hulu Sungai Tengah'},
        {name: 'Hulu Sungai Utara', enum: 'Hulu Sungai Utara'},
        {name: 'Indragiri Hilir', enum: 'Indragiri Hilir'},
        {name: 'Indragiri Hulu', enum: 'Indragiri Hulu'},
        {name: 'Indramayu', enum: 'Indramayu'},
        {name: 'Jakarta Barat', enum: 'Jakarta Barat'},
        {name: 'Jakarta Pusat', enum: 'Jakarta Pusat'},
        {name: 'Jakarta Selatan', enum: 'Jakarta Selatan'},
        {name: 'Jakarta Timur', enum: 'Jakarta Timur'},
        {name: 'Jakarta Utara', enum: 'Jakarta Utara'},
        {name: 'Jambi', enum: 'Jambi'},
        {name: 'Jayapura', enum: 'Jayapura'},
        {name: 'Jember', enum: 'Jember'},
        {name: 'Jembrana', enum: 'Jembrana'},
        {name: 'Jeneponto', enum: 'Jeneponto'},
        {name: 'Jepara', enum: 'Jepara'},
        {name: 'Jombang', enum: 'Jombang'},
        {name: 'Kampar', enum: 'Kampar'},
        {name: 'Kapuas', enum: 'Kapuas'},
        {name: 'Kapuas Hulu', enum: 'Kapuas Hulu'},
        {name: 'Karanganyar', enum: 'Karanganyar'},
        {name: 'Karangasem', enum: 'Karangasem'},
        {name: 'Karawang', enum: 'Karawang'},
        {name: 'Karo', enum: 'Karo'},
        {name: 'Kebumen', enum: 'Kebumen'},
        {name: 'Kediri', enum: 'Kediri'},
        {name: 'Kendal', enum: 'Kendal'},
        {name: 'Kendari', enum: 'Kendari'},
        {name: 'Kepulauan Sangihe', enum: 'Kepulauan Sangihe'},
        {name: 'Kepulauan Selayar', enum: 'Kepulauan Selayar'},
        {name: 'Kepulauan Seribu', enum: 'Kepulauan Seribu'},
        {name: 'Kepulauan Yapen', enum: 'Kepulauan Yapen'},
        {name: 'Ketapang', enum: 'Ketapang'},
        {name: 'Klaten', enum: 'Klaten'},
        {name: 'Klungkung', enum: 'Klungkung'},
        {name: 'Kolaka', enum: 'Kolaka'},
        {name: 'Kolaka Utara', enum: 'Kolaka Utara'},
        {name: 'Konawe', enum: 'Konawe'},
        {name: 'Konawe Selatan', enum: 'Konawe Selatan'},
        {name: 'Kotabaru', enum: 'Kotabaru'},
        {name: 'Kotamobagu', enum: 'Kotamobagu'},
        {name: 'Kotawaringin Barat', enum: 'Kotawaringin Barat'},
        {name: 'Kotawaringin Timur', enum: 'Kotawaringin Timur'},
        {name: 'Kuantan Singingi', enum: 'Kuantan Singingi'},
        {name: 'Kubu Raya', enum: 'Kubu Raya'},
        {name: 'Kudus', enum: 'Kudus'},
        {name: 'Kuningan', enum: 'Kuningan'},
        {name: 'Kupang', enum: 'Kupang'},
        {name: 'Kutai Barat', enum: 'Kutai Barat'},
        {name: 'Kutai Kartanegara', enum: 'Kutai Kartanegara'},
        {name: 'Kutai Timur', enum: 'Kutai Timur'},
        {name: 'Labuhanbatu', enum: 'Labuhanbatu'},
        {name: 'Lahat', enum: 'Lahat'},
        {name: 'Lamandau', enum: 'Lamandau'},
        {name: 'Lamongan', enum: 'Lamongan'},
        {name: 'Lampung Barat', enum: 'Lampung Barat'},
        {name: 'Lampung Tengah', enum: 'Lampung Tengah'},
        {name: 'Lampung Timur', enum: 'Lampung Timur'},
        {name: 'Lampung Utara', enum: 'Lampung Utara'},
        {name: 'Langkat', enum: 'Langkat'},
        {name: 'Langsa', enum: 'Langsa'},
        {name: 'Lebak', enum: 'Lebak'},
        {name: 'Lembata', enum: 'Lembata'},
        {name: 'Lhokseumawe', enum: 'Lhokseumawe'},
        {name: 'Lombok Tengah', enum: 'Lombok Tengah'},
        {name: 'Lombok Timur', enum: 'Lombok Timur'},
        {name: 'Lubuk Linggau', enum: 'Lubuk Linggau'},
        {name: 'Lumajang', enum: 'Lumajang'},
        {name: 'Luwu', enum: 'Luwu'},
        {name: 'Luwu Utara', enum: 'Luwu Utara'},
        {name: 'Madiun', enum: 'Madiun'},
        {name: 'Magelang', enum: 'Magelang'},
        {name: 'Magetan', enum: 'Magetan'},
        {name: 'Majalengka', enum: 'Majalengka'},
        {name: 'Majene', enum: 'Majene'},
        {name: 'Makassar', enum: 'Makassar'},
        {name: 'Malang', enum: 'Malang'},
        {name: 'Maluku Barat Daya', enum: 'Maluku Barat Daya'},
        {name: 'Maluku Tengah', enum: 'Maluku Tengah'},
        {name: 'Mamuju', enum: 'Mamuju'},
        {name: 'Manado', enum: 'Manado'},
        {name: 'Mandailing Natal', enum: 'Mandailing Natal'},
        {name: 'Manggarai', enum: 'Manggarai'},
        {name: 'Maros', enum: 'Maros'},
        {name: 'Mataram', enum: 'Mataram'},
        {name: 'Medan', enum: 'Medan'},
        {name: 'Mempawah', enum: 'Mempawah'},
        {name: 'Merangin', enum: 'Merangin'},
        {name: 'Merauke', enum: 'Merauke'},
        {name: 'Metro', enum: 'Metro'},
        {name: 'Minahasa', enum: 'Minahasa'},
        {name: 'Mojokerto', enum: 'Mojokerto'},
        {name: 'Muara Enim', enum: 'Muara Enim'},
        {name: 'Muaro Jambi', enum: 'Muaro Jambi'},
        {name: 'Muko Muko', enum: 'Muko Muko'},
        {name: 'Muna', enum: 'Muna'},
        {name: 'Nabire', enum: 'Nabire'},
        {name: 'Ngada', enum: 'Ngada'},
        {name: 'Nganjuk', enum: 'Nganjuk'},
        {name: 'Ngawi', enum: 'Ngawi'},
        {name: 'Nias Barat', enum: 'Nias Barat'},
        {name: 'Nias Utara', enum: 'Nias Utara'},
        {name: 'Ogan Ilir', enum: 'Ogan Ilir'},
        {name: 'Ogan Komering Ilir', enum: 'Ogan Komering Ilir'},
        {name: 'Ogan Komering Ulu', enum: 'Ogan Komering Ulu'},
        {name: 'Ogan Komering Ulu Timur', enum: 'Ogan Komering Ulu Timur'},
        {name: 'Pacitan', enum: 'Pacitan'},
        {name: 'Padang', enum: 'Padang'},
        {name: 'Padang Panjang', enum: 'Padang Panjang'},
        {name: 'Padang Sidempuan', enum: 'Padang Sidempuan'},
        {name: 'Pagar Alam', enum: 'Pagar Alam'},
        {name: 'Palangka Raya', enum: 'Palangka Raya'},
        {name: 'Palembang', enum: 'Palembang'},
        {name: 'Palopo', enum: 'Palopo'},
        {name: 'Palu', enum: 'Palu'},
        {name: 'Pamekasan', enum: 'Pamekasan'},
        {name: 'Pandeglang', enum: 'Pandeglang'},
        {name: 'Pangandaran', enum: 'Pangandaran'},
        {name: 'Pangkajene Kepulauan', enum: 'Pangkajene Kepulauan'},
        {name: 'Pangkal Pinang', enum: 'Pangkal Pinang'},
        {name: 'Paniai', enum: 'Paniai'},
        {name: 'Parepare', enum: 'Parepare'},
        {name: 'Pariaman', enum: 'Pariaman'},
        {name: 'Pasuruan', enum: 'Pasuruan'},
        {name: 'Pati', enum: 'Pati'},
        {name: 'Payakumbuh', enum: 'Payakumbuh'},
        {name: 'Pekalongan', enum: 'Pekalongan'},
        {name: 'Pekanbaru', enum: 'Pekanbaru'},
        {name: 'Pemalang', enum: 'Pemalang'},
        {name: 'Pematang Siantar', enum: 'Pematang Siantar'},
        {name: 'Penajam Paser Utara', enum: 'Penajam Paser Utara'},
        {name: 'Penukal Abab Lematang Ilir', enum: 'Penukal Abab Lematang Ilir'},
        {name: 'Pidie', enum: 'Pidie'},
        {name: 'Pinrang', enum: 'Pinrang'},
        {name: 'Pohuwato', enum: 'Pohuwato'},
        {name: 'Polewali Mandar', enum: 'Polewali Mandar'},
        {name: 'Ponorogo', enum: 'Ponorogo'},
        {name: 'Pontianak', enum: 'Pontianak'},
        {name: 'Poso', enum: 'Poso'},
        {name: 'Prabumulih', enum: 'Prabumulih'},
        {name: 'Probolinggo', enum: 'Probolinggo'},
        {name: 'Pulang Pisau', enum: 'Pulang Pisau'},
        {name: 'Pulau Morotai', enum: 'Pulau Morotai'},
        {name: 'Purbalingga', enum: 'Purbalingga'},
        {name: 'Purwakarta', enum: 'Purwakarta'},
        {name: 'Purworejo', enum: 'Purworejo'},
        {name: 'Rejang Lebong', enum: 'Rejang Lebong'},
        {name: 'Rembang', enum: 'Rembang'},
        {name: 'Rokan Hilir', enum: 'Rokan Hilir'},
        {name: 'Rote Ndao', enum: 'Rote Ndao'},
        {name: 'Sabang', enum: 'Sabang'},
        {name: 'Salatiga', enum: 'Salatiga'},
        {name: 'Samarinda', enum: 'Samarinda'},
        {name: 'Sampang', enum: 'Sampang'},
        {name: 'Sanggau', enum: 'Sanggau'},
        {name: 'Sawah Lunto', enum: 'Sawah Lunto'},
        {name: 'Sekadau', enum: 'Sekadau'},
        {name: 'Semarang', enum: 'Semarang'},
        {name: 'Serang', enum: 'Serang'},
        {name: 'Seruyan', enum: 'Seruyan'},
        {name: 'Sibolga', enum: 'Sibolga'},
        {name: 'Sidenreng Rappang', enum: 'Sidenreng Rappang'},
        {name: 'Sidoarjo', enum: 'Sidoarjo'},
        {name: 'Sikka', enum: 'Sikka'},
        {name: 'Simalungun', enum: 'Simalungun'},
        {name: 'Singkawang', enum: 'Singkawang'},
        {name: 'Sinjai', enum: 'Sinjai'},
        {name: 'Sintang', enum: 'Sintang'},
        {name: 'Situbondo', enum: 'Situbondo'},
        {name: 'Sleman', enum: 'Sleman'},
        {name: 'Solok', enum: 'Solok'},
        {name: 'Soppeng', enum: 'Soppeng'},
        {name: 'Sragen', enum: 'Sragen'},
        {name: 'Subang', enum: 'Subang'},
        {name: 'Sukabumi', enum: 'Sukabumi'},
        {name: 'Sukoharjo', enum: 'Sukoharjo'},
        {name: 'Sumba Barat', enum: 'Sumba Barat'},
        {name: 'Sumba Timur', enum: 'Sumba Timur'},
        {name: 'Sumbawa', enum: 'Sumbawa'},
        {name: 'Sumbawa Barat', enum: 'Sumbawa Barat'},
        {name: 'Sumedang', enum: 'Sumedang'},
        {name: 'Sumenep', enum: 'Sumenep'},
        {name: 'Sungaipenuh', enum: 'Sungaipenuh'},
        {name: 'Surabaya', enum: 'Surabaya'},
        {name: 'Surakarta', enum: 'Surakarta'},
        {name: 'Tabalong', enum: 'Tabalong'},
        {name: 'Tabanan', enum: 'Tabanan'},
        {name: 'Takalar', enum: 'Takalar'},
        {name: 'Tana Toraja', enum: 'Tana Toraja'},
        {name: 'Tanah Bumbu', enum: 'Tanah Bumbu'},
        {name: 'Tanah Datar', enum: 'Tanah Datar'},
        {name: 'Tanah Laut', enum: 'Tanah Laut'},
        {name: 'Tangerang', enum: 'Tangerang'},
        {name: 'Tangerang Selatan', enum: 'Tangerang Selatan'},
        {name: 'Tanjung Balai', enum: 'Tanjung Balai'},
        {name: 'Tanjung Pinang', enum: 'Tanjung Pinang'},
        {name: 'Tapanuli Tengah', enum: 'Tapanuli Tengah'},
        {name: 'Tapanuli Utara', enum: 'Tapanuli Utara'},
        {name: 'Tapin', enum: 'Tapin'},
        {name: 'Tarakan', enum: 'Tarakan'},
        {name: 'Tasikmalaya', enum: 'Tasikmalaya'},
        {name: 'Tebing Tinggi', enum: 'Tebing Tinggi'},
        {name: 'Tegal', enum: 'Tegal'},
        {name: 'Temanggung', enum: 'Temanggung'},
        {name: 'Ternate', enum: 'Ternate'},
        {name: 'Tidore Kepulauan', enum: 'Tidore Kepulauan'},
        {name: 'Timor Tengah Selatan', enum: 'Timor Tengah Selatan'},
        {name: 'Timor Tengah Utara', enum: 'Timor Tengah Utara'},
        {name: 'Toba Samosir', enum: 'Toba Samosir'},
        {name: 'Tojo Una-Una', enum: 'Tojo Una-Una'},
        {name: 'Toli-Toli', enum: 'Toli-Toli'},
        {name: 'Tomohon', enum: 'Tomohon'},
        {name: 'Toraja Utara', enum: 'Toraja Utara'},
        {name: 'Trenggalek', enum: 'Trenggalek'},
        {name: 'Tuban', enum: 'Tuban'},
        {name: 'Tulang Bawang', enum: 'Tulang Bawang'},
        {name: 'Tulungagung', enum: 'Tulungagung'},
        {name: 'Wajo', enum: 'Wajo'},
        {name: 'Wonogiri', enum: 'Wonogiri'},
        {name: 'Wonosobo', enum: 'Wonosobo'},
        {name: 'Yogyakarta', enum: 'Yogyakarta'},
        {name: 'Toli-toli', enum: 'Toli-toli'},
        {name: 'Purwokerto', enum: 'Purwokerto'},
        {name: 'Jakarta', enum: 'Jakarta'},
        {name: 'DKI Jakarta', enum: 'DKI Jakarta'},
        {name: 'Bandung Barat', enum: 'Bandung Barat'},
        {name: 'Batusangkar', enum: 'Batusangkar'},
        {name: 'Kabupaten Bekasi', enum: 'Kabupaten Bekasi'},
        {name: 'Mimika', enum: 'Mimika'},
    ];

    public static findCity(city: string): any {
        return EnumCity.citySelect.find((fin: any) => fin.enum === city.trim());
    }
}
