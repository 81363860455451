import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bootstrap-button',
  templateUrl: './bootstrap-button.component.html',
  styleUrls: ['./bootstrap-button.component.scss']
})
export class BootstrapButtonComponent implements OnInit {

  @Input() label!: string | null
  @Input() id?: string
  @Input() type?: string
  @Input() icon?: boolean;
  @Input() color?: 'primary' | 'secondary' | 'warning' | 'danger' | 'info' |
                   'light' | 'dark' | 'success' | 'process' | 'default'
  @Input() large?: boolean
  @Input() small?: boolean
  @Input() pill?: boolean
  @Input() block?: boolean
  @Input() outline?: boolean
  @Input() light?: boolean
  @Input() iconClass?: string;
  @Input() imgIconClass?: string;
  @Input() svgIcon?: string;
  @Input() imgIcon?: string;
  @Input() additionalClass?: string

  // Restrictor
  @Input() disabled?: boolean
  @Input() loading?: boolean
  @Input() loadingLabel?: string

  // Action
  @Input() routerLink?: string
  @Input() href?: string
  @Input() blank?: boolean
  @Input() download?: boolean

  // Tooltip
  @Input() placement?: string;
  @Input() placementIcon?: string;
  @Input() tooltip?: string;
  @Input() tooltipIcon?: string;

  @Output() onClick = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  get transformColor(): string {
    if (!this.color) {
      if (this.light) {
        return 'btn-light'
      }
      return '';
    }

    if (this.outline) {
      return `btn-outline-${this.color}`;
    }
    if (this.light) {
      return `btn-light-${this.color}`;
    }

    return `btn-${this.color}`;
  }

}
