export class EnumCompanyParent {
    public static companyParentDev: any = [
        { id: 1, client: 'ecfa6c10c985426dab29a2206cfb279b', secret: 'ac77b3dc9ac94c02a0d249e7d8302800', name: 'Marvel' },
    ];

    public static companyParentProd: any = [
        { id: 1, client: '5022dd4e73884ba6862847596a0a063f', secret: '6ef3744417a44637995c285a6dd63d34', name: 'SPC' },
        { id: 152, client: '11c237e9b22c41ed9d600e4af8e81635', secret: 'b169882918f541f0b548b7ebefae900a', name: 'STC' },
    ];
}
