import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {finalize, map, mergeMap, switchMap} from 'rxjs/operators';
import {FilterModel} from 'src/app/_class';

import {DataService} from 'src/app/_service/data.service';
import {HttpConfigService} from 'src/app/_service/http-config.service';
import {ResponseEmployeeKini, EmployeeListKiniModel} from '..';
import {EmployeeKiniHTTPService} from './employee-http';

export type ResponseWhitelistKiniType = ResponseEmployeeKini | undefined;

@Injectable({
  providedIn: 'root'
})
export class EmployeeKiniService {

  // public fields
  currentWhitelist$: Observable<ResponseWhitelistKiniType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentWhitelistSubject: BehaviorSubject<ResponseWhitelistKiniType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private dataService: DataService,
    private whitelistHttp: EmployeeKiniHTTPService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentWhitelistSubject = new BehaviorSubject<ResponseWhitelistKiniType>(undefined);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.currentWhitelist$ = this.currentWhitelistSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getWhitelistV2(
    companyId: number, filter: FilterModel
  ): Observable<ResponseWhitelistKiniType> {
    console.log('test');
    
    this.isLoadingSubject.next(true);
    return this.whitelistHttp.getKiniWhiteListV2(companyId, filter).pipe(
      map((whitelist: ResponseWhitelistKiniType) => {
        // checking whitelist
        if (whitelist) {
          this.currentWhitelistSubject.next(whitelist);
        }
        return whitelist;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  addWhiteList(companyId: number, data: EmployeeListKiniModel[], filter: FilterModel): Observable<ResponseWhitelistKiniType> {
    let index = 0;
    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      mergeMap(async (el) => {
        index++;

        // Request HTTP
        let arg: any;
        try {
          arg = await this.whitelistHttp.postKiniWhiteList(companyId, el.userId).toPromise();
        } catch (error: any) {
          this.dataService.errorEvent([{
            Email: el.email,
            Message: error.error.error
          }]);
        }
      }),
      switchMap(() => this.getWhitelistV2(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  updateWhiteList(companyId: number, data: EmployeeListKiniModel[], filter: FilterModel): Observable<ResponseWhitelistKiniType> {
    let index = 0;
    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      mergeMap(async (el) => {
        index++;

        // Request HTTP
        let arg: any;
        try {
          arg = await this.whitelistHttp.patchKiniWhiteList(companyId, el.userId, el.status).toPromise();
        } catch (error: any) {
          this.dataService.errorEvent([{
            Email: el.email,
            Message: error.error.error
          }]);
        }
      }),
      switchMap(() => this.getWhitelistV2(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  patchEmployeeLimit(companyId: number, userId: number, data: { limit: number }, filter: FilterModel): Observable<ResponseWhitelistKiniType> {
    this.isSubmitedSubject.next(true);
    return this.whitelistHttp.patchKiniLimit(companyId, userId, data).pipe(
      switchMap(() => this.getWhitelistV2(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  syncEmployeeWhitelist(companyId: number, filter: FilterModel): Observable<ResponseWhitelistKiniType> {
    this.isSubmitedSubject.next(true);
    return this.whitelistHttp.syncKiniWhiteList(companyId).pipe(
      switchMap(() => this.getWhitelistV2(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
