import { Injectable } from '@angular/core';
import { Maps } from 'src/app/_model';

@Injectable({
  providedIn: 'root'
})
export class MapsService {

  private maps: Maps = {} as Maps;

  constructor() { }

  gmaps_mapping(data: any): Maps {

    // Debugging Purpose
    // console.log(data);
    
    if (!data || !data.length) {
      console.log('data kosong yang mau dimapping nggak ada.');
      return this.maps;
    }

    data.forEach((element: any) => {
      if (element.types.includes('administrative_area_level_5')) {
        this.maps.street = element.long_name;
      }
      if (element.types.includes('administrative_area_level_4')) {
        this.maps.subdistrict = element.long_name;
      }
      if (element.types.includes('administrative_area_level_3')) {
        this.maps.district = element.long_name.replace(/Kecamatan /g, '');
      }
      if (element.types.includes('administrative_area_level_2')) {
        this.maps.city = element.long_name.replace(/Kabupaten |Kota /g, '');
      }
      if (element.types.includes('administrative_area_level_1')) {
        this.maps.province = element.short_name;
      }
      if (element.types.includes('postal_code')) {
        this.maps.zip = element.long_name;
      }
    });
    return this.maps;
  }

  gmaps_format_address(formattedAddress: string): Maps {
    const addressLocation = formattedAddress.split(', ');
    const newAddressLocation: any = [];
    // tslint:disable-next-line: forin
    addressLocation.forEach(element => {
      if (element.includes('Jl')) {
        newAddressLocation.push(element);
        ////// console.log(array);
      }
      if (element.includes('Jalan')) {
        newAddressLocation.push(element);
        ////// console.log(array);
      }
      // if (element.includes('RT')) {
      //   newAddressLocation.push(element);
      //   ////// console.log(array);
      // }
    });
    this.maps.street = newAddressLocation.join(', ');
    return this.maps;
  }
}
