import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service';
import { FilterModel } from 'src/app/_class';

const API_JOBFAIR_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class JobfairJobHttpService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackbar(status: number) {
    this.config.openSnackBar(status);
  }

  getJobCampaign(campaignId: number, companyId: number | null, filter: FilterModel): Observable<any>{
    return this.http.get<any>(`${API_JOBFAIR_URL}/campaign/${campaignId}/campaign-jobs?${companyId ? 'company_id=' + companyId + '&' : ''}${filter.query}metadata=true&page=${filter.page}&limit=${filter.size ? filter.size : 100}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}`);
  }

  getJobsSearch(filter: FilterModel): Observable<any>{
    return this.http.get<any>(`${API_JOBFAIR_URL}/search/job?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : 20}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`,
  );
  }

  postJobCampaign(param: {jobId: number, campaignCompanyId: number}, campaignId: any){
    return this.http.post<Response>(`${API_JOBFAIR_URL}/campaign/${campaignId}/campaign-jobs`, param);
  }

  // putCompanyCampaign(id: number, param: any): Observable<Response> {
  //   return this.http.put<Response>(`/${API_JOBFAIR_URL}/campaign-companies/${id}`, param)
  //     .pipe(catchError(this.config.handleError));
  // }

  deleteJobCampaign(idCampaign: any, id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_JOBFAIR_URL}/campaign/${idCampaign}/campaign-jobs/${id}`);
  }

}
