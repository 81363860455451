import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { CompanyBankHTTPService } from './company-http';
import { CompanyBankModel, ResponseCompanyBank } from '..';

@Injectable({
  providedIn: 'root'
})
export class CompanyBankService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private companyBankHttp: CompanyBankHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getCompanyBank(companyId: number): Observable<ResponseCompanyBank> {
    // const auth = this.authService.getAuthFromLocalStorage();
    // if (!auth || !auth.id_token || !auth.companyId) {
    //   return of(undefined);
    // }

    this.isLoadingSubject.next(true);
    return this.companyBankHttp.getCompanyBank(companyId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitCompanyBank(companyId: number, bankId: number | null, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    if (bankId) {
      return this.companyBankHttp.putCompanyBank(companyId, bankId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.companyBankHttp.postCompanyBank(companyId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteCompanyBank(companyId: number, bankId: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyBankHttp.delCompanyBank(companyId, bankId).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  checkCompanyBank(companyId: number, bankId: number): Observable<ResponseCompanyBank> {
    this.isSubmitedSubject.next(true);
    return this.companyBankHttp.checkCompanyBank(companyId, bankId).pipe(
      switchMap(() => this.getCompanyBank(companyId)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API


  // Generate Bank Model
  generateBankModel(rawBank: CompanyBankModel): CompanyBankModel {
    const dataBank = {} as CompanyBankModel;
    dataBank.bankAccount = rawBank.bankAccount;
    dataBank.bankBeneficiary = rawBank.bankBeneficiary;
    dataBank.bankEmail = rawBank.bankEmail;
    dataBank.bankName = rawBank.bankName;
    dataBank.differenceBeneficiary = rawBank.differenceBeneficiary;
    dataBank.selected = true;
    return dataBank;
  }
  // End of Generate Bank Model

}
