export class EnumCompanyStatus {
    public static companyStatusSelect: any = [
        { name: 'Approve', enum: 'approved' },
        { name: 'Rejected', enum: 'rejected' },
        { name: 'Pending', enum: 'pending' },
        { name: 'Blacklist', enum: 'blacklisted' },
        { name: 'Perusahaan Penipuan', enum: 'fraud' },
    ];

    public static findCompanyStatus(status: string): any {
        return EnumCompanyStatus.companyStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
    }
}
