/* tslint:disable:no-non-null-assertion */
import { JobsModel } from './jobs.model';

import { EnumRegex } from 'src/app/_enum';
import { /*EnumCity, */EnumCommutingType, EnumJobType, EnumJobCategory, EnumPaymentType } from '..';
import { EnumIndustry } from '../../manage-companies';
import { EnumEmploymentType } from '../../employee-list';

import * as moment from 'moment';
moment.locale('id');

export interface AssignJobModel {
  clientID: number;
  jobID: number;
}

export interface PostJobModel {
    companyId: number;
    title: string;
    jobType: string;
    shortDesc: string | null;
    description: string;
    dateStart: string;
    dateEnd: string;
    latitude: string;
    longitude: string;
    location: string;
    employmentType: string;
    categoryType: string;
    industryType: string;
    paymentType: string;
    commutingType: string;
    salaryMin: number;
    salaryMax: number;
    requirements: string;
    minimumEducation?: any;
    address: string;
    addressStreet: string;
    addressSubDistrict: string;
    addressDistrict: string;
    addressCity: string;
    addressProvince: string | null;
    addressZip: string;
    area: string;
    externalUrl?: any;
    displayStartDate: string;
    displayEndDate: string;
    clientID: number;
}

export class PostJobClass {

  static objModel = [
      'title', 'jobType', 'shortDesc', 'description', 'dateStart', 'dateEnd', 'latitude', 'longitude', 'location', 'employmentType',
      'categoryType', 'industryType', 'paymentType', 'commutingType', 'salaryMin', 'salaryMax', 'requirements', 'minimumEducation',
      'address', 'addressStreet', 'addressSubDistrict', 'addressDistrict', 'addressCity', 'addressProvince', 'addressZip',
      'area', 'externalUrl', 'displayStartDate', 'displayEndDate', 'companyId', 'clientID'
  ];

  public static postJobValidation(payload: PostJobModel): string | null {
      const objPayload = Object.keys(payload);
      const objInvalid: string[] = [];

      // ? Validasi Object Key Payload
      objPayload.forEach((element) => {
          if (!PostJobClass.objModel.includes(element)) {
            objInvalid.push(element);
          }
      });
      if (objInvalid.length) {
        return objInvalid.toString() + ' is invalid payload';
      }

      // ? Validasi Mandatory Payload
      if (!payload.address || !payload.addressStreet || !payload.addressCity || !payload.addressDistrict ||
          !payload.addressSubDistrict || !payload.addressProvince || !payload.addressZip || !payload.area ||
          !payload.categoryType || !payload.commutingType || !payload.companyId || !payload.clientID ||
          !payload.dateEnd || !payload.dateStart || !payload.description || !payload.industryType ||
          !payload.jobType || !payload.paymentType || !payload.requirements || !payload.employmentType ||
          !payload.title) {
          return 'empty mandatory payload detected';
      }


      // ? Validasi Regex Payload
      const regexAddress = EnumRegex.parsingRegex('address')!.test(payload.address);
      const regexStreet = EnumRegex.parsingRegex('address')!.test(payload.addressStreet);
      const regexCity = EnumRegex.parsingRegex('letter_35')!.test(payload.addressCity);
      const regexArea = EnumRegex.parsingRegex('letter_25')!.test(payload.area);
      const regexDistrict = EnumRegex.parsingRegex('letter_25')!.test(payload.addressDistrict);
      const regexSubDistrict = EnumRegex.parsingRegex('alpha_sub_district_25')!.test(payload.addressSubDistrict);
      const regexProvince = EnumRegex.parsingRegex('letter_35')!.test(payload.addressProvince);
      const regexZip = EnumRegex.parsingRegex('zip')!.test(payload.addressZip);
      const regexZipChar = !(payload.addressZip[0] === '0');
      const regexExtUrl = EnumRegex.parsingRegex('web')!.test(payload.externalUrl);
      const regexSalaryMin = (typeof payload.salaryMin === 'string') ?
        EnumRegex.parsingRegex('number')!.test(payload.salaryMin) : true;
      const regexSalaryMax = (typeof payload.salaryMax === 'string') ?
        EnumRegex.parsingRegex('number')!.test(payload.salaryMax) : true;
      const regexDateStart = EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.dateStart);
      const regexDateEnd = EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.dateEnd);
      const regexDDStart = EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.displayStartDate);
      const regexDDEnd = EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.displayEndDate);

      if (!regexAddress) { return 'address invalid'; }
      if (!regexStreet) { return 'address street invalid'; }
      if (!regexCity/*  || !EnumCity.findCity(payload.addressCity) */) { return 'address city invalid'; }
      if (!regexArea) { return 'area invalid'; }
      if (!regexDistrict) { return 'address district invalid'; }
      if (!regexSubDistrict) { return 'address subdistrict invalid'; }
      if (!regexProvince) { return 'address province invalid'; }
      if (!regexZip || !regexZipChar) { return 'address zip invalid'; }
      if (!regexDateStart) { return 'date start invalid'; }
      if (!regexDateEnd) { return 'date end invalid'; }
      if (!EnumJobCategory.findJobCategory(payload.categoryType)) { return 'department invalid'; }
      if (!EnumCommutingType.findCommuting(payload.commutingType, true)) { return 'commuting type invalid'; }
      if (!EnumIndustry.findIndustry(payload.industryType)) { return 'industry invalid'; }
      if (!EnumJobType.findJobType(payload.jobType)) { return 'job type invalid'; }
      if (!EnumPaymentType.findPayment(payload.paymentType)) { return 'payment type invalid'; }
      if (!EnumEmploymentType.findEmployment(payload.employmentType, true)) { return 'employment type invalid'; }
      if (payload.externalUrl && !regexExtUrl) { return 'external url invalid'; }
      if (payload.salaryMin && (payload.salaryMin > 500000000 || !regexSalaryMin)) { return 'salary min invalid'; }
      if (payload.salaryMax && (
            (payload.salaryMax <= payload.salaryMin) || payload.salaryMax > 500000000 || !regexSalaryMax
          )) { return 'salary max invalid'; }
      if (payload.displayStartDate && !regexDDStart) { return 'display date start invalid'; }
      if (payload.displayEndDate && !regexDDEnd) { return 'display date end invalid'; }

      return null;
  }

  public static republishJobValue(job: JobsModel): PostJobModel {
    const postJob: PostJobModel = {} as PostJobModel;

    postJob.title = job.title;
    postJob.jobType = job.jobType;
    postJob.shortDesc = job.shortDesc;
    postJob.description = job.description;

    // begin::format date ke gmt+7 -> case post job dari WITA / WIT
    postJob.dateStart = moment().format('YYYY-MM-DDT00:00:00+07:00');
    postJob.dateEnd = moment().add(30, 'days').format('YYYY-MM-DDT23:59:59+07:00');
    // end::format date ke gmt+7

    postJob.latitude = job.latitude;
    postJob.longitude = job.longitude;
    postJob.location = job.location;
    postJob.employmentType = job.employmentType;
    postJob.categoryType = job.categoryType;
    postJob.industryType = job.industryType;
    postJob.paymentType = job.paymentType;
    postJob.commutingType = job.commutingType;
    postJob.salaryMin = job.salaryMin;
    postJob.salaryMax = job.salaryMax;
    postJob.requirements = job.requirements;
    postJob.minimumEducation = job.minimumEducation;
    postJob.address = job.address;
    postJob.addressStreet = job.addressStreet;
    postJob.addressSubDistrict = job.addressSubDistrict;
    postJob.addressDistrict = job.addressDistrict;
    postJob.addressCity = job.addressCity;
    postJob.addressProvince = job.addressProvince;
    postJob.addressZip = job.addressZip;
    postJob.area = job.area;
    postJob.externalUrl = job.externalUrl;
    postJob.displayStartDate = job.displayStartDate ? job.displayStartDate : job.dateStart;
    postJob.displayEndDate = job.displayEndDate ? job.displayEndDate : job.dateEnd;
    postJob.companyId = job.companyId;
    postJob.clientID = job.clientID;

    return postJob;
  }

  public static formatJobValue(job: PostJobModel, title: string): PostJobModel {
    const formatData: PostJobModel = {} as PostJobModel;

    formatData.title = title;
    formatData.categoryType = job.categoryType.toLowerCase();
    formatData.commutingType = job.commutingType.toLowerCase();
    formatData.employmentType = job.employmentType.toLowerCase();
    formatData.industryType = job.industryType.toLowerCase();
    formatData.paymentType = job.paymentType.toLowerCase();
    formatData.shortDesc = job.description ?
      ((job.description.length >= 140) ? job.description.slice(0, 140) + '...' : formatData.description) : null;
    formatData.salaryMin = job.salaryMin ? +job.salaryMin : 0;
    formatData.salaryMax = job.salaryMax ? +job.salaryMax : 0;

    // begin::format date ke gmt+7 -> case post job dari WITA / WIT
    formatData.dateStart = moment(job.dateStart).format('YYYY-MM-DDT00:00:00+07:00');
    formatData.dateEnd = moment(job.dateEnd).format('YYYY-MM-DDT23:59:59+07:00');
    formatData.displayStartDate = moment(job.dateStart).format('YYYY-MM-DDT00:00:00+07:00');
    formatData.displayEndDate = moment(job.dateEnd).format('YYYY-MM-DDT23:59:59+07:00');
    // end::format date ke gmt+7

    formatData.latitude = job.latitude ? String(job.latitude) : '-6.1753924';
    formatData.longitude = job.longitude ? String(job.longitude) : '106.8249641';
    formatData.jobType = job.jobType;
    formatData.description = job.description;
    formatData.location = job.location ? job.location : '-6.1753924,106.8249641';
    formatData.requirements = job.requirements;
    formatData.minimumEducation = job.minimumEducation ? job.minimumEducation : null;
    formatData.address = job.address;
    formatData.addressStreet = job.addressStreet;
    formatData.addressSubDistrict = job.addressSubDistrict;
    formatData.addressDistrict = job.addressDistrict;
    formatData.addressCity = job.addressCity;
    formatData.addressProvince = job.addressProvince;
    formatData.addressZip = job.addressZip;
    formatData.area = job.area;
    formatData.externalUrl = job.externalUrl;
    formatData.companyId = job.companyId;
    formatData.clientID = job.clientID;

    return formatData;
  }
}
