import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { GlobalConstants, SessionStorage } from "src/app/_class";
import { IndexJfCompanyVariables } from "../../jobfair";
import { CampaignStorage } from "../../campaign";

@Injectable()
export class CompanyCampaignClass {

  constructor() {}

  // Variables Initial
  initVariable(): IndexJfCompanyVariables {
    const varModel = {} as IndexJfCompanyVariables;
    let campaignStorage: CampaignStorage = SessionStorage.getStorageJson('campaignStorage');

    varModel.tempQuery = sessionStorage.getItem('jfCompanyQuery')!;
    varModel.campaignPick = campaignStorage?.campaignSelected ?
      campaignStorage.campaignSelected :
      GlobalConstants.campaignId;
    varModel.startDateCampaign = campaignStorage?.startDate ?
      campaignStorage.startDate :
      GlobalConstants.campaignStartDate;
    varModel.endDateCampaign = campaignStorage?.endDate ?
      campaignStorage.endDate :
      GlobalConstants.campaignEndDate;
    varModel.statusCampaign = campaignStorage?.status ?
      campaignStorage.status :
      GlobalConstants.campaignStatus;
    // varModel.companyPick = sessionStorage.getItem('jfCompanyName')! ? sessionStorage.getItem('jfCompanyName')! : '';

    return varModel;
  }
  // End of Variables Initial

    //Filter Service
  filterService(freeText: FormControl){
    const freetext = (freeText.value ? `company_name=${freeText.value}` : null);

    const data = [freetext];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => (el ? true : false) === true);
    let tempQuery = filteredData.join('&');
    sessionStorage.setItem('jfCompanyQuery', String(tempQuery));
    return tempQuery;
  }

  eventFilter(/* tags: {key: string, value: string}[],  */event?: any, autoType?: string) {
    let eventModel = {} as {
      campaignPick: number,
      companyPick: number
    }

    console.log(event);

    if (event.id) {
      if (autoType === 'campaign') {
        eventModel.campaignPick = event.id;
        let campaignStorage: CampaignStorage = {} as CampaignStorage;
        campaignStorage.campaignSelected = event.id;
        campaignStorage.campaignName = event.campaignName;
        campaignStorage.startDate = event.startDate;
        campaignStorage.endDate = event.endDate;
        campaignStorage.status = event.status;
        sessionStorage.setItem('campaignStorage', JSON.stringify(campaignStorage));
      }
    } else {
      if (autoType === 'campaign') { // Reset Campaign Filter
        sessionStorage.removeItem('campaignStorage');
        GlobalConstants.campaignIdPickedbyAdmin = NaN;
      }
      sessionStorage.removeItem('jfCompanyQuery');
    }
    return eventModel;
  }
}