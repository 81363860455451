/* tslint:disable:no-non-null-assertion */
import { EnumRegex } from 'src/app/_enum';

export interface AccountModelGoapi {
    email: string;
    password: string;
    phoneNumber: string;
    registerVia: string;
}

export class AccountClass {

    static objModel = ['email', 'password', 'phoneNumber', 'registerVia'];

    public static accountValidation(payload: AccountModelGoapi): string | null {
        const objPayload = Object.keys(payload);
        const objInvalid: string[] = [];


        // ? Validasi Object Key Payload
        objPayload.forEach((element) => {
            if (!AccountClass.objModel.includes(element)) { objInvalid.push(element); }
        });
        if (objInvalid.length) { return objInvalid.toString() + ' is invalid payload'; }


        // ? Validasi Mandatory Payload
        if (!payload.email || !payload.password || !payload.phoneNumber || !payload.registerVia) {
            return 'empty mandatory payload detected';
        }


        // ? Validasi Regex Payload
        const regexEmail = EnumRegex.parsingRegex('email')!.test(payload.email);
        const regexPassword = EnumRegex.parsingRegex('password')!.test(payload.password);
        const regexPasswordNum = EnumRegex.parsingRegex('number_whitespace')!.test(payload.password);
        const regexPhone = EnumRegex.parsingRegex('phone')!.test(payload.phoneNumber);

        const mailDomain = payload.email.split('@')[1];
        if (
          !regexEmail ||
          mailDomain.includes('gnail.') ||
          mailDomain.includes('.con')
        ) {
            return 'email invalid';
        }
        if (!regexPasswordNum || !regexPassword) { return 'password invalid'; }
        if (!regexPhone) { return 'phone invalid'; }
        if (payload.registerVia !== 'wmt_web') { return 'register via invalid'; }

        return null;
    }
}
