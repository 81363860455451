export class SessionStorage {

  static getStorage(key: string): any {
    return sessionStorage.getItem(key) ? sessionStorage.getItem(key) : null;
  }

  static getStorageBoolean(key: string, value: string): boolean {
    return sessionStorage.getItem(key) ? (
      sessionStorage.getItem(key) === value
    ) : false;
  }

  static getStorageNumber(key: string, empty?: number): number {
    // @ts-ignore
    return sessionStorage.getItem(key) ? +sessionStorage.getItem(key) : (
      empty ? empty : NaN
    );
  }

  static getStorageJson(key: string): any {
    // tslint:disable-next-line:no-non-null-assertion
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)!) : null;
  }

  static setStorage(key: string, value: any): void {
    sessionStorage.setItem(key, value);
  }

  static removeStorage(key: string): void {
    return sessionStorage.removeItem(key);
  }
}
