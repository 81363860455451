<!-- begin::Header -->
<div mat-dialog-title class="border-bottom">
    <h3 class="mb-6"><strong>Konfirmasi Shift</strong></h3>
</div>
<!-- end::Header -->

<!-- begin::Content -->
<div mat-dialog-content class="d-flex flex-column">

    <form [formGroup]="formConfirmation">
        <!-- begin::Start Date -->
        <div class="d-flex justify-content-between mb-5">
            <label for="startDate" class="my-auto">Tanggal Mulai</label>

            <div class="d-flex flex-column w-250px">
                <input type="date" class="form-control form-control-solid"
                    formControlName="startDate"
                    [min]="minDate"
                    tabindex="-1"
                    [class.is-invalid]="form('startDate')">
                <span class="invalid-feedback" *ngIf="form('startDate')">
                    <ng-container *ngIf="fConfirm.startDate.errors!.required">Tanggal mulai tidak boleh kosong</ng-container>
                </span>
            </div>
        </div>
        <!-- end::Start Date -->

        <label for="endDate" class="mb-5">Pilih Batas Waktu</label>

        <!-- begin::Radio Limit -->
        <div class="radio-list w-100px mb-5">
            <label class="radio radio-square mb-6">
                <input type="radio" name="limitation" [formControl]="limitation" value="week" (change)="radioChange()">
                <span></span>
                1 Minggu
            </label>
    
            <label class="radio radio-square mb-6">
                <input type="radio" name="limitation" [formControl]="limitation" value="month" (change)="radioChange()">
                <span></span>
                1 Bulan
            </label>
    
            <label class="radio radio-square">
                <input type="radio" name="limitation" [formControl]="limitation" value="year" (change)="radioChange()">
                <span></span>
                1  Tahun
            </label>
        </div>
        <!-- end::Radio Limit -->

        <!-- begin::Start Date -->
        <div class="d-flex justify-content-between">
            <div class="radio-inline my-auto">
                <label class="radio radio-square">
                    <input type="radio" name="limitation" [formControl]="limitation" value="custom" (change)="radioChange()">
                    <span></span>
                    Pilih Tanggal
                </label>
            </div>

            <div class="d-flex flex-column w-250px">
                <input type="date" class="form-control form-control-solid"
                    formControlName="endDate"
                    [min]="minDate"
                    [attr.disabled]="(limitation.value && limitation.value === 'custom') ? null : ''"
                    [class.is-invalid]="form('endDate')">
                <span class="invalid-feedback" *ngIf="form('endDate')">
                    <ng-container *ngIf="fConfirm.endDate.errors!.required">Batas waktu tidak boleh kosong</ng-container>
                </span>
            </div>
        </div>
        <!-- end::Start Date -->
    </form>
</div>
<!-- end::Content -->

<!-- begin::Footer -->
<div mat-dialog-actions class="d-flex justify-content-between border-top mt-6">
    <!-- begin::Close Dialog -->
    <app-bootstrap-button
        [color]="'secondary'"
        [outline]="true"
        [pill]="true"
        [additionalClass]="'px-7'"
        [label]="'Batal'"
        (onClick)="modal.close()"
    ></app-bootstrap-button>
    <!-- end::Close Dialog -->

    <!-- begin::Save Data -->
    <app-bootstrap-button
        [color]="'primary'"
        [pill]="true"
        [additionalClass]="'px-7'"
        [label]="'Simpan'"
        (onClick)="onSubmit()"
    ></app-bootstrap-button>
    <!-- end::Save Data -->
</div>
<!-- end::Footer -->