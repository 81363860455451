import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent implements OnInit {

  svgTitle!: string;
  svgClose!: string;
  textColor!: string;

  constructor(
    public snackbar: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: {
      title: string,
      key: number,
      error?: string,
      message?: string
      url?: string
    }
  ) { }

  ngOnInit(): void {
    if (!environment.production) {
      console.log(this.data);
    }

    if (!this.data) {
      this.snackbar.dismiss();
      return;
    }

    this.data.title = this.data.title?.includes(' - ') ?
      this.data.title.split(' - ')[1] :
      this.data.title;

    this.data.message = this.data.message?.includes('-') ?
      this.data.message.split(' - ')[1] :
      this.data.message;

    this.data.url = this.data.url?.includes('?') ?
      this.data.url.split('?')[0] :
      this.data.url;

    if (this.data.key) {
      if (this.data.key === 401 || this.data.key === 403 || this.data.key === 404 || this.data.key === 405) {
        this.svgTitle = 'assets/media/svg/icons/Figma/Alert-Warning.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Warning.svg';
        this.textColor = 'text-warning';
      } else if (this.data.key === 200 || this.data.key === 201) {
        this.svgTitle = 'assets/media/svg/icons/Figma/Success.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Success.svg';
        this.textColor = 'text-success';
      } else if (this.data.key === 400 || this.data.key === 413 || this.data.key === 415) {
        this.svgTitle = 'assets/media/svg/icons/Figma/Error.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Danger.svg';
        this.textColor = 'text-danger';
      } else if (
        this.data.key === 304 || this.data.key === 500 || this.data.key === 501 || this.data.key === 504 ||
        this.data.key === 417 || this.data.key === 422
      ) {
        this.svgTitle = 'assets/media/svg/icons/Figma/Alert-Info.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Info.svg';
        this.textColor = 'text-info';
      } else if (this.data.key.toString()[0] === '5') {
        this.svgTitle = 'assets/media/svg/icons/Figma/Alert-Info.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Info.svg';
        this.textColor = 'text-info';
      } else if (this.data.key.toString()[0] === '4') {
        this.svgTitle = 'assets/media/svg/icons/Figma/Error.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Danger.svg';
        this.textColor = 'text-danger';
      } else if (this.data.key.toString()[0] === '3') {
        this.svgTitle = 'assets/media/svg/icons/Figma/Alert-Info.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Info.svg';
        this.textColor = 'text-info';
      } else if (this.data.key.toString()[0] === '2') {
        this.svgTitle = 'assets/media/svg/icons/Figma/Success.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Success.svg';
        this.textColor = 'text-success';
      } else if (this.data.key.toString()[0] === '1') {
        this.svgTitle = 'assets/media/svg/icons/Figma/Alert-Info.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Info.svg';
        this.textColor = 'text-info';
      } else {
        this.svgTitle = 'assets/media/svg/icons/Figma/Alert-Info.svg';
        this.svgClose = 'assets/media/svg/icons/Figma/Close-Info.svg';
        this.textColor = 'text-info';
      }
    }
  }

}
