/* tslint:disable:no-non-null-assertion */
import {ShiftAssignmentModel, ShiftBoardModel} from './shift-assignment.model';

export interface PostShiftAssignmentModel {
  userID: number;
  shiftID: number;
  shiftDateStart: string;
  shiftDateEnd: string;
}

export interface PutShiftAssignmentModel {
  assignmentId: number; // ! Kemungkinan Deprecated
  data: DataPutAssignmentModel;
}

export interface DataPutAssignmentModel {
  userId: number;
  shiftID: number;
  shiftDateStart: string;
  shiftDateEnd: string;
}

export interface PatchShiftAssignmentModel {
  sourceAssignID: number;
  destAssignID: number;
}

export interface DeleteShiftAssignmentModel {
  assignmentID: number;
}

export class ShiftAssignmentClass {

  static formatAssignment(rawAssignment: ShiftAssignmentModel[]): PostShiftAssignmentModel[] {
    const payloadAssignment: PostShiftAssignmentModel[] = [];

    rawAssignment.forEach((element: ShiftAssignmentModel) => {
      const tempNewShift: ShiftBoardModel[] | undefined = element.arrShift.filter((fil: ShiftBoardModel) =>
        (fil.shiftId && !fil.assignmentId)
      );

      if (tempNewShift && tempNewShift.length) {
        tempNewShift.forEach((newShift: ShiftBoardModel) => {

          // * Hanya user yang belum masuk payload boleh di push
          const indexOfUser = payloadAssignment.findIndex((ind: PostShiftAssignmentModel) =>
            (ind.userID === newShift.userId && ind.shiftID === newShift.shiftId)
          );
          if (indexOfUser === -1) {
            payloadAssignment.push({
              userID: newShift.userId,
              shiftID: newShift.shiftId!,
              shiftDateStart: newShift.shiftDateStart!,
              shiftDateEnd: newShift.shiftDateEnd!,
            });
          }
        });
      }
    });
    return payloadAssignment;
  }

  static formatDeletedAssignment(rawAssignment: ShiftBoardModel[]): DeleteShiftAssignmentModel[] {
    const payloadAssignmentDeleted: DeleteShiftAssignmentModel[] = [];

    const tempNewShift: ShiftBoardModel[] | undefined = rawAssignment.filter((fil: ShiftBoardModel) => (fil.assignmentId));
    if (tempNewShift && tempNewShift.length) {
      tempNewShift.forEach((shift: ShiftBoardModel) => {
        const indexOfUser = payloadAssignmentDeleted.findIndex((ind: DeleteShiftAssignmentModel) =>
          ind.assignmentID === shift.assignmentId
        );
        if (indexOfUser === -1) {
          payloadAssignmentDeleted.push({
            assignmentID: shift.assignmentId!
          });
        }
      });
    }
    return payloadAssignmentDeleted;
  }
}
