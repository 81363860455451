import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_COMPANY_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyAttendanceHTTPService {

  constructor(private http: HttpClient) { }

  getConfig(companyId: number): Observable<any> {
    return this.http.get<any>(`${API_COMPANY_URL}/company/${companyId}/attendance/setting`);
  }

  putConfig(companyId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance/setting`, data);
  }

  getPremium(companyId: number): Observable<any> {
    return this.http.get<any>(`${API_COMPANY_URL}/company/${companyId}/attendance_config`);
  }

  patchPremium(companyId: number, attendanceIsPremium: boolean): Observable<Response> {
    return this.http.patch<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance-premium`, {attendanceIsPremium});
  }

  putPremium(companyId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_COMPANY_URL}/company/${companyId}/attendance_config`, data);
  }
}
