// noinspection RegExpUnnecessaryNonCapturingGroup,RegExpSingleCharAlternation

export class EnumRegex {
    public static regexList: any = [
        {enum: 'address', regex: /^[A-Za-z0-9\/\s\n\r.,()-:&]{0,250}$/},
        {enum: 'alpha', regex: /^[A-Za-z0-9\s]*$/},
        {enum: 'alpha_sub_district', regex: /^[A-Za-z0-9\s-]*$/},
        {enum: 'alpha_sub_district_25', regex: /^[A-Za-z0-9\s-]{0,25}$/},
        {enum: 'alpha_title', regex: /^[A-Za-z0-9\s&\-,.']*$/},
        {enum: 'alpha_title_60', regex: /^[A-Za-z0-9\s&\-,.']{2,60}$/},
        {enum: 'alpha_title_128', regex: /^[A-Za-z0-9\s&\-,.']{2,128}$/},
        {enum: 'date_dmy', regex: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/},
        {enum: 'date_mdy', regex: /^(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])-\d{4}$/},
        {enum: 'date_ymd', regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/},
        {enum: 'decimal', regex: /-?\d+(\.\d*)?/},
        {enum: 'email', regex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/},
        {enum: 'full_name', regex: /^[a-zA-Z\s,.']{3,60}$/},
        {enum: 'html', regex: /<.+>/},
        {enum: 'html_attribute', regex: /<\/?(?:p|a|b|img)(?: [A-Za-z0-9]+=["'`].+["'`])*(?: \/)?>/},
        {enum: 'html_predefined', regex: /<\/?(?:p|a|b|img)(?: \/)?>/},
        {enum: 'ip_address', regex: /(?:25[0-5]|2[0-4]\d|[01]\d{2}|\d{1,2})(?:.(?:25[0-5]|2[0-4]\d|[01]\d{2}|\d{1,2})){3}/},
        {enum: 'ipv6_address', regex: /(?:[A-Fa-f0-9]){0,4}(?: ?:? ?(?:[A-Fa-f0-9]){0,4}){0,7}/},
        {enum: 'ktp', regex: /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/},
        {enum: 'letter', regex: /^[a-zA-Z\s]*$/},
        {enum: 'letter_25', regex: /^[a-zA-Z\s]{1,25}$/},
        {enum: 'letter_35', regex: /^[a-zA-Z\s]{1,35}$/},
        {enum: 'negative', regex: /-?\d+/},
        {enum: 'number', regex: /^\d+/},
        {enum: 'number_whitespace', regex: /\d+/},
        {enum: 'password', regex: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/},
        {enum: 'phone', regex: /^[+][1-9]\d{8,13}$/},
        {enum: 'phone_form', regex: /^[1-9]\d{8,12}$/},
        {enum: 'scale', regex: /^xs|s|m|l|xl$/},
        {enum: 'sosmed_fb', regex: /^\b(?:www|m)\b(?:^|\W)(?:facebook|FACEBOOK).com(?:$|\W)$/},
        {enum: 'sosmed_ig', regex: /^\b(?:www|m)\b(?:^|\W)(?:instagram|INSTAGRAM).com(?:$|\W)$/},
        {enum: 'sosmed_tw', regex: /^\b(?:www|m)\b(?:^|\W)(?:twitter|TWITTER).com(?:$|\W)$/},
        {enum: 'sosmed_ln', regex: /^\b(?:www|m)\b(?:^|\W)(?:linkedin|LINKEDIN).com(?:$|\W)$/},
        {enum: 'text', regex: /[A-Za-z0-9.,;:!?()"'%\-]+/},
        {enum: 'timestamp_utc', regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])Z$/},
        {enum: 'timestamp_gmt7', regex: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\+07:00$/},
        {enum: 'time_12', regex: /(?:12:00|(?:1[01]|[1-9]):(?:[0-5]\d))/},
        {enum: 'time_24', regex: /(?:2[0-3]|[01]\d|\d):(?:[0-5]\d)/},
        {enum: 'time_ampm', regex: /^(?:12:00|(?:1[01]|[1-9]):(?:[0-5]\d))\s?((?:A|P)\.?M\.?)$/i},
        {enum: 'url', regex: /(?:http|https|ftp|mailto|file|data|irc):\/\/[A-Za-z0-9\-]{0,63}(\.[A-Za-z0-9\-]{0,63})+(:\d{1,4})?\/*(\/*[A-Za-z0-9\-._]+\/*)*(\?.*)?(#.*)?/},
        {enum: 'web', regex: /^\b(?:https:\/\/www\.|https:\/\/m\.|https:\/\/|http:\/\/www\.|http:\/\/m\.|http:\/\/|www\.|m\.|)\b[a-zA-Z0-9]+\.\S{2,}$/},
        {enum: 'whitespace', regex: /\s/},
        {enum: 'whitespace_nrt', regex: /\n\r\t/},
        {enum: 'words', regex: /\w/},
        {enum: 'zip', regex: /^\d{5}$/},
    ];

    public static parsingRegex(regex:
        'address' | 'alpha' | 'alpha_sub_district' | 'alpha_sub_district_25' | 'alpha_title' | 'alpha_title_60' |
        'alpha_title_128' | 'date_dmy' | 'date_mdy' | 'date_ymd' | 'decimal' | 'email' | 'full_name' | 'html' |
        'html_attribute' | 'html_predefined' | 'ip_address' | 'ipv6_address' | 'ktp' | 'letter' | 'letter_25' |
        'letter_35' | 'negative' | 'number' | 'number_whitespace' | 'password' | 'phone' | 'phone_form' | 'scale' |
        'sosmed_fb' | 'sosmed_ig' | 'sosmed_tw' | 'sosmed_ln' | 'text' | 'time_12' | 'time_24' | 'time_ampm' |
        'timestamp_utc' | 'timestamp_gmt7' | 'url' | 'web' | 'whitespace' | 'whitespace_nrt' | 'words' | 'zip'
    ): RegExp | null {
        const tempRegex: any = EnumRegex.regexList.find((fin: any) => fin.enum === regex.trim().toLowerCase());
        return (tempRegex) ? tempRegex.regex : null;
    }
}
