import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { FilterModel } from 'src/app/_class';

const API_CLIENT_URL = `${environment.apiUrlAts}`;

@Injectable({
  providedIn: 'root'
})
export class ClientHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  clientRegister(data: any): Observable<Response> {
    return this.http.post<Response>(`${API_CLIENT_URL}/client`, data);
  }
  getAtsClient(filter: FilterModel): Observable<any>{
    const url = `${API_CLIENT_URL}/client?${filter.query}metadata=true&page=${filter.page}&limit=${filter.size ? filter.size : 20}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}`;
    return this.http.get<any>(url, this.config.GHttpOptionsXProvider());
  }
  getAtsClientById(clientId: number): Observable<any> {
    const url = `${API_CLIENT_URL}/client/${clientId}`;
    return this.http.get<Response>(url, this.config.GHttpOptionsXProvider());
  }
  getAtsClientByName(companyId: number, name: string, size?: number): Observable<Response> {
    const url = `${API_CLIENT_URL}/client?page=0&company_id=${companyId}&name=${name}&limit=${(size) ? size : 1}`;
    return this.http.get<Response>(url, this.config.GHttpOptionsXProvider());
  }
  putAtsClient(clientId: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_CLIENT_URL}/client/${clientId}`, data);
  }
  patchAtsClient(clientId: number, data: {status: string, reason?: string}): Observable<Response> {
    return this.http.patch<Response>(`${API_CLIENT_URL}/client/${clientId}`, data);
  }
  deleteAtsClient(clientId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_CLIENT_URL}/client/${clientId}`);
  }
}
