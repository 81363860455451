import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { ResponseCompanyDept } from '../..';

const API_COMPANY_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyDepartmentHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  getCompanyDept(companyId: number): Observable<ResponseCompanyDept> {
    return this.http.get<any>(`${API_COMPANY_URL}/company/${companyId}/department`);
  }

  postCompanyDept(companyId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_COMPANY_URL}/company/${companyId}/department`, data);
  }

  putCompanyDept(companyId: number, deptId: number, data: any): Observable<Response> {
    const url = `${API_COMPANY_URL}/company/${companyId}/department/${deptId}`;
    return this.http.put<Response>(url, data, this.config.GHttpOptionsXVendor('Midtrans_Iris'));
  }

  delCompanyDept(companyId: number, deptId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_COMPANY_URL}/company/${companyId}/department/${deptId}`);
  }
}
