import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

import {GlobalConstants, SessionStorage} from 'src/app/_class';
import {FormService, TagsService} from 'src/app/_service';
import {ClientDataModel, IndexClientVariables, ClientService} from '..';

@Injectable()
export class ClientClass {

  clientReady$: Observable<boolean>;
  currentClient$: Observable<ClientDataModel | undefined>;
  currentClientList$: Observable<ClientDataModel[] | undefined>;
  clientReadySubject: BehaviorSubject<boolean>;
  currentClientSubject: BehaviorSubject<ClientDataModel | undefined>;
  currentClientListSubject: BehaviorSubject<ClientDataModel[] | undefined>;

  constructor(
    private clientService: ClientService,
    private formService: FormService,
    private tagsService: TagsService,
  ) {
    this.clientReadySubject = new BehaviorSubject<boolean>(false);
    this.currentClientSubject = new BehaviorSubject<ClientDataModel | undefined>(undefined);
    this.currentClientListSubject = new BehaviorSubject<ClientDataModel[] | undefined>(undefined);
    this.clientReady$ = this.clientReadySubject.asObservable();
    this.currentClient$ = this.currentClientSubject.asObservable();
    this.currentClientList$ = this.currentClientListSubject.asObservable();
  }

  // Variables Initial
  initVariable(showCompanyList?: boolean): IndexClientVariables {
    const varModel = {} as IndexClientVariables;

    const Session = SessionStorage.getStorageJson('clientTags');

    const tempFreeText = this.formService.filterSession(Session, 'freetext');
    const tempStatus = this.formService.filterSession(Session, 'client_status');
    // const tempType = this.formService.filterSession(Session, 'type');
    // const tempDate = this.formService.filterSession(Session, 'date');
    const tempIndustry = this.formService.filterSession(Session, 'industry');
    const tempCity = this.formService.filterSession(Session, 'city');

    varModel.tempQuery = SessionStorage.getStorage('clientQuery');
    varModel.companyPick = (showCompanyList) ? (
        SessionStorage.getStorage('clientCompanySelected') ?
          SessionStorage.getStorage('clientCompanySelected') : 
          NaN
      ) :
      GlobalConstants.companyId;
    varModel.arrFreeText = (tempFreeText.length) ? tempFreeText[0].value : [];
    // this.typePick.setValue((tempType.length) ? tempType[0].value : null);
    varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    // this.datePick.controls.begin.setValue((tempDate.length) ? tempDate[0].value.begin : null);
    // this.datePick.controls.end.setValue((tempDate.length) ? tempDate[0].value.end : null);
    varModel.industriesPick = (tempIndustry.length) ? tempIndustry[0].value : null;
    varModel.cityPick = (tempCity.length) ? tempCity[0].value : null;
    varModel.page = SessionStorage.getStorageNumber('clientPageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('clientPageSize', 20);
    varModel.sortBy = SessionStorage.getStorage('clientSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('clientSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    return varModel;
  }

  // End of Variables Initial


  // Filter Logic
  filterService(
    companyPick: number,
    freeText: FormControl,
    arrFreeText: FormControl,
    statusPick: FormControl,
    industriesPick: FormControl,
    cityPick: FormControl,
    tags: { key: string, value: string }[]
  ): string {
    if (freeText.value || arrFreeText.value.length) {
      const tempFreeText = freeText.value ? freeText.value.split(',') : [];
      const tempArrFreeText = arrFreeText.value;
      if (tempFreeText.length) {
        tempFreeText.forEach((element: any) => {
          tempArrFreeText.push(element.replace('&', '%26'));
        });
      }
      arrFreeText.setValue(tempArrFreeText);
      tags = this.tagsService.updateKey(tags, 'freetext', arrFreeText, 'array');
      freeText.reset();
    }
    if (statusPick.value) {
      tags = this.tagsService.updateKey(tags, 'client_status', statusPick);
    }
    if (industriesPick.value) {
      tags = this.tagsService.updateKey(tags, 'industry', industriesPick);
    }
    if (cityPick.value) {
      tags = this.tagsService.updateKey(tags, 'city', cityPick);
    }

    // if (typePick.value) {
    //   remove({key: 'type'});
    //   tags.push({key: 'type', value: typePick.value});
    // }
    // if (datePick.value.begin) {
    //   if (!datePick.value.end) return;
    //   remove({key: 'date'});
    //   tags.push({key: 'date', value: datePick.value});
    // }
    // if (registeredViaWebPick) {
    //   sessionStorage.setItem('companyRegiteredViaWeb', registeredViaWebPick);
    // }
    // if (lastLogin) {
    //   lastLoginPick = moment(lastLogin).format('YYYY-MM-DD');
    //   sessionStorage.setItem('companyLastLogin', lastLoginPick);
    // }

    const company = companyPick ? `company_id=${companyPick}` : null;
    const freetext = arrFreeText.value.length ? `name=${arrFreeText.value.join(',')}` : null;
    // const typePick = typePick.value ? `createdBy=${typePick.value}` : null;
    const status = statusPick.value ? `status=${statusPick.value}` : null;
    const city = cityPick.value ? `address_city=${cityPick.value}` : null;
    const industry = industriesPick.value ? `industry=${industriesPick.value}` : null;
    // const createDate = datePick.value.end ?
    // `createdDate:(>=${moment(datePick.value.begin).format('YYYY-MM-DD')} AND <=${moment(datePick.value.end).format('YYYY-MM-DD')})` :
    // null;
    // const registeredViaWebPick = registeredViaWebPick ? `registeredViaWeb:(${registeredViaWebPick})` : null;
    // const referralCode = (event && event.referrerCode) ? `referrerCode:(${event.referrerCode})` : null;
    // const lastLogin = lastLoginPick ? `lastLogin:(<=${lastLoginPick})` : null;

    // console.log(scrapping);

    const data = [
      freetext, company, status, city, industry
    ];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('clientQuery', tempQuery);
    sessionStorage.setItem('clientTags', JSON.stringify(tags));
    return tempQuery;
  }

  eventFilterOutsideClient(
    storageClient: string,
    event: any
  ): number {
    let companyPick: number = NaN;

    if (event.id) {
      companyPick = event.id;
      sessionStorage.setItem(storageClient, companyPick.toString());
    } else {
      sessionStorage.removeItem(storageClient);
    }
    return companyPick;
  }

  removeTags(
    tags: { key: string, value: string }[],
    arrFreeText: FormControl,
    tag: any,
    indexTagValue?: number): void {
    // if (tag.key === 'type') this.typePick.setValue(null);
    // if (tag.key === 'date') { this.datePick.controls.begin.setValue(null); this.datePick.controls.end.setValue(null); };
    if (tag.key === 'freetext') {
      tags = this.tagsService.removeKey(tags, tag.key, arrFreeText, 'array', indexTagValue);
    } else { // by default yang bukan array disini
      tags = this.tagsService.removeKey(tags, tag.key);
    }

    sessionStorage.setItem('clientTags', JSON.stringify(tags));
  }

  // End Filter Logic

  getClientCombo(
    companyPick: number | any,
    clientPick: string | number | any,
    byId?: boolean
  ): void {
    this.clientReadySubject.next(false);
    if (!clientPick) {
      this.clientService.getClientAutoComplete(`company_id=${companyPick}`)
      .subscribe((arg: any) => {
        const listData = arg.data;

        this.currentClientListSubject.next(listData);
        this.clientReadySubject.next(true);
      }, (error: any) => {
        this.clientService.openSnackBar(error.status, error);
      });
    } else {
      if (byId) {
        this.clientService.getClientAutoCompleteById(clientPick).subscribe((arg: any) => {
          const listData = arg;
  
          this.currentClientSubject.next(listData);
          this.currentClientListSubject.next([listData]);
          this.clientReadySubject.next(true);
        }, (error: any) => {
          this.clientService.openSnackBar(error.status, error);
        });
      } else {
        this.clientService.getClientAutoCompleteByName(companyPick, clientPick).subscribe((arg: any) => {
          const listData = arg.data;
  
          this.currentClientSubject.next(listData[0]);
          this.currentClientListSubject.next(listData);
          this.clientReadySubject.next(true);
        }, (error: any) => {
          this.clientService.openSnackBar(error.status, error);
        });
      }
    }
  }
}
