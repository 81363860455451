import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { HttpConfigService } from 'src/app/_service';
import { EmployeeAttendanceHTTPService } from './employee-http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeAttendanceService {

  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private leaveHttp: EmployeeAttendanceHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // public method
  getLeaveConfig(companyId: number, userId: number, query?: string): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.leaveHttp.getLeaveConfig(companyId, userId, query).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitLeaveConfig(companyId: number, userId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.leaveHttp.putLeaveConfig(companyId, userId, data).pipe(
      switchMap(() => this.getLeaveConfig(companyId, userId)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
}
