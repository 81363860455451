import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalConstants } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeSalaryHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  getEmployeeSalary(companyId: number, userId: number): Observable<any> {
    const hasAccess = (GlobalConstants.role.includes('ROLE_HR') || GlobalConstants.role.includes('ROLE_OWNER'));

    const url = `${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/salary`;
    return this.http.get<any>(url, (hasAccess && (!environment.production)) ?
      this.config.GHttpOptionsXGandalf() : this.config.GHttpOptionsDefault());
  }
  createEmployeeSalary(companyId: number, userId: number, data: any): Observable<any> {
    return this.http.post<any>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/salary`, data);
  }
  updateEmployeeSalary(companyId: number, userId: number, data: any): Observable<any> {
    return this.http.put<any>(`${API_EMPLOYEE_URL}/company/${companyId}/employee/${userId}/salary`, data);
  }
}
