import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { SessionStorage } from "src/app/_class";
import { TagsService } from "src/app/_service";
import { IndexProjectVariables } from "..";

@Injectable()
export class ProjectClass {

    constructor(
        private formBuilder: FormBuilder,
        private tagsService: TagsService
    ) {}

    // Variables Initial
    initVariable(): IndexProjectVariables {
        const varModel = {} as IndexProjectVariables;

        const Session = SessionStorage.getStorageJson('projectTags');
        const tempFreeText = this.tagsService.filterSession(Session, 'freetext');
        const tempEmployment = this.tagsService.filterSession(Session, 'project_type');
        const tempStatus = this.tagsService.filterSession(Session, 'project_status');
        const tempRadio = sessionStorage.getItem('projectRadioSelected');

        varModel.tempQuery = SessionStorage.getStorage('projectQuery');
        varModel.freeText = (tempFreeText.length) ? tempFreeText[0].value : null;
        varModel.projectTypePick = (tempEmployment.length) ? tempEmployment[0].value : null;
        varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
        varModel.radioChoose = tempRadio ? tempRadio : null;
        varModel.page = SessionStorage.getStorageNumber('projectPageActive', 1);
        varModel.size = SessionStorage.getStorageNumber('projectPageSize', 10);
        varModel.sortBy = SessionStorage.getStorage('projectSortBy');
        varModel.sortAsc = SessionStorage.getStorageBoolean('projectSortAsc', 'true');
        varModel.tags = Session ? Session : [];

        return varModel;
    }
    // End of Variables Initial


    // Filter Logic
    filterService(
        freeText: FormControl,
        projectTypePick: FormControl,
        statusPick: FormControl,
        radioChoose: FormControl,
        tags: {key: string, value: string}[]
    ): string {
        if (freeText.value) {
            tags = this.tagsService.updateKey(tags, 'freetext', freeText);
        }
        if (projectTypePick.value) {
            tags = this.tagsService.updateKey(tags, 'project_type', projectTypePick, 'array');
        }
        if (statusPick.value) {
            tags = this.tagsService.updateKey(tags, 'project_status', statusPick, 'array');
        }

        const freetext = freeText.value ? (
        radioChoose.value === 'location' ? `location=${freeText.value}` : `name=${freeText.value}`
        ) : null;
        const employment = projectTypePick.value ? `type=${projectTypePick.value.join(',')}` : null;
        const status = statusPick.value ? `status=${statusPick.value.join(',')}` : null;

        const data = [freetext, employment, status];

        // console.log(data);
        // return;

        const filteredData = data.filter(el => el);
        const tempQuery = filteredData.join('&');
        sessionStorage.setItem('projectQuery', tempQuery);
        sessionStorage.setItem('projectTags', JSON.stringify(tags));
        return tempQuery;
    }

    resetFilter(): void {
        sessionStorage.removeItem('projectQuery');
        sessionStorage.removeItem('projectTags');
        sessionStorage.removeItem('projectSortBy');
        sessionStorage.removeItem('projectSortAsc');
    }

    removeTags(
        tags: {key: string, value: string}[],
        projectTypePick: FormControl,
        statusPick: FormControl,
        tag: any,
        indexTagValue?: number): void {
        if (tag.key === 'project_type') {
            tags = this.tagsService.removeKey(tags, tag.key, projectTypePick, 'array', indexTagValue);
        } else if (tag.key === 'project_status') {
            tags = this.tagsService.removeKey(tags, tag.key, statusPick, 'array', indexTagValue);
        } else {
            tags = this.tagsService.removeKey(tags, tag.key);
        }

        sessionStorage.setItem('projectTags', JSON.stringify(tags));
    }
    // End of Filter Logic


    // Generate an
    generateMessage(name: string): string {
        let message: string;
        if (name === 'name') {
        message = 'Nama proyek ';
        } else if (name === 'description') {
        message = 'Deskripsi proyek ';
        } else if (name === 'type') {
        message = 'Tipe proyek ';
        } else {
        message = 'Lokasi ';
        }
        return message + 'wajib di isi.';
    }
    // End of Generate an

    generateForm(dataProject: any): FormGroup {
        return this.formBuilder.group({
          name: [dataProject?.name ? dataProject.name : null, Validators.required],
          description: [dataProject?.description ? dataProject.description : null, Validators.required],
          type: [dataProject?.type ? dataProject.type : null, Validators.required],
          location: [dataProject?.location ? dataProject.location : null, Validators.required],
          status: [dataProject?.status ? dataProject.status : 'active'],
          startDate: [dataProject?.startDate ? dataProject.startDate : null],
          endDate: [dataProject?.endDate ? dataProject.endDate : null],
        });
    }

}