export class EnumLeaveStatus {
    public static leaveStatusSelect: any = [
        { name: 'Diajukan', enum: 'submitted' },
        { name: 'Dibatalkan', enum: 'cancelled' },
        { name: 'Disetujui', enum: 'approved' },
        { name: 'Ditolak', enum: 'rejected' },
    ];

    public static generateLeaveStatus(leave: string): string {
        const tempLeave = EnumLeaveStatus.leaveStatusSelect.find((fil: any) => fil.enum === leave);
        return (tempLeave) ? tempLeave.name : 'Unknown';
    }

    public static findLeaveStatus(status: string): any {
        return EnumLeaveStatus.leaveStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
    }
}
