import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_EMPLOYEE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeEduperienceHTTPService {

  constructor(private http: HttpClient) { }

  //Exp
  postExperience(userId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/work`, data);
  }
  editExperience(userId: number, idExp: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/work/${idExp}`, data);
  }
  deleteExperience(userId: number, idExp: number):Observable<Response> {
    return this.http.delete<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/work/${idExp}`);
  }

  //Edu
  postEducation(userId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/education`, data);
  }
  editEducation(userId: number, idEdu: number, data: any): Observable<Response> {
    return this.http.put<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/education/${idEdu}`, data);
  }
  deleteEducation(userId: number, idEdu: number):Observable<Response> {
    return this.http.delete<Response>(`${API_EMPLOYEE_URL}/worker/${userId}/education/${idEdu}`);
  }
}
