import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { HttpConfigService, ProfileService } from 'src/app/_service';
import { EmployeeHTTPService } from '../../employee-list/services/employee-http';
import { TalentHttpService } from './ats-http/talent-http.service';

@Injectable()
export class TalentService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentData$: Observable<boolean>;

  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  currentDataSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private talentHttp: TalentHttpService
  ) { 
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentDataSubject = new BehaviorSubject<boolean>(false);

    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.currentData$ = this.currentDataSubject.asObservable();
  }

  openSnackbar(status: number, error: HttpErrorResponse) {
    this.config.openSnackBar(status, error);
  }

  openCustomBar(status: number, message: string) {
    this.config.openSnackBar(status, undefined, message);
  }

    // Before Hit API
  getTalentList(companyId: number, query: any, page?: number, size?: number, sort?: string, asc?: boolean): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.talentHttp.getTalent(companyId, query, page, size, sort, asc).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getBookmarkList(companyId: number, query: any, page?: number, size?: number, sort?: string, asc?: boolean): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.talentHttp.getBookmark(companyId, query, page, size, sort, asc).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  bookmarkTalent(jobId: number, talentId: number): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.talentHttp.bookmarkTalent(jobId, talentId).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  unBookmarkTalent(companyId: number, talentId: number): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.talentHttp.unBookmarkTalent(companyId, talentId).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  
  postJobTalent(data: any): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.talentHttp.postTalentJob(data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

}
