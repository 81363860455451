export class EnumEmployeeMaritalStatus {
    public static employeeMaritalStatusSelect: any = [
        { name: 'Lajang', enum: 'single' },
        { name: 'Menikah', enum: 'married' },
        { name: 'Cerai', enum: 'separated' },
        { name: 'Rumit', enum: 'complicated' },
        { name: 'Tidak Menyebutkan', enum: 'prefer_not_to_say' }
    ];

    public static findMaritalStatus(marital: string): any {
        return EnumEmployeeMaritalStatus.employeeMaritalStatusSelect.find((fin: any) => fin.enum === marital.trim().toLowerCase());
    }

    public static generateMaritalStatus(marital: string): string {
        const tempEmployment = EnumEmployeeMaritalStatus.employeeMaritalStatusSelect.find(
          (fin: any) => fin.enum === marital.trim().toLowerCase()
        );
        return (tempEmployment) ? tempEmployment.name : '-';
    }
}
