import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { concatMap, finalize, map, switchMap } from 'rxjs/operators';

import { HttpConfigService } from 'src/app/_service';
import { ResponseProject } from '..';
import { ManageProjectHTTPService } from './manage-team-http';


export type ResponseType = ResponseProject | undefined;

@Injectable({
  providedIn: 'root'
})
export class ManageProjectService {

  // public fields
  currentProject$: Observable<ResponseType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentProjectSubject: BehaviorSubject<ResponseType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private projectHttp: ManageProjectHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentProjectSubject = new BehaviorSubject<ResponseType>(undefined);
    this.currentProject$ = this.currentProjectSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackbar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  customSnackbar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  getProject(companyId: number, query: string, page: number, size: number, sort?: string, asc?: boolean): Observable<ResponseType> {
    this.isLoadingSubject.next(true);
    return this.projectHttp.getProjectList(companyId, query, page, size, sort, asc).pipe(
      map((template: ResponseType) => {
        // checking template
        if (template) {
          this.currentProjectSubject.next(template);
        }
        return template;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getProjectById(companyId: number, projectId: number): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.projectHttp.getProjectDetail(companyId, projectId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  createProject(companyId: number, data: any, getParam: {
    query: string, page: number, size: number, sort?: string, asc?: boolean
  }): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.projectHttp.createProject(companyId, data).pipe(
      switchMap(() => this.getProject(companyId, getParam.query, getParam.page, getParam.size, getParam.sort, getParam.asc)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  updateProject(companyId: number, projectId: any, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.projectHttp.editProject(companyId, projectId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  changeProjectStatus(companyId: number, id: number, data: any, status: string, getParam: {
    query: string, page: number, size: number, sort?: string, asc?: boolean
  }): Observable<ResponseType> {
    const rawProject: any = Object.assign({}, data);
    rawProject.status = status;
    rawProject.configs = '';

    this.isSubmitedSubject.next(true);
    return this.projectHttp.editProject(companyId, id, rawProject).pipe(
      switchMap(() => this.getProject(companyId, getParam.query, getParam.page, getParam.size, getParam.sort, getParam.asc)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  deleteProject(companyId: number, arrId: number[], getParam: {
    query: string, page: number, size: number, sort?: string, asc?: boolean
  }): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return from(arrId).pipe(
      concatMap((id: number) => {
        return this.projectHttp.deleteProject(companyId, id);
      }),
      switchMap(() => this.getProject(companyId, getParam.query, getParam.page, getParam.size, getParam.sort, getParam.asc)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
