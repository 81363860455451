/* tslint:disable */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { GlobalConstants, GlobalHelper } from 'src/app/_class';
import { ErrorJsonHandlingService } from './error-handling.service';
import { AuthModelGoapi, CompanyLoginModel, MeModel, UserLoginModel, AuthClass, Companyrole } from '../layouts/auth';
import { CampaignModel } from '../layouts/main/pages/campaign';
import { AuthHTTPService } from './service-http';
import { CampaignHTTPService } from '../layouts/main/pages/campaign/services/campaign-http';
import { CompanyAttendanceHTTPService, CompanyHTTPService } from '../layouts/main/pages/manage-companies/services/company-http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  private interruptedUrl!: string;

  constructor(
    private router: Router,
    private permissions: NgxPermissionsService,
    private jsonHandling: ErrorJsonHandlingService,
    private authHttp: AuthHTTPService,
    private campaignHttp: CampaignHTTPService,
    private companyHttp: CompanyHTTPService,
    private companyConfigHttp: CompanyAttendanceHTTPService,
    private snackBar: MatSnackBar,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.authHttp.openSnackBar(status, error);
  }
  customSnackbar(status: number, message: string): void {
    this.authHttp.customSnackbar(status, message);
  }

  // TODO : penyesuaian routing untuk employee dan temannya hr
  conditionalRouting(skipFeature?: boolean): string {
    if (!GlobalConstants.companyModule.includes('ATTENDANCE') && !skipFeature) {
      return '/dashboard/features';
    } else {
      if (
        GlobalConstants.role.includes('ROLE_OWNER') ||
        GlobalConstants.role.includes('ROLE_HR') ||
        GlobalConstants.role.includes('ROLE_MANAGER') ||
        GlobalConstants.role.includes('ROLE_FINANCE')
      ) {
        return GlobalConstants.approvedCompany ? '/dashboard/workforce' : '/simple-page';
      }
      return `/employee/profile/${GlobalConstants.userId}`;
    }
  }

  redirectToUrl(userType: 'career' | 'wmt', redirectToUrl?: string): void {
    if (redirectToUrl) {
      this.router.navigateByUrl(`/${redirectToUrl}`);
      localStorage.removeItem('url_redirect');
      return;
    }

    if (this.getInterruptedUrl()) {
      this.router.navigateByUrl(this.getInterruptedUrl());
    } else {
      this.router.navigateByUrl((userType === 'career') ?
        '/career/application' :
        this.conditionalRouting()
      );
    }

    localStorage.removeItem('from_login');
  }

  redirectToUrlAdmin(): void {
    if (this.getInterruptedUrl()) {
      this.router.navigateByUrl(this.getInterruptedUrl());
    } else {
      this.router.navigateByUrl('/simple-page');
    }

    localStorage.removeItem('from_login');
  }

  GLoginForm(payloadAuth: AuthModelGoapi): Observable<Response> {
    const errorMessage = AuthClass.authValidation(payloadAuth);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('POST Authenticate', errorMessage, payloadAuth);
    }

    this.isLoadingSubject.next(true);
    return this.authHttp.login(payloadAuth).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(data: {email: string}): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.authHttp.forgotPassword(data).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetPassword(email: string, data: {resetKey: string, password: string}): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.authHttp.resetPassword(email, data).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout(fromLogin?: boolean): void {
    this.permissions.flushPermissions();
    localStorage.clear();
    sessionStorage.clear();
    GlobalConstants.userId = NaN;
    GlobalConstants.userLogin = GlobalConstants.userName = GlobalConstants.userEmail = GlobalConstants.userPhone = '';
    GlobalConstants.role = '';
    GlobalConstants.companyId = GlobalConstants.companyIdPickedbyAdmin = NaN;
    GlobalConstants.companyModule = GlobalConstants.companyName = '';
    GlobalConstants.approvedCompany = false;
    GlobalConstants.campaignId = GlobalConstants.campaignIdPickedbyAdmin = NaN;
    GlobalConstants.campaignName = '';
    if (!fromLogin) { this.router.navigate(['login']); }
  }

  public getInterruptedUrl(): string {
    return this.interruptedUrl;
  }

  public setInterruptedUrl(url: string): void {
    this.interruptedUrl = url;
  }


  // Authorization V2
  getUserAuthorities(): Observable<UserLoginModel | undefined> {

    if (!localStorage.getItem('gid_token') || localStorage.getItem('gid_token') === 'null') {
      this.snackBar.open('Ilegal statement status:(User key not identified), harap login ulang', 'Tutup', {duration: 4000});
      this.logout();
      return of(undefined);
    }

    const returnParam = {} as UserLoginModel;

    return this.authHttp.getUserAuthorities().pipe(
      map((result: MeModel) => { // * Mapping Get User Authorities Result
        if (!result.activated) { // Tendang yang gapunya roles
          this.snackBar.open('Maaf, kamu tidak punya akses ke WMT', 'Tutup', {duration: 4000});
          this.logout();
          return undefined;
        }

        // console.log('hoi');

        // begin::Handle null company_id and inactive company
        if (result.company_roles) {
          result.company_roles = result.company_roles.filter((fil: Companyrole) =>
            fil.company_id &&
            (fil.status.toLowerCase() === 'active')
          );
        }
        // end::Handle null company_id and inactive company

        // console.log('hoi 1');

        // ? Penanda buat worker
        // returnParam.isWorker = !result.roles || !!result.roles?.toUpperCase().includes('ROLE_WORKER');
        returnParam.isWorker = true; // J2G-5224 comment -> modul portal dibuka buat semua user

        // console.log('hoi 2');

        returnParam.isCompany = !!result.company_roles?.length;

        returnParam.isAdmin = !!(
          result.roles && (
            result.roles.toUpperCase().includes('ROLE_ADMIN') ||
            result.roles.toUpperCase().includes('ROLE_BUSDEV')
          ));

        returnParam.isLender = !!(
          result.roles && (
            result.roles.toUpperCase().includes('ROLE_LENDER')
          ));

        if ((
            !result.company_roles ||
            !result.company_roles.length
          ) &&
          !returnParam.isWorker &&
          !returnParam.isAdmin &&
          !returnParam.isLender) { // Tendang yang gapunya roles
          this.snackBar.open('Maaf, kamu tidak punya akses ke WMT', 'Tutup', {duration: 4000});
          this.logout();
          return undefined;
        }

        // console.log('hoi 3');

        returnParam.arrAuthorities = result.company_roles ? result.company_roles : [];

        if (returnParam.arrAuthorities.length) {
          // begin::Perkondisian Company Selected
          if (localStorage.getItem('activeCompanyID') && localStorage.getItem('activeCompanyName')) {
            GlobalConstants.companyId = +localStorage.getItem('activeCompanyID')!;
            GlobalConstants.companyName = localStorage.getItem('activeCompanyName')!;
          } else {
            GlobalConstants.companyId = returnParam.arrAuthorities[0].company_id;
            GlobalConstants.companyName = returnParam.arrAuthorities[0].company_name;
            localStorage.setItem('activeCompanyID', GlobalConstants.companyId + ''); // set company aktif default sebelum switch company
            localStorage.setItem('activeCompanyName', GlobalConstants.companyName); // set company aktif default sebelum switch company
          }
          // end::Perkondisian Company Selected
        }

        // console.log('hoi 4');

        const tempCompany = returnParam.arrAuthorities.find((fin: any) => fin.company_id === GlobalConstants.companyId);

        if (!tempCompany && !returnParam.isWorker && !returnParam.isAdmin && !returnParam.isLender) { // Tendang yang company nya nggak jelas
          this.snackBar.open('Ilegal statement, harap login ulang', 'Tutup', {duration: 4000});
          this.logout();
          return undefined;
        }

        // console.log('hoi 5');

        returnParam.companyActived = (tempCompany) ? tempCompany.company_name : '';
        returnParam.companyLogo = (tempCompany) ? tempCompany.image_url : '';

        // begin::Setup Internal Roles
        if (tempCompany) {
          let tempAuthority = tempCompany.authority.toUpperCase() + (result.roles ? ',' + result.roles.toUpperCase() : '');
          if (
            !tempAuthority.includes('ROLE_ADMIN') &&
            !tempAuthority.includes('ROLE_LENDER') &&
            !tempAuthority.includes('ROLE_COMPANY') &&
            !tempAuthority.includes('ROLE_WORKER')) {
            tempAuthority = tempAuthority + ',ROLE_WORKER';
          }
          tempCompany.authority = tempAuthority;
        }
        // Hard Code Sharky - Kasbon
        // if (result.email === 'sharky.adrian@job2go.net') {
        //   tempCompany.authority = tempCompany.authority.toUpperCase() + ',ROLE_KASBON';
        // }
        // Hard Code Interflour - Company Attendance
        // if (result.email === 'nabilberry555@gmail.com') {
        //   tempCompany.authority = tempCompany.authority.toUpperCase() + ',ROLE_ATTENDANCE';
        // }
        // begin::Setup Internal Roles

        // console.log('hoi 6');

        if (localStorage.getItem('role') && !localStorage.getItem('fromSwitcher')) {
          let isLoggedOut = false;

          localStorage.getItem('role')!.split(',').forEach(element => {
            if (tempCompany && !tempCompany.authority.toUpperCase().includes(element)) {
              isLoggedOut = true;
            }
          });

          if (isLoggedOut && (localStorage.getItem('role') !== 'ROLE_COMPANY')) {
            this.logout();
            return undefined;
          }
        }

        // console.log('hoi 7');

        GlobalConstants.userId = returnParam.userId = result.ID;
        GlobalConstants.userLogin = result.login;
        GlobalConstants.userEmail = result.email;
        GlobalConstants.userName = result.fullName;
        GlobalConstants.userPhone = result.phone_number;
        GlobalConstants.role = (returnParam.arrAuthorities.length) ? [...new Set(tempCompany.authority.toUpperCase().split(',').filter((item: any) => item).map((item: any) => item))].join(',') : (
          result.roles ? result.roles.toUpperCase() : 'ROLE_WORKER'
        );
        returnParam.arrRole = GlobalConstants.role.split(',');

        returnParam.resultMe = result;
        return returnParam;
      })
    );
  }

  // getUserProfile(me: MeModel, isWorker: boolean) {
  //   if (GlobalConstants.companyId && GlobalConstants.userId) { // Employee Profile
  //     return this.employeeProfile.getEmployeeProfile(GlobalConstants.companyId, GlobalConstants.userId);
  //   } else {
  //     if (!isWorker) { // Tendang yang Bukan Worker
  //       this.snackBar.open('Ilegal statement status:(Worker not identified'), harap login ulang', 'Tutup', {duration: 4000});
  //       this.logout();
  //     }
  //     return this.workerProfile.getWorkerProfileGOapi(me.ID);
  //   }
  // }

  async getCompanyProfile(isWorker: boolean): Promise<any> {
    const returnParam = {} as CompanyLoginModel;
    returnParam.companyModules = [];
    returnParam.companyStatus = false;
    returnParam.companyRejected = false;
    returnParam.campaign = null;

    if (!GlobalConstants.companyId && !isWorker) { // Tendang yang bukan Worker
      this.snackBar.open('Ilegal statement status:(Empty User ID), harap login ulang', 'Tutup', {duration: 4000});
      this.logout();
      throw 'Not Worker';
    }

    if (GlobalConstants.companyId) {
      // Get Module Company
      try {
        const arg: any = await this.companyHttp.getCompanyByIdGOApi(GlobalConstants.companyId).toPromise();
        const argAttendanceConfig: any = await this.companyConfigHttp.getPremium(GlobalConstants.companyId).toPromise();
        if (arg.careerEnabled) { returnParam.companyModules.push('CAREER'); }
        if (arg.atsEnabled) { returnParam.companyModules.push('ATS'); }
        if (arg.attendanceEnabled) { returnParam.companyModules.push('ATTENDANCE'); }
        if (argAttendanceConfig.data && argAttendanceConfig.data[0].isPremium) { returnParam.companyModules.push('ATTENDANT_PREMIUM'); }
        if (arg.bpoEnabled) { returnParam.companyModules.push('BPO'); }
        if (arg.payrollEnabled) { returnParam.companyModules.push('PAYROLL'); }
        if (arg.payslipManualEnabled) { returnParam.companyModules.push('PAYSLIP'); }
        if (arg.payrollEnabled) { returnParam.companyModules.push('PAYFIN'); }
        if (arg.kasbonEnabled) { returnParam.companyModules.push('BEEVER'); }
        if (arg.kiniEnabled) { returnParam.companyModules.push('KINI'); }
        if (arg.kancilEnabled) { returnParam.companyModules.push('KANCIL'); }
        if (arg.gajiGesaEnabled) { returnParam.companyModules.push('GAJIGESA'); }
        GlobalConstants.approvedCompany = returnParam.companyStatus = (arg.status?.toUpperCase() === 'APPROVED');

        if (!GlobalConstants.approvedCompany && (
            arg.status?.toUpperCase() === 'REJECTED' ||
            arg.status?.toUpperCase() === 'BLACKLISTED'
        )) {
          returnParam.companyRejected = true;
        }
      } catch (error: any) {
        this.openSnackBar(error.status, error);
        throw error;
      }

      if (returnParam.companyRejected) {
        this.customSnackbar(417, 'Maaf perusahaanmu sudah di reject.');
        this.logout();
        throw 'Company Rejected';
      } else {
        if (
          GlobalConstants.role.includes('ROLE_ADMIN') ||
          GlobalConstants.role.includes('ROLE_BUSDEV')
        ) {
          // Jobfair Admin Mode
          returnParam.companyModules.push('JOBFAIR');
        } else {
          // Get Campaign Company
          try {
            const arg: any = await this.campaignHttp.getCampaignGoapi(
              GlobalHelper.generateParams(`companyId=${GlobalConstants.companyId}`, 1)
            ).toPromise();

            returnParam.campaign = arg.data
              .filter((fin: CampaignModel) => (fin.companyId === GlobalConstants.companyId) && (fin.status.toLowerCase() === 'actived'))
              .reduce((prev: CampaignModel, current: CampaignModel) => (prev && prev.id > current.id) ? prev : current);
            if (returnParam.campaign) {              
              returnParam.companyModules.push('JOBFAIR');
              GlobalConstants.campaignId = returnParam.campaign.id;
              GlobalConstants.campaignName = returnParam.campaign.campaignName;
              GlobalConstants.campaignStartDate = returnParam.campaign.startDate;
              GlobalConstants.campaignEndDate = returnParam.campaign.endDate;
              GlobalConstants.campaignStatus = returnParam.campaign.status;
            }
          } catch (error: any) {
            this.openSnackBar(error.status, error);
          }
        }
      }
    }

    return returnParam;
  }

  checkUser(me: MeModel, isWorker: boolean, arrAuthorities: any) {
    if (!me.activated && arrAuthorities.length) { // ! Deprecated kayaknya jika User tidak aktif
      this.permissions.loadPermissions([]);
      GlobalConstants.role = '';

      this.snackBar.open('Maaf, akun kamu belum aktif. Silahkan mengganti password terlebih dahulu!', 'Tutup', {duration: 4000});
      return true;
    }

    if (me.roles?.includes('ROLE_ADMIN')) {
      this.permissions.loadPermissions(GlobalConstants.approvedCompany ?
        GlobalConstants.role.split(',') : (
          GlobalConstants.role.includes('ROLE_OWNER') ||
          GlobalConstants.role.includes('ROLE_HR') ||
          GlobalConstants.role.includes('ROLE_HR_STAFF') ?
          ['ROLE_COMPANY', 'ROLE_ADMIN'] :
          ['ROLE_ADMIN']
        ));
    } else if (isWorker) {
      this.permissions.loadPermissions(GlobalConstants.approvedCompany ?
        GlobalConstants.role.split(',') : (
          GlobalConstants.role.includes('ROLE_OWNER') ||
          GlobalConstants.role.includes('ROLE_HR') ||
          GlobalConstants.role.includes('ROLE_HR_STAFF') ?
          ['ROLE_COMPANY', 'ROLE_WORKER'] :
          ['ROLE_WORKER']
        ));
    } else {
      this.permissions.loadPermissions(GlobalConstants.approvedCompany ?
        GlobalConstants.role.split(',') : (
          GlobalConstants.role.includes('ROLE_OWNER') ||
          GlobalConstants.role.includes('ROLE_HR') ||
          GlobalConstants.role.includes('ROLE_HR_STAFF') ?
          ['ROLE_COMPANY'] :
          []
        ));
    }
    return false;
  }
  // End of Authorization V2
}
