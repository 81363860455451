import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { UploadCompanyDocsModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';

const API_DOCUMENT_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyDocumentHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  // companyDocuments(id: number): Observable<Response> {
  //   return this.http.get<Response>(`/api/_search/company-documents?page=-1&query=companyId:(${id})`, this.config.httpOptionsDefault())
  //     .pipe(catchError(this.config.handleErrorReturnAll));
  // }
  createCompanyDocuments(companyId: number, data: UploadCompanyDocsModel): Observable<Response> {
    return this.http.post<Response>(`${API_DOCUMENT_URL}/company/${companyId}/document`, data, this.config.GHttpOptionsDefault());
  }
  editCompanyDocuments(companyId: number, id: number, data: UploadCompanyDocsModel): Observable<Response> {
    return this.http.put<Response>(`${API_DOCUMENT_URL}/company/${companyId}/document/${id}`, data, this.config.GHttpOptionsDefault());
  }
  deleteCompanyDocuments(companyId: number, id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_DOCUMENT_URL}/company/${companyId}/document/${id}`, this.config.GHttpOptionsDefault());
  }

}
