export class BlacklistKeywordsClass {
    public static words: any = [
        'sex', 'judi', 'bet', 'anal', 'psk', 'homosexual', 'homo', 'lesbi', 'lesbian',
        'perek', 'lonte', 'bdsm', 'lotre', 'kontol', 'asu', 'kirik', 'jancok', 'bangsat',
        'bajingan', 'brengsek', 'threesum', 'three', 'sum', 'gankbang', 'gank', 'bang',
        'fuck', 'sialan', 'hell', 'sial', 'shit', 'tai', 'sampah', 'ass', 'bokong', 'asshole',
        'dick', 'moron', 'bego', 'bastard', 'haram', 'ɑnjir', 'doggy', 'doggie', 'jangkrik',
        'kampret', 'pelakor', 'jalang', 'bitch', 'jablay', 'banci', 'maho', 'gigolo', 'jancuk',
        'cukimay', 'peh', 'coh', 'cioh', 'sundel', 'bedebah', 'bacot', 'bencong', 'bloon', 'blo\'on',
        'oon', 'goblok', 'stupid', 'ladyboy', 'budeg', 'bawel', 'centil', 'caper', 'curut', 'dodol',
        'dekil', 'eneg', 'edan', 'ganjen', 'gila', 'gendeng', 'iblis', 'devil', 'jahanam', 'jayus',
        'jembut', 'kancut', 'sempak', 'kampungan', 'katrok', 'kafir', 'kere', 'dungu', 'keledai',
        'kecepirit', 'kecirit', 'lebay', 'laknat', 'monyong', 'matamu', 'memek', 'mesum', 'ngentot',
        'kentot', 'ngentod', 'kentod', 'entot', 'entod', 'nyolot', 'norak', 'pelacur', 'bitchy',
        'callgirl', 'prostitusi', 'prostitute', 'pantat', 'sarap', 'sinting', 'kampes', 'setan',
        'taik', 'taek', 'toket', 'busuk', 'ancok', 'ancuk', 'picek', 'micek', 'peler', 'pentil',
        'pentel', 'montok', 'pukimai', 'anying', 'peli', 'asem', 'kecut', 'bokep', 'kimpet', 'porn',
        'bf', 'pornstar', 'pornhub', 'boobs', 'sexstar', 'milf', 'cebok', 'puki', 'bodoh', 'tolol',
        'babi', 'anjing', 'xnxx', 'xxx', 'porno', 'pukimak', 'kamfret', 'seks', 'ah', 'aah', 'aaah',
        'aaaah', 'aaaaah', 'aa', 'aaa', 'aaaa', 'aaaaa', 'aaaaaa'
    ];
}
