export class EnumEmploymentType {
    public static employmentTypeSelect: any = [
        { name: 'Full Time', enum: 'full_time' },
        { name: 'Part Time', enum: 'part_time' },
        { name: 'Freelance', enum: 'freelance' },
    ];

    public static employmentType: any = [
        { name: 'Full Time', enum: 'full_time' },
        { name: 'Part Time', enum: 'part_time' },
        { name: 'Freelance', enum: 'freelance' },
        { name: 'Full Time', enum: 'emp_fulltime' }, // Deprecated Goapi
        { name: 'Part Time', enum: 'emp_parttime' }, // Deprecated Goapi
        { name: 'Freelance', enum: 'emp_contractor' }, // Deprecated Goapi
    ];

    public static generateEmployment(employement: string): string {
        const tempEmployment = EnumEmploymentType.employmentType.find((fin: any) => fin.enum === employement.trim().toLowerCase());
        return (tempEmployment) ? tempEmployment.name : '-';
    }

    public static findEmployment(employment: string, postMode?: boolean): any {
        if (postMode) {
            return EnumEmploymentType.employmentTypeSelect.find((fin: any) => fin.enum === employment.trim().toLowerCase());
        }
        return EnumEmploymentType.employmentType.find((fin: any) => fin.enum === employment.trim().toLowerCase());
    }
}
