<span
  mat-dialog-title
  class="font-size-h2 font-weight-bolder">
  {{(data && data.title) ? data.title : 'Reject Reason'}}
</span>

<div
  mat-dialog-content
  class="d-flex flex-column">

  <!-- begin::Description -->
  <span
    *ngIf="(data && data.description)"
    class="mb-4">
    {{data.description}}
  </span>
  <!-- end::Description -->

  <!-- begin::Form -->
  <form
    class="row"
    [formGroup]="formDialog"
    (ngSubmit)="submitReason()">

    <div class="col-12 form-group">
      <ng-container *ngIf="(data && data.inputType !== 'password') else Password">
        <input class="form-control"
          [formControlName]="(data && data.inputType) ? data.inputType : 'reason'"
          [type]="(data && data.inputType) ? data.inputType : 'text'"
          [placeholder]="(data && data.placeholder) ? data.placeholder : ''"
          [class.is-invalid]="form(data.inputType ? data.inputType : 'reason')"/>
      </ng-container>
      <ng-template #Password>
        <div class="input-icon input-icon-right">
          <input
            class="form-control h-auto"
            formControlName="password"
            placeholder="Password"
            [type]="hide ? 'password' : 'text'"/>
          <span
            (click)="hide = !hide"
            role="button">
            <i class="icon-md"
               [ngClass]="hide ? 'flaticon-eye' : 'flaticon-visible'">
            </i>
          </span>
        </div>
      </ng-template>
      <span
        *ngIf="form((data && data.inputType) ? data.inputType : 'reason')"
        class="form-text text-danger font-size-sm">
        {{ generateMessage((data && data.inputType) ? data.inputType : 'reason') }}
      </span>
    </div>

    <!-- begin::Password Confirmation -->
    <div
      *ngIf="(data && data.confirmation)"
      class="col-12 form-group">
      <ng-container *ngIf="(data && data.inputType !== 'password') else ConfirmPassword">
        <input class="form-control"
          [formControlName]="(data && data.inputType) ? 'confirm' + (data.inputType | titlecase) : 'reason'"
          [type]="(data && data.inputType) ? data.inputType : 'text'"
          [placeholder]="(data && data.placeholder) ? (data.placeholder).replace('Ketikkan', 'Konfirmasi') : ''"
          [class.is-invalid]="form(data.inputType ? 'confirm' + (data.inputType | titlecase) : 'reason')"/>
      </ng-container>
      <ng-template #ConfirmPassword>
        <div class="input-icon input-icon-right">
          <input
            class="form-control h-auto"
            formControlName="confirmPassword"
            placeholder="Konfirmasi Password"
            [type]="hideConfirm ? 'password' : 'text'"/>
          <span
            (click)="hideConfirm = !hideConfirm"
            role="button">
            <i class="icon-md"
               [ngClass]="hideConfirm ? 'flaticon-eye' : 'flaticon-visible'">
            </i>
          </span>
        </div>
      </ng-template>
      <span
        *ngIf="
          form((data && data.inputType) ?
            'confirm' + (data.inputType | titlecase) :
            'reason')
        "
        class="form-text text-danger font-size-sm"
        >
        {{ generateMessage(
            (data && data.inputType) ?
              'confirm' + (data.inputType | titlecase) :
              'reason') }}
      </span>
    </div>
    <!-- end::Password Confirmation -->
  </form>
  <!-- end::Form -->

  <!-- begin::Notes -->
  <p *ngIf="data.notes">{{data.notes}}</p>
  <!-- end::Notes -->
</div>

<div mat-dialog-actions class="d-flex justify-content-between">
  <button
    class="btn btn-sm btn-outline-secondary px-5"
    (click)="modal.close()">
    {{data.cancelButton ? data.cancelButton : 'Kembali'}}
  </button>

  <button
    class="btn btn-sm btn-primary px-5 mr-3"
    (click)="submitReason()">
    {{data.submitButton ? data.submitButton : 'Submit'}}
  </button>
</div>