export class EnumEmployeeDegree {

    public static employeeDegreeSelectOld: any = [ //kayaknya bakal dihapus
        { name: 'SD', enum: 'SD' },
        { name: 'SMP', enum: 'SMP' },
        { name: 'SMA / Sederajat', enum: 'SMA / Sederajat' },
        { name: 'D3', enum: 'D3' },
        { name: 'S1', enum: 'S1' },
        { name: 'S2', enum: 'S2' },
        { name: 'S3', enum: 'S3' },
    ];

    public static employeeDegreeSelect: any = [
        { name: 'SD', enum: 'SD' },
        { name: 'SMP', enum: 'SMP' },
        { name: 'SMA', enum: 'SMA' },
        { name: 'SMK', enum: 'SMK' },
        { name: 'D1', enum: 'D1' },
        { name: 'D2', enum: 'D2' },
        { name: 'D3', enum: 'D3' },
        { name: 'D4', enum: 'D4' },
        { name: 'S1', enum: 'S1' },
        { name: 'S2', enum: 'S2' },
        { name: 'S3', enum: 'S3' }
    ];

}