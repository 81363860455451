export interface FilterModel {
    query: string,
    page: number,
    size?: number,
    sort?: string,
    asc?: boolean
}

export class GlobalConstants {
    public static userId = NaN;
    public static userLogin = '';
    public static userName = '';
    public static userEmail = '';
    public static userPhone = '';
    public static role = '';
    public static accessApple = false;
    public static accessFacebook = false;
    public static accessGoogle = false;
    public static companyId = NaN;
    public static companyIdPickedbyAdmin = NaN;
    public static companyModule = '';
    public static companyName = '';
    public static approvedCompany = false;
    public static campaignId = NaN;
    public static campaignIdPickedbyAdmin = NaN;
    public static campaignName = '';
    public static campaignStartDate = '';
    public static campaignEndDate = '';
    public static campaignStatus = '';
}

export class GlobalHelper {

    static generateParams(
        query: string,
        page: number,
        size?: number,
        sort?: string,
        asc?: boolean
    ): FilterModel {
        return {
            query: query ? query + '&' : '',
            page: (page - 1),
            size: size,
            sort: sort,
            asc: asc
        }
    }

}