import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, delay, filter, map, retryWhen, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PayrollDetailService } from '../layouts/main/pages/payroll';
import { HttpConfigService } from '.';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  // source: https://blog.briebug.com/blog/making-use-of-websockets-in-angular

  connection$!: WebSocketSubject<any> | null;
  isLoading$: Observable<boolean>;
  isLoadingSend$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoadingSendSubject: BehaviorSubject<boolean>;

  RETRY_SECONDS = 5000;

  constructor(
    private config: HttpConfigService,
    private payrollService: PayrollDetailService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoadingSendSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingSend$ = this.isLoadingSendSubject.asObservable();
  }

  // * Connect to WebSocket
  connect(companyId: number, payrollId: number): Observable<any> {
    this.isLoadingSubject.next(true);
    return of(`${environment.websocketUrl}/company/${companyId}/payroll/${payrollId}/upload?${environment.production ? this.config.WebSocketPathParams() : this.config.WebSocketPathParamsXGandalf()}`).pipe(
      filter(apiUrl => !!apiUrl),
      // https becomes wws, http becomes ws
      map((apiUrl: string) => apiUrl.replace(/^http/, 'ws')),
      switchMap((wsUrl: string) => {
        // console.log(wsUrl, this.connection$);

        if (this.connection$) {
          this.isLoadingSubject.next(false);
          return this.connection$;
        } else {
          this.isLoadingSubject.next(false);
          this.connection$ = webSocket(wsUrl);
          return this.connection$;
        }
      }),
      catchError(this.config.handleErrorReturnAll),
      retryWhen((errors) => errors.pipe(delay(this.RETRY_SECONDS)))
    );
  }

  // * Send Data
  send(data: any): string | null {
    let message = null;
    if (this.connection$) {
      this.isLoadingSendSubject.next(true);
      this.connection$.next({items: data});
    } else {
      message = 'Did not send data, open a connection first';
    }
    return message;
  }
  ping(): string | null {
    let message = null;
    if (this.connection$) {
      this.isLoadingSendSubject.next(true);
      this.connection$.next({ping: 'hallo'});
    } else {
      message = 'Did not send ping, open a connection first';
    }
    return message;
  }

  // * Close Connection
  closeConnection(): void {
    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
      this.payrollService.isLoadingUploadSubject.next(false);
    }
  }
}
