import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { HttpConfigService } from 'src/app/_service';
import { ResponseCompanyAttendance, ResponseCompanyAttendancePremium } from '..';
import { CompanyAttendanceHTTPService } from './company-http';

@Injectable({
  providedIn: 'root'
})
export class CompanyAttendanceService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private companyAttendanceHttp: CompanyAttendanceHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // public method
  getAttendanceConfig(companyId: number): Observable<ResponseCompanyAttendance> {
    this.isLoadingSubject.next(true);
    return this.companyAttendanceHttp.getConfig(companyId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  putAttendanceConfig(companyId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyAttendanceHttp.putConfig(companyId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  getAttendancePremium(companyId: number): Observable<ResponseCompanyAttendancePremium> {
    this.isLoadingSubject.next(true);
    return this.companyAttendanceHttp.getPremium(companyId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  patchPremium(companyId: number, isPremium: boolean): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyAttendanceHttp.patchPremium(companyId, isPremium).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  putAttendancePremium(companyId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyAttendanceHttp.putPremium(companyId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
}
