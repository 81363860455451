import { AgmCoreModule } from '@agm/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxPermissionsModule } from 'ngx-permissions';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import localeId from '@angular/common/locales/id';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { ErrorHandlingService } from './_service';

import { environment } from 'src/environments/environment';
import { httpInterceptorProviders } from './interceptor';

registerLocaleData(localeId, 'id');


export const options: any | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    AgmCoreModule.forRoot(
      {
        apiKey: environment.agm,
        libraries: ['places'],
        region: 'ID',
        language: 'id'
      }
    ),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    // CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
    MatSnackBarModule,
    NgxMaskModule.forRoot(options),
    NgxPermissionsModule.forRoot(),
    SharedModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'id-ID' },
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    httpInterceptorProviders
  ]
})
export class AppModule { }
