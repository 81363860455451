/* tslint:disable:no-non-null-assertion */
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { FilterModel, GlobalConstants } from 'src/app/_class';
import { AuthService, FormService } from 'src/app/_service';
import { AuthModelGoapi } from 'src/app/layouts/auth';
import { EnumPayrollStatus } from '../enums/payroll-status.enum';
// import { PayrolItemModel } from '../models/payroll-item.model';
import { PayrollDetailService/* , ResponseItemType */ } from '../services/payroll-detail.service';

import { PayrollClass } from './payroll.class';

import { DialogReasonData, MaterialAdvanceDialogComponent } from 'src/app/shared/_material-component/material-advance-dialog/material-advance-dialog.component';
import { DialogData, MaterialDialogComponent } from 'src/app/shared/_material-component/material-dialog/material-dialog.component';

@Injectable()
export class PayrollItemClass implements OnDestroy {

  currentDeleteId$: Observable<number[]>;
  currentChecked$: Observable<boolean>;
  currentDownloadId$: Observable<number[]>;
  currentIdSubject: BehaviorSubject<number>;
  currentDeleteIdSubject: BehaviorSubject<number[]>;
  currentCheckedSubject: BehaviorSubject<boolean>;
  currentDownloadIdSubject: BehaviorSubject<number[]>;

  subscription: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private formService: FormService,
    private payrollClass: PayrollClass,
    private payrollDetailService: PayrollDetailService,
  ) {
    this.currentIdSubject = new BehaviorSubject<number>(NaN);
    this.currentDeleteIdSubject = new BehaviorSubject<number[]>([]);
    this.currentCheckedSubject = new BehaviorSubject<boolean>(false);
    this.currentDownloadIdSubject = new BehaviorSubject<number[]>([]);
    this.currentDeleteId$ = this.currentDeleteIdSubject.asObservable();
    this.currentChecked$ = this.currentCheckedSubject.asObservable();
    this.currentDownloadId$ = this.currentDownloadIdSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sb => sb.unsubscribe());
  }

  // Change Status
  changeStatus(
    id: number,
    status: 'request_approval' |
            'manager_rejected' |
            'manager_approved' |
            'finance_rejected' |
            'finance_approved' |
            'draft' |
            'soft_deleted' |
            'processed' |
            'completed_skip_disbursement',
    currentStatus: string,
    filter: FilterModel,
    fromMaster?: boolean
  ): void {
    if (!fromMaster) {
      const listItem = this.payrollDetailService.currentPayrollItemSubject.value;

      if (status === 'request_approval' && (!listItem?.payrollItem || !listItem.payrollItem.length)) {
        return this.payrollDetailService.openCustomBar(417, 'Payroll belum bisa diajukan karena data penggajian masih kosong.');
      }
    }

    if (status.includes('reject') || status.includes('soft_deleted') || status.includes('processed') || status.includes('completed_skip_disbursement')) {
      return this.openReasonModal(id, status, filter, fromMaster);
    }

    const statusShowed = EnumPayrollStatus.generatePayrollStatus(currentStatus);
    const title = EnumPayrollStatus.generatePayrollTitle(status);
    const message = EnumPayrollStatus.generatePayrollMessage(status);
    const body = `Payroll yang diajukan akan ${message} dari status ${statusShowed.toLowerCase()}.${message === 'konsep' ? ' Pastikan data telah terisi dengan benar sebelum mengajukan.' : ' Apakah Kamu yakin ingin melanjutkan?'}`;
    const submitText = EnumPayrollStatus.generatePayrollButton(status);

    const dialogData: DialogData = {} as DialogData;
    dialogData.title = title;
    dialogData.body = body;
    dialogData.labelReject = 'Kembali';
    dialogData.labelConfirm = submitText;

    const dialogRef = this.dialog.open(MaterialDialogComponent, {
      width: '400px',
      data: dialogData,
      // panelClass: 'dialog'
    });

    const dialogSbcr = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.patchPayroll(id, { status }, filter, fromMaster);
        }
      });
    this.subscription.push(dialogSbcr);
  }

  openReasonModal(
    id: number,
    status: string,
    filter: FilterModel,
    fromMaster?: boolean
  ): void {
    const title = EnumPayrollStatus.generatePayrollTitle(status);
    const description = status.includes('reject') ?
      'Apakah Kamu yakin ingin menolak payroll ini? Silahkan masukkan alasan penolakan dibawah ini.' : (
      status.includes('soft_deleted') ?
        'Apakah Kamu yakin ingin membatalkan payroll ini? Silahkan masukkan alasan pembatalan dibawah ini.' : (
        status.includes('processed') ? 'Silahkan masukkan password akunmu untuk melakukan disburse payroll ini.' : 'Payroll akan diproses dan diterbitkan hanya sebagai payslip tanpa men-disburse dana apapun. Silahkan masukkan password akunmu untuk melanjutkan.'
      )
    );
    const placeholder = status.includes('reject') ? 'Masukkan alasan penolakanmu' : (
      status.includes('soft_deleted') ? 'Masukkan alasan pembatalanmu' : ''
    );
    const submitText = EnumPayrollStatus.generatePayrollButton(status);

    const tempData: DialogReasonData = {} as DialogReasonData;
    tempData.title = title;
    tempData.description = description;
    tempData.placeholder = placeholder;
    if (!status.includes('reject') && !status.includes('soft_deleted')) {
      tempData.inputType = 'password';
    }
    tempData.submitButton = submitText;

    const dialogRef = this.dialog.open(MaterialAdvanceDialogComponent, {
      width: '400px',
      data: tempData
      // panelClass: 'dialog'
    });

    const dialogSbcr = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (status.includes('reject') || status.includes('soft_deleted')) {
          this.patchPayroll(id, { status, reason: result.reason }, filter, fromMaster);
          return;
        }

        const rawPayload: AuthModelGoapi = {
          username: GlobalConstants.userEmail,
          password: result.password,
          rememberMe: 'false'
        };

        const publishSbcr = this.authService.GLoginForm(rawPayload).subscribe(() => {
          this.patchPayroll(id, { status }, filter, fromMaster);
        }, error => {
          if (error.status === 401 || error.status === 400) {
            this.authService.customSnackbar(417, 'Email atau Password Salah!');
            return;
          }
          this.authService.openSnackBar(error.status, error);
        });
        this.subscription.push(publishSbcr);
      }
    });
    this.subscription.push(dialogSbcr);
  }

  patchPayroll(
    id: number,
    payload: {status: string, reason?: string},
    filter: FilterModel,
    fromMaster?: boolean
  ): void {
    const patchSbcr = this.payrollDetailService.patchPayroll(GlobalConstants.companyId, id, payload, filter, fromMaster)
      .subscribe(() => {
        if (payload.status === 'processed') {
          this.payrollClass.getCompanyBalance();
        }
      }, error => {
        if (error.error.error.toLowerCase() === 'insufficient balance') {
          this.payrollDetailService.openCustomBar(417, 'Saldo midtrans tidak mencukupi!');
          return;
        }
        this.payrollDetailService.openSnackBar(error.status, error);
      });
    this.subscription.push(patchSbcr);
  }
  // End of Change Status

  // Delete Method
  deleteConfirmationSingle(id: number, fullname: string, filter: FilterModel): void {
    const deletedIds: number[] = this.currentDeleteIdSubject.value;
    deletedIds.push(id);
    this.currentDeleteIdSubject.next(deletedIds);

    const dialogData: DialogData = {} as DialogData;
    dialogData.title = `Kamu yakin ingin menghapus gaji?`;
    dialogData.body = `Gaji ${fullname ? 'a.n. ' + fullname + ' ' : ''}yang telah dimasukkan akan terhapus.`;
    dialogData.labelConfirm = 'Hapus';
    dialogData.labelReject = 'Batal';

    const dialogRef = this.dialog.open(MaterialDialogComponent, {
      width: '400px',
      data: dialogData
    });
    const dialogSbcr = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          return this.deletePayrollItem(filter);
        }

        this.resetCheckBox();
      });
    this.subscription.push(dialogSbcr);
  }

  deleteConfirmationMulti(filter: FilterModel): void {
    const deletedIds: number[] = this.currentDeleteIdSubject.value;
    if (deletedIds.length === 0) {
      this.snackBar.open('Belum ada gaji yang dicentang', 'Tutup', { duration: 4000 });
      return;
    }

    const dialogData: DialogData = {} as DialogData;
    dialogData.title = `Kamu yakin ingin menghapus gaji?`;
    dialogData.body = `Gaji yang telah kamu centang akan terhapus.`;
    dialogData.labelConfirm = 'Hapus';
    dialogData.labelReject = 'Batal';

    const dialogRef = this.dialog.open(MaterialDialogComponent, {
      width: '400px',
      data: dialogData
    });
    const dialogSbcr = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          return this.deletePayrollItem(filter);
        }

        this.resetCheckBox();
      });
    this.subscription.push(dialogSbcr);
  }

  deletePayrollItem(filter: FilterModel): void {

    // Debugging Purpose
    // console.log(this.currentIdSubject.value, this.currentDeleteIdSubject.value);    
    // return;

    const deleteSbcr = this.payrollDetailService.deletePayrollItem(
      GlobalConstants.companyId,
      this.currentIdSubject.value,
      this.currentDeleteIdSubject.value,
      filter
    )
      .subscribe(() => {
        this.payrollDetailService.openCustomBar(200, 'Sukses menghapus gaji dipilih!');
        this.currentCheckedSubject.next(false);
        this.currentDeleteIdSubject.next([]);
      }, error => {
        this.payrollDetailService.openSnackBar(error.status, error);
      });
    this.subscription.push(deleteSbcr);
  }
  // End of Delete Method

  // Begin Percheckbox an
  getCheckbox(id: number, isChecked: boolean): void {
    const arrId: number[] = this.formService.getCheckbox(this.currentDeleteIdSubject.value, id, isChecked);
    this.currentDeleteIdSubject.next(arrId);
  }
  getCheckboxAll(): void {
    const listItem = this.payrollDetailService.currentPayrollItemSubject.value;

    const arrId: number[] = this.formService.getCheckboxAll(
      this.currentDeleteIdSubject.value,
      listItem?.payrollItem,
      this.currentCheckedSubject.value
    );

    this.currentDeleteIdSubject.next(arrId);
  }
  resetCheckBox(): void {
    this.currentCheckedSubject.next(true);
    setTimeout(() => {
      this.currentCheckedSubject.next(false);
      this.getCheckboxAll();
    }, 50);
  }
  // End Percheckbox an

  // Begin Download Payslip
  downloadPayslipZip(payrollId: number, date: string): void {
    const payslipSbcr = this.payrollDetailService.downloadPayslipZip(
      GlobalConstants.companyId,
      payrollId
    )
      .subscribe((response: any) => {
        // File Reader
        const blob = new Blob([response.body], { type: 'application/zip' });
        saveAs(blob, `Payslip-${GlobalConstants.companyName}-${date}.zip`);

      }, error => {
        this.payrollDetailService.openSnackBar(error.status, error);
      });
    this.subscription.push(payslipSbcr);
  }
  
  downloadPayslipSingle(payrollId: number, userId: number, fullName: string): void {
    const downloadId: number[] = this.currentDownloadIdSubject.value;
    downloadId.push(userId);
    this.currentDownloadIdSubject.next(downloadId);
    this.downloadPayslip(payrollId, [fullName]);
  }

  // downloadPayslipMulti(): void {
  //   const payroll: ResponseItemType = this.payrollDetailService.currentPayrollItemSubject.value;

  //   if (!payroll?.payrollItem || !payroll.payrollItem.length) {
  //     this.snackBar.open('Payslip kosong.', 'Tutup', { duration: 4000 });
  //     return;
  //   }

  //   const filteredPayroll: PayrolItemModel = Object.assign({}, payroll);
  //   filteredPayroll.payrollItem = filteredPayroll.payrollItem!.filter((item) => item.status.toUpperCase() === 'COMPLETED');

  //   const downloadId: number[] = [];
  //   const fullName: string[] = [];

  //   filteredPayroll.payrollItem.forEach(element => {
  //     downloadId.push(element.userId);
  //     fullName.push(element.fullName);
  //   });

  //   this.currentDownloadIdSubject.next(downloadId);
  //   this.downloadPayslip(this.currentIdSubject.value, fullName);
  // }

  private downloadPayslip(payrollId: number, fullName: string[]): void {
    let id = 0;

    const payslipSbcr = this.payrollDetailService.downloadPayslip(
      GlobalConstants.companyId,
      this.currentDownloadIdSubject.value,
      payrollId
    )
      .subscribe((response: any) => {
        // File Reader
        const blob = new Blob([response.body], { type: 'application/pdf' });
        saveAs(blob, `Payslip-${fullName[id]}.pdf`);

        if (id === (this.currentDownloadIdSubject.value.length - 1)) {
          this.currentDownloadIdSubject.next([]);
        }

        id++;
      }, error => {
        if (id === (this.currentDownloadIdSubject.value.length - 1)) {
          this.currentDownloadIdSubject.next([]);
        }

        id++;
        this.payrollDetailService.openSnackBar(error.status, error);
      });
    this.subscription.push(payslipSbcr);
  }
  // End of Begin Download Payslip
}
