export class EnumClientStatus {
    public static companyStatusSelect: any = [
        { name: 'Open', enum: 'open' },
        { name: 'Closed', enum: 'close' },
    ];

    public static findClientStatus(status: string): any {
        return EnumClientStatus.companyStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
    }
}
