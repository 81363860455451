import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { CompanyDocumentHTTPService } from './company-http';

@Injectable({
  providedIn: 'root'
})
export class CompanyDocumentService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private companyDocsHttp: CompanyDocumentHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  // getCompanyDocs(companyId: number): Observable<Response> {
  //   this.isLoadingSubject.next(true);
  //   return this.companyDocsHttp.companyDocuments(companyId).pipe(
  //     catchError(this.config.handleErrorReturnAll),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  postCompanyDocs(companyId: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyDocsHttp.createCompanyDocuments(companyId, data).pipe(
      catchError(this.config.handleErrorReturnAll),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  putCompanyDocs(companyId: number, id: number, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyDocsHttp.editCompanyDocuments(companyId, id, data).pipe(
      catchError(this.config.handleErrorReturnAll),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  deleteCompanyDocs(userId: number, id: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.companyDocsHttp.deleteCompanyDocuments(userId, id).pipe(
      catchError(this.config.handleErrorReturnAll),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before hit Api
}
