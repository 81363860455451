/* tslint:disable:no-non-null-assertion */
import { EnumIndustry, ClientDataModel } from '..';

export interface PostCompanyModel {
    name: string;
    city: string | null;
    industry: string;
    companyID: number;
    imageUrl: string | null;
}

export class PostCompanyClass {

  static objModel = ['name', 'city', 'industry', 'companyID', 'imageUrl'];

  public static companyInfoValidation(payload: PostCompanyModel, isEdit?: boolean): string | null {
      const objPayload = Object.keys(payload);
      const objInvalid: string[] = [];


      // ? Validasi Object Key Payload
      objPayload.forEach((element) => {
          if (!PostCompanyClass.objModel.includes(element)) { objInvalid.push(element); }
      });
      if (objInvalid.length) { return objInvalid.toString() + ' is invalid payload'; }


      // ? Validasi Mandatory Payload
      if (!payload.industry || !payload.name || !payload.city || !payload.companyID || !payload.imageUrl) {
          return 'empty mandatory payload detected';
      }


      // ? Validasi Regex Payload
      const industryChecker = EnumIndustry.findIndustry(payload.industry!);
      if (!industryChecker) { return 'industry invalid'; }

      return null;
  }

  public static formatCompany(company: ClientDataModel): PostCompanyModel {
      const payloadCompany = {} as PostCompanyModel;

      payloadCompany.companyID = company.companyID;
      payloadCompany.imageUrl = company.imageUrl;
      payloadCompany.name = company.name;
      payloadCompany.city = company.city;
      payloadCompany.industry = company.industry;

      return payloadCompany;
  }
}
