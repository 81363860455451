<strong *ngIf="data.title" mat-dialog-title class="font-size-h2">{{data.title}}</strong>
<div *ngIf="data.body" mat-dialog-content class="mb-2">
  <p [innerHtml]="data.body"></p>
  <div *ngIf="data.notes" class="d-flex flex-column rounded-lg bg-light fs-16 p-5">
    <span>Notes :</span>
    <p [innerHtml]="data.notes" class="mb-0"></p>
  </div>
</div>
<div mat-dialog-actions
     class="d-flex justify-content-end =pt-4">
  <!-- begin::Btn Batal -->
  <app-bootstrap-button *ngIf="!data.hideReject"
    [additionalClass]="'text-primary px-10'"
    [label]="data.labelReject ? data.labelReject : 'Tidak'"
    (onClick)="dialog.close()"
  ></app-bootstrap-button>
  <!-- end::Btn Batal -->

  <!-- begin::Btn Simpan -->
  <app-bootstrap-button
    [color]="'primary'"
    [additionalClass]="'px-10'"
    [label]="data.labelConfirm ? data.labelConfirm : 'Ya'"
    (onClick)="dialog.close(true)"
  ></app-bootstrap-button>
  <!-- end::Btn Simpan -->
</div>