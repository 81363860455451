export class EnumTransportation {
    public static transportationSelect: any = [
        { name: 'Mobil', enum: 'MOBIL' },
        { name: 'Motor', enum: 'MOTOR' },
        // { name: 'Mobil Barang', enum: 'PICKUP' },
        // { name: 'Truck', enum: 'TRUCK' },
    ];

    public static findTransportation(transportation: string): any {
        return EnumTransportation.transportationSelect.find((fin: any) => fin.enum === transportation.trim().toUpperCase());
    }
}
