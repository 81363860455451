/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ApiKeyInterceptor } from './api-key.interceptor';
import { ErrorHandlingInterceptor } from './error-handling.interceptor';
import { RemoteLoggingInterceptor } from './remote-logging.interceptor';
import { UrlCheckersInterceptor } from './url-checkers.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiKeyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UrlCheckersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RemoteLoggingInterceptor, multi: true },
];
