export class EnumEmployeeRoles {
    public static employeeRolesSelect: any = [
        { name: 'Owner', enum: 'role_owner' },
        { name: 'Manager', enum: 'role_manager' },
        { name: 'HRD', enum: 'role_hr' },
        { name: 'Recruiter', enum: 'role_hr_staff' },
        { name: 'Head Hunter', enum: 'role_headhunter' },
        { name: 'Finance', enum: 'role_finance' },
        { name: 'Staff', enum: 'role_employee' },
    ];

    public static generateEmployeeRoles(role: string): any {
        const tempRole = EnumEmployeeRoles.employeeRolesSelect.find((fin: any) => fin.enum === role.trim().toLowerCase());
        return (tempRole && tempRole.name) ? tempRole.name : 'Unkown';
    }
}
