import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';

import {SessionStorage} from 'src/app/_class';
import {FormService, TagsService} from 'src/app/_service';
import {CompanyDataModel, IndexCompanyVariables, CompanyService} from '..';

@Injectable()
export class CompanyClass {

  companyReady$: Observable<boolean>;
  currentCompany$: Observable<CompanyDataModel | undefined>;
  currentCompanyList$: Observable<CompanyDataModel[] | undefined>;
  companyReadySubject: BehaviorSubject<boolean>;
  currentCompanySubject: BehaviorSubject<CompanyDataModel | undefined>;
  currentCompanyListSubject: BehaviorSubject<CompanyDataModel[] | undefined>;

  constructor(
    private companyService: CompanyService,
    private formService: FormService,
    private tagsService: TagsService,
  ) {
    this.companyReadySubject = new BehaviorSubject<boolean>(false);
    this.currentCompanySubject = new BehaviorSubject<CompanyDataModel | undefined>(undefined);
    this.currentCompanyListSubject = new BehaviorSubject<CompanyDataModel[] | undefined>(undefined);
    this.companyReady$ = this.companyReadySubject.asObservable();
    this.currentCompany$ = this.currentCompanySubject.asObservable();
    this.currentCompanyList$ = this.currentCompanyListSubject.asObservable();
  }

  // Variables Initial
  initVariable(): IndexCompanyVariables {
    const varModel = {} as IndexCompanyVariables;

    const Session = SessionStorage.getStorageJson('companyTags');

    const tempFreeText = this.formService.filterSession(Session, 'freetext');
    const tempScale = this.formService.filterSession(Session, 'scale');
    const tempStatus = this.formService.filterSession(Session, 'company_status');
    const tempVerified = this.formService.filterSession(Session, 'company_verified');
    // const tempType = this.formService.filterSession(Session, 'type');
    // const tempDate = this.formService.filterSession(Session, 'date');
    const tempIndustry = this.formService.filterSession(Session, 'industry');
    const tempCity = this.formService.filterSession(Session, 'city');

    varModel.tempQuery = SessionStorage.getStorage('companyQuery');
    varModel.companyPick = SessionStorage.getStorage('companySelected');
    varModel.arrFreeText = (tempFreeText.length) ? tempFreeText[0].value : [];
    varModel.scalePick = (tempScale.length) ? tempScale[0].value : null;
    // this.typePick.setValue((tempType.length) ? tempType[0].value : null);
    varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    varModel.verifiedPick = (tempVerified.length) ? tempVerified[0].value : null;
    // this.datePick.controls.begin.setValue((tempDate.length) ? tempDate[0].value.begin : null);
    // this.datePick.controls.end.setValue((tempDate.length) ? tempDate[0].value.end : null);
    varModel.industriesPick = (tempIndustry.length) ? tempIndustry[0].value : null;
    varModel.cityPick = (tempCity.length) ? tempCity[0].value : null;
    varModel.page = SessionStorage.getStorageNumber('companyPageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('companyPageSize', 20);
    varModel.sortBy = SessionStorage.getStorage('companySortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('companySortAsc', 'true');
    varModel.tags = Session ? Session : [];

    return varModel;
  }

  // End of Variables Initial


  // Filter Logic
  filterService(
    companyPick: string,
    freeText: FormControl,
    arrFreeText: FormControl,
    scalePick: FormControl,
    statusPick: FormControl,
    verifiedPick: FormControl,
    industriesPick: FormControl,
    cityPick: FormControl,
    tags: { key: string, value: string }[]
  ): string {
    if (freeText.value || arrFreeText.value.length) {
      const tempFreeText = freeText.value ? freeText.value.split(',') : [];
      const tempArrFreeText = arrFreeText.value;
      if (tempFreeText.length) {
        tempFreeText.forEach((element: any) => {
          tempArrFreeText.push(element.replace('&', '%26'));
        });
      }
      arrFreeText.setValue(tempArrFreeText);
      tags = this.tagsService.updateKey(tags, 'freetext', arrFreeText, 'array');
      freeText.reset();
    }
    if (scalePick.value) {
      tags = this.tagsService.updateKey(tags, 'scale', scalePick);
    }
    if (statusPick.value) {
      tags = this.tagsService.updateKey(tags, 'company_status', statusPick);
    }
    if (verifiedPick.value) {
      tags = this.tagsService.updateKey(tags, 'company_verified', verifiedPick);
    }
    if (industriesPick.value) {
      tags = this.tagsService.updateKey(tags, 'industry', industriesPick, 'array');
    }
    if (cityPick.value) {
      tags = this.tagsService.updateKey(tags, 'city', cityPick, 'array');
    }

    // if (typePick.value) {
    //   remove({key: 'type'});
    //   tags.push({key: 'type', value: typePick.value});
    // }
    // if (datePick.value.begin) {
    //   if (!datePick.value.end) return;
    //   remove({key: 'date'});
    //   tags.push({key: 'date', value: datePick.value});
    // }
    // if (registeredViaWebPick) {
    //   sessionStorage.setItem('companyRegiteredViaWeb', registeredViaWebPick);
    // }
    // if (lastLogin) {
    //   lastLoginPick = moment(lastLogin).format('YYYY-MM-DD');
    //   sessionStorage.setItem('companyLastLogin', lastLoginPick);
    // }

    const company = (companyPick && companyPick !== 'ignore') ? `name=${companyPick}` : null;
    const freetext = arrFreeText.value.length ? `name=${arrFreeText.value.join(',')}` : null;
    const scale = scalePick.value ? `scale=${scalePick.value}` : null;
    // const typePick = typePick.value ? `createdBy=${typePick.value}` : null;
    const status = statusPick.value ? `status=${statusPick.value}` : null;
    const verified = (verifiedPick.value !== null) ? `is_verified=${verifiedPick.value}` : null;
    const city = cityPick.value ? `address_city=${cityPick.value.join(',')}` : null;
    const industry = industriesPick.value ? `industry=${industriesPick.value.join(',')}` : null;
    // const createDate = datePick.value.end ?
    // `createdDate:(>=${moment(datePick.value.begin).format('YYYY-MM-DD')} AND <=${moment(datePick.value.end).format('YYYY-MM-DD')})` :
    // null;
    // const registeredViaWebPick = registeredViaWebPick ? `registeredViaWeb:(${registeredViaWebPick})` : null;
    // const referralCode = (event && event.referrerCode) ? `referrerCode:(${event.referrerCode})` : null;
    // const lastLogin = lastLoginPick ? `lastLogin:(<=${lastLoginPick})` : null;

    // console.log(scrapping);

    const data = [
      freetext, company, scale, status, verified, city, industry
    ];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('companyQuery', tempQuery);
    sessionStorage.setItem('companyTags', JSON.stringify(tags));
    return tempQuery;
  }

  eventFilter(event?: any, resetFilter?: boolean): string {
    let companyPick = '';

    if (event.id) {
      companyPick = event.name;
      sessionStorage.setItem('companySelected', companyPick.replace('&', '%26'));
    } else { // Reset Filter
      if (!resetFilter) {
        sessionStorage.removeItem('companySelected');
        sessionStorage.removeItem('companySortBy');
        sessionStorage.removeItem('companySortAsc');
      }
      sessionStorage.removeItem('companyTags');
    }
    return companyPick.replace('&', '%26');
  }

  eventFilterOutsideCompany(
    storageCompany: string,
    event: any
  ): number {
    let companyPick: number = NaN;

    if (event.id) {
      companyPick = event.id;
      sessionStorage.setItem(storageCompany, companyPick.toString());
    } else {
      sessionStorage.removeItem(storageCompany);
    }
    return companyPick;
  }

  removeTags(
    tags: { key: string, value: string }[],
    arrFreeText: FormControl,
    industriesPick: FormControl,
    cityPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
    // if (tag.key === 'type') this.typePick.setValue(null);
    // if (tag.key === 'date') { this.datePick.controls.begin.setValue(null); this.datePick.controls.end.setValue(null); };
    if (tag.key === 'freetext') {
      tags = this.tagsService.removeKey(tags, tag.key, arrFreeText, 'array', indexTagValue);
    } else if (tag.key === 'industry') {
      tags = this.tagsService.removeKey(tags, tag.key, industriesPick, 'array', indexTagValue);
    } else if (tag.key === 'city') {
      tags = this.tagsService.removeKey(tags, tag.key, cityPick, 'array', indexTagValue);
    } else { // by default yang bukan array disini
      tags = this.tagsService.removeKey(tags, tag.key);
    }

    sessionStorage.setItem('companyTags', JSON.stringify(tags));
  }

  // End Filter Logic

  getCompanyCombo(companyPick: string | number | any, byId?: boolean): void {
    this.companyReadySubject.next(false);
    if (!companyPick) {
      this.companyService.getCompanyAutoComplete('')
      .subscribe((arg: any) => {
        const listData = arg.data;
        // listData.unshift({name: 'All', id: ''});

        this.currentCompanyListSubject.next(listData);
        this.companyReadySubject.next(true);
      }, (error: any) => {
        this.companyService.openSnackBar(error.status, error);
      });
    } else {
      if (byId) {
        this.companyService.getCompanyAutoCompleteById(companyPick).subscribe((arg: any) => {
          const listData = arg;
          // this.company = arg;
  
          this.currentCompanySubject.next(listData);
          this.currentCompanyListSubject.next([listData]);
          this.companyReadySubject.next(true);
        }, (error: any) => {
          this.companyService.openSnackBar(error.status, error);
        });
      } else {
        this.companyService.getCompanyAutoCompleteByName(companyPick).subscribe((arg: any) => {
          const listData = arg.data;
          // this.company = arg;
  
          this.currentCompanySubject.next(listData[0]);
          this.currentCompanyListSubject.next(listData);
          this.companyReadySubject.next(true);
        }, (error: any) => {
          this.companyService.openSnackBar(error.status, error);
        });
      }
    }
  }
}
