export class EnumEmployeeStatus {
    public static employeeStatusSelect: any = [
        { name: 'Aktif', enum: 'active' },
        { name: 'Berhenti', enum: 'inactive' },
    ];

    public static findEmployeeStatus(status: string): any {
        return EnumEmployeeStatus.employeeStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
    }

    public static generateEmployeeStatus(status: string): string {
        const tempEmployment = EnumEmployeeStatus.employeeStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
        return (tempEmployment) ? tempEmployment.name : '-';
    }
}
