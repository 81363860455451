import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { PostAssetModel, AssignAssetModel, ResponseAsset, ResponseAssetAssign, AssetClassModel, AssetClassAssignModel } from '..';
import { AssetClassHTTPService } from './company-http';

export type ResponseAssetType = ResponseAsset | undefined;
export type ResponseAssetAssignType = ResponseAssetAssign | undefined;

@Injectable({
  providedIn: 'root'
})
export class AssetClassService {

  // public fields
  currentAsset$: Observable<ResponseAssetType>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentAssetSubject: BehaviorSubject<ResponseAssetType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private assetClassHttp: AssetClassHTTPService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentAssetSubject = new BehaviorSubject<ResponseAssetType>(undefined);
    this.currentAsset$ = this.currentAssetSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackbar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  customSnackbar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // public method
  // Assets Class
  getAsset(filter: FilterModel): Observable<ResponseAssetType> {
    this.isLoadingSubject.next(true);
    return this.assetClassHttp.getAsset(filter).pipe(
      map((assets: ResponseAssetType) => {
        if (assets) {
          this.currentAssetSubject.next(assets);
        }
        return assets;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitAsset(idAsset: number | null, data: PostAssetModel, filter: FilterModel): Observable<ResponseAssetType> {
    this.isSubmitedSubject.next(true);
    if (idAsset) {
      return this.assetClassHttp.putAsset(idAsset, data).pipe(
        switchMap(() => this.getAsset(filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.assetClassHttp.postAsset(data).pipe(
        switchMap(() => this.getAsset(filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteAsset(idAsset: number, filter: FilterModel): Observable<ResponseAssetType> {
    this.isSubmitedSubject.next(true);
    return this.assetClassHttp.deleteAsset(idAsset).pipe(
      switchMap(() => this.getAsset(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }


  // Assign Assets Class
  getAssignedAssets(filter: FilterModel): Observable<ResponseAssetType> {
    this.isLoadingSubject.next(true);
    return this.assetClassHttp.getCompanyAsset(filter).pipe(
      map((companyAssets: any) => {
        if (companyAssets && companyAssets.data) {
          companyAssets.data.forEach((element: AssetClassAssignModel) => {
            element.isSelected = true;
            element.assignId = element.ID;
          });
        }
        this.currentAssetSubject.next(companyAssets);
        return companyAssets;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCompanyAssets(filter: FilterModel): Observable<ResponseAssetType> {
    let tempAssets: ResponseAssetType;

    this.isLoadingSubject.next(true);
    if (filter.query.includes('principal_min') || filter.query.includes('principal_max')) {
      return this.assetClassHttp.getCompanyAsset(filter).pipe(
        map((companyAssets: any) => {
          if (companyAssets && companyAssets.data) {
            companyAssets.data.forEach((element: AssetClassAssignModel) => {
              element.isSelected = true;
              element.assignId = element.ID;
              element.blacklisted = element.blacklisted;
            });
          }
          this.currentAssetSubject.next(companyAssets);
          return companyAssets;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    } else {
      return this.assetClassHttp.getAsset({
        query: '',
        page: 0,
        size: 200  
      }).pipe(
        switchMap((assets: ResponseAssetType) => {
          tempAssets = assets;
          return this.assetClassHttp.getCompanyAsset(filter);
        }),
        map((companyAssets: ResponseAssetAssignType) => {
          if (tempAssets && companyAssets && companyAssets.data) {
            tempAssets.data.forEach((element: AssetClassModel) => {
              const tempCompanyAssets = companyAssets.data.find((companyElement: AssetClassAssignModel) =>
                companyElement.assetID === element.id
              );
  
              if (tempCompanyAssets) {
                element.isSelected = true;
                element.assignId = tempCompanyAssets.ID;
                element.blacklisted = tempCompanyAssets.blacklisted;
              }
            });
          }
          this.currentAssetSubject.next(tempAssets);
          return tempAssets;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    }
  }

  selectAsset(data: AssignAssetModel, filter: FilterModel): Observable<ResponseAssetType> {
    this.isSubmitedSubject.next(true);
    return this.assetClassHttp.selectCompanyAsset(data).pipe(
      switchMap(() => this.getCompanyAssets(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  deselectAsset(assignId: number, filter: FilterModel): Observable<ResponseAssetType> {
    this.isSubmitedSubject.next(true);
    return this.assetClassHttp.deselectCompanyAsset(assignId).pipe(
      switchMap(() => this.getCompanyAssets(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  putCompanyAsset(assignId: number, data: AssignAssetModel): Observable<AssetClassModel> {
    this.isSubmitedSubject.next(true);
    return this.assetClassHttp.putCompanyAsset(assignId, data).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
}
