import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { environment } from 'src/environments/environment';

const API_PROJECT_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class ManageTeamHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getMemberList(companyId: number, projectId: number, filter: FilterModel): Observable<any> {
    return this.http.get<any>(`${API_PROJECT_URL}/company/${companyId}/project/${projectId}/member?${filter.query ? filter.query + '&' : ''}page=${filter.page}&limit=${filter.size ? filter.size : '10'}&metadata=true`);
  }

  getMemberDetail(companyId: number, projectId: number, id: number): Observable<Response> {
    return this.http.get<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${projectId}/member${id}`);
  }

  createMember(companyId: number, projectId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${projectId}/member`, data);
  }

  editMember(companyId: number, projectId: number, userId: number, data: any): Observable<Response> {
    delete data.id;
    delete data.companyId;
    delete data.projectId;
    delete data.userId;
    delete data.fullName;
    delete data.createdBy;
    delete data.lastModifiedBy;
    // per pjp.an
    // delete data.pjpId;
    delete data.pjp_name;
    delete data.pjp_area;
    delete data.pjp_start_location;
    delete data.pjp_end_location;

    return this.http.put<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${projectId}/member/${userId}`, data);
  }

  editPjp(companyId: number, projectId: number, pjpId: number, data: any): Observable<Response> {
    delete data.id;
    delete data.companyId;
    delete data.projectId;
    delete data.userId;
    delete data.fullName;
    delete data.createdBy;
    delete data.lastModifiedBy;
    delete data.pjp_name;
    delete data.pjp_area;
    delete data.pjp_start_location;
    delete data.pjp_end_location;

    return this.http.put<Response>(`${API_PROJECT_URL}/company/${companyId}/project/${projectId}/member/${pjpId}`, data);
  }
}
