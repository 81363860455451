import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { RemoteLoggingClass } from 'src/app/_class/remote-logging.class';
import { HttpConfigService } from '../http-config.service';

const API_LOG_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class RemoteLoggingHTTPService {

  constructor(
    private http: HttpClient,
    private config: HttpConfigService,
  ) { }

  postRemoteLogs(body: RemoteLoggingClass): Observable<any> {
    return this.http.post<any>(`${API_LOG_URL}/rlog`, body, this.config.httpOptions());
  }
}
