import { Injectable } from '@angular/core';

@Injectable()
export class MenuConfig {

  // NOTES: https://docs.google.com/spreadsheets/d/1GkLx1Rev1uLBl5xtvUKkwZXpEpnlJdCadIhzpezWDsI/edit#gid=0

  public config: any = {};

  constructor() {
    this.config = {
      header: [],
      aside: [
        // {
        //   module: 'dashboard',
        //   permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER', 'ROLE_EMPLOYEE'],
        //   allowedModule: 'CAREER',
        //   items: [
        //     {
        //       title: 'Dashboard',
        //       permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER', 'ROLE_EMPLOYEE'],
        //       keyRoute: 'dashboard_wmt',
        //       route: environment.production ? ['/simple-page'] : ['/dashboard/workforce'],
        //       icon: './assets/media/svg/icons/Design/Layers.svg'
        //     },
        //   ]
        // },
        {
          module: 'admin',
          permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'/* , 'ROLE_FREELANCE' */],
          items: [
            {
              title: 'Manage User',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'manage_user',
              route: ['/user'],
              icon: './assets/media/svg/icons/Communication/Group.svg'
            },
            {
              title: 'Company Management',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'manage_companies',
              route: ['/company/list'],
              icon: './assets/media/svg/icons/Home/Building.svg'
            },
            // {
            //   title: 'Interview',
            //   permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
            //   keyRoute: 'interview',
            //   route: ['/interview'],
            //   icon: './assets/media/svg/icons/Communication/Clipboard-check.svg'
            // },
            // {
            //   title: 'Manage Employee',
            //   permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin Edit Only
            //   keyRoute: 'manage_employee',
            //   route: ['/employee/index'],
            //   icon: './assets/media/svg/icons/Clothes/Tie.svg'
            // },
            {
              title: 'Payfin',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
              keyRoute: 'payfin_invoice',
              route: ['/finance/inv/payfin'],
              icon: './assets/media/svg/icons/Figma/Payroll.svg'
            },
            // {
            //   title: 'Sales Motorist',
            //   permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
            //   keyRoute: 'motorist',
            //   route: ['/sales-motorist'],
            //   icon: './assets/media/svg/icons/General/Thunder.svg',
            //   dropdown: true
            // }
            {
              title: 'Career',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'admin_career',
              route: ['/admin_career'],
              icon: './assets/media/svg/icons/Devices/Laptop.svg',
              dropdown: true,
              dropdown_items: [
                {
                  title: 'Job Management',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'/* , 'ROLE_FREELANCE' */],
                  keyRoute: 'manage_jobs',
                  route: ['/jobs/index'],
                  icon: './assets/media/svg/icons/Devices/Laptop.svg'
                },
                {
                  title: 'Applicant Management',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
                  keyRoute: 'list_applicant',
                  route: ['/applicants/index'],
                  icon: './assets/media/svg/icons/Communication/Chat-smile.svg'
                },
                {
                  title: 'Job Template',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
                  keyRoute: 'template',
                  route: ['/template'],
                  icon: './assets/media/svg/icons/Layout/Layout-top-panel-3.svg'
                },
              ]
            },
            {
              title: 'Loan',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'admin_loan',
              route: ['/admin-loan'],
              icon: './assets/media/svg/icons/Shopping/Money.svg',
              dropdown: true,
              dropdown_items: [
                {
                  title: 'Assets Class',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
                  keyRoute: 'assets_class',
                  route: ['/finance/assets-class']
                },
                {
                  title: 'Company Mapping Kini',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
                  keyRoute: 'mapping_kini',
                  route: ['/company/whitelist-kini']
                },
                {
                  title: 'Whitelist Ewa / Kini',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
                  keyRoute: 'admin-whitelist-kini',
                  route: ['/employee/admin-kini']
                },
                {
                  title: 'Company Mapping Gesa',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
                  keyRoute: 'mapping_gg',
                  route: ['/company/whitelist-gesa']
                },
                {
                  title: 'Whitelist Gaji Gesa',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
                  keyRoute: 'admin-whitelist-gg',
                  route: ['/employee/admin-gesa']
                },
                {
                  title: 'EWA Kini',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
                  keyRoute: 'admin-ewa-kini',
                  route: ['/kreditur/admin-kini']
                },
                {
                  title: 'Gaji Gesa',
                  permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
                  keyRoute: 'admin-gg',
                  route: ['/gesa/admin']
                },
              ]
            },
            {
              title: 'Campaign',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'campaign',
              route: ['/campaign'],
              icon: './assets/media/svg/icons/General/Binocular.svg'
            },
            {
              title: 'Point',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'point',
              route: ['/point/index'],
              icon: './assets/media/svg/icons/Figma/Point.svg'
            },
            {
              title: 'Referral',
              permissions: ['ROLE_ADMIN', 'ROLE_SUPPORT'],
              keyRoute: 'referral',
              route: ['/referral'],
              icon: './assets/media/svg/icons/Figma/Referral.svg'
            },
            {
              title: 'Webinar Management',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'webinar',
              route: ['/webinar/index'],
              icon: './assets/media/svg/icons/General/Binocular.svg'
            },
            // {
            //   title: 'ATS',
            //   permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
            //   keyRoute: 'ats',
            //   route: ['/ats'],
            //   icon: './assets/media/svg/icons/Clothes/Shirt.svg',
            //   dropdown: true,
            //   dropdown_items: [
            //     {
            //       title: 'Client',
            //       permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
            //       keyRoute: 'ats-client-admin',
            //       route: ['/ats/admin-client']
            //     },
            //     {
            //       title: 'Jobs',
            //       permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
            //       keyRoute: 'ats-joblist-admin',
            //       route: ['/ats/admin-job']
            //     },
            //     {
            //       title: 'Interview',
            //       permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
            //       keyRoute: 'ats-interview-admin',
            //       route: ['/ats/admin-interview']
            //     },
            //     {
            //       title: 'Talent',
            //       permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
            //       keyRoute: 'ats-talentlist-admin',
            //       route: ['/ats/admin-talent']
            //     }
            //   ]
            // }
          ]
        },
        {
          module: 'kancil',
          permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV', 'ROLE_LENDER', 'ROLE_FINANCE_INTERNAL'],
          items: [
            {
              title: 'Pengajuan Kancil',
              permissions: ['ROLE_LENDER'],
              keyRoute: 'kancil_approval',
              route: ['/kreditur/kancil-approval'],
              icon: './assets/media/svg/icons/General/Thunder-move.svg'
            },
            {
              title: 'Pinjaman Kancil',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV', 'ROLE_LENDER'],
              keyRoute: 'kancil_submission',
              route: ['/kreditur/kancil-submission'],
              icon: './assets/media/svg/icons/Shopping/Credit-card.svg'
            },
            {
              title: 'Invoice Kancil',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV', 'ROLE_FINANCE_INTERNAL'],
              keyRoute: 'kancil_invoice_internal',
              route: ['/finance/invoice/kancil-internal'],
              icon: './assets/media/svg/icons/Shopping/Dollar.svg'
            },
          ]
        },
        {
          module: 'jobfair',
          allowedModule: 'JOBFAIR',
          permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'],
          items: [
            {
              title: 'Overview',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'dashboard_jobfair',
              route: ['/dashboard/jobfair'],
              icon: './assets/media/svg/icons/Design/Sketch.svg'
            },
            {
              title: 'Daftar Perusahaan',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'], // Support, Univ and Admin View Only
              keyRoute: 'jobfair_company',
              route: ['/jobfair/company'],
              icon: './assets/media/svg/icons/Home/Building.svg'
            },
            {
              title: 'Daftar Lowongan',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'], // Support, Univ and Admin View Only
              keyRoute: 'jobfair_jobs',
              route: ['/jobfair/vacancy'],
              icon: './assets/media/svg/icons/Devices/Laptop.svg'
            },
            {
              title: 'Daftar Applicant',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'], // Support, Univ and Admin View Only
              keyRoute: 'jobfair_applicant',
              route: ['/jobfair/applicant'],
              icon: './assets/media/svg/icons/Communication/Chat-smile.svg'
            },
            // {
            //   title: 'Interview Jobfair',
            //   permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'], // Support and Admin View Only
            //   keyRoute: 'jobfair_interview',
            //   route: ['/jobfair/interview'],
            //   icon: './assets/media/svg/icons/Communication/Clipboard-check.svg'
            // },
            {
              title: 'Webinar',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_ADMIN', 'ROLE_BUSDEV'], // Support, Univ and Admin View Only
              keyRoute: 'webinar',
              route: ['/jobfair/webinar'],
              icon: './assets/media/svg/icons/Communication/Clipboard-check.svg'
            },
            {
              title: 'Walkin Interview',
              permissions: ['ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'walkin_interview',
              route: ['/jobfair/walkin-interview'],
              icon: './assets/media/svg/icons/Communication/Clipboard-check.svg'
            },
          ]
        },
        {
          module: 'ats',
          allowedModule: 'ATS',
          permissions: ['ROLE_HEADHUNTER'],
          items: [
            {
              title: 'Client',
              permissions: ['ROLE_HEADHUNTER'],
              keyRoute: 'ats-client',
              route: ['/ats/client'],
              icon: './assets/media/svg/icons/Devices/Laptop.svg'
            },
            {
              title: 'Jobs',
              permissions: ['ROLE_HEADHUNTER'],
              keyRoute: 'ats-joblist',
              route: ['/ats/job'],
              icon: './assets/media/svg/icons/Devices/Laptop.svg'
            },
            {
              title: 'Interview',
              permissions: ['ROLE_HEADHUNTER'],
              keyRoute: 'ats-interview',
              route: ['/ats/interview'],
              icon: './assets/media/svg/icons/Communication/Clipboard-check.svg'
            },
            {
              title: 'Talent',
              permissions: ['ROLE_HEADHUNTER'],
              keyRoute: 'ats-talent',
              route: ['/ats/talent'],
              icon: './assets/media/svg/icons/Code/Spy.svg'
            },
          ]
        },
        {
          module: 'recruitment',
          allowedModule: 'CAREER,JOBFAIR',
          permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF', 'ROLE_MANAGER', 'ROLE_FINANCE', 'ROLE_ADMIN', 'ROLE_BUSDEV', 'ROLE_COMPANY'],
          items: [
            {
              title: 'Overview',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_BUSDEV'],
              keyRoute: 'dashboard_career',
              route: ['/dashboard/career'],
              icon: './assets/media/svg/icons/Design/Sketch.svg'
            },
            {
              title: 'Vacancy',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF', 'ROLE_COMPANY'],
              keyRoute: 'manage_vacancy',
              route: ['/jobs/vacancy'],
              icon: './assets/media/svg/icons/Devices/Laptop.svg'
            },
            {
              title: 'Applicant',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF'],
              keyRoute: 'manage_applicant',
              route: ['/applicants/list'],
              icon: './assets/media/svg/icons/Communication/Chat-smile.svg'
            },
            {
              title: 'Interview',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF'],
              keyRoute: 'interview',
              route: ['/interview'],
              icon: './assets/media/svg/icons/Communication/Clipboard-check.svg'
            },
            {
              title: 'Talent Scouting',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF'],
              keyRoute: 'search_talent',
              route: ['/talent'],
              icon: './assets/media/svg/icons/Code/Spy.svg'
            },
            {
              title: 'Manage Point',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE'],
              keyRoute: 'upgrade_account',
              route: ['point/upgrade-account'],
              icon: './assets/media/svg/icons/Figma/Point.svg'
            },
            {
              title: 'Webinar Event',
              permissions: ['ROLE_OWNER', 'ROLE_HR'],
              keyRoute: 'webinar',
              route: ['/webinar/company'],
              icon: './assets/media/svg/icons/General/Binocular.svg'
            }
          ]
        },
        {
          module: 'workforce',
          permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_EMPLOYEE'/* , 'ROLE_KASBON' */],
          allowedModule: 'ATTENDANCE',
          items: [
            {
              title: 'Company',
              permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_HR'],
              keyRoute: 'company',
              route: ['/company/index'],
              icon: './assets/media/svg/icons/Home/Building.svg'
            },
            {
              title: 'Employee',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER'],
              keyRoute: 'my_employee',
              route: ['/employee/index'],
              icon: './assets/media/svg/icons/Clothes/Tie.svg'
            },
            {
              title: 'Payroll',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER', 'ROLE_FINANCE'], // Finance View Only
              allowedModule: 'PAYROLL,PAYSLIP',
              keyRoute: 'payroll',
              route: ['/payroll'],
              icon: './assets/media/svg/icons/Figma/Payroll.svg'
            },
            {
              title: 'Payfin',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER', 'ROLE_FINANCE'], // Finance View Only
              allowedModule: 'PAYFIN',
              keyRoute: 'payfin',
              route: ['/payfin'],
              icon: './assets/media/svg/icons/Figma/Payroll.svg'
            },
            {
              title: 'Loan',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER'],
              allowedModule: 'BEEVER,KINI,KANCIL,GESA',
              keyRoute: 'loan',
              route: ['/loan'],
              icon: './assets/media/svg/icons/Shopping/Money.svg',
              dropdown: true,
              dropdown_items: [
                {
                  title: 'Beever Kasbon',
                  permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER'],
                  keyRoute: 'kasbon-v2',
                  route: ['/finance/kasbon-v2'],
                  allowedModule: 'BEEVER'
                },
                {
                  title: 'EWA Kini',
                  permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER'],
                  keyRoute: 'kini',
                  route: ['/kreditur/kini'],
                  allowedModule: 'KINI'
                },
                {
                  title: 'Gaji Gesa',
                  permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER'],
                  keyRoute: 'gesa',
                  route: ['/gesa/index'],
                  allowedModule: 'GESA'
                },
                {
                  title: 'Pinjaman Kancil',
                  permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER'],
                  keyRoute: 'kancil',
                  route: ['/kreditur/kancil'],
                  allowedModule: 'KANCIL'
                },
              ]
            },
            {
              title: 'Invoice',
              permissions: ['ROLE_OWNER', 'ROLE_FINANCE'],
              allowedModule: 'KANCIL',
              keyRoute: 'invoice',
              route: ['/invoice'],
              icon: './assets/media/svg/icons/Files/File.svg',
              dropdown: true,
              dropdown_items: [
                {
                  title: 'Invoice Kancil',
                  permissions: ['ROLE_OWNER', 'ROLE_FINANCE'],
                  keyRoute: 'kancil_invoice',
                  route: ['/finance/invoice/kancil'],
                  allowedModule: 'KANCIL'
                },
              ]
            },
            {
              title: 'Loan Setting',
              permissions: ['ROLE_OWNER', 'ROLE_HR'/* , 'ROLE_KASBON' */],
              allowedModule: 'KINI,KANCIL,GESA',
              keyRoute: 'whitelist',
              route: ['/whitelist'],
              icon: './assets/media/svg/icons/Clothes/Shirt.svg',
              dropdown: true,
              dropdown_items: [
                {
                  title: 'Whitelist Ewa / Kini',
                  permissions: ['ROLE_OWNER', 'ROLE_HR'/* , 'ROLE_KASBON' */],
                  keyRoute: 'whitelist-kini',
                  route: ['/employee/kini'],
                  allowedModule: 'KINI'
                },
                {
                  title: 'Whitelist Gaji Gesa',
                  permissions: ['ROLE_OWNER', 'ROLE_HR'/* , 'ROLE_KASBON' */],
                  keyRoute: 'whitelist-gesa',
                  route: ['/employee/gesa'],
                  allowedModule: 'GESA'
                },
                {
                  title: 'Kancil Product',
                  permissions: ['ROLE_OWNER', 'ROLE_HR'],
                  keyRoute: 'assigned_class',
                  route: ['/finance/assign-product'],
                  allowedModule: 'KANCIL'
                },
              ]
            },
            {
              title: 'Employee Profile',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER', 'ROLE_FINANCE', 'ROLE_EMPLOYEE'],
              keyRoute: 'employee_profile',
              route: '',
              icon: './assets/media/svg/icons/General/User.svg'
            },
            {
              title: 'Payslip',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_HR_STAFF', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER', 'ROLE_FINANCE', 'ROLE_EMPLOYEE'],
              allowedModule: 'PAYROLL,PAYSLIP',
              keyRoute: 'payslip',
              route: '',
              icon: './assets/media/svg/icons/Files/File.svg'
            },
            {
              title: 'Financial Log',
              permissions: ['ROLE_OWNER', 'ROLE_FINANCE'],
              keyRoute: 'flog',
              route: ['/finance/financial-log'],
              allowedModule: 'BEEVER',
              icon: './assets/media/svg/icons/Shopping/Chart-line1.svg'
            },
          ]
        },
        {
          module: 'attendance',
          allowedModule: 'ATTENDANCE',
          permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER'],
          items: [
            {
              title: 'Employee Attendance',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER'],
              allowedModule: 'ATTENDANCE',
              keyRoute: 'attendance',
              route: ['/attendance/index'],
              icon: './assets/media/svg/icons/Figma/Calendar.svg'
            },
            {
              title: 'Shift Assignment',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER'], // Support and Admin View Only
              allowedModule: 'ATTENDANCE',
              keyRoute: 'shift',
              route: ['/attendance/shift'],
              icon: './assets/media/svg/icons/Layout/Layout-top-panel-5.svg'
            },
            {
              title: 'Permission Leave',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER'],
              allowedModule: 'ATTENDANCE',
              keyRoute: 'leave',
              route: ['/attendance/leave'],
              icon: './assets/media/svg/icons/Communication/Shield-user.svg'
            },
            {
              title: 'Libur Perusahaan',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_MANAGER'], // Support and Admin View Only
              allowedModule: 'ATTENDANCE',
              keyRoute: 'holiday',
              route: ['/attendance/holiday'],
              icon: './assets/media/svg/icons/Figma/Umbrella.svg'
            },
          ]
        },
        {
          module: 'bpo',
          permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER'],
          allowedModule: 'BPO',
          items: [
            {
              title: 'Project Management',
              permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER'],
              keyRoute: 'project',
              route: ['/project'],
              icon: './assets/media/svg/icons/Communication/Group.svg'
            },
            {
              title: 'SKU',
              permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER'],
              keyRoute: 'sku',
              route: ['/sku'],
              icon: './assets/media/svg/icons/General/Attachment2.svg'
            },
            {
              title: 'PJP',
              permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER'],
              keyRoute: 'pjp',
              route: ['/pjp'],
              icon: './assets/media/svg/icons/General/Attachment2.svg'
            },
            {
              title: 'Point of Interest',
              permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER'],
              keyRoute: 'poi',
              route: ['/poi'],
              icon: './assets/media/svg/icons/General/Attachment2.svg'
            },
            {
              title: 'Sales Motorist',
              permissions: ['ROLE_OWNER', 'ROLE_MANAGER', 'ROLE_PROJECT_MANAGER'],
              keyRoute: 'motorist',
              route: ['/sales-motorist'],
              icon: './assets/media/svg/icons/General/Thunder.svg',
              dropdown: true
            }
          ]
        },
        {
          module: 'career',
          permissions: ['ROLE_WORKER'],
          items: [
            {
              title: 'Lamaranku',
              permissions: ['ROLE_WORKER'],
              keyRoute: 'applied_job',
              route: ['/career/application'],
              icon: './assets/media/svg/icons/Layout/Layout-arrange.svg'
            },
            {
              title: 'Pekerjaan Tersimpan',
              permissions: ['ROLE_WORKER'],
              keyRoute: 'bookmark',
              route: ['/career/bookmark'],
              icon: './assets/media/svg/icons/General/Bookmark.svg'
            },
            // {
            //   title: 'Tugasku',
            //   permissions: ['ROLE_WORKER'],
            //   keyRoute: 'my_task',
            //   route: environment.production ? ['/simple-page'] : ['/career/my-task'],
            //   icon: './assets/media/svg/icons/Files/File-done.svg'
            // },
            {
              title: 'Worker Profile',
              permissions: ['ROLE_WORKER'],
              keyRoute: 'worker_profile',
              route: '',
              icon: './assets/media/svg/icons/General/User.svg'
            },
            {
              title: 'Webinarku',
              permissions: ['ROLE_WORKER'],
              keyRoute: 'mywebinar',
              route: ['/webinar/list'],
              icon: './assets/media/svg/icons/Layout/Layout-arrange.svg'
            },
          ]
        },
        {
          module: 'config',
          permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER', 'ROLE_COMPANY'],
          items: [
            {
              title: 'Fitur',
              permissions: ['ROLE_OWNER', 'ROLE_HR', 'ROLE_FINANCE', 'ROLE_MANAGER', 'ROLE_COMPANY'],
              keyRoute: 'company_module',
              route: ['/dashboard/features'],
              icon: './assets/media/svg/icons/Shopping/Cart3.svg'
            }
          ]
        },
      ],
      menuSection: {
        admin: 'Admin',
        dashboard: 'Dashboard',
        kancil: 'Kasbon Cicil',
        jobfair: 'Jobfair',
        ats: 'Applicant Tracking System',
        recruitment: 'Career',
        workforce: 'WorkForce',
        attendance: 'ATTENDANCE',
        bpo: 'BPO',
        career: 'Job Portal',
        config: 'Setting',
      }
    };
  }
}
