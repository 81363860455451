import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { VersionCheckService } from './_service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private permissions: NgxPermissionsService,
    private versionCheckService: VersionCheckService,
  ) { }

  @HostListener('window:keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    // CTRL + Shift + K -> Goto Company Page
    if (event.ctrlKey && event.shiftKey && event.key === 'K') {
      event.preventDefault();
      this.router.navigateByUrl('/companies');
    }

    // CTRL + Shift + P -> Goto Payroll Page
    if (event.ctrlKey && event.shiftKey && event.key === 'P') {
      event.preventDefault();
      this.router.navigateByUrl('/payroll');
    }

    // CTRL + Shift + E -> Goto Management Employee Page
    if (event.ctrlKey && event.shiftKey && event.key === 'E') {
      event.preventDefault();
      this.router.navigateByUrl('/employee');
    }

    // CTRL + Shift + M -> Goto Management Project Page
    if (event.ctrlKey && event.shiftKey && event.key === 'M') {
      event.preventDefault();
      this.router.navigateByUrl('/project');
    }

    // CTRL + Shift + Space -> Debug Mode
    if (event.ctrlKey && event.shiftKey && event.key === ' ') {
      event.preventDefault();
      sessionStorage.setItem('debug', 'on process');
      alert('Debug Mode On');
    }

    // CTRL + Shift + Super -> Kill Debug Mode
    if (event.ctrlKey && event.shiftKey && event.key === 'Meta') {
      event.preventDefault();
      sessionStorage.removeItem('debug');
      alert('Debug Mode Off');
    }
  }

  ngOnInit(): void {
    if (environment.production || environment.staging) {
      this.versionCheckService.initVersionCheck();
    }
    if (localStorage.getItem('role')) {
      // tslint:disable-next-line:no-non-null-assertion
      this.permissions.loadPermissions(localStorage.getItem('role')!.split(','));
    }
    // this.auth.setInterruptedUrl(this.router.url);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
