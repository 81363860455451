export class EnumJobType {
    public static jobTypeSelect: any = [
      { name: 'Job', enum: 'job' },
      { name: 'GiG', enum: 'gig' },
    ]

    public static generateJobType(jobType: string) {
      const tempjobType = EnumJobType.jobTypeSelect.find((fin: any) => fin.enum === jobType.toLowerCase());
      return tempjobType ? tempjobType.name : '-';
    }

    public static findJobType(jobType: string) {
      return EnumJobType.jobTypeSelect.find((fin: any) => fin.enum === jobType.trim().toLowerCase());
    }
}