import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import {EnumAge, EnumGender, EnumTransportation, EnumWorkDuration} from '../layouts/main/pages/manage-applicants';
import {EnumCompanyStatus, EnumCompanyVerified, EnumIndustry, EnumScale} from '../layouts/main/pages/manage-companies';
import {EnumCity, EnumCommutingType, EnumJobCategory, EnumJobStatus, EnumPaymentType, EnumRolesJob} from '../layouts/main/pages/manage-job';
import {EnumEmployeeStatus, EnumEmploymentType} from '../layouts/main/pages/employee-list';
import {EnumProjectStatus, EnumProjectType} from '../layouts/main/pages/manage-team';
import {EnumAttendanceCommuting, EnumLeaveStatus, EnumLeaveType} from '../layouts/main/pages/attendance';
import {EnumClientStatus, EnumJobStatus as ClientEnumJobStatus} from '../layouts/main/pages/ats';

import * as moment from 'moment';
moment.locale('id');

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  constructor() { }

  filterSession(filterSession: any, key: any): any {
    return (filterSession) ? filterSession.filter((fil: any) => fil.key === key) : [];
  }

  // Remove Tags
  updateKey(
    tags: {key: string, value: string}[],
    key: string, // kunci dari tags yang akan di update
    formData: FormGroup | FormControl | any, // Kalo penanda pick Pakai Form Control
    inputType?: 'array' | 'date' | 'autocomplete' | 'amount',  // Kalo penanda pick Value nya array / date
  ): {key: string, value: string}[] {
    // console.log(tag);
    const index = tags.map(
      // tslint:disable-next-line:only-arrow-functions typedef
      function(tag: {key: string, value: string}) { return tag.key; }
    ).indexOf(key); // get index

    // console.log(index);
    if (index >= 0) {
      tags.splice(index, 1); // remove previous tag
    }

    if (!inputType) {
      tags.push({key, value: formData.value.replace('&', '%26')}); // add new tag
    } else {
      if (inputType === 'array' && formData.value.length) {
        tags.push({key, value: formData.value});
      }
      if (inputType === 'date' && (formData.controls.begin.value || formData.controls.end.value)) {
        tags.push({key, value: formData.value});
      }
      if (inputType === 'autocomplete') {
        tags.push({key, value: formData});
      }
      if (inputType === 'amount' && (formData.value.max > formData.value.min)) {
        tags.push({key, value: formData.value});
      }
    }

    // console.log(tags);

    return tags;
  }

  removeKey(
    tags: {key: string, value: string}[],
    key: string, // kunci dari tags yang akan di update
    formData?: FormControl | FormGroup | any, // Kalo penanda pick Pakai Form Control
    inputType?: 'array',  // Kalo penanda pick Value nya array / date
    indexTagValue?: number,
  ): {key: string, value: string}[] {
    // Debugging Purpose
    // console.log(tags, key, formData.value, inputType, indexTagValue);
    // return tags;
    const index = tags.map(
      // tslint:disable-next-line:only-arrow-functions typedef
      function(tag: {key: string, value: string}) { return tag.key; }
    ).indexOf(key); // get index

    // console.log(index);
    if (index >= 0 && (inputType !== 'array')) { tags.splice(index, 1); } // remove previous tag jika valuenya bukan array

    if (index >= 0 && (inputType === 'array')) { // remove previous tag jika valuenya array
      formData = this.setValueMultipleTags(formData, indexTagValue, (inputType === 'array'));
      tags.splice(index, 1);
      if (formData.value) { tags.push({key, value: formData.value}); }
    }

    return tags;
  }
  // Remove Tags

  contains(target: string, pattern: string[]): number {
    let value = 0;
    pattern.forEach((element: string) => {
      const temp = target.includes(element) ? 1 : 0;
      value = value + temp;
    });
    return value;
  }
  setValueMultipleTags(
    formControl: FormControl,
    indexTagValue?: number,
    isArray?: boolean
  ): FormControl {
    if (isArray) {
      // console.log(indexTagValue);
      formControl.value.splice(indexTagValue, 1);
      formControl.setValue(formControl.value.length ? formControl.value : null);
      return formControl;
    }
    formControl.setValue(null);
    return formControl;
  }

  loopingTagsValue(tag: { key: string, value: string[] }): string[] {
    const tagValue: string[] = [];
    tag.value.forEach((element: any) => {
      tagValue.push(this.generateTagsValue(tag.key, element));
    });
    return tagValue;
  }

  generateTagsValue(key: string, value: any): string {
    // Enum Variables
    // const departementList: EmployeeDepartement[] = [];

    // Material Select
    if (key === 'age') { return EnumAge.findAge(value)?.name; }
    if (key === 'workDuration') { return EnumWorkDuration.findWorkDuration(value)?.name; }
    if (key === 'scale') { return EnumScale.findScale(value)?.name; }
    if (key === 'job_status') { return EnumJobStatus.findJobStatus(value)?.name; }
    if (key === 'client_job_status') { return ClientEnumJobStatus.findJobStatus(value)?.name; }
    if (key === 'roles') { return EnumRolesJob.findRolesJob(value)?.name; }
    if (key === 'payment') { return EnumPaymentType.findPayment(value)?.name; }
    if (key === 'job_employment') { return EnumEmploymentType.findEmployment(value)?.name; }
    if (key === 'commuting') { return EnumCommutingType.findCommuting(value)?.name; }

    // Material Checklist
    if (key === 'employment') { return EnumEmploymentType.findEmployment(value)?.name; }
    if (key === 'employee_status') { return EnumEmployeeStatus.findEmployeeStatus(value)?.name; }
    if (key === 'project_type') { return EnumProjectType.findProjectType(value)?.name; }
    if (key === 'project_status') { return EnumProjectStatus.findProjectStatus(value)?.name; }
    if (key === 'leave_type') { return EnumLeaveType.findLeaveType(value)?.name; }
    if (key === 'leave_status') { return EnumLeaveStatus.findLeaveStatus(value)?.name; }
    if (key === 'company_status') { return EnumCompanyStatus.findCompanyStatus(value)?.name; }
    if (key === 'client_status') { return EnumClientStatus.findClientStatus(value)?.name; }
    if (key === 'company_verified') { return EnumCompanyVerified.findCompanyVerifiedString(value)?.name; }
    if (key === 'gender') { return EnumGender.findGender(value)?.name; }
    if (key === 'transport') { return EnumTransportation.findTransportation(value)?.name; }
    if (key === 'industry') { return EnumIndustry.findIndustry(value)?.name; }
    if (key === 'city') { return EnumCity.findCity(value)?.name; }
    if (key === 'attendance_commuting') { return EnumAttendanceCommuting.findAttendanceComm(value)?.name; }
    if (key === 'category') { return EnumJobCategory.findJobCategory(value)?.name; }

    // Custom Value Formatter
    if (key === 'amount' || key === 'salary' || key === 'expectedSalary') { return value.min + ' s/d ' + value.max; }
    if (key === 'date' || key === 'createdDate') {
      if (value.begin && value.end) {
        return moment(value.begin).format('Do MMMM YYYY') + ' - ' + moment(value.end).format('Do MMMM YYYY');
      }
      if (value.begin && !value.end) {
        return '>= ' + moment(value.begin).format('Do MMMM YYYY');
      }
      if (!value.begin && value.end) {
        return '<= ' + moment(value.end).format('Do MMMM YYYY');
      }
    }
    if (key === 'transactionProduct') { return this.generateTransaction(value.toUpperCase()); }
    if (key === 'transactionType') { return this.generateTransactionType(value.toUpperCase()); }
    if (key === 'transactionStatus') { return this.generateStatus(value.toUpperCase()); }
    return value.replace('%26', '&');
  }

  // Logic Belum Sempet Refactor
  generateTransaction(product: string): string {
    return (product.toUpperCase() === 'OTTOCASH_PULSA') ? 'Pulsa' : (
      (product.toUpperCase() === 'BEEVER_KASBON') ? 'Beever Advance Salary' : (
        (product.toUpperCase() === 'KASBON') ? 'Advance Salary' : 'Other'
      )
    );
  }
  generateTransactionType(type: string): string {
    return (type.toUpperCase() === 'DEBET') ? 'Debit' : (
      (type.toUpperCase() === 'CREDIT') ? 'Kredit' : (
        (type.toUpperCase() === 'REQUISITION') ? 'Rekuisisi' : 'Other'
    ));
  }
  generateStatus(status: string): string {
    return (status.toUpperCase() === 'PENDING') ? 'Pending' : (
      (status.toUpperCase() === 'COMPLETED') ? 'Selesai' : (
        (status.toUpperCase() === 'REJECTED') ? 'Ditolak' : (
          (status.toUpperCase() === 'APPROVED') ? 'Disetujui' : 'Other'
        )
    ));
  }

}
