import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';

import { HttpConfigService } from 'src/app/_service';
import { environment } from 'src/environments/environment';

const API_CONTRACT_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeContractHTTPService {

  constructor(
    private http: HttpClient,
    private config: HttpConfigService
  ) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getContractList(companyId: number, userId: number, filter: FilterModel): Observable<any> {
    const url = `${API_CONTRACT_URL}/employment?${filter.query}company_id=${companyId}&user_id=${userId}&page=${filter.page}&limit=${filter.size ? filter.size : '10'}&sort=${filter.sort ? filter.sort : 'id'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<any>(url);
  }
  patchContract(companyId: number, userId: number, data: {
    contractStatus: string
  }): Observable<Response> {
    return this.http.patch<Response>(`${API_CONTRACT_URL}/company/${companyId}/employee/${userId}/contract`, data);
  }
}
