import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { EmployeeEduperienceHTTPService } from './employee-http';

import * as moment from 'moment';
moment.locale('id');

@Injectable({
  providedIn: 'root'
})
export class EmployeeEduperienceService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private employeeEduPerience: EmployeeEduperienceHTTPService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  submitEmployeeEdu(userId: number, idEdu: number | null, data: any): Observable<Response> {
    delete data.isUneducated;

    this.isSubmitedSubject.next(true);
    if (idEdu) {
      return this.employeeEduPerience.editEducation(userId, idEdu, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.employeeEduPerience.postEducation(userId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteEmployeeEdu(userId: number, id: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeEduPerience.deleteEducation(userId, id).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  submitEmployeeExp(userId: number, idExp: number | null, data: any): Observable<Response> {
    data.industry = data.industry.toLowerCase();
    data.startDate = moment(data.startDate).add(7, 'hours').utcOffset('+07:00').format();
    data.endDate = data.endDate ? moment(data.endDate).add(7, 'hours').utcOffset('+07:00').format() : null;
    delete data.isUnemployed;

    this.isSubmitedSubject.next(true);
    if (idExp) {
      return this.employeeEduPerience.editExperience(userId, idExp, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.employeeEduPerience.postExperience(userId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteEmployeeExp(userId: number, id: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeEduPerience.deleteExperience(userId, id).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API

}
