import {Injectable, OnDestroy} from '@angular/core';
// import {MatDialog} from '@angular/material/dialog';
// import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
// import {saveAs} from 'file-saver';
import {Subscription} from 'rxjs';

import {GlobalConstants, SessionStorage} from 'src/app/_class';

import {PayrollService} from '../services/payroll.service';
import {IndexPayfinInvoiceVariables} from '../models/payroll-list.model';

import * as moment from 'moment';
moment.locale('id');

@Injectable()
export class PayrollClass implements OnDestroy {

  subscription: Subscription[] = [];

  constructor(
    // private dialog: MatDialog,
    // private modalService: NgbModal,
    private payrollService: PayrollService,
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sb => sb.unsubscribe());
  }

  // Variables Initial
  initVariableInvoice(showCompanyList: boolean): IndexPayfinInvoiceVariables {
    const varModel = {} as IndexPayfinInvoiceVariables;

    // const Session = SessionStorage.getStorageJson('payfinInvoiceTags');
    // const tempStatus = this.tagsService.filterSession(Session, 'kancil_status');
    // const tempDate = this.tagsService.filterSession(Session, 'date');
    // const tempAmount = this.tagsService.filterSession(Session, 'amount');

    varModel.tempQuery = SessionStorage.getStorage('payfinInvoiceQuery');
    varModel.companyPick = (showCompanyList) ?
      SessionStorage.getStorage('payfinInvoiceCompanySelected') :
      '' + GlobalConstants.companyId;
    // varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    // varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    // varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    // varModel.amountPickMin = (tempAmount.length) ? tempAmount[0].value.min : null;
    // varModel.amountPickMax = (tempAmount.length) ? tempAmount[0].value.max : null;
    varModel.page = SessionStorage.getStorageNumber('payfinInvoicePageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('payfinInvoicePageSize', 10);
    varModel.sortBy = SessionStorage.getStorage('payfinInvoiceSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('payfinInvoiceSortAsc', 'true');
    // varModel.tags = Session ? Session : [];

    return varModel;
  }
  // End of Variables Initial

  getCompanyBalance() {
    const balanceSbcr = this.payrollService.getCompanyBalance(GlobalConstants.companyId)
      .subscribe(() => {}, () => {
        this.payrollService.openCustomBar(417, 'Gagal mengambil saldo.');
      });
    this.subscription.push(balanceSbcr);
  }

  // openInvoice(item: any, roleFinance: boolean): void {
  //   const modalRef = this.modalService.open(DetailInvoiceKancilComponent, {
  //     ariaLabelledBy: 'detail-kancil-invoice',
  //     // modalDialogClass: 'modal-dialog modal-fullscreen',
  //     size: 'xl',
  //     scrollable: true
  //   });

  //   modalRef.componentInstance.invoiceItem = item;
  //   modalRef.componentInstance.roleFinance = roleFinance;
  // }

  // downloadInvoice(item: KancilInvoiceModel): void {
  //   const downloadSbcr = this.invoiceService.downloadInvoice(item.id)
  //     .subscribe((response: any) => {
  //       // File Reader
  //       const blob = new Blob([response.body], { type: 'application/pdf' });
  //       saveAs(blob, `Invoice ${item.companyName} ${moment(item.dueDate).format('DD-MM-YYYY')}.pdf`);
  //     }, error => {
  //       this.invoiceService.openSnackbar(error.status, error);
  //     });
  //   this.subscription.push(downloadSbcr);
  // }
}
