export class EnumEmployeeGender {
    public static employeeGenderSelect: any = [
        { name: 'Laki Laki', enum: 'male' },
        { name: 'Perempuan', enum: 'female' },
        { name: 'Tidak Menyebutkan', enum: 'other' },
    ];

    public static employeeGenderUpload: any = [
        { name: 'Pria', enum: 'male' },
        { name: 'Wanita', enum: 'female' },
    ];

    public static generateGender(gender: string, genderUpload?: boolean): string {
        let tempGender: any;
        if (!genderUpload) {
            tempGender = EnumEmployeeGender.employeeGenderSelect.find((fin: any) => fin.enum === gender.trim().toLowerCase());
        } else {
            tempGender = EnumEmployeeGender.employeeGenderUpload.find((fin: any) => fin.enum === gender.trim().toLowerCase());
        }
        return (tempGender) ? tempGender.name : '-';
    }

    public static findGender(gender: string): any {
        return EnumEmployeeGender.employeeGenderSelect.find((fin: any) => fin.enum === gender.trim().toLowerCase());
    }
}
