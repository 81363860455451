export interface PaginationModel {
    page?: number;
    size?: number;
    total?: number;
    pageSizeStorage?: string;
    pageActiveStorage?: string;
}

export class PaginationClass {
    /** The current page number. */
    page: number;
    /** The current page size */
    size: number;
    /** The current total number of items being paged */
    total: number;
    /** Session Storage */
    pageSizeStorage: string;
    pageActiveStorage: string;

    constructor(params?: PaginationModel) {
        this.page = (params && params.page) ? params.page : 1;
        this.size = (params && params.size) ? params.size : 10;
        this.total = (params && params.total) ? params.total : 0;
        this.pageSizeStorage = (params && params.pageSizeStorage) ? params.pageSizeStorage : '';
        this.pageActiveStorage = (params && params.pageActiveStorage) ? params.pageActiveStorage : '';
    }

    getTotalPage(): number {
        const temp = Math.ceil(this.total / this.size);
        return (temp) ? temp : 1;
    }
}
