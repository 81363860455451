import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';
import { environment } from 'src/environments/environment';

const API_KINI_URL_AFNAN = `${environment.apiUrl}/kini/company`;

@Injectable({
  providedIn: 'root'
})
export class EmployeeKiniHTTPService {

  constructor(private http: HttpClient) { }

  getKiniWhiteListV2(companyId: number, filter: FilterModel): Observable<any> {
    return this.http.get<any>(`${API_KINI_URL_AFNAN}/${companyId}/employee?${filter.query}page=${filter.page}&limit=${filter.size ? filter.size : 10}&sort=${filter.sort ? filter.sort : 'join_date'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`);
  }
  postKiniWhiteList(companyId: number, userId: number): Observable<Response> {
    return this.http.post<Response>(`${API_KINI_URL_AFNAN}/${companyId}/employee/${userId}`, {status: 'ACTIVE'});
  }
  patchKiniWhiteList(companyId: number, userId: number, status: 'ACTIVE' | 'DEACTIVE'): Observable<Response> {
    return this.http.patch<Response>(`${API_KINI_URL_AFNAN}/${companyId}/employee/${userId}`, {status});
  }
  patchKiniLimit(companyId: number, userId: number, data: { limit: number }): Observable<Response> {
    return this.http.patch<Response>(`${API_KINI_URL_AFNAN}/${companyId}/employee/${userId}/limit`, data);
  }
  syncKiniWhiteList(companyId: number): Observable<Response> {
    return this.http.get<Response>(`${API_KINI_URL_AFNAN}/${companyId}/sync`);
  }
}
