import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormService} from 'src/app/_service';
import {ShiftAssignmentModel, ShiftBoardModel} from '../../../../models/shift-assignment.model';

import * as moment from 'moment';

moment.locale('id');

export interface AssignmentConfirmationModel {
  shiftAssignment: ShiftAssignmentModel[];
  tempBoard: ShiftBoardModel;
  selectedUserId?: number[];
}

@Component({
  selector: 'app-assignment-confirmation',
  templateUrl: './assignment-confirmation.component.html',
  styleUrls: ['./assignment-confirmation.component.scss']
})
export class AssignmentConfirmationComponent implements OnInit {

  formConfirmation!: FormGroup;
  limitation = new FormControl();

  minDate!: string;

  constructor(
    public modal: MatDialogRef<AssignmentConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssignmentConfirmationModel,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.minDate = moment(this.data.tempBoard.date).format('YYYY-MM-DD');

    this.formConfirmation = this.formBuilder.group({
      startDate: [this.minDate, [Validators.required]],
      endDate: [null],
    });
  }

  get fConfirm(): any {
    return this.formConfirmation.controls;
  }

  form(name: string): boolean {
    return this.formService.form(this.formConfirmation, name);
  }

  radioChange(): void {
    if (!this.fConfirm.startDate.value) {
      this.snackBar.open('Tentukan tanggal mulai terlebih dahulu.', 'Tutup', {duration: 4000});
      this.limitation.reset();
      return;
    }

    if (this.limitation.value === 'week') {
      this.fConfirm.endDate.setValue(moment(this.minDate).add(6, 'days').format('YYYY-MM-DD'));
    }
    if (this.limitation.value === 'month') {
      this.fConfirm.endDate.setValue(moment(this.minDate).add(29, 'days').format('YYYY-MM-DD'));
    }
    if (this.limitation.value === 'year') {
      this.fConfirm.endDate.setValue(moment(this.minDate).add(364, 'days').format('YYYY-MM-DD'));
    }
  }

  onSubmit(): void {
    // Set Password
    Object.values(this.formConfirmation.controls).forEach(control => {
      control.markAsTouched();
    });

    // stop here if form is invalid
    if (this.formConfirmation.invalid) {
      this.snackBar.open('Sepertinya ada kesalahan pengisian data. Periksa kembali isianmu di atas', 'Tutup', {duration: 4000});
      return;
    }

    // Ngefilter Shift Assignment yang masuk di range
    const tempEndDate = this.fConfirm.endDate.value ?
      moment(this.fConfirm.endDate.value).unix() :
      moment(this.data.shiftAssignment[6].date).unix();

    const filteredAssignment: ShiftAssignmentModel[] = this.data.shiftAssignment.filter(
      (fil: ShiftAssignmentModel) =>
      (moment(fil.date).unix() >= moment(this.fConfirm.startDate.value).unix()) &&
      moment(fil.date).unix() <= tempEndDate
    );

    // Debugging Purpose
    // console.log(this.data.selectedUserId);
    // console.log(this.data.tempBoard);
    // console.log(filteredAssignment);

    // Ngloping Shift Assighnment
    filteredAssignment.forEach((element: ShiftAssignmentModel) => {

      // Checking apakah allow weekend
      if (!element.isWeekend ||
          (element.isWeekend && this.data.tempBoard.allowWeekend)
      ) {

        // Get User in Board
        if (this.data.selectedUserId && this.data.selectedUserId.length) {
          const tempArrUserBoard = element.arrShift.filter(
            (board: ShiftBoardModel) =>
            this.data.selectedUserId!.includes(board.userId)
          );
          tempArrUserBoard.forEach((board: ShiftBoardModel) => {
            board.fullName = this.data.tempBoard.fullName;
            board.shiftId = this.data.tempBoard.shiftId;
            board.shiftName = this.data.tempBoard.shiftName;
            board.date = element.date;
            board.shiftDateStart = this.fConfirm.startDate.value;
            board.shiftDateEnd = this.fConfirm.endDate.value;
            board.start = this.data.tempBoard.start;
            board.end = this.data.tempBoard.end;
            board.allowWeekend = this.data.tempBoard.allowWeekend;
            board.class = this.data.tempBoard.class;
          });
        } else {
          const tempUserBoard = element.arrShift.find((board: ShiftBoardModel) =>
            board.userId === this.data.tempBoard.userId
          );
          if (tempUserBoard) {
            tempUserBoard.userId = this.data.tempBoard.userId;
            tempUserBoard.fullName = this.data.tempBoard.fullName;
            tempUserBoard.shiftId = this.data.tempBoard.shiftId;
            tempUserBoard.shiftName = this.data.tempBoard.shiftName;
            tempUserBoard.date = element.date;
            tempUserBoard.shiftDateStart = this.fConfirm.startDate.value;
            tempUserBoard.shiftDateEnd = this.fConfirm.endDate.value;
            tempUserBoard.start = this.data.tempBoard.start;
            tempUserBoard.end = this.data.tempBoard.end;
            tempUserBoard.allowWeekend = this.data.tempBoard.allowWeekend;
            tempUserBoard.class = this.data.tempBoard.class;
          }

          // console.log(tempUserBoard);
        }
      }
    });

    this.modal.close(this.formConfirmation.value);
  }

}
