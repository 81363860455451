import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class UrlCheckersInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const urlChecker = request.url.split('/').find(url => (
      url === 'null' ||
      url === 'undefined' ||
      url === 'NaN'
    ));

    let pathParameterChecker: boolean = false;
    if (request.url.includes('?')) {
      pathParameterChecker = !!request.url.split('?')[1].split('&').find(url => (
        url.split('=')[1] === 'null' ||
        url.split('=')[1] === 'undefined' ||
        url.split('=')[1] === 'NaN'
      ));
    }

    if (urlChecker || pathParameterChecker) {
      const tempUrl = request.url.split('?')[0];
      const error = new HttpErrorResponse({
        status: 417,
        error: { error: 'path parameter salah!' },
        url: tempUrl
      });
      return throwError(error) as any;
    } else {
      return next.handle(request);
    }
  }
}
