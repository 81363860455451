export class EnumPayrollStatus {
    static payrollStatusSelect: any = [
        { name: 'Draft', enum: 'draft' },
        { name: 'Diajukan HR', enum: 'request_approval' },
        { name: 'Dibatalkan', enum: 'soft_deleted' },
        { name: 'Disetujui Manajer', enum: 'manager_approved' },
        { name: 'Disetujui Finance', enum: 'finance_approved' },
        { name: 'Ditolak Manajer', enum: 'manager_rejected' },
        { name: 'Ditolak Finance', enum: 'finance_rejected' },
        { name: 'Payslip', enum: 'completed_skip_disbursement' },
        { name: 'Dalam Antrian', enum: 'queued' },
        { name: 'Diproses', enum: 'processed' },
        { name: 'Sukses Sebagian', enum: 'partial_completed' },
        { name: 'Sukses', enum: 'completed' },
        { name: 'Gagal', enum: 'failed' },
        { name: 'Retry', enum: 'retried' },
        { name: 'Approved', enum: 'approved' },
        { name: 'Rejected', enum: 'rejected' },
    ];

    static payrollMessageApproval: any = [
        { name: 'kembali ke konsep', enum: 'draft' },
        { name: 'melewati proses approval', enum: 'request_approval' },
        { name: 'melewati proses pembatalan', enum: 'soft_deleted' },
        { name: 'disetujui manager', enum: 'manager_approved' },
        { name: 'disetujui finance', enum: 'finance_approved' },
        { name: 'ditolak manajer', enum: 'manager_rejected' },
        { name: 'ditolak finance', enum: 'finance_rejected' },
    ];

    static payrollTitleApproval: any = [
        { name: 'Kembalikan Payroll ke Draft', enum: 'draft' },
        { name: 'Ajukan Payroll', enum: 'request_approval' },
        { name: 'Batalkan Payroll', enum: 'soft_deleted' },
        { name: 'Setujui Payroll', enum: 'manager_approved' },
        { name: 'Setujui Payroll', enum: 'finance_approved' },
        { name: 'Tolak Payroll', enum: 'manager_rejected' },
        { name: 'Tolak Payroll', enum: 'finance_rejected' },
        { name: 'Konversi Menjadi Payslip', enum: 'completed_skip_disbursement' },
        { name: 'Disburse Payroll', enum: 'processed' },
    ];

    static payrollButtonApproval: any = [
        { name: 'Kembali ke Draft', enum: 'draft' },
        { name: 'Ajukan', enum: 'request_approval' },
        { name: 'Batalkan', enum: 'soft_deleted' },
        { name: 'Setuju', enum: 'manager_approved' },
        { name: 'Setuju', enum: 'finance_approved' },
        { name: 'Tolak', enum: 'manager_rejected' },
        { name: 'Tolak', enum: 'finance_rejected' },
        { name: 'Convert', enum: 'completed_skip_disbursement' },
        { name: 'Disburse', enum: 'processed' },
    ];

    static payrollStatusFilter: any = [
        { name: 'All', enum: '' },
        { name: 'Draft', enum: 'draft' },
        { name: 'Diajukan HR', enum: 'request_approval' },
        { name: 'Dibatalkan', enum: 'soft_deleted' },
        { name: 'Disetujui Manajer', enum: 'manager_approved' },
        { name: 'Disetujui Finance', enum: 'finance_approved' },
        { name: 'Ditolak Manajer', enum: 'manager_rejected' },
        { name: 'Ditolak Finance', enum: 'finance_rejected' },
        { name: 'Payslip', enum: 'completed_skip_disbursement' },
        { name: 'Dalam Antrian', enum: 'queued' },
        { name: 'Diproses', enum: 'processed' },
        { name: 'Sukses Sebagian', enum: 'partial_completed' },
        { name: 'Sukses', enum: 'completed' },
        { name: 'Gagal', enum: 'failed' },
    ];

    static generatePayrollStatus(status: string): string {
        const message = EnumPayrollStatus.payrollStatusSelect.find((fil: any) => fil.enum === status.toLowerCase());
        return (message) ? message.name : 'Unknown Status';
    }
    static generatePayrollMessage(status: string): string {
        const message = EnumPayrollStatus.payrollMessageApproval.find((fil: any) => fil.enum === status.toLowerCase());
        return (message) ? message.name : 'Unknown Message';
    }
    static generatePayrollTitle(status: string): string {
        const message = EnumPayrollStatus.payrollTitleApproval.find((fil: any) => fil.enum === status.toLowerCase());
        return (message) ? message.name : 'Unknown Title';
    }
    static generatePayrollButton(status: string): string {
        const message = EnumPayrollStatus.payrollButtonApproval.find((fil: any) => fil.enum === status.toLowerCase());
        return (message) ? message.name : 'Unknown Button';
    }
}
