import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';

import { HttpConfigService } from 'src/app/_service/http-config.service';
import { environment } from 'src/environments/environment';

const API_ATTENDANCE_URL = `${environment.apiUrl}/company`;

@Injectable({
  providedIn: 'root'
})
export class AttendanceHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getDataAbsen(companyId: number, filter: FilterModel): Observable<Response> {
    const url = `${API_ATTENDANCE_URL}/${companyId}/attendance?${filter.query}page=${filter.page}&${filter.size ? (filter.size > 200 ? `xlimit=${filter.size}` : `limit=${filter.size}`) : 'limit=20'}&sort=${filter.sort ? filter.sort : 'day'}:${filter.asc ? 'asc' : 'desc'}&metadata=true`;
    return this.http.get<Response>(url);
  }

  getAbsenByDate(companyId: number, userId: number, dateYmD: string): Observable<Response> {
    return this.http.get<Response>(`${API_ATTENDANCE_URL}/${companyId}/employee/${userId}/attendance/${dateYmD}`);
  }

  getMonthlyReport(companyId: number, month: number, year: number): Observable<any> {
    return this.http.get<any>(`${API_ATTENDANCE_URL}/${companyId}/attendance/monthly/${year}/${month}`);
  }
}
