export class EnumWorkDuration {
    public static workDurationSelect: any = [
        { name: '< 2 tahun', enum: '1,24' },
        { name: '2 tahun - 5 tahun', enum: '24,60' },
        { name: '5 tahun - 10 tahun', enum: '60,120' },
        { name: '> 10 tahun', enum: '120' },
    ];

    public static findWorkDuration(workDuration: string): any {
        return EnumWorkDuration.workDurationSelect.find((fin: any) => fin.enum === workDuration.trim());
    }
}
