export class EnumPaymentType {
    public static paymentTypeSelect: any = [
        { name: 'Periodical (Salary)', enum: 'pay_periodical' },
        { name: 'Performance Fee', enum: 'pay_performance' },
        { name: 'Project Based', enum: 'pay_project' },
        { name: 'Duration', enum: 'pay_duration' },
        { name: 'Pro Bono (Volunteer)', enum: 'pay_probono' },
    ];

    public static generatePayment(payment: string): string {
        const tempPayment = EnumPaymentType.paymentTypeSelect.find((fin: any) => fin.enum === payment.trim().toLowerCase());
        return (tempPayment) ? tempPayment.name : '-';
    }

    public static findPayment(paymentType: string): any {
        return EnumPaymentType.paymentTypeSelect.find((fin: any) => fin.enum === paymentType.trim().toLowerCase());
      }
}
