import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RemoteLoggingService } from './remote-logging.service';

@Injectable()
export class ErrorHandlingService implements ErrorHandler {

  constructor(private remoteLogging: RemoteLoggingService) { }

  handleError(error: Error): void {
    this.remoteLogging.errorStackDetails(error, '417');
  }
}


@Injectable({
  providedIn: 'root'
})
export class ErrorJsonHandlingService {

  constructor(private remoteLogging: RemoteLoggingService) { }

  handleJsonError(action: string, message: any, payload: any): Observable<any> {
    // tslint:disable-next-line:new-parens
    const error: Error = new Error;
    error.message = message;
    error.name = action;
    error.stack = payload;
    this.remoteLogging.errorStackDetails(error, '422');

    const errorHttp = new HttpErrorResponse({
      status: 422,
      statusText: 'JSON Payload Error',
      error: { error: message },
      url: action
    });
    return throwError(errorHttp) as any;
  }
}
