import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RemoteLoggingClass, RemoteLoggingModel } from 'src/app/_class';
import { RemoteLoggingHTTPService } from './service-http';

@Injectable({
  providedIn: 'root'
})
export class RemoteLoggingService {

  constructor(
    private remoteLogsHttp: RemoteLoggingHTTPService
  ) { }

  logDetails(
    event: HttpResponseBase,
    payload: any,
    method: string,
    type: 'debug' | 'info',
    module?: 'attendance' | 'motorist' | 'payroll' | 'applicant' | 'worker' | 'company' | 'kasbon' | 'kancil' | 'kini' | 'leave-approval' | 'leave-request'
  ): void {
    this.sendLog(RemoteLoggingClass.formatSuccessLogging(event, payload, method, type, module));
  }

  errorDetails(
    error: HttpErrorResponse,
    payload: any,
    method: string,
    type: 'error' | 'warning',
    module?: 'attendance' | 'motorist' | 'payroll' | 'applicant' | 'worker' | 'company' | 'kasbon' | 'kancil' | 'kini' | 'leave-approval' | 'leave-request'
  ): void {
    this.sendLog(RemoteLoggingClass.formatErrorLogging(error, payload, method, type, module));
  }

  errorStackDetails(error: Error, code: '417' | '422'): void {
    this.sendLog(RemoteLoggingClass.formatErrorStack(error, code));
  }

  sendLog(payload: RemoteLoggingModel): void {
    if (
      (environment.production || environment.staging) &&
      payload.apiPath !== 'HttpErrorResponse'
    ) {
      this.remoteLogsHttp.postRemoteLogs(payload).subscribe();
    } else {
      console.log(payload);
    }

    // Debugging Purpose
    // else this.remoteLogsHttp.postRemoteLogs(payload).subscribe();
  }
}
