/* tslint:disable:no-non-null-assertion */
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {finalize, map, mergeMap, switchMap} from 'rxjs/operators';
import {DataService, HttpConfigService} from 'src/app/_service';
import {
  ShiftAssignmentClass,
  ShiftAssignmentModel,
  ShiftBoardModel,
  PutShiftAssignmentModel,
  PatchShiftAssignmentModel,
  PostShiftAssignmentModel
} from '..';
import {ShiftAssignmentHTTPService} from './attendance-http';
import {CompanyShiftService, HolidayModel} from '../../manage-companies';

@Injectable({
  providedIn: 'root'
})
export class ShiftAssignmentService {

  isLoading$: Observable<boolean>;
  currentAssignment$: Observable<any>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentAssignmentSubject: BehaviorSubject<any>;

  constructor(
    private config: HttpConfigService,
    private dataService: DataService,
    private companyService: CompanyShiftService,
    private assignmentHttp: ShiftAssignmentHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentAssignmentSubject = new BehaviorSubject<any>(null);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.currentAssignment$ = this.currentAssignmentSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }

  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getHoliday(companyId: number): Observable<any> {
    const objHoliday: HolidayModel = {} as HolidayModel;

    this.isLoadingSubject.next(true);
    return this.assignmentHttp.getCompanyHoliday(companyId).pipe(
      switchMap((arg: any) => {
        objHoliday.company = arg.data;
        return this.assignmentHttp.getCompanyHoliday(companyId);
      }),
      map((arg: any) => {
        objHoliday.national = arg.data;
        return objHoliday;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getShiftAssignment(companyId: number, query: string, page: number, size: number): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.assignmentHttp.getShiftAssign(companyId, query, page, size).pipe(
      map((result: any) => {
        if (result) {
          this.currentAssignmentSubject.next(result);
        }
        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  postShiftAssignment(
    companyId: number,
    data: ShiftAssignmentModel[],
    getParams?: {
      query: string,
      page: number,
      size: number
    } | null
  ): Observable<Response | any> {
    const rawData = ShiftAssignmentClass.formatAssignment(data);
    if (!rawData.length) {
      return of(rawData);
    }

    if (getParams) {
      this.dataService.buttonTopbarLoading(true);
      return this.assignmentHttp.postShiftAssign(companyId, rawData).pipe(
        switchMap(() => this.companyService.getCompanyShift(companyId)),
        switchMap(() => this.getShiftAssignment(companyId, getParams.query, getParams.page, getParams.size)),
        finalize(() => this.dataService.buttonTopbarLoading(false))
      );
    } else {
      return this.assignmentHttp.postShiftAssign(companyId, rawData).pipe(
        switchMap(() => this.companyService.getCompanyShift(companyId))
      );
    }
  }

  putShiftAssignment(
    companyId: number,
    data: PutShiftAssignmentModel[],
    getParams: {
      query: string,
      page: number,
      size: number
    }
  ): Observable<Response | any> {
    // Debugging Purpose
    // console.log(data);
    // return of(data);

    const rawAssignmentArray: PostShiftAssignmentModel[] = [];

    // ? Sengaja agar tidak merombak logic
    data.forEach(element => {
      const rawAssignment: PostShiftAssignmentModel = {} as PostShiftAssignmentModel;
  
      rawAssignment.userID = element.data.userId;
      rawAssignment.shiftID = element.data.shiftID;
      rawAssignment.shiftDateStart = element.data.shiftDateStart;
      rawAssignment.shiftDateEnd = element.data.shiftDateEnd;

      rawAssignmentArray.push(rawAssignment);
    });
    
    this.dataService.buttonTopbarLoading(true);
    return this.assignmentHttp.postShiftAssign(companyId, rawAssignmentArray).pipe(
      switchMap(() => this.companyService.getCompanyShift(companyId)),
      switchMap(() => this.getShiftAssignment(companyId, getParams.query, getParams.page, getParams.size)),
      finalize(() => this.dataService.buttonTopbarLoading(false))
    );
  }

  patchShiftAssignment(
    companyId: number,
    data: PatchShiftAssignmentModel[],
    getParams: {
      query: string,
      page: number,
      size: number
    } | null
  ): Observable<Response | any> {
    // Debugging Purpose
    // console.log(data);
    // return of(data);

    this.dataService.buttonTopbarLoading(true);
    return from(data).pipe(
      mergeMap((el) => {
        return this.assignmentHttp.patchShiftAssign(companyId, el);
      }),
      switchMap(() => this.getShiftAssignment(
        companyId,
        getParams!.query,
        getParams!.page,
        getParams!.size
      )),
      finalize(() => this.dataService.buttonTopbarLoading(false))
    );
  }

  deleteSingleShiftAssignment(
    companyId: number,
    data: ShiftBoardModel[],
    getParams: {
      query: string,
      page: number,
      size: number
    }
  ): Observable<Response | any> {
    // Debugging Purpose
    // console.log(data);
    // return of(data);

    this.dataService.buttonTopbarLoading(true);
    return from(data).pipe(
      mergeMap((el) => {
        return this.assignmentHttp.deleteSingleShiftAssign(companyId, el.userId, el.date!);
      }),
      switchMap(() => this.getShiftAssignment(
        companyId,
        getParams!.query,
        getParams!.page,
        getParams!.size
      )),
      finalize(() => this.dataService.buttonTopbarLoading(false))
    );
  }

  deleteShiftAssignment(
    companyId: number,
    dataDelete: ShiftBoardModel[],
    dataPost: ShiftAssignmentModel[],
    getParams: {
      query: string,
      page: number,
      size: number
    }
  ): Observable<any> {
    const rawData = ShiftAssignmentClass.formatDeletedAssignment(dataDelete);
    if (!rawData.length) {
      this.postShiftAssignment(companyId, dataPost, getParams);
    }

    this.dataService.buttonTopbarLoading(true);
    return this.assignmentHttp.deleteShiftAssign(companyId, rawData).pipe(
      switchMap(() => this.postShiftAssignment(companyId, dataPost)),
      switchMap(() => this.getShiftAssignment(companyId, getParams.query, getParams.page, getParams.size)),
      switchMap(() => this.companyService.getCompanyShift(companyId)),
      finalize(() => this.dataService.buttonTopbarLoading(false))
    );
  }

  // End ofLogic Before Hit API


  filterService(
    employeePick: FormControl,
    departmentPick: FormControl,
    shiftPick: FormControl,
  ): string {
    const fullName = (employeePick.value) ? `full_name=${employeePick.value}` : null;
    const department = (departmentPick.value) ? `department=${departmentPick.value}` : null;
    const shift = (shiftPick.value) ? `shift_id=${shiftPick.value}` : null;

    const data = [fullName, department, shift];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    return filteredData.join('&');
  }
}
