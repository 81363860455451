import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { GlobalConstants } from './global-variable.class';

import * as moment from 'moment';
moment.locale('id');

export interface RemoteLoggingModel {
    apiPath?: string | null;
    code?: string;
    email?: string;
    message: string;
    module: string;
    logLevel: string;
    platform: string;
    environment: string;
    timestamp: string;
    userAgent?: string;
    userId?: string;
    payloadRequest?: any;
    payloadResponse?: any;
}

export class RemoteLoggingClass {

    public static formatSuccessLogging(
      event: any,
      payload: any,
      method: string,
      type: string,
      module?: string
    ): RemoteLoggingModel {

        const browserVersion = RemoteLoggingClass.detectBrowserVersion();
        const remoteLogging = {} as RemoteLoggingModel;

        remoteLogging.environment = environment.production ? 'production' : 'staging';
        remoteLogging.message = event.statusText;
        remoteLogging.platform = 'wmt_web';
        remoteLogging.timestamp = moment().format();
        remoteLogging.module = module ? module : 'worker';
        remoteLogging.apiPath = `[${method}] ${event.url}`;
        remoteLogging.code = event.status + '';
        remoteLogging.userAgent = browserVersion;
        remoteLogging.logLevel = type;
        remoteLogging.payloadRequest = (payload && typeof payload === 'object') ? payload : null;
        remoteLogging.payloadResponse = (event.body && typeof event.body === 'object') ? event.body : null;
        remoteLogging.email = GlobalConstants.userEmail;
        remoteLogging.userId = GlobalConstants.userId + '';

        return remoteLogging;
    }

    public static formatErrorLogging(
      event: HttpErrorResponse,
      payload: any,
      method: string,
      type: string,
      module?: string
    ): RemoteLoggingModel {

        const browserVersion = RemoteLoggingClass.detectBrowserVersion();
        const remoteLogging = {} as RemoteLoggingModel;

        remoteLogging.environment = environment.production ? 'production' : 'staging';
        remoteLogging.message = event.statusText;
        remoteLogging.platform = 'wmt_web';
        remoteLogging.timestamp = moment().format();
        remoteLogging.module = module ? module : 'worker';
        remoteLogging.apiPath = `[${method}] ${event.url}`;
        remoteLogging.code = event.status + '';
        remoteLogging.userAgent = browserVersion;
        remoteLogging.logLevel = type;
        remoteLogging.payloadRequest = (payload && typeof payload === 'object') ? payload : null;
        remoteLogging.payloadResponse = (event.error && typeof event.error === 'object') ? event.error : null;
        remoteLogging.email = GlobalConstants.userEmail;
        remoteLogging.userId = GlobalConstants.userId + '';

        return remoteLogging;
    }

    public static formatErrorStack(
        error: Error,
        code: '417' | '422'
    ): RemoteLoggingModel {

        const browserVersion = RemoteLoggingClass.detectBrowserVersion();
        const remoteLogging = {} as RemoteLoggingModel;

        remoteLogging.environment = environment.production ? 'production' : 'staging';
        remoteLogging.message = error.message;
        remoteLogging.platform = 'wmt_web';
        remoteLogging.timestamp = moment().format();
        remoteLogging.module = 'worker';
        remoteLogging.apiPath = error.name;
        remoteLogging.code = code;
        remoteLogging.userAgent = browserVersion;
        remoteLogging.logLevel = 'error';
        remoteLogging.payloadRequest = (code === '417') ? {
            stack: error.stack
        } : error.stack;
        remoteLogging.payloadResponse = null;
        remoteLogging.email = GlobalConstants.userEmail;
        remoteLogging.userId = GlobalConstants.userId + '';

        return remoteLogging;
    }

    static detectBrowserVersion(): string {
        const userAgent = navigator.userAgent;
        let tem: any;
        let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])){
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (matchTest[1] === 'Chrome'){
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
        }
        matchTest = matchTest[2] ?
            [matchTest[1], matchTest[2]] :
            [navigator.appName, navigator.appVersion, '-?'];
        tem = userAgent.match(/version\/(\d+)/i);
        if (tem) {
            matchTest.splice(1, 1, tem[1]);
        }
        return matchTest.join(' ');
    }
}
