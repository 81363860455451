import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from 'src/app/_class';
import { environment } from 'src/environments/environment';

const API_CAMPAIGN_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CampaignHTTPService {

  constructor(private http: HttpClient) {}

  getCampaignGoapi(filter: FilterModel): Observable<any>{
    const url = `${API_CAMPAIGN_URL}/campaign?${filter.query ? filter.query : ''}metadata=true&page=${filter.page ? filter.page : 0}&limit=${filter.size ? filter.size : 100}&sort=id:desc`;
    return this.http.get<any>(url);
  }
  getCampaignIdGoapi(id: number): Observable<any>{
    return this.http.get<any>(`${API_CAMPAIGN_URL}/campaign/${id}`);
  }
  getDashboard(company_id: number, query: any){
    return this.http.get<any>(`${API_CAMPAIGN_URL}/dashboardjobfair?company_id=${company_id}${query ? '&'+query : ''}`);
  }

  //for company
  getCampaignCompany(company_id: number, page?: number, size?: number): Observable<any>{
    const url = `${API_CAMPAIGN_URL}/campaign?&metadata=true&company_id=${company_id}&page=${page ? page : 0}&limit=${size ? size : 100}&sort=id:desc`;
    return this.http.get<any>(url);
  }

  postCampaignGoapi(param: any){
    return this.http.post<Response>(`${API_CAMPAIGN_URL}/campaign`, param);
  }

  putCampaignGoapi(id: number, param: any): Observable<Response> {
    return this.http.put<Response>(`${API_CAMPAIGN_URL}/campaign/${id}`, param);
  }

  patchCampaignGoapi(id: number, param: any): Observable<Response> {
    return this.http.patch<Response>(`${API_CAMPAIGN_URL}/campaign/${id}`, param);
  }

  deleteCampaignGoapi(id: number): Observable<Response> {
    return this.http.delete<Response>(`${API_CAMPAIGN_URL}/campaign/${id}`);
  }
}
