import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { EmployeeBankModel, ResponseEmployeeBank } from '..';
import { EmployeeBankHTTPService } from './employee-http';

@Injectable({
  providedIn: 'root'
})
export class EmployeeBankService {

  // public fields
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private employeeBankHttp: EmployeeBankHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getEmployeeBank(companyId: number, userId: number): Observable<ResponseEmployeeBank> {
    // const auth = this.authService.getAuthFromLocalStorage();
    // if (!auth || !auth.id_token || !auth.companyId) {
    //   return of(undefined);
    // }

    this.isLoadingSubject.next(true);
    return this.employeeBankHttp.getEmployeeBank(companyId, userId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitEmployeeBank(companyId: number, userId: number, bankId: number | null, data: any): Observable<Response> {
    this.isSubmitedSubject.next(true);
    if (bankId) {
      return this.employeeBankHttp.updateEmployeeBank(companyId, userId, bankId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.employeeBankHttp.createEmployeeBank(companyId, userId, data).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deleteEmployeeBank(companyId: number, userId: number, bankId: number): Observable<Response> {
    this.isSubmitedSubject.next(true);
    return this.employeeBankHttp.deleteEmployeeBank(companyId, userId, bankId).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  checkEmployeeBank(companyId: number, userId: number, bankId: number): Observable<ResponseEmployeeBank> {
    this.isSubmitedSubject.next(true);
    return this.employeeBankHttp.checkEmployeeBank(companyId, userId, bankId).pipe(
      switchMap(() => this.getEmployeeBank(companyId, userId)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API


  // Generate Bank Model
  generateBankModel(rawBank: EmployeeBankModel): EmployeeBankModel {
    const dataBank = {} as EmployeeBankModel;
    dataBank.bankAccount = rawBank.bankAccount;
    dataBank.bankBeneficiary = rawBank.bankBeneficiary;
    dataBank.bankEmail = rawBank.bankEmail;
    dataBank.bankName = rawBank.bankName;
    dataBank.differenceBeneficiary = rawBank.differenceBeneficiary;
    dataBank.selected = true;
    return dataBank;
  }
  // End of Generate Bank Model
}
