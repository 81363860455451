import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpConfigService } from 'src/app/_service/http-config.service';

const API_SIGNUP_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class SignupHTTPService {

  constructor(
    private http: HttpClient,
    private config: HttpConfigService
  ) { }

  openSnackBar(status: number, error: HttpErrorResponse) {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string) {
    this.config.openSnackBar(status, undefined, message);
  }

  accountRegister(data: any): Observable<Response> {
    return this.http.post<Response>(`${API_SIGNUP_URL}/account/register`, data);
  }
  companyRegister(userId: number, data: any): Observable<Response> {
    return this.http.post<Response>(`${API_SIGNUP_URL}/company_self_register/${userId}`, data);
  }
  companyRegisterByAdmin(data: any): Observable<Response> {
    return this.http.post<Response>(`${API_SIGNUP_URL}/company`, data);
  }
}
