/* tslint:disable:no-non-null-assertion */
import {Injectable} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {SessionStorage} from 'src/app/_class';
import {EnumRegex} from 'src/app/_enum';
import {DataService, TagsService} from 'src/app/_service';
import {
  UploadEmployeeData, EnumEmploymentType, EnumEmployeeGender, EnumEmployeeRoles, IndexEmployeeVariables, EmployeeService
} from '..';

import * as moment from 'moment';
moment.locale('id');

@Injectable()
export class EmployeeClass {

  constructor(
    private dataService: DataService,
    private tagsService: TagsService,
    private employeeService: EmployeeService
  ) {
  }

  // Variables Initial
  initVariable(): IndexEmployeeVariables {
    const varModel = {} as IndexEmployeeVariables;

    const Session = SessionStorage.getStorageJson('employeeTags');
    const tempFreeText = this.tagsService.filterSession(Session, 'fullname');
    const tempTitle = this.tagsService.filterSession(Session, 'titles');
    const tempEmployment = this.tagsService.filterSession(Session, 'employment');
    const tempStatus = this.tagsService.filterSession(Session, 'employee_status');
    const tempDate = this.tagsService.filterSession(Session, 'date');

    varModel.tempQuery = SessionStorage.getStorage('employeeQuery');
    varModel.freeText = (tempFreeText.length) ? tempFreeText[0].value : null;
    varModel.titlePick = (tempTitle.length) ? tempTitle[0].value : null;
    varModel.employmentPick = (tempEmployment.length) ? tempEmployment[0].value : null;
    varModel.statusPick = (tempStatus.length) ? tempStatus[0].value : null;
    varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    varModel.page = SessionStorage.getStorageNumber('employeePageActive', 1);
    varModel.size = SessionStorage.getStorageNumber('employeePageSize', 10);
    varModel.sortBy = SessionStorage.getStorage('employeeSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('employeeSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(
    freeText: FormControl,
    titlePick: FormControl,
    employmentPick: FormControl,
    statusPick: FormControl,
    datePick: FormGroup,
    tags: {key: string, value: string}[]
  ): string {
    if (freeText.value) { tags = this.tagsService.updateKey(tags, 'fullname', freeText); }
    if (titlePick.value) { tags = this.tagsService.updateKey(tags, 'titles', titlePick); }
    if (employmentPick.value) { tags = this.tagsService.updateKey(tags, 'employment', employmentPick, 'array'); }
    if (statusPick.value) { tags = this.tagsService.updateKey(tags, 'employee_status', statusPick, 'array'); }
    if (datePick.value) { tags = this.tagsService.updateKey(tags, 'date', datePick, 'date'); }

    const freetext = freeText.value ? `full_name=${freeText.value}` : null;
    const title = titlePick.value ? `department=${titlePick.value}` : null;
    const employment = employmentPick.value ? `employment_type=${employmentPick.value.join(',')}` : null;
    const status = statusPick.value ? `status=${statusPick.value.join(',')}` : null;
    const date = datePick.value ? this.generateDateFilter(datePick) : null;

    const data = [freetext, title, employment, status, date];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('employeeQuery', tempQuery);
    sessionStorage.setItem('employeeTags', JSON.stringify(tags));
    return tempQuery;
  }

  removeTags(
    tags: {key: string, value: string}[],
    employmentPick: FormControl,
    statusPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
      if (tag.key === 'employment') { tags = this.tagsService.removeKey(tags, tag.key, employmentPick, 'array', indexTagValue); }
      else if (tag.key === 'employee_status') { tags = this.tagsService.removeKey(tags, tag.key, statusPick, 'array', indexTagValue); }
      else { tags = this.tagsService.removeKey(tags, tag.key); }

      sessionStorage.setItem('employeeTags', JSON.stringify(tags));
  }

  filterNonTags(
    titlePick: FormControl,
    employmentPick: FormControl,
    statusPick: FormControl
  ): string {
    const title = titlePick.value ? `department=${titlePick.value}` : null;
    const employment = employmentPick.value ? `employment_type=${employmentPick.value.join(',')}` : null;
    const status = statusPick.value ? `status=${statusPick.value.join(',')}` : null;

    const data = [title, employment, status];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    return filteredData.join('&');
  }
  // End Filter Logic

  // Generate Filter Value
  generateDateFilter(datePick: FormGroup): string | undefined {
    if (datePick.controls.begin.value && datePick.controls.end.value) {
      return `join_date_min=${datePick.controls.begin.value}:00Z` + '&' + `join_date_max=${datePick.controls.end.value}:00Z`;
    }
    if (datePick.controls.begin.value && !datePick.controls.end.value) {
      // return `transaction_date=gte:${datePick.controls.begin.value}:00Z`;
      return `join_date_min=${datePick.controls.begin.value}:00Z`;
    }
    if (!datePick.controls.begin.value && datePick.controls.end.value) {
      // return `transaction_date=lte:${datePick.controls.end.value}:00Z`;
      return `join_date_max=${datePick.controls.end.value}:00Z`;
    }

    return undefined;
  }
  // End of Generate Filter Value

  // Generate Pipe Value
  generateExacDuration(duration: number): string {
    let wordingSisa = '';
    if (duration && (duration / 30) > 12) {
      const sisaBulan = Math.floor(((duration / 30) % 12));
      wordingSisa = sisaBulan ? ` ${sisaBulan} bulan` : '';
    }
    return (duration) ? (
      ((duration / 30) > 12) ? Math.floor(((duration / 30) / 12)) + ' tahun' + wordingSisa : (
        (duration >= 30) ? Math.floor((duration / 30)) + ' bulan' : duration + ' hari'
      )
    ) : 'unknown';
  }
  // End of Generate Pipe Value

  // Upload CSV
  convertFile(files: File[], companyId: number): any {
    if (files.length > 0) {
      const input = files[0];

      const reader = new FileReader();
      reader.readAsText(input);
      return new Promise((resolve) => {
        reader.onload = () => {
          const Data: UploadEmployeeData[] = [];
          const text = reader.result as string;
          // console.log(text);
          const arrText = text.split(/\r?\n|\r/);
          const arrIndex: any = [];
  
          // Doble Checking Empty Array
          arrText.forEach((element, index) => {
            // Beware This When Add Field
            if (element === ',,,,,,,,,,,,,,,') { arrIndex.push(index); }
            if (element === ';;;;;;;;;;;;;;;') { arrIndex.push(index); }
          });
          for (let index = arrIndex.length; index > 0; index--) {
            const element = arrIndex[index - 1];
            arrText.splice(element, 1);
          }
          // End of Doble Checking Empty Array
  
          arrText.pop();
          // console.log(arrText);
          // return;
          try {
            // Ngetrap 200 Record
            if (arrText.length > 201) {
              // noinspection ExceptionCaughtLocallyJS
              throw new Error('Data yang diupload melebihi 200 record.');
            }
            // End of Ngetrap 200 Record
  
            arrText.forEach((element: any, index: number) => {
              // Column Name Validation
              if (!index) {
                const rawColumn = element.includes(';') ? element.split(';') : element.split(',');
                if (rawColumn[0].toLowerCase() !== 'nama') { throw new Error('Penamaan kolom ' + rawColumn[0] + ' tidak sesuai format. NB: Kolom 1'); }
                if (rawColumn[1].toLowerCase() !== 'email') { throw new Error('Penamaan kolom ' + rawColumn[1] + ' tidak sesuai format. NB: Kolom 2'); }
                if (rawColumn[2].toLowerCase() !== 'ktp') { throw new Error('Penamaan kolom ' + rawColumn[2] + ' tidak sesuai format. NB: Kolom 3'); }
                if (rawColumn[3].toLowerCase() !== 'telepon') { throw new Error('Penamaan kolom ' + rawColumn[3] + ' tidak sesuai format. NB: Kolom 4'); }
                if (rawColumn[4].toLowerCase() !== 'tipe pegawai') { throw new Error('Penamaan kolom ' + rawColumn[4] + ' tidak sesuai format. NB: Kolom 5'); }
                if (rawColumn[5].toLowerCase() !== 'jabatan') { throw new Error('Penamaan kolom ' + rawColumn[5] + ' tidak sesuai format. NB: Kolom 6'); }
                if (rawColumn[6].toLowerCase() !== 'departemen') { throw new Error('Penamaan kolom ' + rawColumn[6] + ' tidak sesuai format. NB: Kolom 7'); }
                if (rawColumn[7].toLowerCase() !== 'tanggal lahir') { throw new Error('Penamaan kolom ' + rawColumn[7] + ' tidak sesuai format. NB: Kolom 8'); }
                if (rawColumn[8].toLowerCase() !== 'jenis kelamin') { throw new Error('Penamaan kolom ' + rawColumn[8] + ' tidak sesuai format. NB: Kolom 9'); }
                if (rawColumn[9].toLowerCase() !== 'role') { throw new Error('Penamaan kolom ' + rawColumn[9] + ' tidak sesuai format. NB: Kolom 10'); }
                if (rawColumn[10].toLowerCase() !== 'akhir kontrak') { throw new Error('Penamaan kolom ' + rawColumn[10] + ' tidak sesuai format. NB: Kolom 11'); }
                if (rawColumn[11].toLowerCase() !== 'kode pegawai') { throw new Error('Penamaan kolom ' + rawColumn[11] + ' tidak sesuai format. NB: Kolom 12'); }
                if (rawColumn[12].toLowerCase() !== 'npwp') { throw new Error('Penamaan kolom ' + rawColumn[12] + ' tidak sesuai format. NB: Kolom 13'); }
                if (rawColumn[13].toLowerCase() !== 'no. rekening') { throw new Error('Penamaan kolom ' + rawColumn[13] + ' tidak sesuai format. NB: Kolom 14'); }
                if (rawColumn[14].toLowerCase() !== 'nama penerima') { throw new Error('Penamaan kolom ' + rawColumn[14] + ' tidak sesuai format. NB: Kolom 15'); }
                if (rawColumn[15].toLowerCase() !== 'bank') { throw new Error('Penamaan kolom ' + rawColumn[15] + ' tidak sesuai format. NB: Kolom 16'); }
              }
              // Column Name Validation
  
              if (index) {
                // Cara Anyar
                const rawData = element.includes(';') ? element.split(';') : element.split(',');
                // console.log(rawData);
                // End::Marsing Rupiah
  
                // Begin: Column Length
                // console.log(rawData.length);
                if (rawData.length !== 16) { throw new Error('Panjang kolom tidak sesuai format. Mohon periksa kembali apakah ada pemisah desimal dengan Koma. ' + 'Line ' + (index + 1)); }
                // End: Column Length
  
                // Email, Gaji Pokok, Tunj. Jabatan, Tunj. Transport, Tunj. Makan, THR, Pesangon, Bonus, JHT, JP, BPJS Kesehatan, TK, KK, JK,
                // Pph21, Admin, Absensi, Salary Advance, Total
                const tempData: UploadEmployeeData = {} as UploadEmployeeData;
                tempData.fullName = rawData[0];
                tempData.email = rawData[1];
                tempData.ktp = rawData[2].replace(/'/gi, '');
                tempData.phone = this.parsingPhone(rawData[3]).replace(/'/gi, '');
                tempData.employmentType = this.parsingEmployment(rawData[4]);
                tempData.title = rawData[5];
                tempData.department = rawData[6];
                tempData.birthDate = rawData[7];
                tempData.gender = this.parsingGender(rawData[8]);
                tempData.roles = this.parsingRoles(rawData[9]);
                tempData.endContractDate = rawData[10];
                tempData.employeeCode = rawData[11];
                tempData.npwp = rawData[12].replace(/'/gi, '');
                tempData.bankAccount = rawData[13].replace(/'/gi, '');
                tempData.bankBeneficiary = rawData[14];
                tempData.bankName = rawData[15];
  
                // console.log(tempData);
  
                // Cek Kosong Semua
                if (!tempData.fullName && !tempData.email && !tempData.phone && !tempData.employmentType && !tempData.title &&
                  !tempData.department && !tempData.birthDate && !tempData.gender && !tempData.roles
                ) {
                  throw new Error('Data karyawan tidak boleh kosong. ' + 'Line ' + (index + 1));
                }

                // console.log('debug 1');
  
                // Cek Regex Email
                const mailDomain = tempData.email.split('@')[1];
                if (
                  !EnumRegex.parsingRegex('email')!.test(tempData.email.trim()) ||
                  mailDomain.includes('gnail.') ||
                  mailDomain.includes('.con')
                ) {
                  throw new Error('Email tidak valid. ' + 'Line ' + (index + 1));
                }

                // console.log('debug 2');
                
                // Cek Email Huruf Depan Angka
                if (!isNaN(Number(tempData.email[0])) && tempData.email.includes('@gmail')) {
                  throw new Error('Karakter pertama email tidak boleh angka ' + 'Line ' + (index + 1));
                }

                // console.log('debug 3');

                // Cek KTP
                if (tempData.ktp.length < 16) {
                  throw new Error('Ktp tidak valid. (min: 16 Karakter) ' + 'Line ' + (index + 1));
                }
                if (tempData.ktp.length > 16) {
                  throw new Error('Ktp tidak valid. (maks: 16 Karakter) ' + 'Line ' + (index + 1));
                }
                if (!EnumRegex.parsingRegex('ktp')!.test(tempData.ktp)) {
                  throw new Error('Ktp tidak valid. (penulisan tidak sesuai format ktp) ' + 'Line ' + (index + 1));
                }

                // console.log('debug 4');
  
                // Cek Department Length
                if (tempData.department.length < 2) {
                  throw new Error('Departemen tidak valid. (min: 2 Karakter) ' + 'Line ' + (index + 1));
                }
                if (tempData.department.length > 60) {
                  throw new Error('Departemen tidak valid. (maks: 60 Karakter) ' + 'Line ' + (index + 1));
                }
                if (!EnumRegex.parsingRegex('alpha_title')!.test(tempData.department)) {
                  throw new Error('Departemen tidak valid. (angka dan karakter spesial tidak diperbolehkan) ' + 'Line ' + (index + 1));
                }

                // console.log('debug 5');
  
                // Cek Title Length
                if (tempData.title.length < 2) {
                  throw new Error('Jabatan tidak valid. (min: 2 Karakter) ' + 'Line ' + (index + 1));
                }
                if (tempData.title.length > 128) {
                  throw new Error('Jabatan tidak valid. (maks: 128 Karakter) ' + 'Line ' + (index + 1));
                }
                if (!EnumRegex.parsingRegex('alpha_title')!.test(tempData.title)) {
                  throw new Error('Jabatan tidak valid. (angka dan karakter spesial tidak diperbolehkan) ' + 'Line ' + (index + 1));
                }

                // console.log('debug 6');
  
                // Cek Employee Code
                // J2G-4349 - Employee Code 0 semua tidak valid
                let zeroLength: number = 0;
                [...tempData.employeeCode].forEach((element: string) => {
                  if (element === '0') {
                    zeroLength++;
                  }
                });
                if (zeroLength === tempData.employeeCode.length) {
                  throw new Error('Kode pegawai tidak valid. ' + 'Line ' + (index + 1));
                }

                // console.log('debug 7');

                // Cek NPWP
                if (tempData.npwp.length < 15) {
                  throw new Error('Npwp tidak valid. (min: 15 Karakter) ' + 'Line ' + (index + 1));
                }
                if (tempData.npwp.length > 15) {
                  throw new Error('Npwp tidak valid. (maks: 15 Karakter) ' + 'Line ' + (index + 1));
                }

                // console.log('debug 8');
  
                // Cek Invalid Enum
                if (tempData.employmentType === 'err' || tempData.gender === 'err' || tempData.roles === 'err' || tempData.phone === 'err') {
                  throw new Error(((tempData.employmentType === 'err') ? 'Tipe Karyawan' : (
                      (tempData.gender === 'err') ? 'Jenis Kelamin' : (
                        (tempData.roles === 'err') ? 'Role' : 'Telepon'
                      )
                    )
                  ) + ' tidak valid, Mohon disesuaikan dengan template. ' + 'Line ' + (index + 1));
                }

                // console.log('debug 9');
  
                // Cek Invalid Date
                if (!/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/.test(tempData.birthDate)) {
                  throw new Error('Format Tanggal Lahir tidak valid, Mohon disesuaikan dengan template. ' + 'Line ' + (index + 1));
                } else if (moment().diff(moment(tempData.birthDate, 'DD/MM/YYYY'), 'years') < 18) {
                  throw new Error('Pegawai belum cukup umur, usia minimal 18 tahun. ' + 'Line ' + (index + 1));
                } else {
                  tempData.birthDate = moment(tempData.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }

                // console.log('debug 10');
  
                // Cek Invalid Cotract Date
                if (tempData.endContractDate) {
                  if (!/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/.test(tempData.endContractDate)) {
                    throw new Error('Format Tanggal Kontrak tidak valid, Mohon disesuaikan dengan template. ' + 'Line ' + (index + 1));
                  } else if (moment(tempData.endContractDate, 'DD/MM/YYYY').diff(moment(), 'days') < 0) {
                    throw new Error('Tanggal kontrak minimal adalah hari ini. ' + 'Line ' + (index + 1));
                  } else {
                    tempData.endContractDate = moment(tempData.endContractDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                  }
                }
                // End: Data Validation

                // console.log('debug 11');
  
                // Setup Company ID and Password
                // tempData.companyID = companyId;
                // tempData.password = tempData.phone.replace('+62', '0');
                Data.push(tempData);
              }
            });
          } catch (error: any) {
            this.dataService.errorEvent([]);
            this.employeeService.openCustomBar(417, error);
            return;
          }
  
          // console.log(Data);
          // return;
          resolve(Data);
        };
      });
    }
  }

  parsingPhone(rawData: string): string {
    if (!rawData) { return ''; }

    return (rawData[0] + rawData[1] === '62') ? '+' + rawData : (
      (rawData[0] === '0') ? '+62' + rawData.slice(1) : 'err'
    );
  }
  parsingEmployment(rawData: string): string {
    if (!rawData) { return ''; }

    const tempEmployment = EnumEmploymentType.employmentTypeSelect.find((ind: any) => ind.name === rawData);
    if (tempEmployment) { return tempEmployment.enum; }
    else { return 'err'; }
  }
  parsingGender(rawData: string): string {
    if (!rawData) { return ''; }

    const tempGender = EnumEmployeeGender.employeeGenderUpload.find((ind: any) => ind.name === rawData);
    if (tempGender) { return tempGender.enum.toLowerCase(); }
    else { return 'err'; }
  }
  parsingRoles(rawData: string): string {
    if (!rawData) { return 'err'; }

    const tempRoles = EnumEmployeeRoles.employeeRolesSelect.find((ind: any) => ind.name === rawData);
    if (tempRoles) { return tempRoles.enum; }
    else { return 'err'; }
  }
}
