import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpConfigService } from '../http-config.service';

const API_AUTH_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class AuthHTTPService {

  constructor(
    private http: HttpClient,
    private config: HttpConfigService,
  ) { }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  customSnackbar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  login(data: any): Observable<Response> {
    return this.http.post<Response>(`${API_AUTH_URL}/account/authenticate`, data, this.config.httpOptions());
  }

  userActivation(key: string): Observable<Response> {
    return this.http.get<Response>(`${API_AUTH_URL}/account/activation/${key}`, this.config.httpOptions());
  }

  // getDataUserLogin(): Observable<Response> {
  //   return this.http.get<Response>(`/api/account/`, {
  //     headers: { Authorization: 'Bearer ' + localStorage.getItem('id_token') }})
  //   .pipe(catchError(this.config.handleErrorReturnAll));
  // }
  // getDataByEmail(email: string) {
  //   return this.http
  //   .get<Response>(`api/_search/users/email:("${email}")`, {
  //     headers:{"Authorization": 'Bearer '+localStorage.getItem('id_token') } })
  //   .pipe( catchError(this.config.handleErrorReturnAll));
  // }

  getUserAuthorities(): Observable<any> {
    return this.http.get<any>(`${API_AUTH_URL}/account/me`);
  }

  // getUserByLogin(login: string): Observable<Response> {
  //   return this.http.get<Response>('/api/users/' + login, this.config.httpOptionsDefault())
  //     .pipe(catchError(this.config.handleErrorReturnAll));
  // }

  // changePassword(param: {currentPassword: string, newPassword: string}): Observable<Response> {
  //   return this.http.post<Response>('/api/account/change-password', param, this.config.httpOptionsDefault())
  //     .pipe(map(response => response), catchError(this.config.handleError));
  // }

  forgotPassword(param: {email: string}): Observable<Response> {
    return this.http.post<Response>(`${API_AUTH_URL}/account/reset/${param.email}`, '', this.config.httpOptions());
  }
  resetPassword(email: string, param: {resetKey: string, password: string}): Observable<Response> {
    return this.http.post<Response>(`${API_AUTH_URL}/account/replace_password/${email}`, param, this.config.httpOptions());
  }
  // updateUser(data: any): Observable<Response> {
  //   return this.http.put<Response>(`/api/users`, data, this.config.httpOptionsDefault())
  //     .pipe(catchError(this.config.handleErrorReturnAll));
  // }

  // emailVerification(email: any): Observable<Response> { // Send email forget password
  //   return this.http.post<Response>(`api/worker/account/verify-email/init`, email, this.config.httpOptionsDefault());
  // }
  // emailpinVerification(verifiedEmail: any): Observable<Response> { // Send pin & new password forget password
  //   return this.http.post<Response>(`api/worker/account/verify-email/finish`, verifiedEmail, this.config.httpOptionsDefault());
  // }

  //oauth
  // ---Google--- //
  linkToGoogle(param: any): Observable<Response> {
    return this.http.post<Response>(`${API_AUTH_URL}/auth/google/link`, param, this.config.httpOptions());
  }
  deleteToGoogle(){
    return this.http.delete<Response>(`${API_AUTH_URL}/auth/google/unlink`, this.config.httpOptions());
  }

  // ---FB--- //
  linkToFB(param: any): Observable<Response> {
    return this.http.post<Response>(`${API_AUTH_URL}/auth/facebook/link`, param, this.config.httpOptions());
  }
  deleteToFB(){
    return this.http.delete<Response>(`${API_AUTH_URL}/auth/facebook/unlink`, this.config.httpOptions());
  }

  // ---Apple--- //
  linkToApple(param: any): Observable<Response> {
    return this.http.post<Response>(`${API_AUTH_URL}/auth/apple/link`, param, this.config.httpOptions());
  }
  deleteToApple(){
    return this.http.delete<Response>(`${API_AUTH_URL}/auth/apple/unlink`, this.config.httpOptions());
  }

}
