export class EnumHowDoYou {
    public static howDoYouSelect: any = [
        { name: 'Twitter', enum: 'twitter' },
        { name: 'Facebook', enum: 'facebook' },
        { name: 'Instagram', enum: 'instagram' },
        { name: 'LinkedIn', enum: 'linkedin' },
        { name: 'Mailing List', enum: 'mailingList' },
        { name: 'Telegram', enum: 'telegram' },
        { name: 'Whatsapp', enum: 'whatsapp' },
        { name: 'Acquittance', enum: 'acquittance' },
        { name: 'Email', enum: 'email' },
        { name: 'Job Fair', enum: 'jobfair' },
        { name: 'Website', enum: 'website' },
        { name: 'Telepon', enum: 'phone' },
        { name: 'Lainnya', enum: 'other' },
    ];

    // ! Gabisa di lower case soalnya ada enum camelCase [jobFair & mailingList]

    public static generateHowDoYou(howdoyou: string): string {
        const tempHowDoYou = EnumHowDoYou.howDoYouSelect.find((fin: any) => fin.enum === howdoyou.trim());
        return (tempHowDoYou) ? tempHowDoYou.name : '-';
    }

    public static findHowDoYou(howdoyou: string): any {
        return EnumHowDoYou.howDoYouSelect.find((fin: any) => fin.enum === howdoyou.trim());
    }
}
