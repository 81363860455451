import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, retry, switchMap } from 'rxjs/operators';
import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service';
import { ResponsePayroll } from '..';
import { PayrollHTTPService } from './payroll-http';

export type ResponseType = ResponsePayroll | undefined;

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  // public fields
  currentPayroll$: Observable<ResponseType>;
  currentPayrollBalance$: Observable<any>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentPayrollSubject: BehaviorSubject<ResponseType>;
  currentPayrollBalanceSubject: BehaviorSubject<any>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private payrollHttp: PayrollHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentPayrollSubject = new BehaviorSubject<ResponseType>(undefined);
    this.currentPayrollBalanceSubject = new BehaviorSubject<any>(undefined);
    this.currentPayroll$ = this.currentPayrollSubject.asObservable();
    this.currentPayrollBalance$ = this.currentPayrollBalanceSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse, showMessage?: boolean): void {
    this.config.openSnackBar(status, error, undefined, showMessage);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  getCompanyBalance(companyId: number): Observable<Response | any> {
    return this.payrollHttp.getBalance(companyId).pipe(
      map((balance: any) => {
        // checking balance
        if (balance) {
          this.currentPayrollBalanceSubject.next(balance);
        }
        return balance;
      })
    );
  }

  getPayroll(companyId: number, filter: FilterModel): Observable<ResponseType> {
    this.isLoadingSubject.next(true);
    return this.payrollHttp.getPayroll(companyId, filter).pipe(
      map((payroll: ResponseType) => {
        // checking payroll
        if (payroll) {
          this.currentPayrollSubject.next(payroll);
        }
        return payroll;
      }),
      retry(2), // ? Jaga Jaga kalo Error ditengah jalan
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitPayroll(companyId: number, id: number | null, data: any, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    if (id) {
      return this.payrollHttp.updatePayroll(companyId, id, data).pipe(
        switchMap(() => this.getPayroll(companyId, filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.payrollHttp.createPayroll(companyId, data).pipe(
        switchMap(() => this.getPayroll(companyId, filter)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  deletePayroll(companyId: number, id: number, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.payrollHttp.deletePayroll(companyId, id).pipe(
      switchMap(() => this.getPayroll(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  checkStatus(companyId: number, id: number, filter: FilterModel): Observable<ResponseType> {
    this.isSubmitedSubject.next(true);
    return this.payrollHttp.getPayrollStatus(companyId, id).pipe(
      switchMap(() => this.getPayroll(companyId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
