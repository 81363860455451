import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from 'src/app/_service/form.service';
import { CustomValidators } from 'src/app/_class/custom-validator.class';

export interface DialogReasonData {
  title: string;
  description: string;
  placeholder: string;
  notes?: string;
  inputType?: 'password' | 'email' | 'phone';
  confirmation?: boolean;
  cancelButton?: string;
  submitButton?: string;
}

@Component({
  selector: 'app-material-advance-dialog',
  templateUrl: './material-advance-dialog.component.html',
  styleUrls: ['./material-advance-dialog.component.scss']
})
export class MaterialAdvanceDialogComponent implements OnInit {

  formDialog!: FormGroup;

  // Password
  hide = true;
  hideConfirm = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogReasonData,
    public modal: MatDialogRef<MaterialAdvanceDialogComponent>,
    private snackBar: MatSnackBar,
    private formService: FormService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (!this.data.inputType) {
      this.formDialog = this.formBuilder.group({
        reason: [null, [Validators.required]],
      });
    }
    if (this.data.inputType && this.data.inputType === 'password') {
      this.formDialog = this.formBuilder.group({
        password: [null, [Validators.required]],
        confirmPassword: [null],
      });

      if (this.data.confirmation) { this.formDialog.controls.confirmPassword.setValidators(Validators.required); }
    }
    if (this.data.inputType && this.data.inputType === 'email') {
      this.formDialog = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        confirmEmail: [null],
      });

      if (this.data.confirmation) { this.formDialog.controls.confirmEmail.setValidators(Validators.required); }
    }
    if (this.data.inputType && this.data.inputType === 'phone') {
      this.formDialog = this.formBuilder.group({
        phone: [null, [Validators.required, CustomValidators.patternValidator(
          /^[+62][1-9]\d{8,13}$/, { hasSpecialNumbers: true }
        )]],
        confirmPhone: [null],
      });

      if (this.data.confirmation) { this.formDialog.controls.confirmPhone.setValidators(Validators.required); }
    }
  }

  submitReason(): void {
    Object.values(this.formDialog.controls).forEach(control => { control.markAsTouched(); });

    //   // stop here if form is invalid
    if (this.formDialog.invalid) {
      console.log(this.formDialog);
      this.snackBar.open('Ada kesalahan pengisian data. Tolong periksa lagi isianmu ya.', 'Tutup', {duration: 4000});
      return;
    }

    if (this.data.confirmation) {
      if (
        (this.data.inputType === 'password') &&
        (this.formDialog.controls.password.value !== this.formDialog.controls.confirmPassword.value)
      ) {
        this.formDialog.controls.confirmPassword.setErrors({passwordNotSame: true});
        this.snackBar.open('Konfirmasi password tidak sama dengan password', 'Tutup', {duration: 4000});
        return;
      }
      if ((this.data.inputType === 'email') && (this.formDialog.controls.email.value !== this.formDialog.controls.confirmEmail.value)) {
        this.formDialog.controls.confirmEmail.setErrors({emailNotSame: true});
        this.snackBar.open('Konfirmasi email tidak sama dengan email', 'Tutup', {duration: 4000});
        return;
      }
      if ((this.data.inputType === 'phone') && (this.formDialog.controls.phone.value !== this.formDialog.controls.confirmPhone.value)) {
        this.formDialog.controls.confirmPhone.setErrors({phoneNotSame: true});
        this.snackBar.open('Konfirmasi kontak tidak sama dengan kontak', 'Tutup', {duration: 4000});
        return;
      }
    }

    const rawData = this.formDialog.value;

    this.modal.close(rawData);
  }

  // convenience getter for easy access to form fields
  get fDialog(): any {
    return this.formDialog.controls;
  }
  form(name: string): boolean {
    return this.formService.form(this.formDialog, name);
  }

  generateMessage(name: string): string {
    let message: string;
    if (name === 'phone') {
      message = (this.fDialog[name].errors?.required) ? 'Nomor telepon wajib di isi' : (
        'Mohon isikan nomor telepon yang benar (i.e: +6221992882)'
      );
    } else if (name === 'email') {
      message = (this.fDialog[name].errors?.required) ? 'Alamat email wajib di isi' : (
        'Tolong isikan alamat email yang benar (i.e: nama@company.com)'
      );
    } else if (name === 'password') {
      message = 'Password wajib di isi';
    } else if (name === 'confirmPassword') {
      message = this.fDialog[name].errors?.passwordNotSame ? 'Konfirmasi password salah.' : 'Konfirmasi password wajib di isi.';
    } else if (name === 'confirmEmail') {
      message = this.fDialog[name].errors?.emailNotSame ? 'Konfirmasi email salah.' : 'Konfirmasi email wajib di isi.';
    } else if (name === 'confirmPhone') {
      message = this.fDialog[name].errors?.phoneNotSame ? 'Konfirmasi phone salah.' : 'Konfirmasi phone wajib di isi.';
    } else {
      message = 'Alasan wajib di isi';
    }
    return message;
  }
}
