import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { GlobalConstants, SessionStorage } from "src/app/_class";
import { ApplicantClass, IndexJfApplicantVariables } from "..";
import { CampaignStorage } from "../../campaign";
import { CompanyStorage } from "../../jobfair";

import * as moment from "moment";
moment.locale('id');

@Injectable()
export class ApplicantCampaignClass {

  constructor(
    private applicantClass: ApplicantClass,
    // private notification: PushNotificationService,
  ) {}

  // Variables Initial
  initVariable(): IndexJfApplicantVariables {
    const varModel = {} as IndexJfApplicantVariables;
    let campaignStorage: CampaignStorage = SessionStorage.getStorageJson('campaignStorage');
    let companyStorage: CompanyStorage = SessionStorage.getStorageJson('companyStorage');

    varModel.tempQuery = SessionStorage.getStorage('jfApplicantQuery');
    varModel.campaignPick = campaignStorage?.campaignSelected ?
      campaignStorage.campaignSelected :
      GlobalConstants.campaignId;
    varModel.companyPick = companyStorage?.companySelected ?
      companyStorage.companySelected :
      GlobalConstants.companyId;
    // varModel.campaignPick = (isAdmin) ? SessionStorage.getStorageNumber('jfApplicantCampaignSelected') : GlobalConstants.campaignId;
    // varModel.companyPick =  SessionStorage.getStorageNumber('jfApplicantCompanySelected');
    varModel.statusPick = SessionStorage.getStorage('jfApplicantStatusSelected');
    // varModel.jobPick = (tempJob.length) ? tempJob[0].value : null;
    // varModel.agePick = (tempAge.length) ? tempAge[0].value : null;
    // varModel.workDurationPick = (tempWorkDuration.length) ? tempWorkDuration[0].value : null;
    // // varModelCurrentSalaryPick. = (tempCurrentSalary.length) ? tempCurrentSalary[0].value : null;
    // varModel.expectedSalaryMin = (tempExpectedSalary.length) ? tempExpectedSalary[0].value.min : null;
    // varModel.expectedSalaryMax = (tempExpectedSalary.length) ? tempExpectedSalary[0].value.max : null;
    // varModel.cityPick = (tempCity.length) ? tempCity[0].value : null;
    // varModel.genderPick = (tempGender.length) ? tempGender[0].value : null;
    // varModel.transportPick = (tempTransport.length) ? tempTransport[0].value : null;
    // // varModelDocumentPick. = (tempDocument.length) ? tempDocument[0].value : null;
    // varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    // varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    // varModel.tags = Session ? Session : [];

    return varModel;
  }
  // End of Variables Initial

  // Filter Logic
  filterService(
    companyPick: number,
    statusPick: string,
    jobPick: FormControl,
    agePick: FormControl,
    // workDurationPick: FormControl,
    expectedSalaryPick: FormGroup,
    cityPick: string,
    genderPick: FormControl,
    transportPick: FormControl,
    datePick: FormGroup,
    // tags: {key: string, value: string}[]
  ): string {
    // if (agePick.value) tags = this.tagsService.updateKey(tags, 'age', agePick);
    // if (workDurationPick.value) tags = this.tagsService.updateKey(tags, 'workDuration', workDurationPick);
    // if (expectedSalaryPick.value) tags = this.tagsService.updateKey(tags, 'expectedSalary', expectedSalaryPick, 'amount');
    // if (cityPick.value) tags = this.tagsService.updateKey(tags, 'city', cityPick, 'array');
    // if (genderPick.value) tags = this.tagsService.updateKey(tags, 'gender', genderPick, 'array');
    // if (transportPick.value) tags = this.tagsService.updateKey(tags, 'transport', transportPick, 'array');
    // if (datePick.value) tags = this.tagsService.updateKey(tags, 'date', datePick, 'date');

    const idCompany = (companyPick) ? `company_id=${companyPick}` : null;
    const status = (statusPick) ? `status=${statusPick.toLowerCase()}` : null;
    const job = jobPick.value ? `title=${jobPick.value}` : null;
    const age = agePick.value ? `${this.applicantClass.generateAge(agePick.value)}` : null;
    // const workDuration = workDurationPick.value ? `${this.generateWorkDuration(workDurationPick.value)}` : null;
    // const currentSalaryPick = currentSalaryPick.value ? `current_salary:(${currentSalaryPick.value})` : null;
    const expectedSalary = expectedSalaryPick.value.max ? `expected_salary_min=${expectedSalaryPick.value.min}&expected_salary_max=${expectedSalaryPick.value.max}` : null;
    const city = cityPick ? `worker_address_city=${cityPick}` : null;
    const gender = genderPick.value ? `gender=${genderPick.value.join(',').toLowerCase()}` : null;
    const tempTransport = transportPick.value ? `${this.applicantClass.generateTransport(transportPick.value)}` : null;
    // const tempDocument = documentPick.value ? `docs=${documentPick.value.join(', ').toLowerCase()}` : null;
    const date = datePick.value.end ? `application_date_min=${moment(datePick.value.begin).format('YYYY-MM-DDT00:00:00')}Z&application_date_max=${moment(datePick.value.end).format('YYYY-MM-DDT00:00:00')}Z` : null;

    const data = [
      idCompany, status, job, age/* , workDuration */, expectedSalary, city, gender, tempTransport, date
    ];

    // console.log(data);

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    SessionStorage.setStorage('jfApplicantQuery', tempQuery);
    // sessionStorage.setItem('applicantTags', JSON.stringify(tags));
    return tempQuery;
  }

  eventFilter(/* tags: {key: string, value: string}[],  */event?: any, autoType?: string): {
    campaignPick: number,
    companyPick: number,
    jobPick: string
  } {
    const eventModel = {} as {
      campaignPick: number,
      companyPick: number,
      jobPick: string
    };
    const tempJobControl = new FormControl();

    // console.log(event);

    if (event.id) {
      if (autoType === 'campaign') {
        eventModel.campaignPick = event.id;
        let campaignStorage: CampaignStorage = SessionStorage.getStorageJson('campaignStorage') || {};
        campaignStorage.campaignSelected = event.id;
        campaignStorage.campaignName = event.campaignName;
        campaignStorage.startDate = event.startDate;
        campaignStorage.endDate = event.endDate;
        campaignStorage.status = event.status;

        sessionStorage.setItem('campaignStorage', JSON.stringify(campaignStorage));
        sessionStorage.removeItem('companyStorage');
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      if (autoType === 'company') {
        eventModel.companyPick = event.companyId;

        let companyStorage: CompanyStorage = SessionStorage.getStorageJson('companyStorage') || {};
        companyStorage.campaignSelected = event.campaignId;
        companyStorage.companySelected = event.companyId;
        companyStorage.companyName = event.companyName;
        sessionStorage.setItem('companyStorage', JSON.stringify(companyStorage));
      }
      if (autoType === 'job') {
        eventModel.jobPick = event.jobTitle;
        tempJobControl.setValue(event.jobTitle);
        // tags = this.tagsService.updateKey(tags, 'job', tempJobControl);
      }      
    } else {
      if (autoType === 'campaign') { // Reset Campaign Filter
        sessionStorage.removeItem('campaignStorage');
        sessionStorage.removeItem('companyStorage');
        GlobalConstants.campaignIdPickedbyAdmin = NaN;
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      if (autoType === 'company') { // Reset Company Filter
        sessionStorage.removeItem('companyStorage');
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      sessionStorage.removeItem('jfApplicantQuery');
      // sessionStorage.removeItem('applicantTags');
    }

    return eventModel;
  }

  // ! Won't Do
  // removeTags(
  //   tags: {key: string, value: string}[],
  //   cityPick: FormControl,
  //   genderPick: FormControl,
  //   transportPick: FormControl,
  //   tag: any,
  //   indexTagValue?: number): void {
  //     // Debugging Purpose
  //     // console.log(tag, indexTagValue, tag.key);
  //     // return;

  //     // if (tag.key === 'freetext') { this.arrFreeText.splice(ind, 1); if (!this.arrFreeText.length) this.tags.splice(index, 1); }
  //     if (tag.key === 'city') tags = this.tagsService.removeKey(tags, tag.key, cityPick, 'array', indexTagValue);
  //     else if (tag.key === 'gender') tags = this.tagsService.removeKey(tags, tag.key, genderPick, 'array', indexTagValue);
  //     else if (tag.key === 'transport') tags = this.tagsService.removeKey(tags, tag.key, transportPick, 'array', indexTagValue);
  //     else tags = this.tagsService.removeKey(tags, tag.key); // by default yang bukan array disini

  //     sessionStorage.setItem('applicantTags', JSON.stringify(tags));
  // }
  // End Filter Logic

}