import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, map, switchMap} from 'rxjs/operators';
import {HttpConfigService} from 'src/app/_service';
import {CompanyHolidayModel, NationalHolidayModel} from '../../attendance';
import {ConfigHTTPService} from '../../attendance/services/attendance-http';
import {CompanyShiftHTTPService} from './company-http';
import {ResponseCompanyShift} from '../models/response.model';

export interface HolidayModel {
  company: CompanyHolidayModel;
  national: NationalHolidayModel;
}

export type ResponseTypeShift = ResponseCompanyShift | undefined;

@Injectable({
  providedIn: 'root'
})
export class CompanyShiftService {

  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentShift$: Observable<ResponseTypeShift>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;
  currentShiftSubject: BehaviorSubject<ResponseTypeShift>;

  constructor(
    private config: HttpConfigService,
    private shiftHttp: CompanyShiftHTTPService,
    private configHttp: ConfigHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentShiftSubject = new BehaviorSubject<ResponseTypeShift>(undefined);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
    this.currentShift$ = this.currentShiftSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }

  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  getHoliday(companyId: number): Observable<any> {
    const objHoliday: HolidayModel = {} as HolidayModel;

    this.isLoadingSubject.next(true);
    return this.configHttp.getCompanyHoliday(companyId, '', 0, 50).pipe(
      switchMap((arg: any) => {
        objHoliday.company = arg.data;
        return this.configHttp.getNationalHoliday('');
      }),
      map((arg: any) => {
        objHoliday.national = arg.data;
        return objHoliday;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getCompanyShift(companyId: number): Observable<ResponseTypeShift> {
    this.isLoadingSubject.next(true);
    return this.shiftHttp.getCompanyShift(companyId).pipe(
      map((result: ResponseTypeShift) => {
        if (result) {
          this.currentShiftSubject.next(result);
        }

        return result;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  submitCompanyShift(companyId: number, shiftId: number | null, data: any): Observable<ResponseTypeShift> {
    if (!shiftId) {
      this.isSubmitedSubject.next(true);
      return this.shiftHttp.postCompanyShift(companyId, data).pipe(
        switchMap(() => this.getCompanyShift(companyId)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      this.isSubmitedSubject.next(true);
      return this.shiftHttp.putCompanyShift(companyId, shiftId, data).pipe(
        switchMap(() => this.getCompanyShift(companyId)),
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  delCompanyShift(companyId: number, shiftId: number): Observable<ResponseTypeShift> {
    this.isSubmitedSubject.next(true);
    return this.shiftHttp.delCompanyShift(companyId, shiftId).pipe(
      switchMap(() => this.getCompanyShift(companyId)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  // End ofLogic Before Hit API
}
