import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { GlobalConstants, SessionStorage } from "src/app/_class";
import { CampaignStorage } from "../../campaign";
import { CompanyStorage, IndexJfJobVariables } from "../../jobfair";

import * as moment from "moment";
moment.locale('id');

@Injectable()
export class JobCampaignClass {

  constructor() {}

  // Variables Initial
  initVariable(): IndexJfJobVariables {
    const varModel = {} as IndexJfJobVariables;
    let campaignStorage: CampaignStorage = SessionStorage.getStorageJson('campaignStorage');
    let companyStorage: CompanyStorage = SessionStorage.getStorageJson('companyStorage');

    varModel.tempQuery = sessionStorage.getItem('jfJobsQuery')!;
    varModel.campaignPick = campaignStorage?.campaignSelected ?
      campaignStorage.campaignSelected :
      GlobalConstants.campaignId;
    varModel.companyPick = companyStorage?.companySelected ?
      companyStorage.companySelected :
      GlobalConstants.companyId;
    // varModel.jobPick = sessionStorage.getItem('jfJobTitle')! ? sessionStorage.getItem('jfJobTitle')! : '';
    // varModel.cityPick = sessionStorage.getItem('jfJobCity')! ? sessionStorage.getItem('jfJobCity')! : '';

    return varModel;
  }
  // End of Variables Initial

  //Filter Service
  filterService(
    freeText: FormControl,
    cityPick: FormControl,
    datePick: FormGroup,
    statusPick: FormControl
  ) {
    const freetext = (freeText.value ? `title=${freeText.value}` : null);
    // const city = cityPick.value ? `company_city=${cityPick.value}` : null;
    const date = datePick.value.end ? `job_start_date=${moment(datePick.value.begin).format('YYYY-MM-DDT00:00:00')}Z&job_end_date=${moment(datePick.value.end).format('YYYY-MM-DDT00:00:00')}Z` : null;
    const status = statusPick.value ? `job_status=${statusPick.value.toLowerCase()}` : null;

    const data = [freetext, date, status];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => (el ? true : false) === true);
    let tempQuery = filteredData.join('&');
    sessionStorage.setItem('jfJobsQuery', String(tempQuery));
    return tempQuery;
  }

  eventFilter(/* tags: {key: string, value: string}[],  */event?: any, autoType?: 'campaign' | 'company') {
    let eventModel = {} as {
      campaignPick: number,
      companyPick: number,
      // jobPick: string
    }
    // let tempJobControl = new FormControl();

    console.log(event);

    if (event.id) {
      if (autoType === 'campaign') {
        eventModel.campaignPick = event.id;
        let campaignStorage: CampaignStorage = SessionStorage.getStorageJson('campaignStorage') || {};
        campaignStorage.campaignSelected = event.id;
        campaignStorage.campaignName = event.campaignName;
        campaignStorage.startDate = event.startDate;
        campaignStorage.endDate = event.endDate;
        campaignStorage.status = event.status;

        sessionStorage.setItem('campaignStorage', JSON.stringify(campaignStorage));
        sessionStorage.removeItem('companyStorage');
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      if (autoType === 'company') {
        eventModel.companyPick = event.companyId;
        
        let companyStorage: CompanyStorage = SessionStorage.getStorageJson('companyStorage') || {};
        companyStorage.campaignSelected = event.campaignId;
        companyStorage.companySelected = event.companyId;
        companyStorage.companyName = event.companyName;
        sessionStorage.setItem('companyStorage', JSON.stringify(companyStorage));
      }
      // if (autoType === 'job') {
      //   eventModel.jobPick = event.jobTitle;
      //   tempJobControl.setValue(event.jobTitle);
      //   // tags = this.tagsService.updateKey(tags, 'job', tempJobControl);
      // }
    } else {
      if (autoType === 'campaign') { // Reset Campaign Filter
        sessionStorage.removeItem('campaignStorage');
        sessionStorage.removeItem('companyStorage');
        GlobalConstants.campaignIdPickedbyAdmin = NaN;
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      if (autoType === 'company') { // Reset Company Filter
        sessionStorage.removeItem('companyStorage');
        GlobalConstants.companyIdPickedbyAdmin = NaN;
      }
      sessionStorage.removeItem('jfJobsQuery');
      // sessionStorage.removeItem('jfJobsTags');
    }
    return eventModel;
  }

}