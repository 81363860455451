import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize, map, switchMap } from 'rxjs/operators';

import { FilterModel } from 'src/app/_class';
import { EmployeeContractHTTPService } from './employee-http';
import { ResponseContract } from '..';

@Injectable({
  providedIn: 'root'
})
export class EmployeeContractService {

  // public fields
  currentContracts$: Observable<ResponseContract>;
  isLoading$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentContractsSubject: BehaviorSubject<ResponseContract>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  constructor(
    private contractHttp: EmployeeContractHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentContractsSubject = new BehaviorSubject<ResponseType | any>(undefined);
    this.currentContracts$ = this.currentContractsSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.contractHttp.openSnackBar(status, error);
  }

  openCustomBar(status: number, message?: string): void {
    this.contractHttp.openCustomBar(status, message);
  }


  // Logic Before Hit API
  // public method
  getContract(companyId: number, userId: number, filter: FilterModel): Observable<ResponseContract> {
    this.isLoadingSubject.next(true);
    return this.contractHttp.getContractList(companyId, userId, filter).pipe(
      map((employee: ResponseContract) => {
        // checking employee
        if (employee) {
          this.currentContractsSubject.next(employee);
        }
        return employee;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  patchContract(
    companyId: number,
    userId: number,
    data: { contractStatus: string },
    filter: FilterModel
  ): Observable<ResponseContract> {
    this.isSubmitedSubject.next(true);
    return this.contractHttp.patchContract(companyId, userId, data).pipe(
      switchMap(() => this.getContract(companyId, userId, filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }
}
