/* tslint:disable:no-non-null-assertion */
import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

// import {GlobalConstants} from 'src/app/_class';
import {DataService} from 'src/app/_service';
import {DialogConfigModel, ShiftAssignmentModel, ShiftBoardModel} from '../models/shift-assignment.model';
import {
  AssignmentConfirmationComponent,
  AssignmentConfirmationModel
} from '../modules/attendance-premium/shift-assignment/assignment-confirmation/assignment-confirmation.component';

import {ShiftAssignmentService} from '../services/shift-assignment.service';
import {ShiftAssignment} from './shift-assignment.class';
import {cloneDeep} from 'lodash';
import {PatchShiftAssignmentModel, PutShiftAssignmentModel} from '../models/shift-assignment-post.model';

@Injectable()
export class BoardHandler implements OnDestroy {

  // * Temporary Data
  updatedAssignment$: Observable<PutShiftAssignmentModel[]>;
  patchAssignment$: Observable<PatchShiftAssignmentModel[]>;
  updatedAssignmentSubject: BehaviorSubject<PutShiftAssignmentModel[]>;
  patchAssignmentSubject: BehaviorSubject<PatchShiftAssignmentModel[]>;

  onEditShift$: Observable<boolean>;
  onEditShiftSubject: BehaviorSubject<boolean>;

  private subscription: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private shiftAssignmentService: ShiftAssignmentService
  ) {
    // * Temporary Data
    this.updatedAssignmentSubject = new BehaviorSubject<PutShiftAssignmentModel[]>([]);
    this.patchAssignmentSubject = new BehaviorSubject<PatchShiftAssignmentModel[]>([]);
    this.updatedAssignment$ = this.updatedAssignmentSubject.asObservable();
    this.patchAssignment$ = this.patchAssignmentSubject.asObservable();

    this.onEditShiftSubject = new BehaviorSubject<boolean>(false);
    this.onEditShift$ = this.onEditShiftSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sb => {
      sb.unsubscribe();
    });
  }

  // sameDays(event: any): void {
  //   // ! Nuker Full Name & User Id
  //   event = ShiftAssignment.switchFullNameUserId(event);
  //   // console.log('container after switch fullname - same day', event.container.data, event.previousContainer.data, event.currentIndex, event.previousIndex);

  //   // # Sama2 ada assignmentnnya
  //   if (event.container.data[event.currentIndex].assignmentId && event.previousContainer.data[event.previousIndex].assignmentId) {

  //     // ? Set Patch Assignment Temporary Data
  //     this.lukirAssignment(event);
  //     ShiftAssignment.moveContainerData(event);

  //   // # Sama2 gaada assignmentnya
  //   } else if (!event.container.data[event.currentIndex].assignmentId && !event.previousContainer.data[event.previousIndex].assignmentId) {

  //     ShiftAssignment.moveContainerData(event);

  //   // # Salah satu gaada assignment
  //   } else {
  //     // Nanti dulu aja habis disscuss
  //     return;

  //     // ? Call API Delete
  //     // ? Belum di switch container masih belum ada perubahan
  //     const deleteSbcr = this.shiftAssignmentService.deleteSingleShiftAssignment(
  //       GlobalConstants.companyId,
  //       event.previousContainer.data[event.previousIndex].assignmentId
  //     )
  //       .subscribe(() => {
  //         ShiftAssignment.moveContainerData(event);
  //         delete event.container.data[event.currentIndex].assignmentId;

  //         // this.onEditShift = true;
  //         // this.dataService.showButtonTopbar('Simpan');
  //       }, (error: any) => {
  //         this.shiftAssignmentService.openSnackBar(error.status, error);
  //       });
  //     this.subscription.push(deleteSbcr);
  //   }
  // }

  // differenceDays(event: any): void {
  //   if (
  //     event.container.data[event.currentIndex].allowWeekend !== undefined &&
  //     event.container.data[event.currentIndex].allowWeekend === false &&
  //     !event.previousContainer.data[event.previousIndex].shiftName
  //   ) {
  //     return;
  //   }

  //   // ! Nuker Full Name & User Id
  //   event = ShiftAssignment.switchFullNameUserId(event);
  //   // console.log('container after switch fullname - difference day', event.container.data, event.previousContainer.data, event.currentIndex, event.previousIndex);

  //   // # Sama2 ada assignment
  //   if (
  //     event.container.data[event.currentIndex].assignmentId &&
  //     event.previousContainer.data[event.previousIndex].assignmentId
  //   ) {

  //     // ? Set Patch Assignment Temporary Data
  //     this.lukirAssignment(event);
  //     ShiftAssignment.switchContainerData(event);

  //   // # Sama2 gaada assignment
  //   } else if (
  //     !event.container.data[event.currentIndex].assignmentId &&
  //     !event.previousContainer.data[event.previousIndex].assignmentId
  //   ) {

  //     ShiftAssignment.switchContainerData(event);

  //   // # Salah satu gaada shift assignment
  //   } else {

  //     // Bubar nunggu disscuss dulu
  //     return;
  //     // ? Call API Delete
  //     // ? Belum di switch container masih belum ada perubahan
  //     const deleteSbcr = this.shiftAssignmentService.deleteSingleShiftAssignment(
  //       GlobalConstants.companyId,
  //       event.previousContainer.data[event.previousIndex].assignmentId
  //     )
  //       .subscribe(() => {
  //         ShiftAssignment.switchContainerData(event);
  //         delete event.container.data[event.currentIndex].assignmentId;

  //         //   this.onEditShift = true;
  //         //   this.dataService.showButtonTopbar('Simpan');
  //       }, (error: any) => {
  //         this.shiftAssignmentService.openSnackBar(error.status, error);
  //       });
  //     this.subscription.push(deleteSbcr);
  //   }
  // }

  shiftList(
    event: any,
    selectedUserId: number[],
    shiftsAssignment: ShiftAssignmentModel[],
    styleBackground: string[]
  ): void {
    const tempShiftId = event.previousContainer.data[event.previousIndex].id;
    const tempShiftName = event.previousContainer.data[event.previousIndex].shiftName;
    const tempShiftStart = event.previousContainer.data[event.previousIndex].start;
    const tempShiftEnd = event.previousContainer.data[event.previousIndex].end;
    const tempAllowWeekend = event.previousContainer.data[event.previousIndex].isWeekendAllowed;

    // ? 3.1 cegatan kalo assign ke shift board yang ada isinya
    if (event.container.data[event.currentIndex].shiftName) {

      // ? Handling untuk Drag dari Shift List ke Assignment yang ada isinya
      if (event.container.data[event.currentIndex].assignmentId) {
        this.openShiftConfirmation(
          shiftsAssignment,
          event,
          {
            tempShiftId,
            tempShiftName,
            tempShiftStart,
            tempShiftEnd,
            tempAllowWeekend,
            styleBackground,
            editShiftList: true
          }
        );
      } else {
        this.openShiftConfirmation(
          shiftsAssignment,
          event,
          {
            tempShiftId,
            tempShiftName,
            tempShiftStart,
            tempShiftEnd,
            tempAllowWeekend,
            styleBackground,
            selectedUserId
          }
        );
      }
    } else {

      // ? cegatan kalo assign ke shift board yang sudah terisi sesuai batas
      // tslint:disable-next-line:max-line-length
      // ? Misal Senin pagi hanya boleh "1" orang => jika sudah ada 1 orang yang mengisi Senin Pagi maka tidak boleh assign orang lain lagi ke Senin Pagi
      // if (tempDestinationDesc.length >= 1) {
      //   this.companyShift.openCustomBar(417, 'Perhatian, Shift telah terambil');
      // }
      // ? Lolos Assign Shift
      // else {
      this.openShiftConfirmation(
        shiftsAssignment,
        event,
        {
          tempShiftId,
          tempShiftName,
          tempShiftStart,
          tempShiftEnd,
          tempAllowWeekend,
          styleBackground,
          selectedUserId
        }
      );
      // }
    }
  }

  // Shift Confirmation
  openShiftConfirmation(shiftsAssignment: ShiftAssignmentModel[], event: any, config?: DialogConfigModel): void {
    const tempEvent = cloneDeep(event);
    const tempBoard: ShiftBoardModel = ShiftAssignment.setContainerData(
      tempEvent.container.data[tempEvent.currentIndex],
      tempEvent.previousIndex,
      config!.tempShiftId,
      config!.tempShiftName,
      config!.tempShiftStart,
      config!.tempShiftEnd,
      config!.tempAllowWeekend,
      config!.styleBackground
    );

    const dataConfirmation: AssignmentConfirmationModel = {} as AssignmentConfirmationModel;
    dataConfirmation.shiftAssignment = shiftsAssignment;
    dataConfirmation.tempBoard = Object.assign({}, tempBoard);
    if (config?.selectedUserId) {
      dataConfirmation.selectedUserId = config.selectedUserId;
    }

    // Debugging Purpose
    // console.log(tempBoard, dataConfirmation);

    const dialogRef = this.dialog.open(AssignmentConfirmationComponent, {
      width: '500px',
      data: dataConfirmation
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('dari konfirmasi', shiftsAssignment);

        // ? Dari Create Shift Kosongan
        if (!config?.editShiftList) {
          this.onEditShiftSubject.next(true);
          this.dataService.showButtonTopbar('Simpan');
          this.dataService.showButtonCancelTopbar('Batal');
          return;
        }

        // ? Dari Edit Shift
        if (config?.editShiftList) {
          // ? Set Put Assignment Temporary Data
          const tempArrAssignment: any = this.updatedAssignmentSubject.value;
          tempArrAssignment.push({
            assignmentId: tempBoard.assignmentId!,
            data: {
              userId: tempBoard.userId!,
              shiftID: tempBoard.shiftId!,
              shiftDateStart: result.startDate,
              shiftDateEnd: result.endDate
            }
          });
          this.updatedAssignmentSubject.next(tempArrAssignment);

          this.onEditShiftSubject.next(true);
          this.dataService.showButtonTopbar('Simpan');
          this.dataService.showButtonCancelTopbar('Batal');
        }
      } else {
        if (!config?.editShiftList) {
          delete tempBoard.shiftId;
          delete tempBoard.start;
          delete tempBoard.end;
          delete tempBoard.allowWeekend;
          delete tempBoard.class;
          tempBoard.shiftName = '';
        }
      }
    });
  }

  // Lukir Assignment
  lukirAssignment(event: any): void {
    // ? Set Patch Assignment Temporary Data
    const tempArrAssignment: any = this.patchAssignmentSubject.value;
    tempArrAssignment.push({
      sourceAssignID: event.previousContainer.data[event.previousIndex].assignmentId,
      destAssignID: event.container.data[event.currentIndex].assignmentId,
    });
    this.patchAssignmentSubject.next(tempArrAssignment);

    console.log(tempArrAssignment);

    this.onEditShiftSubject.next(true);
    this.dataService.showButtonTopbar('Simpan');
    this.dataService.showButtonCancelTopbar('Batal');
  }
}
