import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'payment',
    loadChildren: () => import('./layouts/main/pages/point/modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'ats-invitation',
    loadChildren: () => import('./layouts/main/pages/ats/modules/talent-invitation/talent-invitation.module').then((m) => m.TalentInvitationModule),
  },
  {
    path: '',
    loadChildren: () => import('./layouts/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./layouts/main/main.module').then((m) => m.MainModule),
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
