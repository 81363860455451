export class EnumLeaveType {
    public static leaveTypeSelect: any = [
        { name: 'Cuti', enum: 'holiday_leave' },
        { name: 'Sakit', enum: 'sick_leave' },
        { name: 'Menikah', enum: 'marital_leave' },
        { name: 'Bersalin', enum: 'maternity_leave' },
    ];

    public static generateLeaveType(leave: string): string {
        const tempLeave = EnumLeaveType.leaveTypeSelect.find((fil: any) => fil.enum === leave);
        return (tempLeave) ? tempLeave.name : 'Unknown';
    }

    public static findLeaveType(leave: string): any {
        return EnumLeaveType.leaveTypeSelect.find((fin: any) => fin.enum === leave.trim().toLowerCase());
    }
}
