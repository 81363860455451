export class EnumGender {
    public static genderSelect: any = [
        { name: 'Pria', enum: 'male' },
        { name: 'Wanita', enum: 'female' },
    ];

    public static genderShort: any = [
        { name: 'L', enum: 'male' },
        { name: 'P', enum: 'female' },
    ];

    public static generateGender(gender: string, isShort?: boolean): string {
        let tempGender: any;
        if (isShort) {
            tempGender = EnumGender.genderShort.find((fin: any) => fin.enum === gender.trim().toLowerCase());
        } else {
            tempGender = EnumGender.genderSelect.find((fin: any) => fin.enum === gender.trim().toLowerCase());
        }
        return (tempGender) ? tempGender.name : '-';
    }

    public static findGender(gender: string): any {
        return EnumGender.genderSelect.find((fin: any) => fin.enum === gender.trim().toLowerCase());
    }
}
