/* tslint:disable:no-non-null-assertion */
import { EnumRegex } from 'src/app/_enum/regex.enum';
import { EnumEmployeeGender, EnumEmployeeMaritalStatus } from '../../employee-list';
// import { EnumCity } from "../../manage-job";
import { WorkerProfileModel } from './worker-profile.model';

import * as moment from 'moment';
moment.locale('id');

interface PutWorkerProfileModel {
    fullName: string | null;
    gender: string | null;
    birthDate: string | null;
    maritalStatus: string | null;
    siblingNo: number | null;
    siblings: number | null;
    medicalRecords?: any;
    imageUrl: string | null;
    addressStreet: string | null;
    addressDistrict: string | null;
    addressSubDistrict: string | null;
    addressCity: string | null;
    addressZip: string | null;
    facebookUrl: string | null;
    googleUrl: string | null;
    twitterUrl: string | null;
    linkedInUrl: string | null;
    instagramUrl: string | null;
    websiteUrl: string | null;
    hasMotor: boolean;
    hasCar: boolean;
    expectedSalary: number | null;
    preferenceCategories: string | null;
    preferenceCommuting: string | null;
    preferenceLocations: string | null;
    preferenceSkills: string | null;
    preferenceLanguages: string | null;
    timePreference: TimePreference;
}

interface TimePreference {
    onMondays: boolean;
    onTuesdays: boolean;
    onWednesdays: boolean;
    onThursdays: boolean;
    onFridays: boolean;
    onSaturdays: boolean;
    onSundays: boolean;
    shiftWork01: boolean;
    shiftWork02: boolean;
    shiftWork03: boolean;
    shiftWork04: boolean;
    shiftWork05: boolean;
    shiftWorkAll: boolean;
}

export class WorkerProfileClass {

    static objModel = [
        'fullName', 'gender', 'birthDate', 'maritalStatus', 'siblingNo', 'siblings', 'medicalRecords', 'imageUrl', 'addressStreet',
        'addressDistrict', 'addressSubDistrict', 'addressCity', 'addressZip', 'facebookUrl', 'googleUrl', 'twitterUrl',
        'linkedInUrl', 'instagramUrl', 'websiteUrl', 'hasMotor', 'hasCar', 'expectedSalary', 'preferenceCategories', 'preferenceCommuting',
        'preferenceLocations', 'preferenceSkills', 'preferenceLanguages', 'timePreference'
    ];

    public static workerProfileValidation(payload: PutWorkerProfileModel): string | null {
        const objPayload = Object.keys(payload);
        const objInvalid: string[] = [];


        // ? Validasi Object Key Payload
        objPayload.forEach((element) => {
            if (!WorkerProfileClass.objModel.includes(element)) { objInvalid.push(element); }
        });
        if (objInvalid.length) { return objInvalid.toString() + ' is invalid payload'; }


        // ? Validasi Mandatory Payload
        if (!payload.fullName || !payload.birthDate || !payload.gender || !payload.maritalStatus ||
            !payload.addressStreet || !payload.addressCity || !payload.addressDistrict || !payload.addressSubDistrict ||
            !payload.addressZip
        ) {
            return 'empty mandatory payload detected';
        }


        // ? Validasi Regex Payload
        const regexFullname = EnumRegex.parsingRegex('full_name')!.test(payload.fullName);
        const regexBoD = EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.birthDate);
        const regexStreet = EnumRegex.parsingRegex('address')!.test(payload.addressStreet);
        const regexCity = EnumRegex.parsingRegex('letter_35')!.test(payload.addressCity);
        const regexDistrict = EnumRegex.parsingRegex('letter_25')!.test(payload.addressDistrict);
        const regexSubDistrict = EnumRegex.parsingRegex('alpha_sub_district_25')!.test(payload.addressSubDistrict);
        const regexZip = EnumRegex.parsingRegex('zip')!.test(payload.addressZip);
        const regexZipChar = (payload.addressZip[0] !== '0');
        // let regexFb = EnumRegex.parsingRegex('sosmed_fb').test(payload.facebookUrl);
        // let regexIg = EnumRegex.parsingRegex('sosmed_ig').test(payload.instagramUrl);
        // let regexTw = EnumRegex.parsingRegex('sosmed_tw').test(payload.twitterUrl);
        // let regexLn = EnumRegex.parsingRegex('sosmed_ln').test(payload.linkedInUrl);
        // let regexWebsite = EnumRegex.parsingRegex('web').test(payload.websiteUrl);

        if (!regexFullname) { return 'full name invalid'; }
        if (!regexBoD) { return 'birth date invalid'; }
        if (!regexStreet) { return 'address street invalid'; }
        if (!regexCity/*  || !EnumCity.findCity(payload.addressCity) */) { return 'address city invalid'; }
        if (!regexDistrict) { return 'address district invalid'; }
        if (!regexSubDistrict) { return 'address subdistrict invalid'; }
        if (!regexZip || !regexZipChar) { return 'address zip invalid'; }
        if (!EnumEmployeeGender.findGender(payload.gender)) { return 'gender invalid'; }
        if (!EnumEmployeeMaritalStatus.findMaritalStatus(payload.maritalStatus)) { return 'marital status invalid'; }
        // if (payload.facebookUrl && !regexFb) { return 'facebook url invalid'; }
        // if (payload.instagramUrl && !regexIg) { return 'instagram url invalid'; }
        // if (payload.twitterUrl && !regexTw) { return 'twitter url invalid'; }
        // if (payload.linkedInUrl && !regexLn) { return 'linkedin url invalid'; }
        // if (payload.websiteUrl && !regexWebsite) { return 'web url invalid'; }
        if (payload.preferenceCategories && (typeof payload.preferenceCategories !== 'string')) { return 'preference categories invalid'; }
        if (payload.preferenceCommuting && (typeof payload.preferenceCommuting !== 'string')) { return 'preference commuting invalid'; }
        if (payload.preferenceLanguages && (typeof payload.preferenceLanguages !== 'string')) { return 'preference languages invalid'; }
        if (payload.preferenceLocations && (typeof payload.preferenceLocations !== 'string')) { return 'preference locations invalid'; }
        if (payload.preferenceSkills && (typeof payload.preferenceSkills !== 'string')) { return 'preference skills invalid'; }
        if (payload.siblingNo && (typeof payload.siblingNo !== 'number')) { return 'sibling no invalid'; }
        if (payload.siblings && (typeof payload.siblings !== 'number')) { return 'siblings invalid'; }
        if (payload.expectedSalary && (typeof payload.expectedSalary !== 'number')) { return 'expected salary invalid'; }

        return null;
    }

    public static formatWorkerProfile(workerProfile: WorkerProfileModel): PutWorkerProfileModel {
        const putWP: PutWorkerProfileModel = {} as PutWorkerProfileModel;

        putWP.fullName = workerProfile.fullName;
        putWP.gender = workerProfile.gender ? workerProfile.gender.toLowerCase() : null;
        putWP.birthDate = moment(workerProfile.birthDate).add(7, 'hours').utcOffset('+07:00').format();
        putWP.maritalStatus = workerProfile.maritalStatus;
        putWP.siblingNo = workerProfile.siblingNo;
        putWP.siblings = workerProfile.siblings;
        putWP.medicalRecords = workerProfile.medicalRecords;
        putWP.imageUrl = workerProfile.imageUrl;
        putWP.addressStreet = workerProfile.addressStreet;
        putWP.addressDistrict = workerProfile.addressDistrict;
        putWP.addressSubDistrict = workerProfile.addressSubDistrict;
        putWP.addressCity = workerProfile.addressCity;
        putWP.addressZip = workerProfile.addressZip;
        putWP.facebookUrl = workerProfile.facebookUrl;
        putWP.googleUrl = workerProfile.googleUrl;
        putWP.twitterUrl = workerProfile.twitterUrl;
        putWP.linkedInUrl = workerProfile.linkedInUrl;
        putWP.instagramUrl = workerProfile.instagramUrl;
        putWP.websiteUrl = workerProfile.websiteUrl;
        putWP.hasMotor = workerProfile.hasMotor;
        putWP.hasCar = workerProfile.hasCar;
        putWP.expectedSalary = workerProfile.expectedSalary;
        putWP.timePreference = workerProfile.workTimePreference;

        // Transform Skill
        const skill: string[] = [];
        if (workerProfile.preferenceSkills && workerProfile.preferenceSkills.length) {
          workerProfile.preferenceSkills.forEach((element: string) => skill.push(element.trim()) );
        }
        putWP.preferenceSkills = skill.length ? skill.join(',') : null;

        // Transform Categories
        const category: string[] = [];
        if (workerProfile.preferenceCategories && workerProfile.preferenceCategories.length) {
          workerProfile.preferenceCategories.forEach((element: string) => category.push(element.toLowerCase().trim()) );
        }
        putWP.preferenceCategories = category.length ? category.join(',') : null;

        // Transform Commuting
        const commuting: string[] = [];
        if (workerProfile.preferenceCommuting && workerProfile.preferenceCommuting.length) {
          workerProfile.preferenceCommuting.forEach((element: string) => commuting.push(element.toLowerCase().trim()) );
        }
        putWP.preferenceCommuting = commuting.length ? commuting.join(',') : null;

        // Transform Location
        const location: string[] = [];
        if (workerProfile.preferenceLocations && workerProfile.preferenceLocations.length) {
          workerProfile.preferenceLocations.forEach((element: string) => location.push(element.trim()) );
        }
        putWP.preferenceLocations = location.length ? location.join(',') : null;

        // Transform Language
        const language: string[] = [];
        if (workerProfile.preferenceLanguages && workerProfile.preferenceLanguages.length) {
          workerProfile.preferenceLanguages.forEach((element: string) => language.push(element.trim()) );
        }
        putWP.preferenceLanguages = language.length ? language.join(',') : null;

        return putWP;
    }
}
