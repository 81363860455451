import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { finalize, map, mergeMap, switchMap } from 'rxjs/operators';

import { FilterModel } from 'src/app/_class';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { ResponseApplicant, ApplicantModelV2 } from '..';
import { ManageApplicantHTTPService } from './manage-applicant-http';

import * as moment from 'moment';
moment.locale('id');

export type ResponseType = ResponseApplicant | undefined;

@Injectable({
  providedIn: 'root'
})
export class ManageApplicantService {

  // public fields
  currentApplicant$: Observable<ResponseType>;
  isLoading$: Observable<boolean>;
  isLoadingCount$: Observable<boolean>;
  isLoadingExport$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentApplicantSubject: BehaviorSubject<ResponseType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoadingCountSubject: BehaviorSubject<boolean>;
  isLoadingExportSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  // public fields mission
  missionApplicant$: Observable<boolean>;
  missionApplicantSubject: Subject<boolean>;
  missionJfApplicant$: Observable<number>;
  missionJfApplicantSubject: Subject<number>;

  constructor(
    private config: HttpConfigService,
    private applicantHttp: ManageApplicantHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoadingCountSubject = new BehaviorSubject<boolean>(false);
    this.isLoadingExportSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentApplicantSubject = new BehaviorSubject<ResponseType>(undefined);
    this.currentApplicant$ = this.currentApplicantSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingCount$ = this.isLoadingCountSubject.asObservable();
    this.isLoadingExport$ = this.isLoadingExportSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();

    this.missionApplicantSubject = new Subject<boolean>();
    this.missionApplicant$ = this.missionApplicantSubject.asObservable();
    this.missionJfApplicantSubject = new Subject<number>();
    this.missionJfApplicant$ = this.missionJfApplicantSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  getApplicant(filter: FilterModel, skipState?: boolean): Observable<ResponseType> {
    // const auth = this.authService.getAuthFromLocalStorage();
    // if (!auth || !auth.id_token || !auth.companyId) {
    //   return of(undefined);
    // }

    this.isLoadingSubject.next(true);
    return this.applicantHttp.getApplicantGOapi(filter).pipe(
      map((applicant: ResponseType) => {
        // checking applicant
        if (applicant && !skipState) {
          this.currentApplicantSubject.next(applicant);
        }
        return applicant;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  exportApplicant(companyId: number, query: string): Observable<Response> {
    this.isLoadingExportSubject.next(true);
    return this.applicantHttp.exportApplicantGOapi(companyId, query).pipe(
      finalize(() => this.isLoadingExportSubject.next(false))
    );
  }

  changeApplicantStatus(status: string, data: ApplicantModelV2[], filter: FilterModel): Observable<any> {
    this.isSubmitedSubject.next(true);
    let hasError = false;
    return from(data).pipe(
      mergeMap(async (el) => {
        try {
          await this.applicantHttp.putApplicantStatus(el.id, {status: status.toLowerCase()}).toPromise();
        } catch (error: any) {
          hasError = true;
          this.openSnackBar(error.status, error);
        }
      }),
      switchMap(() => this.getApplicant(filter)),
      map(() => hasError),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  dowloadCvApplicant(id: number, filter: FilterModel): Observable<any> {
    this.isSubmitedSubject.next(true);
    return this.applicantHttp.dowloadCvApplicant(id).pipe(
      switchMap(() => this.getApplicant(filter)),
      finalize(() => {
        // J2G-4582 - Pekara Tombol Convert di klik berkali kali
        setTimeout(() => {
          this.isSubmitedSubject.next(false);
        }, 500);
      })
    );
  }
  // End of Logic Before Hit API
}
