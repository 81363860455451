export class EnumEmployeeContractStatus {
    public static employeeContractStatusSelect: any = [
        { name: 'Intern', enum: 'intern' },
        { name: 'Permanent', enum: 'permanent' },
    ];

    public static findEmployeeStatus(status: string): any {
        return EnumEmployeeContractStatus.employeeContractStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
    }

    public static generateEmployeeStatus(status: string): string {
        const tempEmployment = EnumEmployeeContractStatus.employeeContractStatusSelect.find((fin: any) => fin.enum === status.trim().toLowerCase());
        return (tempEmployment) ? tempEmployment.name : '-';
    }
}
