import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpConfigService } from 'src/app/_service/http-config.service';
import { environment } from 'src/environments/environment';
import { PostCompanyHolidayModel } from '../..';

const API_ATTENDANCE_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class ConfigHTTPService {

  constructor(private http: HttpClient, private config: HttpConfigService) { }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message?: string): void {
    this.config.openSnackBar(status, undefined, message);
  }

  getTodayConfig(companyId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${API_ATTENDANCE_URL}/company/${companyId}/employee/${userId}/attendance_config`);
  }

  // Company Holiday
  getCompanyHoliday(companyId: number, query?: string, page?: number, size?: number): Observable<Response> {
    return this.http.get<any>(`${API_ATTENDANCE_URL}/company/${companyId}/company_holiday?${query ? query + '&' : ''}page=${page ? page : 0}&limit=${size ? size : 20}&metadata=true`);
  }

  getNationalHoliday(query?: string, page?: number, size?: number): Observable<Response> {
    return this.http.get<any>(`${API_ATTENDANCE_URL}/company/1/employee/1/national_holiday?${query ? query + '&' : ''}page=${page ? page : 0}&limit=${size ? size : 50}&metadata=true`);
  }

  postCompanyHoliday(companyId: number, data: PostCompanyHolidayModel): Observable<Response> {
    return this.http.post<Response>(`${API_ATTENDANCE_URL}/company/${companyId}/company_holiday`, data);
  }

  putCompanyHoliday(companyId: number, holidayId: number, data: PostCompanyHolidayModel): Observable<Response> {
    return this.http.put<Response>(`${API_ATTENDANCE_URL}/company/${companyId}/company_holiday/${holidayId}`, data);
  }

  deleteCompanyHoliday(companyId: number, holidayId: number): Observable<Response> {
    return this.http.delete<Response>(`${API_ATTENDANCE_URL}/company/${companyId}/company_holiday/${holidayId}`);
  }
  // End of Company Holiday
}
