import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {SessionStorage} from 'src/app/_class';
import { TagsService } from 'src/app/_service';
import { IndexAttendanceVariables } from '..';

import * as moment from 'moment';
moment.locale('id');

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private tagsService: TagsService) { }

  // Variables Initial
  initVariable(): IndexAttendanceVariables {
    const varModel = {} as IndexAttendanceVariables;

    const Session = SessionStorage.getStorageJson('attendanceTags');
    const tempUser = this.tagsService.filterSession(Session, 'user');
    // const tempTitle = this.tagsService.filterSession(Session, 'titles');
    // const tempLocation = this.tagsService.filterSession(Session, 'lokasi');
    const tempCommuting = this.tagsService.filterSession(Session, 'commuting_attendance');
    const tempDate = this.tagsService.filterSession(Session, 'date');

    varModel.tempQuery = sessionStorage.getItem('attendanceQuery');
    varModel.userPick = (tempUser.length) ? tempUser[0].value : null;
    // varModel.cityPick.setValue((tempLocation.length) ? tempLocation[0].value : null);
    varModel.commutingPick = (tempCommuting.length) ? tempCommuting[0].value : null;
    varModel.datePickBegin = (tempDate.length) ? tempDate[0].value.begin : null;
    varModel.datePickEnd = (tempDate.length) ? tempDate[0].value.end : null;
    // varModel.paginateOption.page = sessionStorage.getItem('attendancePageActive') ? +sessionStorage.getItem('attendancePageActive')! : 1;
    // varModel.paginateOption.size = sessionStorage.getItem('attendancePageSize') ? +sessionStorage.getItem('attendancePageSize')! : 20;
    varModel.sortBy = SessionStorage.getStorage('attendanceSortBy');
    varModel.sortAsc = SessionStorage.getStorageBoolean('attendanceSortAsc', 'true');
    varModel.tags = Session ? Session : [];

    return varModel;
  }
  // End of Variables Initial


  // Filter Logic
  filterService(
    userIdPick: number,
    commutingPick: FormControl,
    datePick: FormGroup,
    tags: {key: string, value: string}[]
  ): string {
    // if (this.cityPick.value) this.tags = this.tagsService.updateKey(this.tags, 'lokasi', this.cityPick);
    if (commutingPick.value) { tags = this.tagsService.updateKey(tags, 'commuting_attendance', commutingPick, 'array'); }
    if (datePick.controls.begin.value && datePick.controls.end.value) { tags = this.tagsService.updateKey(tags, 'date', datePick, 'date'); }

    const userId = userIdPick ? `user_id=${userIdPick}` : null;
    // const location = cityPick.value ? `address=${cityPick.value}` : '';
    const commutingType = (commutingPick.value && commutingPick.value.length) ? `commuting_type=${commutingPick.value}` : null;
    const startDate = (datePick.controls.begin.value && datePick.controls.end.value) ? `day_min=${moment(datePick.value.begin).format('YYYY-MM-DDT00:00:00').split('+')[0] + 'Z'}` : null;
    const endDate = (datePick.controls.begin.value && datePick.controls.end.value) ? `day_max=${moment(datePick.value.end).format('YYYY-MM-DDT23:59:59').split('+')[0] + 'Z'}` : null;

    const data = [userId, commutingType, startDate, endDate];

    // console.log(data);
    // return;

    const filteredData = data.filter(el => el);
    const tempQuery = filteredData.join('&');
    sessionStorage.setItem('attendanceQuery', tempQuery);
    sessionStorage.setItem('attendanceTags', JSON.stringify(tags));
    return tempQuery;
  }

  eventFilter(tags: {key: string, value: string}[], event?: any): string {
    let userPick = '';
    const tempUserPick = new FormControl();

    if (event.id) {
      userPick = event.fullName;
      tempUserPick.setValue(event.fullName);
      // tags = this.tagsService.updateKey(tags, 'user', tempUserPick);
    } else { // Reset Filter
      sessionStorage.removeItem('attendanceTags');
      sessionStorage.removeItem('attendanceQuery');
      sessionStorage.removeItem('attendanceSortBy');
      sessionStorage.removeItem('attendanceSortAsc');
    }
    return userPick;
  }

  removeTags(
    tags: {key: string, value: string}[],
    commutingPick: FormControl,
    tag: any,
    indexTagValue?: number): void {
      if (tag.key === 'commuting_attendance') { tags = this.tagsService.removeKey(tags, tag.key, commutingPick, 'array', indexTagValue); }
      // else if (tag.key === 'lokasi') tags = tagsService.removeKey(tags, tag.key, cityPick, 'array', indexTagValue);
      else { tags = this.tagsService.removeKey(tags, tag.key); }

      sessionStorage.setItem('attendanceTags', JSON.stringify(tags));
  }
  // End Filter Logic


  // Attendance Chart
  prepareSeries(dataWeek: any, tempSeries: {label: string, count: number}[]): any {
    dataWeek.forEach((element: any) => {

      // ? Create New Array on Series when not Existed
      const index = tempSeries.findIndex((ind: any) => moment(ind.label).format('DD MM') === moment(element.day).format('DD MM'));
      if (index === -1) { tempSeries.push({label: element.day, count: 0}); }

      // ? Update Count
      const indexDetail = tempSeries.findIndex((ind: any) => moment(ind.label).format('DD MM') === moment(element.day).format('DD MM'));
      if (indexDetail !== -1) { tempSeries[indexDetail].count++; }

    });

    return tempSeries;
  }

  generateSeries(tempSeries: {label: string, count: number}[], arrSeries: number[], arrLabels: string[]): any {
    const tempDays: number[] = [];

    // Logic Ngurutin Tanggal
    this.sortDate(tempSeries);

    tempSeries.forEach((element: any, indexSeries: number) => {
      const tempDate: any = moment(element.label).format('D');

      arrSeries.push(element.count);
      arrLabels.push(tempDate);
      tempDays.push(moment(element.label).day()); // value day() sama seperti urutan arrays 0 - 6 => 0 untuk Minggu, 6 untuk Sabtu

      // Buat Debug
      // console.log(tempDays);

      // Logic saat tanggal lompat2
      if ((tempSeries.length < 7) && (tempSeries.length === (indexSeries + 1))) {
        for (let indexOfLoop = 0; indexOfLoop < 7; indexOfLoop++) {
          if (!tempDays.includes(indexOfLoop)) {
            const labels = moment(moment(element.label).startOf('week')).add((indexOfLoop), 'days').format('D');

            arrSeries.splice(indexOfLoop, 0, 0);
            arrLabels.splice(indexOfLoop, 0, labels);
          }
        }
      }
    });

    return {
      arrSeries,
      arrLabels
    };
  }

  sortDate(data: any): void {
    data.sort((a: any, b: any) => {
      return moment(a.label).unix() - moment(b.label).unix();
    });
  }
  // End of Attendance Chart
}
