import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';

import { AuthService } from 'src/app/_service/auth.service';
import { ErrorJsonHandlingService } from 'src/app/_service/error-handling.service';
import { HttpConfigService } from 'src/app/_service/http-config.service';
import { AuthHTTPService } from 'src/app/_service/service-http';
import { AccountClass, AccountModelGoapi, AuthModelGoapi, CompanyInfoClass, PostCompanyModel } from '..';
import { SignupHTTPService } from './signup-http';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private config: HttpConfigService,
    private signupHttp: SignupHTTPService,
    private authHttp: AuthHTTPService,
    private authService: AuthService,
    private jsonHandling: ErrorJsonHandlingService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  openSnackBar(status: number, error: HttpErrorResponse) {
    this.config.openSnackBar(status, error);
  }
  openCustomBar(status: number, message: string) {
    this.config.openSnackBar(status, undefined, message);
  }

  // Logic Before Hit API
  // public method
  signupCompany(payloadAccount: AccountModelGoapi, hasAccount: boolean): Observable<any> {
    if (hasAccount) {
      const payload: AuthModelGoapi = {} as AuthModelGoapi;
      payload.username = payloadAccount.email;
      payload.password = payloadAccount.password;
      payload.rememberMe = 'false';
      
      this.isLoadingSubject.next(true);
      return this.authService.GLoginForm(payload).pipe(
        switchMap((result: any) => {
          if (result) {
            localStorage.setItem('tempEmail', payloadAccount.email);
            localStorage.setItem('tempPassword', payloadAccount.password!);
            localStorage.setItem('gid_token', result.id_token);
          }
          return this.authHttp.getUserAuthorities();
        }),
        map((result: any) => {
          if (result) {
            localStorage.setItem('registeredUserId', result.ID);
          }
          return result;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      )
    } else {
      const errorMessage = AccountClass.accountValidation(payloadAccount);
      if (errorMessage) {
        return this.jsonHandling.handleJsonError('SIGNUP -> User Registration', errorMessage, payloadAccount);
      }

      // Debugging Purpose
      // return of(undefined);

      this.isLoadingSubject.next(true);
      return this.signupHttp.accountRegister(payloadAccount).pipe(
        map((result: any) => {
          if (result) {
            localStorage.setItem('tempEmail', payloadAccount.email);
            localStorage.setItem('tempPassword', payloadAccount.password!);
            localStorage.setItem('registeredUserId', result.ID);
          }
          return result;
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    }
  }

  companyRegister(payloadCompany: PostCompanyModel): Observable<any> {
    const errorMessage = CompanyInfoClass.companyInfoValidation(payloadCompany);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('SIGNUP -> Company Registration', errorMessage, payloadCompany);
    }

    let userId = localStorage.getItem('registeredUserId')!;
    let email = localStorage.getItem('tempEmail')!;
    let password = localStorage.getItem('tempPassword')!;

    // ? Buat Menentukan Langkah Selanjutnya
    let hasLoggedIn = localStorage.getItem('gid_token') ? true : false;

    // Param Login
    const param: AuthModelGoapi = { username: email, password: password, rememberMe: 'false' };

    // Debugging Purpose
    // console.log(payloadCompany);
    // return of(undefined);

    this.isLoadingSubject.next(true);

    if (!hasLoggedIn) { // Jika User Belum Login
      return this.authService.GLoginForm(param).pipe(
        switchMap((result: any) => {
          localStorage.setItem('gid_token', result.id_token!);
  
          // Save Company Data
          return this.signupHttp.companyRegister(+userId, payloadCompany);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
    } else { // Default
      return this.signupHttp.companyRegister(+userId, payloadCompany).pipe(
        finalize(() => this.isLoadingSubject.next(false))
      );
    }
  }

  companyRegisterByAdmin(payloadCompany: PostCompanyModel): Observable<any> {
    const errorMessage = CompanyInfoClass.companyInfoValidation(payloadCompany);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('SIGNUP -> Company Registration by Admin', errorMessage, payloadCompany);
    }

    this.isLoadingSubject.next(true);
    return this.signupHttp.companyRegisterByAdmin(payloadCompany).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  // End of Logic Before Hit API
}
