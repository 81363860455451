import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, from, Observable/*, of*/} from 'rxjs';
import {finalize, map, mergeMap, switchMap} from 'rxjs/operators';

import {FilterModel, GlobalHelper, SessionStorage} from 'src/app/_class';
import {ErrorJsonHandlingService, FormService, HttpConfigService} from 'src/app/_service';
import {ResponseJobs, EnumJobCategory, PostJobModel, PostJobClass} from '..';
import {ManageJobHTTPService} from './manage-job-http';
import {JobfairJobHttpService} from '../../jobfair/services/jobfair-http/jobfair-job-http.service';

export type ResponseType = ResponseJobs | undefined;

export interface EventFilterJobModel {
  companyPick: string;
  jobPick: string;
}

@Injectable({
  providedIn: 'root'
})
export class ManageJobService {

  // public fields
  currentJobs$: Observable<ResponseType>;
  isLoading$: Observable<boolean>;
  isLoadingAutoComplete$: Observable<boolean>;
  isSubmited$: Observable<boolean>;
  currentJobsSubject: BehaviorSubject<ResponseType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoadingAutoCompleteSubject: BehaviorSubject<boolean>;
  isSubmitedSubject: BehaviorSubject<boolean>;

  // Enum
  jobCategory = EnumJobCategory.jobCategorySelect;

  constructor(
    private config: HttpConfigService,
    private formService: FormService,
    private jsonHandling: ErrorJsonHandlingService,
    private manageJobHttp: ManageJobHTTPService,
    private jobCampaignHttp: JobfairJobHttpService,
  ) {
    this.currentJobsSubject = new BehaviorSubject<ResponseType>(undefined);
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoadingAutoCompleteSubject = new BehaviorSubject<boolean>(false);
    this.isSubmitedSubject = new BehaviorSubject<boolean>(false);
    this.currentJobs$ = this.currentJobsSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingAutoComplete$ = this.isLoadingAutoCompleteSubject.asObservable();
    this.isSubmited$ = this.isSubmitedSubject.asObservable();
  }

  openSnackbar(status: number, error: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }

  customSnackbar(status: number, message: string): void {
    this.config.openSnackBar(status, undefined, message);
  }


  // Logic Before Hit API
  // public method
  getJob(filter: FilterModel): Observable<ResponseType> {
    this.isLoadingSubject.next(true);
    return this.manageJobHttp.getJobs(filter).pipe(
      map((jobs: ResponseType) => {
        // checking jobs
        if (jobs) {
          this.currentJobsSubject.next(jobs);
        }
        return jobs;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getJobDetail(jobId: number): Observable<Response> {
    this.isLoadingSubject.next(true);
    return this.manageJobHttp.getJobsDetail(jobId).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getJobsAutoComplete(
    query?: string,
    size?: number,
    skipLoading?: boolean
  ): Observable<Response> {
    if (!skipLoading) {
      this.isLoadingAutoCompleteSubject.next(true);
    }
    return this.manageJobHttp.getJobs(GlobalHelper.generateParams(query ? query : '', 1, size)).pipe(
      finalize(() => this.isLoadingAutoCompleteSubject.next(false))
    );
  }

  postJob(data: PostJobModel, campaignId?: number): Observable<Response> {
    const tempData: any = PostJobClass.formatJobValue(data, this.formService.camelCase(data.title));

    const errorMessage = PostJobClass.postJobValidation(tempData);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('MANAGE JOB -> Post Job', errorMessage, tempData);
    }

    // Debugging Purpose;
    // console.log(tempData);
    // return of(tempData);

    this.isSubmitedSubject.next(true);

    if (campaignId) { // ? Entry ke Campaign
      if (data.status === 'published') {
        return this.manageJobHttp.postJob(tempData).pipe(
          switchMap((result: any) => {
            return this.manageJobHttp.patchJob(result.id, data.status);
          }),
          switchMap((result: any) => {
            return this.jobCampaignHttp.postJobCampaign({
                jobId: result.id,
                campaignCompanyId: SessionStorage.getStorageJson('companyStorage')?.campaignCompanySelected
              },
              campaignId);
          }),
          finalize(() => this.isSubmitedSubject.next(false))
        );
      } else {
        return this.manageJobHttp.postJob(tempData).pipe(
          switchMap((result: any) => {
            return this.jobCampaignHttp.postJobCampaign({
                jobId: result.id,
                campaignCompanyId: SessionStorage.getStorageJson('companyStorage')?.campaignCompanySelected
              },
              campaignId
            );
          }),
          finalize(() => this.isSubmitedSubject.next(false))
        );
      }
    } else { // ? Post job only
      if (data.status === 'published') {
        return this.manageJobHttp.postJob(tempData).pipe(
          switchMap((result: any) => {
            return this.manageJobHttp.patchJob(result.id, data.status);
          }),
          finalize(() => this.isSubmitedSubject.next(false))
        );
      } else {
        return this.manageJobHttp.postJob(tempData).pipe(
          finalize(() => this.isSubmitedSubject.next(false))
        );
      }
    }
  }

  putJob(jobId: number, data: any): Observable<Response> {
    const tempData: any = PostJobClass.formatJobValue(data, this.formService.camelCase(data.title));

    const errorMessage = PostJobClass.postJobValidation(tempData);
    if (errorMessage) {
      return this.jsonHandling.handleJsonError('MANAGE JOB -> Edit Job', errorMessage, tempData);
    }

    // Debugging Purpose;
    // return of(tempData);

    this.isSubmitedSubject.next(true);
    return this.manageJobHttp.putJob(jobId, tempData).pipe(
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  patchJob(data: any, status: string, filter: FilterModel): Observable<ResponseType> {
    let index = 0;
    this.isSubmitedSubject.next(true);
    return from(data).pipe(
      mergeMap((el: any) => {
        index++;
        if (status.toUpperCase() === 'REPUBLISH') {
          const tempData = PostJobClass.republishJobValue(el);
          return this.manageJobHttp.republishJob(el.id, tempData);
        } else {
          return this.manageJobHttp.patchJob(el.id, status.toLowerCase());
        }
      }),
      switchMap(() => this.getJob(filter)),
      finalize(() => this.isSubmitedSubject.next(false))
    );
  }

  patchJobSingle(id: number, status: string, job: any): Observable<Response> {
    const tempData = PostJobClass.republishJobValue(job);

    this.isSubmitedSubject.next(true);
    if (status.toUpperCase() === 'REPUBLISH') {
      return this.manageJobHttp.republishJob(id, tempData).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    } else {
      return this.manageJobHttp.patchJob(id, status.toLowerCase()).pipe(
        finalize(() => this.isSubmitedSubject.next(false))
      );
    }
  }

  // End of Logic Before Hit API
}
