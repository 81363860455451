import { Pipe, PipeTransform } from '@angular/core';

import { CompanyDataModel } from 'src/app/layouts/main/pages/manage-companies';
import { JobsModel } from 'src/app/layouts/main/pages/manage-job';
import { PoiListModel } from 'src/app/layouts/main/pages/poi';
import { WorkerProfileModel } from 'src/app/layouts/main/pages/manage-applicants';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: any, type: 'job' | 'poi' | 'shift' | 'worker'): string {

    if (!value) {
      return '-';
    }

    if (type === 'job') {
      let tempValue: JobsModel = {} as JobsModel;
      tempValue = Object.assign(tempValue, value);

      const arrAddress = [
        tempValue.addressCity,
        tempValue.addressProvince
      ];
      const tempAddress = arrAddress.filter(fil => fil).length ? arrAddress.join(', ') : 'Not Set';

      if (tempAddress.includes('Daerah Khusus Ibukota')) {
        return tempAddress.replace('Daerah Khusus Ibukota', 'DKI');
      }
      return tempAddress;
    }

    if (type === 'shift') {
      let tempValue: CompanyDataModel = {} as CompanyDataModel;
      tempValue = Object.assign(tempValue, value);

      const arrAddress: any = [
        tempValue.addressStreet,
        tempValue.addressDistrict,
        tempValue.addressSubDistrict,
        tempValue.addressCity,
        tempValue.addressZip
      ];

      arrAddress.filter((fil: string) => fil);
      return arrAddress.length ? arrAddress.join(', ') : 'Not Set';
    }

    if (type === 'poi') {
      let tempValue: PoiListModel = {} as PoiListModel;
      tempValue = Object.assign(tempValue, value);

      const arrAddress: any = [
        tempValue.addressDistrict,
        tempValue.addressSubDistrict,
        tempValue.addressCity
      ];

      arrAddress.filter((fil: string) => fil);
      return arrAddress.length ? arrAddress.join(', ') : 'Not Set';
    }

    if (type === 'worker') {
      const tempValue: WorkerProfileModel = Object.assign({}, value);
      const arrAddress: any = [
        tempValue.addressStreet ? tempValue.addressStreet.replace('/[\n\r]+/g', ' ') : null,
        tempValue.addressDistrict,
        tempValue.addressSubDistrict,
        tempValue.addressCity,
        tempValue.addressZip
      ];

      arrAddress.filter((fil: string) => fil);
      return arrAddress.length ? arrAddress.join(', ') : 'Not Set';
    }

    return value;
  }

}
