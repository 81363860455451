import { CampaignModel } from '../../main/pages/campaign';

export interface AuthModelGoapi {
    password: string;
    rememberMe: string;
    username: string;
}

export interface UserLoginModel {
    resultMe: MeModel;
    isWorker: boolean;
    isCompany: boolean;
    isAdmin: boolean;
    isLender: boolean;
    arrAuthorities: any;
    companyActived: string;
    companyLogo: string;
    userId: number;
    arrRole: string[];
}

export interface CompanyLoginModel {
    companyModules: string[];
    companyStatus: boolean;
    companyRejected: boolean;
    campaign: CampaignModel | null;
}

export interface MeModel {
    ID: number;
    login: string;
    email: string;
    phone_number: string;
    activated: boolean;
    roles: string;
    referralCode: string;
    fullName: string;
    imageUrl?: string;
    gender: string;
    age: number;
    accessGoogle: boolean;
    accessFacebook: boolean;
    accessApple: boolean;
    company_roles: Companyrole[];
}

export interface Companyrole {
    company_id: number;
    company_name: string;
    image_url?: string;
    authority: string;
    status: string;
    companyStatus?: string;
}


export class AuthClass {

    static objModel = ['password', 'rememberMe', 'username'];

    public static authValidation(payload: AuthModelGoapi): string | null {
        const objPayload = Object.keys(payload);
        const objInvalid: string[] = [];


        // ? Validasi Object Key Payload
        objPayload.forEach((element) => {
            if (!AuthClass.objModel.includes(element)) { objInvalid.push(element); }
        });
        if (objInvalid.length) { return objInvalid.toString() + ' is invalid payload'; }

        // ? Validasi Mandatory Payload
        if (!payload.username || !payload.password || !payload.rememberMe) {
            return 'empty mandatory payload detected';
        }


        // ? Validasi Regex Payload
        const regexRememberMe = /^true|false$/.test(payload.rememberMe);
        if (!regexRememberMe) { return 'phone invalid'; }

        return null;
    }
}
