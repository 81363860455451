/* tslint:disable:no-non-null-assertion */
import { EnumRegex } from 'src/app/_enum';
import { EnumEmployeeGender } from '../enums/employee-gender.enum';
import { EnumEmployeeMaritalStatus } from '../enums/employee-marital-status.enum';
import { EnumEmploymeeReligion } from '../enums/employee-religion.enum';
import { EnumEmploymentType } from '../enums/employment-type.enum';
import { EmployeeProfileModel } from './employee-profile.model';

import * as moment from 'moment';
moment.locale('id');

interface PutEmployeeProfileModel {
    userId: number;
    fullName: string;
    nik: string;
    selectedBankId: number;
    shiftId: number;
    title: string;
    department: string;
    joinDate: string | null;
    endContractDate: string | null;
    religion: string;
    ktp: string | null;
    ktpAddress: string;
    npwp: string;
    bpjs: string;
    personalEmail: string;
    corporateEmail: string;
    nationality: string;
    hobby: string;
    parentSimpleAddress: string;
    homeBase: string;
    econName: string;
    econSimpleAddress: string;
    econPhone: string | null;
    econRelationship: string;
    modules: string;
    employmentType: string;
    imageUrl: string;
    gender: string;
    birthDate: string | null;
    maritalStatus: string;
    siblingNo: number;
    siblings: number;
    addressStreet: string | null;
    addressDistrict: string | null;
    addressSubDistrict: string | null;
    addressCity: string | null;
    addressZip: string | null;
    facebookUrl: string;
    googleUrl: string;
    twitterUrl: string;
    linkedInUrl: string;
    instagramUrl: string;
    websiteUrl: string;
    medicalRecords: string;
    hasCar: boolean;
    hasMotor: boolean;
}

export class EmployeeProfileClass {

    static objModel = [
        'userId', 'fullName', 'nik', 'gender', 'selectedBankId', 'shiftId', 'title', 'department', 'joinDate', 'religion',
        'birthDate', 'maritalStatus', 'siblingNo', 'siblings', 'ktp', 'ktpAddress', 'npwp', 'bpjs', 'personalEmail',
        'corporateEmail', 'nationality', 'hobby', 'parentSimpleAddress', 'homeBase', 'econName', 'econSimpleAddress', 'econPhone',
        'econRelationship', 'modules', 'employmentType', 'imageUrl', 'medicalRecords', 'addressStreet', 'addressDistrict',
        'addressSubDistrict', 'addressCity', 'addressZip', 'facebookUrl', 'googleUrl', 'twitterUrl', 'linkedInUrl', 'instagramUrl',
        'websiteUrl', 'hasMotor', 'hasCar', 'endContractDate'
    ];

    public static employeeProfileValidation(payload: PutEmployeeProfileModel): string | null {
        const objPayload = Object.keys(payload);
        const objInvalid: string[] = [];


        // ? Validasi Object Key Payload
        objPayload.forEach((element) => {
            if (!EmployeeProfileClass.objModel.includes(element)) { objInvalid.push(element); }
        });
        if (objInvalid.length) { return objInvalid.toString() + ' is invalid payload'; }


        // ? Validasi Mandatory Payload
        if (!payload.userId || !payload.employmentType) {
            let emptyField: string;
            if (!payload.userId) { emptyField = 'User ID'; }
            else { emptyField = 'Jenis Kepegawaian'; }
            return 'empty mandatory payload detected => ' + emptyField;
        }


        // ? Validasi Regex Payload
        const regexFullname = EnumRegex.parsingRegex('full_name')!.test(payload.fullName);
        const regexBoD = payload.birthDate ? EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.birthDate) : true;
        const regexJoin = payload.joinDate ? EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.joinDate) : true;
        const regexEoCD = payload.endContractDate ? EnumRegex.parsingRegex('timestamp_gmt7')!.test(payload.endContractDate) : true;
        const regexStreet = payload.addressStreet ? EnumRegex.parsingRegex('address')!.test(payload.addressStreet) : true;
        const regexCity = payload.addressCity ? EnumRegex.parsingRegex('letter_35')!.test(payload.addressCity) : true;
        const regexDistrict = payload.addressDistrict ? EnumRegex.parsingRegex('letter_25')!.test(payload.addressDistrict) : true;
        const regexSubDistrict = payload.addressSubDistrict ?
          EnumRegex.parsingRegex('alpha_sub_district_25')!.test(payload.addressSubDistrict) : true;
        const regexZip = payload.addressZip ? EnumRegex.parsingRegex('zip')!.test(payload.addressZip) : true;
        const regexZipChar = (payload.addressZip && payload.addressZip[0] !== '0');
        const regexKtp = payload.ktp ? EnumRegex.parsingRegex('ktp')!.test(payload.ktp) : true;

        if (payload.fullName && !regexFullname) { return 'full name invalid'; }
        if (payload.joinDate && !regexJoin) { return 'join date invalid'; }
        if (payload.endContractDate && !regexEoCD) { return 'end contract date invalid'; }
        if (payload.birthDate && !regexBoD) { return 'birth date invalid'; }
        if (payload.addressStreet && !regexStreet) { return 'address street invalid'; }
        if (payload.addressCity && !regexCity) { return 'address city invalid'; }
        if (payload.addressDistrict && !regexDistrict) { return 'address district invalid'; }
        if (payload.addressSubDistrict && !regexSubDistrict) { return 'address subdistrict invalid'; }
        if (payload.addressZip && (!regexZip || !regexZipChar)) { return 'address zip invalid'; }
        if (payload.ktp && (!regexKtp || payload.ktp.length !== 16)) { return 'ktp invalid'; }
        if (payload.npwp && (payload.npwp.length !== 15)) { return 'npwp invalid'; }
        if (payload.gender && !EnumEmployeeGender.findGender(payload.gender)) { return 'gender invalid'; }
        if (payload.maritalStatus && !EnumEmployeeMaritalStatus.findMaritalStatus(payload.maritalStatus)) {
          return 'marital status invalid';
        }
        if (payload.religion && !EnumEmploymeeReligion.findReligion(payload.religion)) { return 'religion invalid'; }
        if (!EnumEmploymentType.findEmployment(payload.employmentType, true)) { return 'employment invalid'; }
        if (payload.selectedBankId && (typeof payload.selectedBankId !== 'number')) { return 'selected bank invalid'; }
        if (payload.shiftId && (typeof payload.shiftId !== 'number')) { return 'shift invalid'; }
        if (payload.siblingNo && (typeof payload.siblingNo !== 'number')) { return 'sibling no invalid'; }
        if (payload.siblings && (typeof payload.siblings !== 'number')) { return 'siblings invalid'; }

        return null;
    }

    public static formatEmployeeProfile(employeeProfile: EmployeeProfileModel): PutEmployeeProfileModel {
        const putEP: PutEmployeeProfileModel = {} as PutEmployeeProfileModel;

        putEP.userId = employeeProfile.userId;
        putEP.fullName = employeeProfile.fullName;
        putEP.nik = employeeProfile.nik;
        putEP.selectedBankId = employeeProfile.selectedBankId;
        putEP.shiftId = employeeProfile.shiftId;
        putEP.bpjs = employeeProfile.bpjs;
        putEP.siblingNo = employeeProfile.siblingNo;
        putEP.siblings = employeeProfile.siblings;
        putEP.title = employeeProfile.title;
        putEP.department = employeeProfile.department;
        putEP.joinDate = employeeProfile.joinDate ?
          moment(employeeProfile.joinDate).add(7, 'hours').utcOffset('+07:00').format() : null;
        putEP.endContractDate = employeeProfile.endContractDate ?
          moment(employeeProfile.endContractDate).add(7, 'hours').utcOffset('+07:00').format() : null;
        putEP.religion = employeeProfile.religion;
        putEP.ktp = employeeProfile.ktp;
        putEP.ktpAddress = employeeProfile.ktpAddress;
        putEP.npwp = employeeProfile.npwp;
        putEP.personalEmail = employeeProfile.personalEmail;
        putEP.corporateEmail = employeeProfile.corporateEmail;
        putEP.nationality = employeeProfile.nationality;
        putEP.hobby = employeeProfile.hobby;
        putEP.parentSimpleAddress = employeeProfile.parentSimpleAddress;
        putEP.homeBase = employeeProfile.homeBase;
        putEP.econName = employeeProfile.econName;
        putEP.econSimpleAddress = employeeProfile.econSimpleAddress;
        putEP.econPhone = employeeProfile.econPhone ? (
          employeeProfile.econPhone.includes('+62') ?
            `+${employeeProfile.econPhone.split('+')[employeeProfile.econPhone.split('+').length - 1]}` :
            '+62' + employeeProfile.econPhone
        ) : null;
        putEP.econRelationship = employeeProfile.econRelationship;
        putEP.modules = employeeProfile.modules;
        putEP.employmentType = employeeProfile.employmentType;
        putEP.imageUrl = employeeProfile.imageUrl;
        putEP.gender = employeeProfile.gender;
        putEP.birthDate = employeeProfile.birthDate ? moment(employeeProfile.birthDate).add(7, 'hours').utcOffset('+07:00').format() : null;
        putEP.maritalStatus = employeeProfile.maritalStatus;
        putEP.addressStreet = employeeProfile.addressStreet;
        putEP.addressDistrict = employeeProfile.addressDistrict;
        putEP.addressSubDistrict = employeeProfile.addressSubDistrict;
        putEP.addressCity = employeeProfile.addressCity;
        putEP.addressZip = employeeProfile.addressZip;
        putEP.facebookUrl = employeeProfile.facebookUrl;
        putEP.googleUrl = employeeProfile.googleUrl;
        putEP.twitterUrl = employeeProfile.twitterUrl;
        putEP.linkedInUrl = employeeProfile.linkedInUrl;
        putEP.instagramUrl = employeeProfile.instagramUrl;
        putEP.websiteUrl = employeeProfile.websiteUrl;
        putEP.medicalRecords = employeeProfile.medicalRecords;
        putEP.hasCar = employeeProfile.hasCar;
        putEP.hasMotor = employeeProfile.hasMotor;

        return putEP;
    }
}
