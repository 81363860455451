export const environment = {
  production: false,
  staging: true,
  firebase: {
    apiKey: "AIzaSyDe-yfNUzbeSTm9VxOakUpNZzm79iAHAkY",
    authDomain: "job2go-web.firebaseapp.com",
    databaseURL: "https://job2go-web.firebaseio.com",
    projectId: "job2go-web",
    storageBucket: "job2go-web.appspot.com",
    messagingSenderId: "621667935257",
    appId: "1:621667935257:web:a54c6d848d7ac6c36cb688",
    measurementId: "G-FBHM0REQ6X"
  },
  agm: 'AIzaSyDRVNM_cQdsHhzQ8KNtU2AHDub6wRTYpZI',
  apiUrl: 'api/v3',
  apiUrlV4: 'api/v4',
  apiUrlKini: 'app/v1',
  apiUrlAts: 'headhunting/v1',
  apiKey: '8d39c658c6232f08692da0a28874bf0b1c1049ae56b3d3174500ee664ec5fc0335c23449ce5f3327dfe2db33950febe825f618fe7018dc68bdb90688607c0f90',
  baseUrl: 'https://dev-staging.job2go.net',
  baseUrlSHA: 'dev-staging.job2go.net',
  websocketUrl: 'https://dev-staging.job2go.net/api/v4',
  internal: {
    mailto: 'work.marespradana@gmail.com',
    wato: '+62896-3940-0204',
    baMandiri: 1270010258919,
    faMandiri: '127 0010 2589 19',
    bnMandiri: 'SINERGI TEKNO CIPTA',
  }
};
