import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { GlobalConstants } from 'src/app/_class';
import { HttpConfigService } from '.';

const API_UPLOAD_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  isEdit$: Observable<boolean>;
  uploadProgress$: Observable<number>;
  isEditSubject: BehaviorSubject<boolean>;
  uploadProgressSubject: BehaviorSubject<number>;

  constructor(
    private config: HttpConfigService,
    private http: HttpClient
  ) {
    this.isEditSubject = new BehaviorSubject<boolean>(false);
    this.uploadProgressSubject = new BehaviorSubject<number>(0);
    this.isEdit$ = this.isEditSubject.asObservable();
    this.uploadProgress$ = this.uploadProgressSubject.asObservable();
  }

  openSnackBar(status: number, error?: HttpErrorResponse): void {
    this.config.openSnackBar(status, error);
  }

  uploadFile(file: File, module: 'attendance' | 'motorist' | 'payroll' | 'applicant' | 'worker' | 'company' | 'kasbon' | 'kancil' | 'kini' | 'leave-approval' | 'leave-request' | 'ats'): Observable<any> {
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('userID', GlobalConstants.userId + '');
    formData.append('module', module);

    this.uploadProgressSubject.next(0);
    return this.http.post(`${API_UPLOAD_URL}/upload`, formData, {
      headers: new HttpHeaders({Authorization : 'Bearer ' + localStorage.getItem('gid_token')}),
      reportProgress: true,
      responseType: 'text',
      observe: 'events'
    }).pipe(
      finalize(() => {
        this.isEditSubject.next(false);
        this.uploadProgressSubject.next(0);
      })
    );
  }
}
