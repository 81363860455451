import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class WmtPermataService {

  constructor(private http: HttpClient) { }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
    }
    return throwError(error.status);
  }

  generateQuery(start: any, end: any) {
    let dateStartPick!: string;
    let dateEndPick: string;

    if (start) {
      dateStartPick = moment(start).format('YYYY-MM-DD');
    }
    if (end) {
      dateEndPick = moment(end).format('YYYY-MM-DD');
    } else {
      dateEndPick = '';
    }

    const dateStart = dateStartPick ? `lastModifiedDate:(>=${dateStartPick})` : null;
    const dateEnd = dateEndPick ? `lastModifiedDate:(<=${dateEndPick})` : null;

    const temp = [dateStart, dateEnd];

    return temp.filter(el => (el ? true : false) === true).join(' AND ');
  }

  getSubmission(page: number, data: any, size: number) {
    return this.http.get<any>(
      // tslint:disable-next-line: max-line-length
      `/api/_search/wmt-submission-permatas?page=${(page) ? page : 0}&query=${(data) ? data : '(*)'}&size=${(size) ? size : 20}&sort=id,desc`,
      {
        observe: 'response',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('id_token'),
        }
      }).pipe(retry(0), catchError(this.handleError));
  }

  getUserLoginVisit(id: number) {
    return this.http.get<any>(`/api/visits/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('id_token'),
        }
      }).pipe(retry(0), catchError(this.handleError));
  }

  getVisit(page: number, data: any, size: number) {
    return this.http.get<any>(
      // tslint:disable-next-line: max-line-length
      `/api/_search/visits?page=${(page) ? page : 0}&query=${(data) ? data : '(*)'}&size=${(size) ? size : 20}&sort=id,desc`,
      {
        observe: 'response',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('id_token'),
        }
      }).pipe(
        map(visits => {
          const visit = visits;
          // for (const key in visits) {
          //   if (Object.prototype.hasOwnProperty.call(visits, key)) {
          //     const element = visits[key];
          //     visit.push(element.id);
          //   }
          // }
          return visit;
        }),
        mergeMap(async visit => {
          let workerId: string;
          let visitId: string;
          const arrWorkerId = [];
          const arrVisitId = [];
          // const submissions = [];
          // console.log(visit.body, visit.headers.get('x-total-count'));

          // Mengelompokkan Id
          for (const key in visit.body) {
            if (Object.prototype.hasOwnProperty.call(visit.body, key)) {
              const element = visit.body[key];
              arrWorkerId.push(element.workerId);
              arrVisitId.push(element.id);
            }
          }
          workerId = arrWorkerId.join(' OR ');
          visitId = arrVisitId.join(' OR ');
          const profile = await this.http.get<any>(
            `/api/_search/worker-profiles?page=0&query=user.id:(${workerId})&size=${size}&sort=id,asc`,
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('id_token') } }
          ).toPromise();
          const submission = await this.http.get<any>(
            `/api/_search/wmt-submission-permatas?page=0&query=visit.id:(${visitId})&size=${size}&sort=id,desc`,
            { headers: { Authorization: 'Bearer ' + localStorage.getItem('id_token') } }
          ).toPromise();

          visit.body.forEach((element: any) => {
            const tempSubmission = submission.filter((fil: any) => fil.visitId === element.id)[0];
            if (tempSubmission) {
              delete tempSubmission.id;
              delete tempSubmission.createdDate;
              delete tempSubmission.createdBy;
              delete tempSubmission.lastModifiedDate;
              delete tempSubmission.lastModifiedBy;
              Object.assign(element, tempSubmission);
              const tempProfile = profile.filter((fil: any) => fil.userId === element.workerId).map((ele: any) => {
                return {salesName: ele.fullName};
              })[0];
              Object.assign(element, tempProfile);
            }
          });
          // console.log([]);
          return [visit.body, +visit.headers.get('x-total-count')!];
        }),
      );
  }

  updateVisit(data: any) {
    return this.http.put<Response>(`/api/visits`, data, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('id_token'),
        },
      }).pipe(retry(0), catchError(this.handleError));
  }
}
