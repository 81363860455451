export interface DanaModel {
    amount: number;
    companyId: number;
    module: string;
    totalPoint: number;
    successURL: string;
}

export interface OvoModel {
    mobilePhone: string;
    amount: number;
    companyId: number;
    module: string;
    totalPoint: number;
}

export interface VirtualAccountModel {
    bankCode: string;
    amount: number;
    companyId: number;
    module: string;
    totalPoint: number;
}

export interface TransactionModel {
    module: 'point';
    companyId: number;
    amount: number;
    totalPoint: number;
}


export class Transaction {
    static module: 'point' = 'point';
    static companyId = NaN;
    static amount = NaN;
    static totalPoint = NaN;

    static setToLocal(): void {
        sessionStorage.setItem('pointTransaction', JSON.stringify(Transaction.getTransaction()));
    }

    static removeFromLocal(): void {
        sessionStorage.removeItem('pointTransaction');
    }

    static getTransaction(): TransactionModel {
        const transaction: TransactionModel = {} as TransactionModel;
        transaction.module = Transaction.module;
        transaction.amount = Transaction.amount;
        transaction.companyId = Transaction.companyId;
        transaction.totalPoint = Transaction.totalPoint;

        return transaction;
    }

    static getFromLocal(): TransactionModel {
        let transaction: TransactionModel;

        transaction = sessionStorage.getItem('pointTransaction') ?
          // tslint:disable-next-line:no-non-null-assertion
          JSON.parse(sessionStorage.getItem('pointTransaction')!) : Transaction.getTransaction();

        return transaction;
    }
}
