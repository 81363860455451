/* tslint:disable:typedef */
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { /* Observable,  */throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SnackbarModel } from 'src/app/_model';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigService {

  hostWmt = environment.production ? 'https://client.job2go.net/' : (
    environment.staging ? 'https://client-staging.job2go.net/' : 'https://client-staging.job2go.net/'
  );
  hostWeb = environment.production ? 'https://job2go.net/' : (
    environment.staging ? 'https://web-staging.job2go.net/' : 'https://web-staging.job2go.net/'
  );
  endpointJhi = environment.production ? 'https://app-prod.job2go.net/' : (
    (environment.staging) ? 'https://app-staging.job2go.net/' : 'https://app-staging.job2go.net/'
  );
  // endpointGoapi = environment.production ? 'https://dev.job2go.net/' : (
  //   (environment.staging) ? 'https://dev-staging.job2go.net/' : 'https://dev-staging.job2go.net/'
  // );

  constructor(private snackbar: MatSnackBar) { }

  // Http Options
  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-Platform': 'web_wmt'
      })
    };
  }

  httpOptionsDefault() {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('id_token')})
    };
  }
  GHttpOptionsDefault() {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('gid_token'),
        'X-Platform': 'web_wmt'
      })
    };
  }
  GHttpOptionsXVendor(vendor: string) {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token'), 'X-Vendor': vendor}),
    };
  }
  GHttpOptionsXGandalf() {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token'), 'X-Gandalf': 'youshallnotpass'}),
    };
  }
  GHttpOptionsXPayment() {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token'), 'X-Payment': 'Midtrans_Iris'}),
    };
  }
  GHttpOptionsXSauron() {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token'), 'X-Payment': 'Midtrans_Iris', 'X-Sauron': 'Mordor'}),
    };
  }
  // GHttpOptionsXRetry() {
  //   return {
  //     headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token'), 'X-Retry': 'true'}),
  //   };
  // }
  GHttpOptionsXProvider() {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token'), 'X-Provider': 'raw'}),
    };
  }
  GHttpOptionsPayload(payload: any) {
    return {
      headers: new HttpHeaders({Authorization: 'Bearer ' + localStorage.getItem('gid_token')}),
      body: payload
    };
  }
  GHttpOptionsPdf(): any {
    return {
      headers: new HttpHeaders({Authorization : 'Bearer ' + localStorage.getItem('gid_token')}),
      responseType: 'blob',
      observe: 'response'
    };
  }

  WebSocketPathParams() {
    return `Authorization=Bearer ${localStorage.getItem('gid_token')}`;
  }
  WebSocketPathParamsXGandalf() {
    return `Authorization=Bearer ${localStorage.getItem('gid_token')}&X-Gandalf=youshallnotpass`;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError(error.status);
  }

  handleErrorReturnAll(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`
      );
    }
    return throwError(error);
  }

  // snackBarConfig(status: number, error?: HttpErrorResponse) {
  //   const config: SnackbarModel = this.generateSnackbarConfig(status, error);
  //   return config;
  // }
  // customSnackBarConfig(status: number, message?: string) {
  //   const config: SnackbarModel = this.generateSnackbarConfig(status, null, message);
  //   return config;
  // }

  openSnackBar(status: number, error?: HttpErrorResponse, message?: string, showMessage?: boolean) {
    const config: SnackbarModel = this.generateSnackbarConfig(status, error, message, !!showMessage);
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      data: config.data,
      duration: config.duration,
      panelClass: config.panelClass
    });
  }

  generateSnackbarConfig(status: number, error?: HttpErrorResponse | null, message?: string, showMessage?: boolean): any {
    const responseConfig: SnackbarModel = {} as SnackbarModel;
    const debugMode = sessionStorage.getItem('debug') ? true : (!!showMessage);
    if (status === 304 || (error?.error?.errorCode && error?.error?.errorCode === 304)) {
      responseConfig.data = { title: 'Tidak ada perubahan yang dilakukan', key: 304 };
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status === 200 || status === 201) {
      responseConfig.data = { title: message ? message : 'Sukses', key: status };
      responseConfig.panelClass = ['snackbar-success'];
    } else if (status === 400) {
      responseConfig.data = {
        title: 'Ups, Terjadi kesalahan', key: 400,
        message: error?.error.error ? error?.error.error : 'Tidak ada Pesan ditampilkan.',
        error: (!environment.production || debugMode) ? (
          error?.error.extendedMessage ? error?.error.extendedMessage : 'Unknown Error'
        ) : '',
        url: (!environment.production || debugMode) ? error?.url : ''
      };
      responseConfig.duration = (!environment.production || debugMode) ? 25000 : 5000;
      responseConfig.panelClass = ['snackbar-danger'];
    } else if (status === 401) {
      responseConfig.data = { title: 'Kunci Akses kadaluarsa, harap login ulang', key: 401 };
      responseConfig.panelClass = ['snackbar-warning'];
    } else if (status === 403) {
      responseConfig.data = { title: 'Anda tidak memiliki akses terhadap fitur ini', key: 403 };
      responseConfig.panelClass = ['snackbar-warning'];
    } else if (status === 404) {
      responseConfig.data = { title: message ? message : 'Data Tidak ditemukan', key: 404 };
      responseConfig.panelClass = ['snackbar-warning'];
    } else if (status === 405) {
      responseConfig.data = { title: 'Permintaan tidak diperbolehkan', key: 405 };
      responseConfig.panelClass = ['snackbar-warning'];
    } else if (status === 413) {
      responseConfig.data = { title: 'Ukuran file yang diupload terlalu besar', key: 413 };
      responseConfig.panelClass = ['snackbar-danger'];
    } else if (status === 415) {
      responseConfig.data = { title: 'Invalid payload', key: 415 };
      responseConfig.panelClass = ['snackbar-danger'];
    } else if (status === 500) {
      responseConfig.data = { title: 'Internal Server Error', key: 500 };
      responseConfig.duration = (!environment.production || debugMode) ? 25000 : 5000,
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status === 501) {
      responseConfig.data = { title: 'Mock Up', key: 501 };
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status === 504) {
      responseConfig.data = { title: 'Koneksi terputus, silakan refresh halaman', key: 504 };
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status === 417) {
      responseConfig.data = {
        title: message ? message : 'Client Exception Error', key: 417,
        message: error?.error.error ? error?.error.error : '',
        url: (!environment.production || debugMode) ? error?.url : ''
      };
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status === 422) {
      responseConfig.data = {
        title: 'JSON Payload Error', key: 422,
        message: error?.error.error ? error?.error.error : '',
        url: (!environment.production || debugMode) ? error?.url : ''
      };
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status && status.toString()[0] === '5') {
      responseConfig.data = {
        title: status + ' Server Errors', key: status,
        message: error?.error.error ? error?.error.error : 'Tidak ada Pesan ditampilkan.',
        error: (!environment.production || debugMode) ? (
          error?.error.extendedMessage ? error?.error.extendedMessage : 'Unknown Error'
        ) : '',
        url: (!environment.production || debugMode) ? error?.url : ''
      };
      responseConfig.duration = (!environment.production || debugMode) ? 25000 : 5000;
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status && status.toString()[0] === '4') {
      responseConfig.data = {
        title: status + ' Client Errors', key: status,
        message: error?.error.error ? error?.error.error : 'Tidak ada Pesan ditampilkan.',
        error: (!environment.production || debugMode) ? (
          error?.error.extendedMessage ? error?.error.extendedMessage : 'Unknown Error'
        ) : '',
        url: (!environment.production || debugMode) ? error?.url : ''
      };
      responseConfig.duration = (!environment.production || debugMode) ? 25000 : 5000;
      responseConfig.panelClass = ['snackbar-danger'];
    } else if (status && status.toString()[0] === '3') {
      responseConfig.data = { title: status + ' Redirects', key: status };
      responseConfig.panelClass = ['snackbar-info'];
    } else if (status && status.toString()[0] === '2') {
      responseConfig.data = { title: status + ' Berhasil di Proses', key: status };
      responseConfig.panelClass = ['snackbar-success'];
    } else if (status && status.toString()[0] === '1') {
      responseConfig.data = { title: status + ' Mohon Tunggu...', key: status };
      responseConfig.panelClass = ['snackbar-info'];
    } else {
      if (status) {
        responseConfig.data = { title: 'Internal Client Error', key: status };
        responseConfig.panelClass = ['snackbar-info'];
      }
    }

    responseConfig.duration = responseConfig.duration ? responseConfig.duration : 5000;
    return responseConfig;
  }
}
